import React, { useEffect, useState } from 'react';
import {
    ReportWidgetSettingsBuilderProps,
    SavedConfigFilterType,
} from 'waypoint-types';
import { useGridConfigSettings } from 'waypoint-hooks';
import KendoEditor from 'waypoint-react/kendo/Editor';
import { Button, Modal, Popconfirm, Tooltip } from 'antd';
import {
    SavedConfigEditor,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import theme from 'config/theme';

const MAXIMUM_LENGTH_NARRATIVE_TEMPLATE_CONTENT = 10000;

const NarrativeSettingsBuilder = ({
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [content, setContent] = useState<string>(
        currentSettings?.content ?? ''
    );
    const [oldContent, setOldContent] = useState<string>(
        currentSettings?.content ?? ''
    );

    const [enableTemplateSaveChanges, setEnableTemplateSaveChanges] =
        useState<boolean>(false);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        localConfig,
        setLocalConfig,
        localConfigIsChanged,
        savedConfigurationsData,
        onDeleteConfig,
        onUpdateConfig,
        onSaveConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.ReportNarrative);

    useEffect(() => {
        setLocalConfig({ content });
    }, [content]);

    useEffect(() => {
        setEnableTemplateSaveChanges(
            !!selectedConfiguration && localConfigIsChanged()
        );
    }, [localConfig, selectedConfiguration]);

    const onUpdateContent = (text: string) => {
        setContent(
            text.length > MAXIMUM_LENGTH_NARRATIVE_TEMPLATE_CONTENT
                ? text.slice(0, MAXIMUM_LENGTH_NARRATIVE_TEMPLATE_CONTENT - 1)
                : text
        );
    };

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['content'] !== undefined &&
                onUpdateContent(config['content']);
            setLocalConfig(config);
            return;
        }
        onUpdateContent(oldContent);
        setLocalConfig({ content: oldContent });
    }, [selectedConfiguration]);

    const buildReportWidgetSettingsInputs = () => {
        return {
            content,
        };
    };

    useEffect(() => {
        if (isModalOpen) {
            setOldContent(content);
            return;
        }
    }, [isModalOpen]);

    const onCancel = () => {
        onUpdateContent(oldContent);
        setIsModalOpen(false);
    };

    return (
        <Modal
            zIndex={5}
            open={isModalOpen}
            closable={false}
            maskClosable={false}
            width={1100}
            footer={[
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button key="back" onClick={onCancel}>
                        Cancel
                    </Button>
                    <div>
                        <Button
                            key="save"
                            onClick={() => setIsEditorOpen(true)}
                        >
                            Save Template
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={() =>
                                onSaveWidgetSettings(
                                    buildReportWidgetSettingsInputs()
                                )
                            }
                        >
                            Apply
                        </Button>
                    </div>
                </div>,
            ]}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div>
                    <h3>Narrative Widget Settings</h3>
                    <p style={{ fontStyle: 'italic' }}>
                        Pre-populate the narrative section with template text
                        that can be edited in the report
                    </p>
                </div>
                <div>
                    <SavedGridConfigSelect
                        savedConfigurationsData={savedConfigurationsData}
                        selectedConfiguration={selectedConfiguration}
                        setSelectedConfiguration={setSelectedConfiguration}
                        customMenuLabels={[
                            'Select a Narrative Template...',
                            'My Saved Templates',
                            'Shared Templates',
                        ]}
                        isNarrativeTemplate={true}
                        resetOptions={() => setSelectedConfiguration(null)}
                    />
                    <Popconfirm
                        placement="topRight"
                        title={`Are you sure you want to save changes to this template?`}
                        onConfirm={onUpdateConfig}
                        onCancel={() => {}}
                        okType="danger"
                        okText="Save Changes"
                        cancelText="Cancel"
                        disabled={!enableTemplateSaveChanges}
                    >
                        <Tooltip
                            title={
                                enableTemplateSaveChanges
                                    ? 'Save Template Changes'
                                    : null
                            }
                        >
                            <i
                                style={{
                                    color: enableTemplateSaveChanges
                                        ? theme.colors.blues.linkBlue
                                        : theme.colors.grays.disabled,
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    marginLeft: '10px',
                                }}
                                className="fa-solid fa-floppy-disk"
                            />
                        </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                        placement="topRight"
                        title={`Are you sure you want to delete this template?`}
                        onConfirm={onDeleteConfig}
                        onCancel={() => {}}
                        okType="danger"
                        okText="Delete"
                        cancelText="Cancel"
                        disabled={!selectedConfiguration}
                    >
                        <Tooltip
                            title={
                                !!selectedConfiguration
                                    ? 'Delete Template'
                                    : null
                            }
                        >
                            <i
                                style={{
                                    color: !!selectedConfiguration
                                        ? theme.colors.red
                                        : theme.colors.grays.disabled,
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    marginLeft: '10px',
                                }}
                                className="fa-solid fa-trash"
                            />
                        </Tooltip>
                    </Popconfirm>
                </div>
            </div>
            <KendoEditor
                key={`${localConfig}_${selectedConfiguration?.id ?? ''}`}
                referenceType={''}
                height={350}
                content={content}
                onChange={onUpdateContent}
                isLoading={false}
                disableImageUpload={true}
                data-test="report-narrative-editor"
            />
            {isEditorOpen && (
                <SavedConfigEditor
                    handleClose={() => setIsEditorOpen(false)}
                    onSave={onSaveConfig}
                    isAdmin={isAdmin}
                    existingConfigNames={existingConfigNames}
                    headerText={'Template'}
                />
            )}
        </Modal>
    );
};

export default NarrativeSettingsBuilder;
