import { leases as LEASES } from 'state/actionTypes';

export const loadPropertyLeaseStatistics = (data) => {
    return {
        type: LEASES.LOAD_PROPERTY_LEASE_STATISTICS,
        payload: data,
    };
};

export const loadLeases = (data) => {
    return {
        type: LEASES.LOAD_LEASES,
        payload: data,
    };
};
