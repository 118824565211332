import urlBuilder from 'utils/urlBuilder';
import { isFunction, isUndefined, get } from 'lodash';
import { clearCookie } from './cookie';
import superagent from 'superagent';
import { API_URL } from 'config/constants';

const getLoginRedirectUrl = () => {
    // Prevent re-encoding the URL again if we're on the correct page
    if (window.location.pathname === '/login/index.html') {
        return window.location.href;
    }
    const baseUrlPort = process.env.NODE_ENV === 'development' ? '3000' : '';
    const loginUrl = urlBuilder.addQuery(
        urlBuilder.locationOrigin(baseUrlPort) + '/login/index.html',
        {
            // success lets the UI render the green "You have logged out" message
            success: true,
            state: window.location.href,
        }
    );
    return loginUrl;
};

export const logoutAuth0andRedirect = () => {
    const loginUrl = getLoginRedirectUrl();
    console.debug('logoutAuth0andRedirect: loginUrl =', loginUrl);

    // Avoid stale data issues with cached client object
    window.sessionStorage.clear();

    const redirectUrl =
        'https://' +
        window.___AUTH0_DOMAIN +
        '/v2/logout?federated&client_id=' +
        window.___AUTH0_CLIENT_ID +
        '&returnTo=' +
        encodeURIComponent(loginUrl);

    window.location.replace(redirectUrl);
};

export const logoutIf401 = (error) => {
    const shouldLogoutAndRedirect = get(error, 'status') === 401;
    if (shouldLogoutAndRedirect) {
        clearCookie('laravel_session');
        clearCookie('XSRF_TOKEN');
        logoutAuth0andRedirect();
    }
    return error;
};

export function handle401() {
    return (next) => (action) => {
        const { type, status } = action;
        const isRequestFailure = type === '@@query/REQUEST_FAILURE';
        const shouldLogoutAndRedirect = isRequestFailure && status === 401;
        if (shouldLogoutAndRedirect) {
            clearCookie('laravel_session');
            clearCookie('XSRF_TOKEN');
            logoutAuth0andRedirect();
            return superagent.get(`${API_URL}/logout`).withCredentials();
        }
        return next(action);
    };
}

// TODO (Nicholas): checkSession is doing too much
//                  turn onEnter & onChange into pipelines
//                  then move GA page code our of checkSession
export function checkSession({ addIdle }) {
    return function (oldState, newState) {
        // kick user to location.origin:80 if there's no xsrf token
        if (!document.cookie.includes('laravel_session')) {
            const loginUrl = urlBuilder.addQuery(
                urlBuilder.locationOrigin() + '/login/index.html',
                {
                    state: window.location.href,
                    idle: addIdle,
                }
            );
            clearCookie('XSRF-TOKEN');
            clearCookie('laravel_session');
            window.location.replace(loginUrl);
        }

        // Google Analytics: set URL (page view hit will be sent later in the load chain)
        if (!isUndefined(window.ga)) {
            // This isFunction check handles differences in the onEnter and onChange
            // callback fn signatures
            // https://github.com/ReactTraining/react-router/blob/v2.8.1/docs/API.md#onenternextstate-replace-callback
            const state = !isFunction(newState) ? newState : oldState;
            const { pathname, search } = state.location;
            window.ga('set', 'page', pathname + search);
            window.urlChange = true;
        }
    };
}
