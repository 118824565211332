import store from 'state';
import { ClientData } from 'waypoint-types/clients/ClientData';

// @see https://stackoverflow.com/a/57894749
export function useGetClientData<K extends keyof ClientData>(
    lookup: K
): ClientData[K] {
    const clientObject: ClientData = store.getState().user.clientObj;
    return clientObject[lookup];
}
