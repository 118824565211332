import { get } from 'lodash';
import { searchAccountGraphForAccount } from 'waypoint-utils';

const searchAccountGraphForAccountName = (accountGraph, accountMappingCode) => {
    return get(
        searchAccountGraphForAccount(accountGraph, accountMappingCode),
        'name'
    );
};

export default searchAccountGraphForAccountName;
