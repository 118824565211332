import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useRef, useState } from 'react';

import { EntityReportWidgetPdfSettings } from '../../ReportUtils';
import TopTenantsCard from 'components/leases/tenants/top-tenants/TopTenantsCard';
import { DataGrid } from 'devextreme-react';

interface TopTenantsReportWidgetProps {
    widgetId: string;
    widgetType: string;
    entityCode: string;
    widgetSettings: { [key: string]: any };
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const TopTenantsReportWidget = React.forwardRef<
    PDFExportable,
    TopTenantsReportWidgetProps
>(
    (
        {
            widgetId,
            widgetType,
            entityCode,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: TopTenantsReportWidgetProps,
        ref
    ) => {
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const dataGridRef = useRef<DataGrid<any, any>>(null);
        const {
            widgetMetric,
            widgetSelectedChargeCode,
            widgetTopNTenants,
            widgetVisualOption,
        } = widgetSettings;

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;
        const chartExportId = `topTenantsChart_${widgetType}_${widgetId}`;

        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(
                widgetVisualOption === 'chart_table' ? false : true
            );

        let exportWidth = '1060px';
        if (pdfSettings?.landscape) {
            exportWidth =
                pdfSettings?.paperSize === 'Legal' ? '1900px' : '1418px';
        }

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading && isChartReadyToExport;
            },
        }));

        return (
            <div id={widgetExportId}>
                <TopTenantsCard
                    hideSelectorsFromTitle
                    entityCodes={[entityCode]}
                    widgetMetric={widgetMetric}
                    widgetSelectedChargeCode={widgetSelectedChargeCode}
                    widgetTopNTenants={widgetTopNTenants}
                    widgetVisualOption={widgetVisualOption}
                    isPDFExport={isPDFExport}
                    dataGridRef={dataGridRef}
                    setIsChartReadyToExport={
                        isPDFExport ? setIsChartReadyToExport : undefined
                    }
                    chartExportId={chartExportId}
                    gridExportId={widgetExportId}
                    exportWidth={exportWidth}
                    setIsLoading={setIsLoading}
                    isPropertyProfilePage={false}
                />
            </div>
        );
    }
);

export default TopTenantsReportWidget;
