import { get, isUndefined } from 'lodash';
import {
    FEATURE_NATIVE_ANALYTICS,
    FEATURE_PORTFOLIO_SEGMENTATION,
    FEATURE_CROSSTAB,
    FEATURE_BALANCE_SHEET,
    FEATURE_BALANCE_SHEET_OVERVIEW,
    FEATURE_TENANTS,
    FEATURE_OCCUPANCY_TREND,
    FEATURE_VARIANCE_REPORT_DASHBOARD,
    FEATURE_CAPITAL_TRACKER,
    FEATURE_REPORT_TEMPLATE,
    FEATURE_PLANNING_SECTION,
    LEASES_FEATURE_FLAG,
    FEATURE_PROPERTY_ATTRIBUTES,
    FEATURE_EXPIRATION_SCHEDULE,
    FEATURE_RENT_ROLL,
    FEATURE_UNIT_MIX,
    FEATURE_AGED_RECEIVABLES,
    FEATURE_BUDGET_UPLOAD,
    FEATURE_VALUE_AND_DEBT,
    FEATURE_OWNERSHIP_PERCENTAGE,
    FEATURE_DOCUMENTS,
} from 'config/constants';

export const selectCurrentUser = (state) => get(state, 'user');

export const selectExpenseUnits = (state) => get(state, 'user.clientObj.units');

export const selectClientId = (state) => get(state, 'user.clientObj.id');

export const selectRelatedUserTypes = (state) =>
    get(state, 'user.clientObj.relatedUserTypes');

export const selectClientConfigurations = (state) => {
    return get(state, 'user.configuration');
};

export const selectClientDashboardConfiguration = (state) => {
    return state.user?.clientObj?.config_json?.DASHBOARDS;
};

export const getAvailableYears = (state) => {
    return get(
        state,
        'user.clientObj.config_json.WAYPOINT_LEDGER_DROPDOWNS.FILTERS.year'
    );
};

export const hasNativeAnalyticsFeature = (s) =>
    get(s, `user.configuration.${FEATURE_NATIVE_ANALYTICS}`) === true ||
    isUndefined(get(s, `user.configuration.${FEATURE_NATIVE_ANALYTICS}`));

export const hasSegmentationDashboard = (state) =>
    get(state, `user.configuration.${FEATURE_PORTFOLIO_SEGMENTATION}`, false);

export const hasBalanceSheetEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_BALANCE_SHEET], false);

export const hasCrosstabEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_CROSSTAB], false);

export const hasBalanceSheetOverviewEnabled = (state) =>
    get(
        state,
        ['user', 'configuration', FEATURE_BALANCE_SHEET_OVERVIEW],
        false
    );

export const hasTenantsEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_TENANTS], false);

export const hasOccupancyTrendEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_OCCUPANCY_TREND], false);

export const hasVarianceReportDashboardEnabled = (state) =>
    get(
        state,
        ['user', 'configuration', FEATURE_VARIANCE_REPORT_DASHBOARD],
        false
    );

export const hasCapitalTrackerEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_CAPITAL_TRACKER], false);

export const hasReportTemplateEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_REPORT_TEMPLATE], false);

export const hasPlanningSectionEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_PLANNING_SECTION], true);

export const hasLeasesEnabled = (state) =>
    get(state, ['user', 'configuration', LEASES_FEATURE_FLAG], false);

export const hasAttributesEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_PROPERTY_ATTRIBUTES], false);

export const hasRentRollEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_RENT_ROLL], false);

export const hasLeasesExpirationEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_EXPIRATION_SCHEDULE], false);

export const hasUnitMixEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_UNIT_MIX], false);

export const hasAgedReceivablesEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_AGED_RECEIVABLES], false);

export const hasBudgetUploadEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_BUDGET_UPLOAD], false);

export const hasDebtAndValuePropertyInfo = (state) =>
    get(state, ['user', 'configuration', FEATURE_VALUE_AND_DEBT], false);

export const hasOwnershipPercentageEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_OWNERSHIP_PERCENTAGE], false);

export const hasDocumentsEnabled = (state) =>
    get(state, ['user', 'configuration', FEATURE_DOCUMENTS], false);

export const selectUserFeaturePermissions = (state) =>
    get(state, 'user.featurePermissions', []);
