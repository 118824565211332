import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { css, cx } from 'emotion';
import { isEmpty, omit } from 'lodash';
import { theme } from 'config/colors';
import { DASH_DASH } from 'config/constants';
import { ActionsCard, TextLoadingBar } from 'waypoint-react';
import { Card as AntDesignCard, Empty } from 'antd';

const card = css`
    display: flex;
    flex-direction: column;
    .ant-card-body {
        height: 100%;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .ant-card-head {
        min-height: 40px !important;
    }
`;

const boxShadow = css({
    boxShadow: `0px 3px 5px -5px rgba(0,0,0,0.20),
            0px 6px 10px 0px rgba(0, 0, 0, 0.04)
            `,
});

const content = css`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const subheadingStyle = css`
    height: 12px;
    color: ${theme.colors.text.normal};
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
`;

const figure = css`
    height: 100%;
    color: ${theme.colors.text.normal};
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.87px;
    line-height: 19px;
    margin-top: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const metricStyle = css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${theme.colors.text.normal};

    :empty {
        height: 23px;
    }
`;
/**
 * This is a statistics card intended to be used in dashboards. It has many render paths. Loading states change based on if the option subheading prop is passed. If an action is passed, the ActionsCard will be used and an icon will be displayed in top right corner (see ActionsCard to configure icon). If no subheading or action prop is passed, it will only display a title and content. Empty states are displayed for each element, including optional subheading.
 */
class StatisticsCard extends Component {
    renderLoadingTextBar(style) {
        return (
            <TextLoadingBar
                style={{
                    height: '12px',
                    width: '50px',
                    marginTop: '0px',
                    ...style,
                }}
            />
        );
    }

    renderEmptyContentState() {
        return (
            <Empty
                data-test-empty-content
                imageStyle={{
                    height: 30,
                }}
                description={<span />}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                }}
            />
        );
    }

    renderTitle() {
        const { title } = this.props;
        return title || DASH_DASH;
    }

    renderCardWithoutIcon() {
        return (
            <AntDesignCard
                size="small"
                {...omit(this.props, 'loading')} // subvert native AntD loading in favor of custom one
                title={this.renderTitle()}
                className={cx([card, boxShadow, this.props.className])}
            >
                <div className={content}>
                    <div className={subheadingStyle}>
                        {this.renderSubheading()}
                    </div>
                    <div className={figure}>{this.renderContent()}</div>
                </div>
            </AntDesignCard>
        );
    }

    renderSubheading() {
        const { subheading } = this.props;
        const showDisplaySubheading = this.props.hasOwnProperty('subheading');
        if (!showDisplaySubheading) {
            return <span style={{ width: '35px' }} />;
        }
        return <div data-test-subheading>{subheading || DASH_DASH}</div>;
    }

    renderActionsCard() {
        return (
            <ActionsCard
                onClick={this.props.action}
                {...omit(this.props, 'loading')} // subvert ActionsCard loading in favor of custom one
                title={this.renderTitle()}
                className={cx([card, this.props.className])}
            >
                <div className={content} id="stat_card_content">
                    <div className={subheadingStyle}>
                        {this.renderSubheading()}
                    </div>
                    <div className={figure}>{this.renderContent()}</div>
                </div>
            </ActionsCard>
        );
    }

    renderContent() {
        const { loading, value, metric } = this.props;

        if (loading) {
            return this.renderLoadingTextBar({
                marginLeft: 'auto',
                width: '75px',
            });
        }

        if (isEmpty(value)) {
            return this.renderEmptyContentState();
        }

        return (
            <div data-test-content>
                {metric ? (
                    <div className={metricStyle}> {metric} </div>
                ) : (
                    <div className={metricStyle} />
                )}
                {value}
            </div>
        );
    }

    renderLoadingCard() {
        return (
            <ActionsCard
                {...omit(this.props, 'loading')} // subvert ActionsCard loading in favor of custom one
                title={this.renderLoadingTextBar({
                    width: '100px',
                    marginTop: '5px',
                })}
                className={cx([card, this.props.className])}
                extra={<span />}
            >
                <div
                    className={content}
                    style={{ height: '100%' }}
                    id="stat_card_content"
                >
                    <div className={subheadingStyle}>
                        {this.renderLoadingTextBar({
                            width: '35px',
                            marginBottom: '5px',
                        })}
                    </div>
                    <div className={figure}>
                        {this.renderLoadingTextBar({
                            marginLeft: 'auto',
                            width: '75px',
                            marginBottom: '10px',
                        })}
                    </div>
                </div>
            </ActionsCard>
        );
    }

    render() {
        if (this.props.loading) {
            return this.renderLoadingCard();
        }
        if (!this.props.action) {
            return this.renderCardWithoutIcon();
        }
        return this.renderActionsCard();
    }
}

StatisticsCard.propTypes = {
    action: PropTypes.func,
    className: PropTypes.string,
    loading: PropTypes.bool,
    subheading: PropTypes.any,
    title: PropTypes.any,
    value: PropTypes.any,
    metric: PropTypes.any,
};

export default StatisticsCard;
