import { cloneDeep, merge, without } from 'lodash';
import {
    opportunities as TYPES,
    comments as COMMENT_TYPES,
} from 'state/actionTypes';

export const defaultState = {};

export function load(state, payload) {
    return cloneDeep(merge(state, payload));
}

function remove(state, payload) {
    const newState = cloneDeep(state);
    delete newState[payload.id];
    return newState;
}

function removeComments(state, action) {
    const { ids, commentableId } = action.payload;
    const newState = cloneDeep(state);
    const { comments } = state[commentableId];

    newState[commentableId].comments = without(comments, ...ids);

    return newState;
}

export default function reducer(opportunities = defaultState, action) {
    const { type, payload } = action;
    switch (type) {
        case TYPES.LOAD:
            return load(opportunities, payload);

        case TYPES.DELETE:
            return remove(opportunities, payload);

        case TYPES.LOAD_ATTACHMENTS:
            return payload;

        case COMMENT_TYPES.DELETE:
            if (payload.commentableType === 'Opportunity') {
                return removeComments(opportunities, action);
            }
            break;

        default:
    }
    return opportunities;
}
