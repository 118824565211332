import React, { useEffect, useState } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import ReportDetail from './ReportDetail';
import { WorkflowTabKeys, reportStatus } from './constants';
import { Button, Dropdown, Popconfirm, StepProps, Steps } from 'antd';
import { ReportBasics } from './ReportBasics';
import {
    ReportBottomNavigationCardStyle,
    ReportWorkflowTabsStyle,
} from './ReportUtils';
import ReportGrid from './ReportGrid';
import { useGetReportById } from 'waypoint-hooks';
import { useHistory } from 'react-router-dom';
import { DisabledDashboard } from 'waypoint-react';
import theme from 'config/theme';
import { fullPageDrawerZIndex } from 'components/app/layout/AppLayoutUtils';

interface ReportWorkflowContainerProps {
    reportId: string;
}

const ReportWorkflowContainer = ({
    reportId,
}: ReportWorkflowContainerProps) => {
    const history = useHistory();
    const [activeStep, setActiveStep] = useState<number>(
        WorkflowTabKeys.Details
    );
    const [currentReportId, setCurrentReportId] = useState<string>(reportId);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [workflowCompleteSteps, setWorkflowCompleteSteps] = useState<
        number[]
    >([]);

    const { data: report, isValidating } = useGetReportById(
        currentReportId,
        false
    );

    const reportIsDraft =
        !report ||
        (report?.id && report?.state.toLowerCase() === reportStatus.draft);

    useEffect(() => {
        const advanceToSetupOnEnterPage =
            report?.id && report?.name?.length && !workflowCompleteSteps.length;
        if (advanceToSetupOnEnterPage) {
            setWorkflowCompleteSteps([WorkflowTabKeys.Details]);
            setActiveStep(WorkflowTabKeys.Setup);
        }
    }, [report]);

    if (isValidating) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    const handleOpenChange = (flag: boolean) => {
        setDropdownIsOpen(flag);
    };

    const onStepChange = (current: number) => {
        setActiveStep(current);
    };

    const steps: StepProps[] = [
        {
            title: 'Details',
            disabled: false,
        },
        {
            title: 'Setup',
            disabled: !workflowCompleteSteps.includes(WorkflowTabKeys.Details),
        },
        {
            title: 'Assign',
            disabled: !workflowCompleteSteps.includes(WorkflowTabKeys.Setup),
        },
    ];

    const renderBottomNavigation = (
        onNext: () => void,
        onSave?: () => void,
        isNextDisabled?: boolean
    ): JSX.Element => {
        const handleSaveAndExit = () => {
            onSave && onSave();
            history.push('/reports/manager');
        };

        const handleSaveProgress = () => {
            onSave && onSave();
            setDropdownIsOpen(false);
        };

        const handleBack = () => setActiveStep(activeStep - 1);

        const saveDropdownMenu = (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <Button type="link" onClick={handleSaveAndExit}>
                                    Save and Exit
                                </Button>
                            ),
                        },
                        {
                            key: '2',
                            label: (
                                <Button
                                    type="link"
                                    onClick={handleSaveProgress}
                                >
                                    Save
                                </Button>
                            ),
                        },
                    ],
                }}
                trigger={['click']}
                placement="bottomLeft"
                open={dropdownIsOpen}
                onOpenChange={handleOpenChange}
            >
                <Button
                    data-test="save-dropdown-button"
                    disabled={isNextDisabled}
                >
                    Save
                </Button>
            </Dropdown>
        );

        const nextButton = (
            <Button
                type="primary"
                onClick={onNext}
                style={{
                    marginLeft: '8px',
                    backgroundColor: isNextDisabled
                        ? 'rgba(0, 0, 0, 0.04)'
                        : activeStep === WorkflowTabKeys.Assign
                          ? '#37ae9f'
                          : theme.colors.blues.activeBlue,
                    color: 'white',
                }}
                disabled={isNextDisabled}
            >
                {activeStep === WorkflowTabKeys.Assign ? 'Activate' : 'Next'}
            </Button>
        );

        const activateButton = (
            <Popconfirm
                placement="top"
                title={
                    'Activating a report will move the report status from Draft to Active and notify all assignees and reviewers. Are you sure you want to proceed?'
                }
                okType="danger"
                okText="Activate"
                onConfirm={() => {
                    onNext();
                    history.push('/reports/manager');
                }}
                cancelText="Cancel"
            >
                <Button
                    style={{
                        marginLeft: '8px',
                        backgroundColor: '#37ae9f',
                    }}
                    type="primary"
                >
                    Activate
                </Button>
            </Popconfirm>
        );

        return (
            <div className={ReportBottomNavigationCardStyle}>
                <div className="left-buttons">
                    <Popconfirm
                        placement="topRight"
                        title={
                            'Are you sure you want to leave? Any unsaved changes will be lost.'
                        }
                        okType="danger"
                        okText="Exit"
                        onConfirm={() => history.push('/reports/manager')}
                        cancelText="Cancel"
                    >
                        <Button danger>Exit</Button>
                    </Popconfirm>
                </div>
                <div className="center-buttons">
                    {activeStep > WorkflowTabKeys.Details && (
                        <Button onClick={handleBack}>Back</Button>
                    )}
                    {activeStep < WorkflowTabKeys.Assign
                        ? nextButton
                        : activateButton}
                </div>
                <div className="right-buttons">
                    {activeStep < WorkflowTabKeys.Assign && report?.id
                        ? saveDropdownMenu
                        : null}
                </div>
            </div>
        );
    };

    const stepsContent = [
        <ReportBasics
            reportId={currentReportId}
            setReportId={setCurrentReportId}
            setActiveTabKey={setActiveStep}
            setWorkflowCompleteSteps={setWorkflowCompleteSteps}
            renderWorkflowStepsNavigation={renderBottomNavigation}
        />,
        <ReportDetail
            workflowReportId={currentReportId}
            isWorkflowSteps={true}
            setWorkflowActiveTabKey={setActiveStep}
            setWorkflowCompleteSteps={setWorkflowCompleteSteps}
            renderWorkflowStepsNavigation={renderBottomNavigation}
        />,
        <ReportGrid
            isWorkflowSteps={true}
            workflowReportId={currentReportId}
            renderWorkflowStepsNavigation={renderBottomNavigation}
        />,
    ];

    return !reportIsDraft ? (
        <ReportDetail />
    ) : (
        <div className={ReportWorkflowTabsStyle}>
            <div className="report-title">
                <h2>
                    New Report Setup {report?.id ? ` > ${report.name}` : ''}
                </h2>
            </div>
            <div className="steps-container">
                <Steps
                    current={activeStep}
                    onChange={onStepChange}
                    className="steps-navigation"
                    items={steps.map(({ title, disabled }) => ({
                        title,
                        disabled,
                    }))}
                />
            </div>
            <div className="steps-scroll">
                <div className="steps-content">{stepsContent[activeStep]}</div>
            </div>
        </div>
    );
};

const mapState = (s: RootStateOrAny) => {
    const {
        routing: {
            locationBeforeTransitions: { pathname },
        },
    } = s;
    const reportId = pathname.split('/').pop();

    return {
        reportId: reportId === 'manager' ? 'create' : reportId,
    };
};

export default connect(mapState)(ReportWorkflowContainer);
