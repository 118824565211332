import store from 'state';
import { roles as userRoles } from 'config/constants';

interface userData {
    userId: number;
    clientId: number;
    isAdmin: boolean;
}
export const useGetUserData = (): userData => {
    const { id, client_id, roles } = (store.getState() as any).user.userObj;

    return {
        userId: id,
        clientId: client_id,
        isAdmin: roles?.includes(userRoles.CLIENT_ADMIN) ?? false,
    };
};
