import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { get } from 'lodash';
import theme from 'config/theme';
import { periodicityOptions } from 'components/analytics/constants';
import { AccountSelect, Modal, Select, Checkbox } from 'waypoint-react';
import { Divider, Radio, Button, Card as AntDesignCard } from 'antd';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';

const content = css`
    .ant-card-body {
        padding-top: 10px;
    }

    h3 {
        text-transform: uppercase;
        color: ${theme.colors.grays.mediumGray};
        font-weight: 400;
    }
`;

const controls = css`
    display: flex;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
`;

const subControls = css`
    display: flex;
    justify-content: space-between;
    width: 175px;
`;
// TODO: MenuModal should be a reusable component

/**
 * This is the menu modal for Balance Sheet Overview.  It helps manage ephemeral menu state. It is a controlled component that receives menu selections as values.
 * A user may choose new values in the menu, but may or may not ultimately apply them.
 * The menuValues state is clean until a new slection is made. If the user clicks the Apply button, then the menuValue state is passed to a handleApply callback passed by its parent. Otherwise, it is wiped clean when the user closes the modal.
 */

const BalanceSheetOverviewMenu = ({
    className,
    accountGraph,
    selections,
    showHideNullValuesSelector,
    showPeriodicitySelector,
    showDisplayBySelector,
    handleApply,
    handleClose,
    handleReset,
}) => {
    const [accountMappingSelection, setAccountGraphSelection] = useState(
        selections.accountMapping
    );
    const [periodicitySelection, setPeriodicitySelection] = useState(
        selections.periodicity
    );
    const [displayTypeSelection, setDisplayTypeSelection] = useState(
        selections.displayType
    );
    const [isHideNullSelection, setIsHideNullSelection] = useState(
        selections.isHideNull
    );
    const handleSubmit = () => {
        handleApply({
            accountMapping: accountMappingSelection,
            periodicity: periodicitySelection,
            isHideNull: isHideNullSelection,
            displayType: displayTypeSelection,
        });
    };
    return (
        <Modal>
            <AntDesignCard
                title="Card Settings"
                className={cx([className, content])}
            >
                <h3 style={{ marginTop: 5 }}>Account</h3>
                <AccountSelect
                    value={get(accountMappingSelection, 'code')}
                    accountGraph={accountGraph}
                    onChange={(code, name) => {
                        setAccountGraphSelection({
                            code,
                            name,
                        });
                    }}
                />
                {showDisplayBySelector && (
                    <>
                        <div style={{ marginTop: 5 }}>
                            <h3>Display By</h3>
                            <Radio.Group
                                options={displayTypeOptions}
                                value={displayTypeSelection}
                                onChange={(e) =>
                                    setDisplayTypeSelection(e.target.value)
                                }
                            />
                        </div>
                    </>
                )}
                {showPeriodicitySelector && (
                    <div style={{ marginTop: 5 }}>
                        <h3>Period</h3>
                        <Select
                            options={periodicityOptions}
                            value={periodicitySelection}
                            onChange={(value) => setPeriodicitySelection(value)}
                        />
                    </div>
                )}
                {showHideNullValuesSelector && (
                    <div style={{ marginTop: 20 }}>
                        <Checkbox
                            checked={isHideNullSelection}
                            onChange={(value) =>
                                setIsHideNullSelection(value.target.checked)
                            }
                        >
                            Hide zero accounts
                        </Checkbox>
                    </div>
                )}
                <Divider />
                <div className={controls}>
                    <div>
                        <Button onClick={handleReset}>Reset Default</Button>
                    </div>
                    <div className={subControls}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                    </div>
                </div>
            </AntDesignCard>
        </Modal>
    );
};

BalanceSheetOverviewMenu.propTypes = {
    className: PropTypes.string,
    handleApply: PropTypes.func,
    handleClose: PropTypes.func,
    handleReset: PropTypes.func,
    showDisplayBySelector: PropTypes.bool,
    selections: PropTypes.shape({
        accountMapping: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        }),
        periodicity: PropTypes.string,
    }),
};

export default BalanceSheetOverviewMenu;
