import React from 'react';
import NotificationBell from 'components/app/layout/notifications/NotificationBell';
import { useGetUserNotificationPreferences } from 'waypoint-hooks';
import { MagicBellProvider } from '@magicbell/magicbell-react';
import { UserNotificationSettings } from 'waypoint-types';
import { getUserNotificationSettings } from 'waypoint-requests';
import useSWR from 'swr';
import { Menu } from 'antd';
import { css } from 'emotion';
import theme from 'config/theme';
import { MenuSections, menuItemStyle } from '../AppLayoutUtils';
import { RouteUrls } from 'routes/RouteConstants';
import { useLocation } from 'react-router-dom';

const notificationTheme = {
    icon: { borderColor: '#ffffff', width: '24px' },
    unseenBadge: { backgroundColor: '#DF4759' },
    header: {
        backgroundColor: '#00ade2',
        textColor: '#ffffff',
        borderRadius: '16px',
        fontFamily: 'Helvetica',
    },
    footer: {
        backgroundColor: '#00ade2',
        textColor: '#ffffff',
        borderRadius: '16px',
        fontFamily: 'Helvetica',
    },
    notification: {
        default: {
            textColor: '#15091F',
            borderRadius: '12px',
            backgroundColor: '#00ade2',
            fontFamily: 'Helvetica',
        },
        unseen: {
            backgroundColor: '#00ade2',
            textColor: '#15091F',
            borderRadius: '12px',
            fontFamily: 'Helvetica',
        },
        unread: {
            backgroundColor: '#00ade2',
            textColor: '#15091F',
            borderRadius: '12px',
            fontFamily: 'Helvetica',
        },
    },
};

const notificationImages = {
    emptyInboxUrl:
        'https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg',
};

const overrideMenuItemStyle = css`
    height: 40%;
    .ant-menu-item {
        height: 40px;
        line-height: 40px;
    }
    .ant-menu-item-selected {
        background-color: unset;
        color: unset;
    }
    color: ${theme.colors.white};
    align-content: end;
`;

interface UserMenuParams {
    collapsed: boolean;
    showDrawer: (menuKey: string) => void;
}

const UserMenu = ({ collapsed, showDrawer }: UserMenuParams) => {
    const { data: notificationSettings } = useSWR<UserNotificationSettings>(
        '/users/notification-settings',
        () => getUserNotificationSettings(),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
        }
    );
    const { pathname } = useLocation();
    const {
        data: preferences,
        isLoading: isLoadingPreferences,
        mutate: preferencesMutate,
    } = useGetUserNotificationPreferences();

    return (
        <Menu mode="inline" theme="dark" className={overrideMenuItemStyle}>
            {!!notificationSettings && (
                <Menu.Item
                    key="0"
                    title={MenuSections.Notifications}
                    icon={
                        <MagicBellProvider
                            apiKey={notificationSettings.apiKey}
                            userExternalId={notificationSettings.id.toString()}
                            userKey={notificationSettings.hmac}
                            theme={notificationTheme}
                            images={notificationImages}
                            locale="en"
                        >
                            <div
                                style={{
                                    marginTop: '6px',
                                }}
                            >
                                <NotificationBell
                                    preferences={preferences}
                                    preferencesMutate={preferencesMutate}
                                    isLoadingPreferences={isLoadingPreferences}
                                    collapsed={collapsed}
                                />
                            </div>
                        </MagicBellProvider>
                    }
                />
            )}

            <Menu.Item
                key="1"
                onClick={() => showDrawer(MenuSections.Help)}
                title={MenuSections.Help}
                icon={
                    <i
                        className="fa-regular fa-question-circle fa-lg"
                        style={{
                            height: '18px',
                            maxHeight: '18px',
                            marginRight: '5px',
                            fontSize: '16px',
                        }}
                    />
                }
            >
                {MenuSections.Help}
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => showDrawer(MenuSections.Settings)}
                title={MenuSections.Settings}
                icon={
                    <i
                        className="fa-solid fa-cog fa-lg"
                        style={{
                            height: '18px',
                            maxHeight: '18px',
                            marginRight: '6px',
                            fontSize: '16px',
                        }}
                    />
                }
                style={menuItemStyle(pathname, [RouteUrls.SETTINGS], false)}
            >
                {MenuSections.Settings}
            </Menu.Item>
        </Menu>
    );
};

export default UserMenu;
