import { merge, cloneDeep } from 'lodash';
import { dashboardConfigurations as DASHBOARD_CONFIGURATIONS } from 'state/actionTypes';
/* NOTE: When adding using dashboard configurations for a new module - like leases - a new "sub" state slice will need to be created here */
const defaultState = {
    analytics: {},
    // TODO: Ranking should be a sub state slice of analytics
    ranking: {},
    // Add new module dashboard configurations here
};

const loadDashboardConfiguration = (state, payload) => {
    const { module, configuration } = payload;
    const { id } = configuration;
    return cloneDeep(merge(state, { [module]: { [id]: configuration } }));
};

export default function reducer(
    dashboardConfigurations = defaultState,
    action
) {
    const { type, payload } = action;
    switch (type) {
        case DASHBOARD_CONFIGURATIONS.LOAD:
            return loadDashboardConfiguration(dashboardConfigurations, payload);
        default:
            return dashboardConfigurations;
    }
}
