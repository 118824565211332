import { Card } from 'antd';
import { AttributesExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/AttributesExportableGrid';
import { AppFeaturePermissions, EntityAttributesFromAPI } from 'contexts';
import { DataGrid } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import { Entity } from 'waypoint-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface PropertyProfileAttributesTableParams {
    entityAttributes: EntityAttributesFromAPI | undefined;
    entity: Entity;
    dataGridRef?: React.RefObject<DataGrid<any, any>>;
    isPDFExport?: boolean;
}
export const PropertyProfileAttributesTable = ({
    entityAttributes,
    entity,
    dataGridRef,
    isPDFExport,
}: PropertyProfileAttributesTableParams) => {
    const entityAttributeRows =
        entityAttributes?.attributes.map((attr) => ({
            ...attr,
            value: entity[attr.dataIndex],
        })) ?? [];

    if (isPDFExport) {
        return <AttributesExportableGrid data={entityAttributeRows} />;
    }

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.Attributes}>
            <Card
                title="Property Attributes"
                id="property-attributes"
                style={{ margin: '20px 0' }}
                data-test="property-detail-attributes-card"
            >
                <DataGrid
                    ref={dataGridRef}
                    dataSource={entityAttributeRows}
                    data-test="property-detail-attributes-grid"
                    showBorders={true}
                    height="450px"
                >
                    <Paging enabled={false} />
                    <Column
                        caption="Attribute"
                        dataField="title"
                        defaultSortIndex={1}
                        defaultSortOrder="asc"
                    />
                    <Column dataField="value" />
                </DataGrid>
            </Card>
        </PermissionedWrapper>
    );
};
