import { API_URL } from 'config/constants';
import { convertObjKeysToSnakeCase } from 'waypoint-utils';

const updateUserEntityPermission = async (params, userId) => {
    const requestBody = convertObjKeysToSnakeCase({ ...params });
    const response = await fetch(
        `${API_URL}/entity-permissions/user/${userId}`,
        {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default updateUserEntityPermission;
