import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Select, Radio, Space } from 'antd';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { DatePicker } from 'waypoint-react';
import moment from 'moment';
import { hasDebtAndValuePropertyInfo } from 'state/user/selectors';
import { RootStateOrAny, connect } from 'react-redux';
import { propertyProfileGavSources } from 'components/propertyProfile/PropertyProfileConstants';
import { css } from 'emotion';
import { AppFeaturePermissions, usePermissions } from 'contexts';

const { Option } = Select;

const selectorsBox = css`
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-inline-start: 0;
    }
`;

const PropertyInformationSettingsBuilder = ({
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
    hasDebtAndValueEnabled,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const { featurePermissions } = usePermissions();
    const [includeMap, setIncludeMap] = useState<boolean>(
        currentSettings?.includeMap ?? true
    );
    const [includePhoto, setIncludePhoto] = useState<boolean>(
        currentSettings?.includePhoto ?? true
    );
    const [imgMapPosition, setImgMapPosition] = useState<string>(
        currentSettings?.imgMapPosition ?? 'above'
    );

    const [periodSelected, setPeriodSelected] = useState<string>(
        currentSettings?.periodSelected ?? ''
    );

    const [timing, setTiming] = useState<string>(
        currentSettings?.timing ?? 'ytd'
    );

    const [teamEnabled, setTeamEnabled] = useState<boolean>(
        currentSettings?.teamEnabled ?? false
    );

    const [debtAndValueEnabled, setDebtAndValueEnabled] = useState<boolean>(
        currentSettings?.debtAndValueEnabled ?? false
    );

    const [gavSource, setGavSource] = useState<string>(
        currentSettings?.gavSource ?? propertyProfileGavSources.BALANCE_SHEET
    );

    const [showCoreInfo, setShowCoreInfo] = useState<boolean>(
        currentSettings?.showCoreInfo ?? true
    );

    const [showPurchaseInfo, setShowPurchaseInfo] = useState<boolean>(
        currentSettings?.showPurchaseInfo ?? true
    );

    const [showSiteInfo, setShowSiteInfo] = useState<boolean>(
        currentSettings?.showSiteInfo ?? true
    );

    const [showAmenities, setShowAmenities] = useState<boolean>(
        currentSettings?.showAmenities ?? true
    );

    const [showCertifications, setShowCertifications] = useState<boolean>(
        currentSettings?.showCertifications ?? true
    );

    const buildReportWidgetSettingsInputs = () => {
        return {
            includeMap,
            includePhoto,
            imgMapPosition,
            periodSelected,
            timing,
            debtAndValueEnabled,
            teamEnabled,
            gavSource,
            showCoreInfo,
            showPurchaseInfo,
            showSiteInfo,
            showAmenities,
            showCertifications,
        };
    };

    const debtAndValuePermission = featurePermissions.includes(
        AppFeaturePermissions.PropertyValueDebt
    );

    useEffect(() => {
        if (debtAndValueEnabled === false || !debtAndValuePermission) {
            setGavSource(propertyProfileGavSources.BALANCE_SHEET);
        }
    }, [debtAndValueEnabled, debtAndValuePermission]);

    const isPositionDisabled = !includeMap && !includePhoto;

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '10px', marginTop: 36 }}>
                <Checkbox
                    checked={includeMap}
                    onChange={(ev) => setIncludeMap(ev.target.checked)}
                >
                    <span>Include Map Tile</span>
                </Checkbox>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <Checkbox
                    checked={includePhoto}
                    onChange={(ev) => setIncludePhoto(ev.target.checked)}
                >
                    <span>Include Photo</span>
                </Checkbox>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <span>Photo & Map Position:</span>
                <Select
                    value={imgMapPosition}
                    onChange={(value) => setImgMapPosition(value)}
                    disabled={isPositionDisabled}
                    style={{ marginLeft: 8, width: 120 }}
                >
                    <Option value="above">Above</Option>
                    <Option value="below">Below</Option>
                </Select>
            </div>

            <Divider />

            <p>Include Sections: </p>

            <div className={selectorsBox}>
                <Checkbox
                    value={showCoreInfo}
                    onChange={(e) => {
                        setShowCoreInfo(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={showCoreInfo}
                >
                    Core Info
                </Checkbox>

                <Checkbox
                    value={showPurchaseInfo}
                    onChange={(e) => {
                        setShowPurchaseInfo(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={showPurchaseInfo}
                >
                    Purchase Info
                </Checkbox>

                <Checkbox
                    value={showSiteInfo}
                    onChange={(e) => {
                        setShowSiteInfo(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={showSiteInfo}
                >
                    Site Info
                </Checkbox>

                <Checkbox
                    value={showAmenities}
                    onChange={(e) => {
                        setShowAmenities(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={showAmenities}
                >
                    Amenities
                </Checkbox>

                <Checkbox
                    value={showCertifications}
                    onChange={(e) => {
                        setShowCertifications(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={showCertifications}
                >
                    Certifications
                </Checkbox>
                <Checkbox
                    value={teamEnabled}
                    onChange={(e) => {
                        setTeamEnabled(e.target.checked);
                    }}
                    style={{
                        marginBottom: 12,
                    }}
                    checked={teamEnabled}
                >
                    Team
                </Checkbox>
            </div>

            {!hasDebtAndValueEnabled && debtAndValuePermission ? (
                <></>
            ) : (
                <>
                    <Checkbox
                        value={debtAndValueEnabled}
                        onChange={(e) => {
                            setDebtAndValueEnabled(e.target.checked);
                        }}
                        style={{
                            marginBottom: 12,
                        }}
                        checked={debtAndValueEnabled}
                    >
                        Debt & Value
                    </Checkbox>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 12,
                        }}
                    >
                        <span style={{ minWidth: 100 }}>As of Period:</span>

                        <DatePicker
                            disabled={!debtAndValueEnabled}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                if (e) {
                                    setPeriodSelected(e.format('YYYY-MM-DD'));
                                }
                            }}
                            picker="month"
                            placeholder="Select Period"
                            value={
                                periodSelected ? moment(periodSelected) : null
                            }
                            format={'MMMM YYYY'}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 12,
                        }}
                    >
                        <span>GAV Source:</span>
                        <Radio.Group
                            disabled={!debtAndValueEnabled}
                            onChange={(e) => setGavSource(e.target.value)}
                            value={gavSource}
                        >
                            <Space direction="horizontal">
                                <Radio
                                    value={
                                        propertyProfileGavSources.BALANCE_SHEET
                                    }
                                >
                                    Balance Sheet
                                </Radio>
                                <Radio
                                    value={propertyProfileGavSources.VALUATIONS}
                                >
                                    Valuations
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 24,
                        }}
                    >
                        <span>Debt Service Timing:</span>
                        <Radio.Group
                            disabled={!debtAndValueEnabled}
                            onChange={(e) => setTiming(e.target.value)}
                            value={timing}
                        >
                            <Space direction="horizontal">
                                <Radio value={'ytd'}>YTD</Radio>
                                <Radio value={'trailing_12'}>T-12</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </>
            )}
        </ReportWidgetSettingsModal>
    );
};

const mapState = (s: RootStateOrAny) => {
    const hasDebtAndValueEnabled = hasDebtAndValuePropertyInfo(s);

    return {
        hasDebtAndValueEnabled,
    };
};

export default connect(mapState)(PropertyInformationSettingsBuilder);
