import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import defaultStylePP from './defaultStylePP';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { css } from 'emotion';
import { roles } from 'config/constants';

const resetButtonStyle = css`
    border: none;
    outline: none;
    background: none;
`;

interface ILinkNewPageCell extends RouteComponentProps {
    datum: { id: number; roles: string[]; roleHighest: string };
    style: {
        backgroundColor: string;
        opacity: string;
        width: string;
        height: string;
        display: string;
        overflow: string;
        paddingLeft: string;
        paddingRight: string;
    };
    cellData: string[];
}

export const PermissionNewPageCell = ({
    cellData,
    datum,
    history,
    style,
    ...rest
}: ILinkNewPageCell): JSX.Element => {
    const goToPermissions = (userId: number) => {
        history.push(`/settings/admin/users/${userId}/permissions`);
    };

    if (cellData.includes(roles.CLIENT_ADMIN)) {
        return (
            <>
                <div style={style} {...rest} />
            </>
        );
    }

    return (
        <div style={style} {...rest}>
            <button
                className={resetButtonStyle}
                onClick={() => goToPermissions(datum.id)}
            >
                <i
                    style={{ color: '#00ADE2', cursor: 'pointer' }}
                    className="fa-solid fa-lock"
                />
            </button>
        </div>
    );
};

export const PermissionsCellRenderer = defaultStylePP(
    withRouter(PermissionNewPageCell)
);
export default PermissionsCellRenderer;
