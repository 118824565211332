import { css } from 'emotion';
import theme from 'config/theme';
import Button from 'antd/lib/button';
import {
    AvailabilityDateType,
    SelectedPeriodType,
} from 'components/financials/balanceSheet/types';
import React, { useState, useEffect } from 'react';
import { Badge, Checkbox, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useBalanceSheet } from 'components/financials/balanceSheet/components/BalanceSheetProvider';
import { CommentsButton } from 'waypoint-react/comment/CommentsButton';
import { useBalanceSheetSettings } from 'waypoint-hooks';
import BalanceSheetSettingsMenu from '../menu/BalanceSheetSettingsMenu';

const bannerStyle = css`
    width: 100%;
    border: none;
    box-shadow: none !important;
    display: flex;
    align-items: center;
`;

const activeSettingsButton = css`
    border-color: #424242 !important;
    background: #424242 !important;
    color: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const selectionBox = css`
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    @media (max-width: 886px) {
        margin-left: 0;
    }
`;

interface BalanceSheetSelectionBannerProps {
    dataAvailabilityDate: AvailabilityDateType;
    onReset: () => void;
    onApply: (selectedPeriod: SelectedPeriodType) => void;
    isPropertyProfile?: boolean;
    entityCodes: string[];
    isBannerCollapsed: boolean;
    isSettingsModalOpen: boolean;
    setIsSettingsModalOpen: (flag: boolean) => void;
}

const BalanceSheetSelectionBanner = ({
    dataAvailabilityDate,
    onReset,
    onApply,
    entityCodes,
    isSettingsModalOpen,
    setIsSettingsModalOpen,
}: BalanceSheetSelectionBannerProps): JSX.Element => {
    const { hideNull, setHideNull, hideNotes, setHideNotes } =
        useBalanceSheet();

    const { setPrimaryPeriod, setSecondaryPeriod } =
        useBalanceSheetSettings(entityCodes);

    const [isSettingsActive, setIsSettingsActive] = useState<boolean>(false);
    const [settingsActiveCount, setSettingsActiveCount] = useState<number>(0);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const isSingleEntity = entityCodes.length === 1;

    const onClearFilters = () => {
        setHideNull(false);
        setHideNotes(false);
        setSettingsActiveCount(0);
    };

    const handleOpenChange = (flag: boolean) => {
        setDropdownIsOpen(flag);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Checkbox
                    data-test="hide-null-button"
                    checked={hideNull}
                    onChange={() => {
                        setHideNull(!hideNull);
                        setSettingsActiveCount(
                            hideNull
                                ? settingsActiveCount - 1
                                : settingsActiveCount + 1
                        );
                    }}
                >
                    Hide Zeros/Nulls
                </Checkbox>
            ),
        },
        ...(isSingleEntity
            ? [
                  {
                      key: '2',
                      label: (
                          <Checkbox
                              data-test="hide-notes-button"
                              checked={hideNotes}
                              onChange={() => {
                                  setHideNotes(!hideNotes);
                                  setSettingsActiveCount(
                                      hideNotes
                                          ? settingsActiveCount - 1
                                          : settingsActiveCount + 1
                                  );
                              }}
                          >
                              Hide Notes
                          </Checkbox>
                      ),
                  },
              ]
            : []),
    ];

    useEffect(() => {
        onClearFilters();
    }, [entityCodes]);

    useEffect(() => {
        setPrimaryPeriod(dataAvailabilityDate.primary);
        setSecondaryPeriod(dataAvailabilityDate.secondary);
    }, [dataAvailabilityDate]);

    const hasDataAvailabilityDate = !!dataAvailabilityDate.primaryDefault;

    return (
        <div data-test="selections-banner" className={bannerStyle}>
            <BalanceSheetSettingsMenu
                isOpen={isSettingsModalOpen}
                onClose={() => setIsSettingsModalOpen(false)}
                onApply={onApply}
                onReset={onReset}
                entityCodes={entityCodes}
                dataAvailabilityDate={dataAvailabilityDate}
                onClearFilters={onClearFilters}
            />

            {hasDataAvailabilityDate && (
                <div className={selectionBox}>
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        placement="bottomLeft"
                        open={dropdownIsOpen}
                        onOpenChange={handleOpenChange}
                        arrow
                    >
                        <Button
                            data-test="quick-settings-button"
                            type="primary"
                            onClick={() => {
                                setIsSettingsActive(!isSettingsActive);
                            }}
                            className={activeSettingsButton}
                            disabled={!hasDataAvailabilityDate}
                        >
                            Quick Settings{' '}
                            <DownOutlined style={{ fontSize: '10px' }} />
                            {settingsActiveCount > 0 ? (
                                <Badge
                                    style={{
                                        backgroundColor:
                                            theme.colors.blues.primary,
                                        marginLeft: '10px',
                                    }}
                                    size="small"
                                    count={settingsActiveCount}
                                />
                            ) : null}
                        </Button>
                    </Dropdown>

                    {settingsActiveCount > 0 && (
                        <Button
                            data-test="quick-settings-button"
                            type="text"
                            style={{
                                borderLeft: '2px solid lightgray',
                                borderRadius: '0px',
                            }}
                            onClick={() => onClearFilters()}
                        >
                            Clear Settings
                        </Button>
                    )}
                </div>
            )}
            {hasDataAvailabilityDate && isSingleEntity && (
                <CommentsButton
                    buttonStyle={{
                        marginRight: '10px',
                        marginLeft: 'auto',
                    }}
                />
            )}
        </div>
    );
};

export default BalanceSheetSelectionBanner;
