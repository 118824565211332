import React, { useState } from 'react';
import { ActionsCard, DisabledDashboard } from 'waypoint-react';

import { useSelectedDataLevel, useUnitMix } from 'waypoint-hooks';
import { RecurringChargeSelect } from '../components/recurring-charge/RecurringChargeSelect';
import UnitMixTable from './UnitMixTable';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface UnitMixCardProps {
    entityCode: string;
    hidePropertyColumns?: boolean;
}

const UnitMixCard = ({ entityCode, hidePropertyColumns }: UnitMixCardProps) => {
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>([]);
    const selectedDataLevel = useSelectedDataLevel();

    const { isLoading, rentRoll, recurringChargesData, isError } = useUnitMix({
        entityCode,
        selectedDataLevel,
    });

    const onChange = (value: string[]) => {
        setSelectedChargeCode(value);
    };

    if (isError) {
        return <DisabledDashboard text={'Error loading data'} />;
    }

    if (!recurringChargesData || !rentRoll) {
        return (
            <DisabledDashboard
                text={'No bedroom/bathroom data for this property'}
            />
        );
    }

    const { summary } = recurringChargesData;

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.UnitMix}>
            <ActionsCard
                data-test="unit-mix-card"
                title={
                    <div style={{ padding: '12px 0' }}>
                        <h2>Unit Mix</h2>
                    </div>
                }
                extra={
                    <RecurringChargeSelect
                        recurringCharges={summary.buckets}
                        selectedChargeCodes={selectedChargeCode}
                        onChange={onChange}
                        style={{
                            width: 'auto',
                            minWidth: '200px',
                        }}
                    />
                }
                loadingData={isLoading}
            >
                <UnitMixTable
                    entityCode={entityCode}
                    selectedDataLevel={selectedDataLevel}
                    hidePropertyColumns={hidePropertyColumns}
                    selectedChargeCode={selectedChargeCode}
                />
            </ActionsCard>
        </PermissionedWrapper>
    );
};

export default UnitMixCard;
