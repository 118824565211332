import { snakeCase, isArray, isPlainObject } from 'lodash';

/**
 * A recursive function to transform deeply nested keys for API consumption
 * @param {Object} input - The collection to be transformed
 * @return {Object} an object preserving the shape of the input but with its keys transformed to snakecase
 */
const convertObjKeysToSnakeCase = (input) => {
    if (isPlainObject(input)) {
        return Object.entries(input).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [snakeCase(key)]: convertObjKeysToSnakeCase(value),
            }),
            {}
        );
    }

    if (isArray(input)) {
        return input.map(convertObjKeysToSnakeCase);
    }

    return input;
};

export default convertObjKeysToSnakeCase;
