import { createResponsiveStateReducer } from 'redux-responsive';
import { createPageTitle } from 'utils/pageTitleHelpers';
import { browser as TYPES } from './actionTypes';

// create redux-responsive params
const responsiveStateReducer = createResponsiveStateReducer({
    xs: 768,
    sm: 992,
    md: 1200,
    lg: 9999,
});

const defaultState = {
    sizeEvent: 0,
    topbarHeight: 50,
};

// TODO (Nicholas): Move action creators out of the reducer files
export function setPageTitle(url) {
    const action = { type: TYPES.PAGE_TITLE };
    action.pageTitle = createPageTitle(url);
    document.title = action.pageTitle;
    action.url = url;
    return action;
}

export function reducer(state, action) {
    // calculate browser window using redux-responsive
    // also initializes state if undefined
    const newState = responsiveStateReducer(state, action);

    // oldState will avoid state is undefined errors
    const oldState = state || defaultState;

    if (newState === state) {
        // act like regular reducer if no redux-responsive
        switch (action.type) {
            case TYPES.SIZE_EVENT:
                return Object.assign({}, state, {
                    sizeEvent: oldState.sizeEvent + 1,
                });

            case TYPES.TOPBAR_HEIGHT:
                if (action.payload !== oldState.topbarHeight) {
                    return Object.assign({}, state, {
                        topbarHeight: action.payload,
                        sizeEvent: oldState.sizeEvent + 1,
                    });
                }
                return state;
            case TYPES.PAGE_TITLE:
                return Object.assign({}, state, {
                    pageTitle: action.pageTitle,
                    currentUrl: action.url,
                });
            default:
                return state;
        }
    } else {
        // if redux-responsive was run...
        // copy over additional keys and increase sizeEvent
        return Object.assign({}, oldState, newState, {
            sizeEvent: oldState.sizeEvent + 1,
        });
    }
}
