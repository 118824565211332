import { message } from 'antd';
import { useEffect, useState } from 'react';
import {
    SavedConfigFilterType,
    SavedConfiguration,
    SavedConfigurationObject,
} from 'waypoint-types';
import { useGetSavedConfigurations } from './data-access/useGetSavedConfigurations';
import { useGetUserData } from 'waypoint-hooks';
import { isEqual, mapKeys } from 'lodash';
import { createUUID } from 'waypoint-utils';
import {
    createSavedConfiguration,
    deleteGlobalFilter,
    updateSavedConfiguration,
} from 'waypoint-requests';
import { stringSort } from 'utils/tables/sorters';
import { FIELD_PROPERTY } from 'components/financials/crosstab/CrosstabConstants';
import { Field } from 'devextreme/ui/pivot_grid/data_source';
import { CrosstabExpandedListElement } from 'components/financials/crosstab/CrosstabTypes';

export const useGridConfigSettings = (filterType: SavedConfigFilterType) => {
    const [selectedConfiguration, setSelectedConfiguration] =
        useState<SavedConfiguration | null>(null);
    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);
    const [gridConfig, setGridConfig] = useState<{ [x: string]: any } | null>(
        null
    );
    const [localConfig, setLocalConfig] = useState<{ [x: string]: any } | null>(
        null
    );
    const [defaultConfig, setDefaultConfig] =
        useState<SavedConfigurationObject | null>(null);
    const [saveConfigDisabled, setSaveConfigDisabled] = useState<boolean>(true);
    const [configKey, setConfigKey] = useState<string | null>(null);

    const { userId, clientId, isAdmin } = useGetUserData();
    const { data: savedConfigurationsData, mutate: mutateSavedConfigurations } =
        useGetSavedConfigurations(userId, clientId, filterType);

    const crosstabFieldsAreChanged = (crosstabSavedGridConfig: {
        [x: string]: any;
    }) => {
        // make sure all fields, even those that are undefined, are counted in the config comparison
        const fieldsBaseObject: { [x: string]: any } = {
            area: undefined,
            areaIndex: undefined,
            dataField: undefined,
            expanded: undefined,
            filterType: undefined,
            filterValues: undefined,
            name: undefined,
            sortBy: undefined,
            sortBySummaryField: undefined,
            sortBySummaryPath: undefined,
            sortOrder: undefined,
            summaryDisplayMode: undefined,
            summaryType: undefined,
        };
        const crosstabFields = (crosstabSavedGridConfig['fields'] ?? [])
            .map((field: { [x: string]: any }, index: number) => {
                const newField = {
                    ...fieldsBaseObject,
                    ...field,
                };
                // handle some special cases, either due to our custom logic or from devextreme wonkiness
                newField['expanded'] = gridConfig?.fields[index]['expanded'];
                if (
                    gridConfig?.fields[index]['sortOrder'] === '' &&
                    !newField['sortOrder']
                ) {
                    newField['sortOrder'] = '';
                }
                if (
                    gridConfig?.fields[index]['sortOrder'] === 'asc' &&
                    !newField['sortOrder']
                ) {
                    newField['sortOrder'] = 'asc';
                }
                if (
                    !gridConfig?.fields[index]['summaryDisplayMode'] &&
                    !newField['summaryDisplayMode']
                ) {
                    newField['summaryDisplayMode'] =
                        gridConfig?.fields[index]['summaryDisplayMode'];
                }
                if (
                    gridConfig?.fields[index]['summaryType'] === '' &&
                    !newField['summaryType']
                ) {
                    newField['summaryType'] = '';
                }
                if (newField['dataField'] === undefined) {
                    newField['filterType'] =
                        gridConfig?.fields[index]['filterType'];
                    newField['filterValues'] =
                        gridConfig?.fields[index]['filterValues'];
                }
                return newField;
            })
            .filter((field: { [x: string]: any }) => !!field);

        const gridConfigFields = (gridConfig?.fields ?? [])
            .map((field: { [x: string]: any }) => {
                return {
                    ...fieldsBaseObject,
                    ...field,
                };
            })
            .filter((field: { [x: string]: any }) => !!field);

        const fullSelectedGridConfig: { [x: string]: any } = {
            ...crosstabSavedGridConfig,
            fields: crosstabFields,
        };

        // devextreme sometimes adds hash objects to these arrays,
        // which we don't want to include in the equality check
        const expandedArraysEqualityCheck = (expandedPathField: string) => {
            return !isEqual(
                fullSelectedGridConfig[expandedPathField]?.filter(
                    (path: CrosstabExpandedListElement) => Array.isArray(path)
                ),
                (gridConfig ? gridConfig[expandedPathField] : [])?.filter(
                    (path: CrosstabExpandedListElement) => Array.isArray(path)
                )
            );
        };

        return (
            !isEqual(gridConfig, {}) &&
            (!isEqual(fullSelectedGridConfig.fields, gridConfigFields) ||
                expandedArraysEqualityCheck('rowExpandedPaths') ||
                expandedArraysEqualityCheck('columnExpandedPaths'))
        );
    };

    const gridConfigIsChanged = () => {
        /*
        addressing a bug where performance overview configs were being always 
        saved with 'acquisitiondate' as the column name for the selected attribute,
        regardless of which attribute was selected; for the equality check, replace it
        with the selected attribute key from the local config attribute selection
        */
        if (
            filterType === SavedConfigFilterType.PerformanceOverview &&
            selectedConfiguration?.filters_json?.grid_config?.columns &&
            selectedConfiguration?.filters_json?.local_config
                ?.attributeSelection
        ) {
            const columnsWithCorrectedAttributeName = mapKeys(
                selectedConfiguration?.filters_json?.grid_config?.columns,
                (_: null, k: string) => {
                    if (k === 'acquisitiondate') {
                        return selectedConfiguration.filters_json.local_config
                            .attributeSelection.key;
                    }
                    return k;
                }
            );
            const gridConfigForComparison = {
                ...selectedConfiguration.filters_json.grid_config,
                columns: columnsWithCorrectedAttributeName,
            };
            return !isEqual(gridConfigForComparison, gridConfig);
        }

        if (
            filterType === SavedConfigFilterType.Crosstab &&
            selectedConfiguration?.filters_json?.grid_config?.fields
        ) {
            return crosstabFieldsAreChanged(
                selectedConfiguration.filters_json.grid_config
            );
        }

        return !isEqual(
            selectedConfiguration?.filters_json?.grid_config,
            gridConfig
        );
    };

    const localConfigIsChanged = () => {
        if (
            filterType === SavedConfigFilterType.CapitalProjects &&
            selectedConfiguration?.filters_json?.local_config?.columnYears &&
            selectedConfiguration?.filters_json?.local_config?.yearsSelected ===
                undefined
        ) {
            // we were using the wrong field for saving selected years, thus this check
            const configWithoutColumnYears = mapKeys(
                selectedConfiguration?.filters_json?.local_config,
                (_: null, k: string) => {
                    if (k === 'columnYears') {
                        return 'yearsSelected';
                    }
                    return k;
                }
            );
            // no actual active customers have saved custom year ranges in prod configs
            // (actually no active customers have any saved configs for capital projects as of now)
            // so we can safely set this to an empty array
            configWithoutColumnYears['yearsSelected'] = [];
            return !isEqual(configWithoutColumnYears, localConfig);
        }

        return !isEqual(
            selectedConfiguration?.filters_json?.local_config,
            localConfig
        );
    };

    useEffect(() => {
        setSaveConfigDisabled(true);
        if (!gridConfig || !localConfig) {
            return;
        }
        if (!defaultConfig) {
            setDefaultConfig({
                grid_config: gridConfig,
                local_config: localConfig,
            });
            return;
        }

        if (!selectedConfiguration) {
            if (filterType === SavedConfigFilterType.Crosstab) {
                if (
                    crosstabFieldsAreChanged(defaultConfig?.grid_config) ||
                    !isEqual(defaultConfig.local_config, localConfig)
                ) {
                    setSaveConfigDisabled(false);
                }
                return;
            }

            if (
                !isEqual(defaultConfig.grid_config, gridConfig) ||
                !isEqual(defaultConfig.local_config, localConfig)
            ) {
                setSaveConfigDisabled(false);
            }
            return;
        }

        if (gridConfigIsChanged() || localConfigIsChanged()) {
            setSaveConfigDisabled(false);
        }
    }, [gridConfig, localConfig]);

    const onSetSelectedConfiguration = (config: SavedConfiguration | null) => {
        setSelectedConfiguration(config);

        setGridConfig(null);
        setLocalConfig(null);
    };

    const resetSelectedConfiguration = () => {
        onSetSelectedConfiguration(selectedConfiguration);
        setConfigKey(createUUID());
    };

    const onSaveConfig = async (name: string, reference_type: string) => {
        const clearedGridConfig: { [x: string]: any } | null =
            getGridConfigWithoutConflictingFilteringProps();

        const newConfigParams = {
            reference_type,
            name,
            filters_json: {
                grid_config: clearedGridConfig ?? {},
                local_config: localConfig ?? {},
            },
            filter_type: filterType,
        };

        const updatedConfigurationData = savedConfigurationsData
            ? [...savedConfigurationsData]
            : [];

        await mutateSavedConfigurations(
            async () => {
                try {
                    const savedConfig =
                        await createSavedConfiguration(newConfigParams);
                    message.success('Successfully saved configuration!');
                    updatedConfigurationData.push(savedConfig);
                    setSelectedConfiguration(savedConfig);
                } catch {
                    message.error('Failed to save configuration');
                } finally {
                    setIsEditorOpen(false);
                }
                return updatedConfigurationData.sort(
                    (a: SavedConfiguration, b: SavedConfiguration) =>
                        stringSort(b.name, a.name)
                );
            },
            {
                optimisticData: updatedConfigurationData,
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
            }
        );
    };

    const onUpdateConfig = async () => {
        if (!selectedConfiguration) {
            return;
        }

        const clearedGridConfig: { [x: string]: any } | null =
            getGridConfigWithoutConflictingFilteringProps();

        const updatedConfigParams = {
            id: selectedConfiguration.id,
            name: selectedConfiguration.name,
            filters_json: {
                grid_config: clearedGridConfig ?? {},
                local_config: localConfig ?? {},
            },
            reference_type: selectedConfiguration.reference_type,
        };

        const updatedConfigurationData = (
            savedConfigurationsData ? [...savedConfigurationsData] : []
        ).filter((config) => config.id !== selectedConfiguration.id);

        await mutateSavedConfigurations(
            async () => {
                try {
                    const updatedConfig = (await updateSavedConfiguration(
                        updatedConfigParams
                    )) as SavedConfiguration;
                    message.success('Successfully updated configuration!');
                    updatedConfigurationData.push(updatedConfig);
                    setSelectedConfiguration(updatedConfig);
                    setConfigKey(createUUID());
                } catch {
                    message.error('Failed to update configuration');
                } finally {
                    setIsEditorOpen(false);
                }
                return updatedConfigurationData;
            },
            {
                optimisticData: updatedConfigurationData,
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
            }
        );
    };

    const getGridConfigWithoutConflictingFilteringProps = (): {
        [x: string]: any;
    } | null => {
        if (!gridConfig) {
            return null;
        }

        if (!gridConfig.fields) {
            return gridConfig;
        }

        const clearedGridConfig = { ...gridConfig };
        // do not save any filtered month selected since that could conflict with date range selector
        const monthFieldIndex = clearedGridConfig.fields.findIndex(
            (field: Field) =>
                field?.area === 'column' &&
                !field.dataField && // month filter does not have a datafield name
                (!field.filterValues || // include all sets value to null
                    field.filterValues?.every(
                        (value: any) =>
                            new Date(value)?.getTime() !== Number.NaN
                    ))
        );

        if (clearedGridConfig.fields[monthFieldIndex]) {
            clearedGridConfig.fields[monthFieldIndex].filterType = 'include';
            clearedGridConfig.fields[monthFieldIndex].filterValues = [];
        }

        // do not save any filtered property since that could conflict with our property filter
        const propertyIndex = clearedGridConfig.fields?.findIndex(
            (field: Field) => field.dataField === FIELD_PROPERTY
        );

        if (clearedGridConfig.fields[propertyIndex]) {
            clearedGridConfig.fields[propertyIndex].filterType = 'include';
            clearedGridConfig.fields[propertyIndex].filterValues = [];
        }

        return clearedGridConfig;
    };

    const onDeleteConfig = async () => {
        if (!selectedConfiguration) {
            return;
        }
        const updatedConfigurationData = (
            savedConfigurationsData ? [...savedConfigurationsData] : []
        ).filter((config) => config.id !== selectedConfiguration.id);
        await mutateSavedConfigurations(
            async () => {
                try {
                    await deleteGlobalFilter(selectedConfiguration?.id, false);
                    message.success('Successfully deleted configuration!');
                    onSetSelectedConfiguration(null);
                } catch {
                    message.error('Failed to delete configuration');
                } finally {
                    setIsEditorOpen(false);
                }
                return updatedConfigurationData;
            },
            {
                optimisticData: updatedConfigurationData,
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
            }
        );
    };

    const existingConfigNames =
        savedConfigurationsData?.map((config) => config.name) ?? [];

    return {
        selectedConfiguration,
        setSelectedConfiguration: onSetSelectedConfiguration,
        setSaveConfigDisabled,
        defaultConfig,
        setDefaultConfig,
        gridConfig,
        setGridConfig,
        localConfig,
        setLocalConfig,
        localConfigIsChanged,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    };
};
