import { sanitize } from 'dompurify';

// we need this attribute for images to be requested by CORS in exported pdfs
const appendCloudImagesCrossOrigin = (html: string) => {
    const cloudImagesRegex = /<img([^>]*\bid="[^"]*"[^>]*)>/g;
    return html.replace(
        cloudImagesRegex,
        '<img crossorigin="use-credentials"$1>'
    );
};

export const sanitizeAndFormatHTML = (
    isPDFExport: boolean,
    content: string
) => {
    const contentToSanitize = isPDFExport
        ? appendCloudImagesCrossOrigin(content)
        : content;
    return sanitize(contentToSanitize, {
        ADD_ATTR: isPDFExport ? ['target', 'crossorigin'] : ['target'],
    });
};
