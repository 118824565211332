import { TypeOfContractsView } from 'components/contracts/interfaces';
import { Dictionary } from 'ts-essentials';
import {
    Contract,
    ContractsWithVendorAndServiceTypeOptions,
    PropertyDetailsProps,
} from 'waypoint-types';
import { safeDivision } from 'waypoint-utils';
import {
    buildWeightedAverageDevExtremeConfig,
    CalculateCustomSummaryOptions,
} from 'waypoint-utils/dev-extreme/weighted-averages';
import moment from 'moment/moment';

type CustomSummaryContractProperties = {
    annual_cost_rsf: Contract['annual_cost_rsf'];
    total_rentable_sq_ft_per_property: Contract['total_rentable_sq_ft_per_property'];
    entity_code: Contract['entity_code'];
};

export const isPropertyProfilePage = (typeOfView: TypeOfContractsView) =>
    typeOfView === TypeOfContractsView.PropertyProfilePage;
export const isMultiplePropertiesPage = (typeOfView: TypeOfContractsView) =>
    typeOfView === TypeOfContractsView.MultipleProperties;
export const getContractsData = (
    leasesPerformanceData?: PropertyDetailsProps[],
    contractsData?: ContractsWithVendorAndServiceTypeOptions
) => {
    if (leasesPerformanceData) {
        const allPropertyData = leasesPerformanceData.reduce((acc, lpd) => {
            acc[lpd.entity_code] = lpd;
            return acc;
        }, {} as Dictionary<PropertyDetailsProps>);
        contractsData?.contracts.forEach((contract) => {
            const entityCode = contract.entity_code;
            const propertyData = allPropertyData[entityCode];
            const propertyName = propertyData?.property_name ?? '';
            contract.total_rentable_sq_ft_per_property =
                propertyData?.rentable_sq_ft ?? 0;
            contract.annual_cost_rsf = contract.annual_cost
                ? safeDivision(
                      contract.annual_cost,
                      contract.total_rentable_sq_ft_per_property
                  )
                : null;
            if (propertyName) {
                contract.property = {
                    name: propertyName,
                    code: entityCode,
                };
            }
        });
    }
    return contractsData;
};

const { calculateCustomSummary: weightedAnnualCostRSFCalculation } =
    buildWeightedAverageDevExtremeConfig(
        [
            {
                metricKey: 'annual_cost_rsf',
                scaleKey: 'total_rentable_sq_ft_per_property',
                uniqueKeys: [
                    'entity_code',
                    'total_rentable_sq_ft_per_property',
                ],
            },
        ],
        {
            displayFormat: '{0}',
            valueFormat: { type: 'currency', precision: 2 },
        }
    );

export const calculateCustomSummary = (
    options: CalculateCustomSummaryOptions<CustomSummaryContractProperties, any>
) => {
    if (options.name === 'annual_cost_rsf') {
        weightedAnnualCostRSFCalculation(options);
    } else if (options.name === 'total_rentable_sq_ft_per_property') {
        if (options.summaryProcess === 'start') {
            options.totalValue = {
                prevValue: 0,
                uniqueRSF: new Set<string>(),
            };
        }
        if (options.summaryProcess === 'calculate') {
            const uniqueRSFKey = `${options.value.entity_code}_${options.value.total_rentable_sq_ft_per_property}`;
            if (!options.totalValue.uniqueRSF.has(uniqueRSFKey)) {
                options.totalValue.prevValue +=
                    options.value.total_rentable_sq_ft_per_property;
            }
            options.totalValue.uniqueRSF.add(uniqueRSFKey);
        }
        if (options.summaryProcess === 'finalize') {
            options.totalValue = options.totalValue.prevValue;
        }
    }
};

export const formatBooleanValues = ({ value }: { value: boolean }) =>
    value ? 'Yes' : 'No';

export const formatDateValues = ({ value }: { value: string }) => {
    const date = moment.utc(value).format('MM/DD/YYYY');
    if (date !== 'Invalid date') {
        return moment.utc(value).format('MM/DD/YYYY');
    }
    return '';
};

export const formatUpdatedRowDate = (date: Date | null) => {
    if (!date) {
        return null;
    }
    const dateStr = moment.utc(date).format('MM/DD/YYYY');
    const splitDate = dateStr.split('/');
    const fixedFormatDate = `${splitDate[0]}/${
        splitDate[1]
    }/${splitDate[2].substring(2)}`;
    return moment.utc(fixedFormatDate).format('MM/DD/YYYY');
};

export const validateEditRowDate = ({ value: date }: { value: Date }) => {
    if (!date) {
        return true;
    }

    const dateStr = moment.utc(date).format('MM/DD/YYYY');

    if (!moment(dateStr).isValid()) {
        return false;
    }

    const splitDate = dateStr.split('/');
    const year = splitDate[2].substring(0, 2);

    return moment(dateStr).isValid() && year !== '00';
};
