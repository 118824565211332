import React from 'react';
import { Modal } from 'antd';
import { COMPONENTS_LIST } from 'components/reports/constants';

interface ReportWidgetModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    settingsJsonBuilder: () => { [key: string]: any };
    onSaveWidgetSettings: (settings: { [key: string]: any }) => void;
    widgetType: string;
    children: JSX.Element | JSX.Element[];
    saveDisabled?: boolean;
    width?: number;
}

const ReportWidgetSettingsModal = ({
    isModalOpen,
    setIsModalOpen,
    settingsJsonBuilder,
    onSaveWidgetSettings,
    widgetType,
    children,
    saveDisabled = false,
    width,
}: ReportWidgetModalProps): JSX.Element => {
    const getWidgetSettingsTitle = () => {
        const widgetTypeLabel =
            COMPONENTS_LIST.find((c) => c.value === widgetType)?.label ?? '';
        return <h3>{`${widgetTypeLabel} Widget Settings`}</h3>;
    };
    return (
        <Modal
            data-test="report-widget-modal"
            centered={true}
            width="max-content"
            open={isModalOpen || false}
            closable={false}
            maskClosable={false}
            forceRender
            okText={'Save'}
            okButtonProps={saveDisabled ? { disabled: true } : {}}
            onOk={() => onSaveWidgetSettings(settingsJsonBuilder())}
            onCancel={() => setIsModalOpen(false)}
        >
            {getWidgetSettingsTitle()}
            <div style={{ marginLeft: '25px', marginRight: '25px' }}>
                {children}
            </div>
        </Modal>
    );
};

export default ReportWidgetSettingsModal;
