import React, { useState } from 'react';
import { Button, Dropdown, Popconfirm } from 'antd';
import { css } from 'emotion';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { DISABLED_GRAY } from 'config/colors';

const greyedOutListItem = css`
    color: ${DISABLED_GRAY} !important;
    pointer-events: none;
`;

interface SavedConfigManageDropdownParams {
    onUpdateConfig: () => Promise<void>;
    onDeleteConfig: () => Promise<void>;
    resetSelectedConfiguration: () => void;
    fullStateReset?: () => void;
    hasUserAccess: boolean;
    allowDeleteOnly: boolean;
    defaultConfigSelected: boolean;
    setIsEditorOpen: (value: boolean) => void;
    setIsMenuOpen?: (value: boolean) => void;
}

const SavedConfigManageDropdown = ({
    onUpdateConfig,
    onDeleteConfig,
    resetSelectedConfiguration,
    fullStateReset,
    hasUserAccess,
    allowDeleteOnly,
    defaultConfigSelected,
    setIsEditorOpen,
    setIsMenuOpen,
}: SavedConfigManageDropdownParams) => {
    const [clickedOption, setClickedOption] = useState<string | null>(null);

    const onConfirm = () => {
        if (clickedOption === 'update') {
            onUpdateConfig();
        } else {
            onDeleteConfig();
            // Reset back to Account Detail view when a saved view is deleted in Crosstab
            fullStateReset && fullStateReset();
        }
        setClickedOption(null);
    };

    const allItems: ItemType[] = [
        {
            key: 'save_as_new',
            label: 'Add New View',
            icon: <i className="fa-solid fa-plus" />,
            onClick: () => setIsEditorOpen(true),
        },
        {
            key: 'save_changes',
            label: 'Save Changes',
            icon: <i className="fa-solid fa-floppy-disk" />,
            onClick: () => setClickedOption('update'),
        },
        {
            key: 'reset_selection',
            label: 'Reset',
            icon: <i className="fa-solid fa-refresh" />,
            onClick: () => resetSelectedConfiguration(),
        },
        {
            key: 'delete_view',
            label: 'Delete',
            icon: <i className="fa-solid fa-trash" />,
            onClick: () => setClickedOption('delete'),
        },
    ];

    const getStyledItems = () => {
        const items = [...allItems];

        if (hasUserAccess && allowDeleteOnly) {
            return setGreyedOutClass(items);
        }

        if (allowDeleteOnly) {
            if (defaultConfigSelected) {
                return setGreyedOutClass(items);
            } else {
                return setGreyedOutClass(items, [0, 1, 2]); // Grey out menu items other than delete
            }
        }

        if (defaultConfigSelected || hasUserAccess) {
            return setGreyedOutClass(items, [1, 3]); // Grey out save and reset items for default Crosstab views
        }

        return items;
    };

    const setGreyedOutClass = (
        items: ItemType[],
        indexes: number[] = Array.from(items.keys())
    ) => {
        for (const index of indexes) {
            if (!items[index]) {
                continue;
            }

            items[index]!.className = greyedOutListItem;
        }

        return items;
    };

    const items = getStyledItems();
    const allItemsDisabled = items.every(
        (item) => item?.className === greyedOutListItem
    );

    return (
        <Dropdown
            menu={{ items }}
            trigger={allItemsDisabled ? [] : ['click']}
            onOpenChange={(open: boolean) =>
                setIsMenuOpen && setIsMenuOpen(open)
            }
        >
            <Popconfirm
                open={!!clickedOption}
                placement="bottom"
                title={`Are you sure you want to ${clickedOption} this view?`}
                onConfirm={onConfirm}
                onCancel={() => setClickedOption(null)}
                okType="danger"
                okText="OK"
                cancelText="Cancel"
            >
                <Button
                    type="primary"
                    style={{
                        marginLeft: '10px',
                    }}
                    disabled={allItemsDisabled}
                >
                    <span>Views</span>
                    <i
                        style={{
                            marginTop: '0px',
                            marginLeft: '10px',
                        }}
                        className="fa-solid fa-chevron-down"
                    />
                </Button>
            </Popconfirm>
        </Dropdown>
    );
};

export default SavedConfigManageDropdown;
