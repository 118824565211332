import { AppFeaturePermissions } from 'contexts';
import React, { useState } from 'react';
import { EntityProfileCards } from 'waypoint-react';
import {
    AddItemButtonLocationOptions,
    EntityProfileCardsGroupReduceType,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import {
    containerSort,
    getRows,
    getUniqueYears,
} from 'waypoint-react/entity-profile-cards/EntityProfileCardUtils';
import { SectionType } from 'waypoint-types';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface MarketOverviewProps {
    entityCode: string;
    title?: string;
    selectedYear?: number[];
    showReportWidgetOnly?: boolean;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

export const MARKET_OVERVIEW_CARD_TYPE = 'marketOverview';

const marketOverviewSuffix = 'Market Overview';

const sections: SectionType = {
    marketOverview: {
        title: 'Market Overview',
    },
};

export const MarketOverview = ({
    entityCode,
    title,
    selectedYear,
    showReportWidgetOnly = false,
    isPDFExport,
    includeNotesInPdf = false,
    setIsReadyToExport,
}: MarketOverviewProps) => {
    const [earlyYear, setEarlyYear] = useState<string>('');

    const getContainerConfig = (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => {
        const uniqueYears = getUniqueYears(yearMetadata);

        return uniqueYears.map((year) => {
            return {
                title: `${year} ${marketOverviewSuffix}`,
                column: 0,
                deleteStrategy: {
                    type: 'yearMetadata',
                    get: (
                        items: EntityProfileCard[],
                        yearMetadata: EntityProfileCardYearMetadata[]
                    ) => {
                        return yearMetadata.find((ym) => ym.year === year);
                    },
                },

                shouldHideContainerIfEmpty: (
                    yearsFilter: number[]
                ): boolean => {
                    return !yearsFilter.includes(year);
                },
                getEmptyGroupState: (): EntityProfileCardsGroupReduceType => {
                    const result: EntityProfileCardsGroupReduceType = {};

                    Object.keys(sections).forEach((sectionKey) => {
                        const { title } = sections[sectionKey];
                        result[sectionKey] = {
                            title,
                            colSpan: 2,
                            items: [],
                            addItemButton: {
                                addItemTitleLabel: title,
                                addItemTooltip: `Add ${title}`,
                                getDefaultCard: () => {
                                    return {
                                        id: '',
                                        title: '',
                                        section: sectionKey,
                                        notes: '',
                                        sort_index: 0,
                                        year: year,
                                        type: MARKET_OVERVIEW_CARD_TYPE,
                                    };
                                },
                            },
                        };
                    });
                    return result;
                },
                group: (
                    result: EntityProfileCardsGroupReduceType,
                    item: EntityProfileCard
                ) => {
                    result[item.type].items.push(item);
                    return result;
                },
                filter: (item: EntityProfileCard) => {
                    return item.year === year;
                },
                addItemButtonLocations: [
                    'group',
                ] as AddItemButtonLocationOptions,
                renderYearMetadataOptions: () => {
                    return [];
                },
                getYearMetadataValue: (
                    yearMetadata: EntityProfileCardYearMetadata[]
                ): EntityProfileCardYearMetadata | undefined => {
                    return yearMetadata.find((ym) => ym.year === year);
                },
            };
        });
    };

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.MarketOverview}>
            <EntityProfileCards
                isCollapse
                enableYearFilter
                enableYearMetadata
                title={title ?? 'Market Overview'}
                type={MARKET_OVERVIEW_CARD_TYPE}
                entityCode={entityCode}
                columns={1}
                rows={getRows}
                containerSort={containerSort}
                getContainerConfig={getContainerConfig}
                enabledFields={['title']}
                setEarlyYear={setEarlyYear}
                selectedYear={selectedYear}
                defaultExpandedCategories={`${earlyYear} ${marketOverviewSuffix}`}
                showReportWidgetOnly={showReportWidgetOnly}
                data-test="market-overview-card"
                isPDFExport={isPDFExport}
                includeNotesInPdf={includeNotesInPdf}
                setIsReadyToExport={setIsReadyToExport}
            />
        </PermissionedWrapper>
    );
};
