import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import React, { Component, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { getAccountGraph, getAsOfDates } from 'waypoint-requests';
import { decorateAccountGraphForAntDesign } from 'waypoint-utils';
import { cloneDeep, find, get } from 'lodash';
import { DisabledDashboard } from 'waypoint-react';
import { adjustLayoutForGroup } from 'components/analytics/financialOverview/grid/utils';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withDashboardConfiguration from 'components/dashboards/withDashboardConfiguration';
import withDashboardConfigurationRequest from 'components/dashboards/withDashboardConfigurationRequest';

interface FinancialDashboardProps {
    dashboardConfiguration: any;
    isDashboardConfigurationLoading: boolean;
    accountGraphCode: string;
    component: typeof Component;
    module: string;
}

/*
    This component:
        1. Fetches and reads a dashboard configuration
        2. Fetches a module specific account import { compose, graphql } from 'react-apollo'
        3. Fetches an entity specific as of date
        4. Conditionally renders a disabled or loading state
        5. Passes props to child component
    Dashboard config is requested via HOC. Account Graph & Entity as of date is requested via component lifecycle methods
*/

const FinancialDashboard = ({
    accountGraphCode,
    isDashboardConfigurationLoading,
    dashboardConfiguration,
    component,
}: FinancialDashboardProps): JSX.Element => {
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoadingAccountGraph, setIsLoadingAccountGraph] =
        useState<boolean>(true);
    const [isLoadingAsOfDate, setIsLoadingAsOfDate] = useState<boolean>(true);
    const [accountGraph, setAccountGraph] = useState<any>(null);
    const [asOfDate, setAsOfDate] = useState<string | null>(null);

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    useEffect(() => {
        setIsLoadingAccountGraph(true);
        setAccountGraph(null);

        const fetchAccountGraph = async () => {
            try {
                const data = await getAccountGraph(
                    accountGraphCode,
                    selectedDataLevel
                );
                const accountGraph = decorateAccountGraphForAntDesign(
                    data.children
                );

                setAccountGraph(accountGraph);
                setIsLoadingAccountGraph(false);
            } catch (e) {
                setIsError(true);

                toastr.error(
                    'Request Failed',
                    'An error occurred while requesting accounts'
                );
            } finally {
                setIsLoadingAccountGraph(false);
            }
        };

        fetchAccountGraph();
    }, [
        accountGraphCode,
        selectedDataLevel.stakeholder,
        selectedDataLevel.percentageType,
    ]);

    useEffect(() => {
        const fetchAsOfDate = async () => {
            if (!entityCodes?.length) {
                return;
            }

            setIsLoadingAsOfDate(true);
            setAsOfDate(null);

            try {
                const data = await getAsOfDates(entityCodes);
                transformAndSetAsOfDate(data);
            } catch (e) {
                setIsError(true);

                toastr.error(
                    'Request Failed',
                    'An error occurred while requesting as of date'
                );
            } finally {
                setIsLoadingAsOfDate(false);
            }
        };

        const transformAndSetAsOfDate = (asOfDateResponse: any) => {
            const actualPeriodEnd = get(
                find(asOfDateResponse.accounting, { label: 'Actual' }),
                'period_end'
            );

            setAsOfDate(actualPeriodEnd);
        };

        fetchAsOfDate();
    }, [entityCodes]);

    if (!dashboardConfiguration && !isDashboardConfigurationLoading) {
        return <DisabledDashboard />;
    }

    if (isError) {
        return (
            <DisabledDashboard text={'There was an error loading your data.'} />
        );
    }

    if (!entityCodes.length) {
        return <DisabledDashboard text={'No properties selected'} />;
    }

    if (
        isDashboardConfigurationLoading ||
        isLoadingAsOfDate ||
        isLoadingAccountGraph
    ) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    const ComponentToRender = component;

    return (
        <ComponentToRender
            entityCodes={entityCodes}
            dashboardConfiguration={dashboardConfiguration}
            asOfDate={asOfDate}
            accountGraph={accountGraph}
            selectedDataLevel={selectedDataLevel}
        />
    );
};

const mapState = (s: any, props: any) => ({
    dashboardConfigurationId: props.dashboardConfigurationId,
    module: props.module,
});

export default compose(
    connect(mapState),
    withDashboardConfigurationRequest,
    withDashboardConfiguration
)(FinancialDashboard);
