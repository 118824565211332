import { EntityProfileCardYearMetadata } from 'waypoint-types';
import { getUniqueArray } from 'waypoint-utils/collection/getUniqueArray';
import { EntityProfileCardContainerDefinition } from './EntityProfileCards';

export const getUniqueYears = (data: { year: number }[]): number[] => {
    return getUniqueArray<number>(data.map((item) => item.year));
};

export const containerSort = (
    containerA: EntityProfileCardContainerDefinition,
    containerB: EntityProfileCardContainerDefinition
) => {
    return -containerA.title.localeCompare(containerB.title);
};

export const getRows = (yearMetadata: EntityProfileCardYearMetadata[]) => {
    return yearMetadata.length;
};
