import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { pick, get, isEqual } from 'lodash';
import { saveAs } from 'file-saver';
import { getFilenameFromResponse } from 'waypoint-utils';
import { toISO } from 'components/dates/utils';
import { BalanceSheetOverviewMenu } from 'components/analytics/balance-sheet-overview/menu';

class BalanceSheetOverviewGridItem extends Component {
    static propTypes = {
        accountGraph: PropTypes.arrayOf(PropTypes.object),
        componentToRender: PropTypes.node,
        componentName: PropTypes.string,
        defaults: PropTypes.shape({
            accountMapping: PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
            }),
            displayType: PropTypes.string,
            periodicity: PropTypes.string,
        }),
        globalPeriod: PropTypes.arrayOf(PropTypes.string),
        entityCodes: PropTypes.arrayOf(PropTypes.string),
        request: PropTypes.func,
    };

    state = {
        data: [],
        loading: true,
        downloading: false,
        showMenu: false,
        selections: null,
    };

    componentDidMount = () => {
        this.fetchData();
    };

    /**
     * Fetch new data if user switches to a new property/property group OR selects new values in the menu/global period select
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (
            !isEqual(prevState.selections, this.state.selections) ||
            !isEqual(prevProps.entityCodes, this.props.entityCodes) ||
            this.getHasGlobalPeriodChanged(
                prevProps.globalPeriod,
                this.props.globalPeriod
            )
        ) {
            this.fetchData();
        }
    };
    /**
     * This is a patch. Comparing moment instances in componentDidUpdate caused new requests because the unix timestamp of the end date technically changes as time passes. However, we are only concerned with date changes and a better fix would be to store only ISO dates in state.
     */
    getHasGlobalPeriodChanged = (prevGlobalPeriod, currGlobalPeriod) => {
        return (
            toISO(prevGlobalPeriod[0]) !== toISO(currGlobalPeriod[0]) ||
            toISO(prevGlobalPeriod[1]) !== toISO(currGlobalPeriod[1])
        );
    };

    /**
     * This utlizes getSelectionOrDefaultFor to get all menu values. Period is the exception. It is dervied from the globalPeriod passed from the parent.
     * @returns {object} - all selections (or defaults)
     */
    getAllSelectionsOrDefaults = () => {
        return {
            period: this.props.globalPeriod,
            isHideNull: this.getSelectionOrDefaultFor('isHideNull'),
            displayType: this.getSelectionOrDefaultFor('displayType'),
            periodicity: this.getSelectionOrDefaultFor('periodicity'),
            accountMapping: this.getSelectionOrDefaultFor('accountMapping'),
        };
    };

    /**
     * This returns a user selected value for a field. If the field is clean, it returns a default selection received as a prop.
     * @param {string} field
     * @returns selected value or default field
     */
    getSelectionOrDefaultFor = (field) => {
        return get(this.state.selections, field, this.props.defaults[field]);
    };

    /**
     * Integration of menu values with API. It includes the periodicity param on overtime requests and the isHideNull on breakdown.
     * Overtime uses both the start and end date of the range selected. However, all other cards only use the end date of the range selected for their params.
     * @returns {object} - params
     */
    getParametersForRequest = () => {
        const selections = this.getAllSelectionsOrDefaults();
        const params = {
            account_mapping_code: selections.accountMapping.code,
            account_mapping_name: selections.accountMapping.name,
            start_date: selections.period[1] // TODO: move to utils
                .startOf('month')
                .format('YYYY-MM-DD'),
            end_date: selections.period[1].endOf('month').format('YYYY-MM-DD'),
            display_type: selections.displayType,
        };

        if (Array.isArray(selections.accountMapping.name)) {
            params.account_mapping_name = selections.accountMapping.name[0];
        }

        if (this.props.componentName === 'overtime') {
            params.periodicity = selections.periodicity;
            params.start_date = selections.period[0] // TODO: move to utils
                .startOf('month')
                .format('YYYY-MM-DD');
            return params;
        }

        if (this.props.componentName === 'breakdown') {
            params.isHideNull = selections.isHideNull;
            return params;
        }

        return params;
    };

    showMenu = () => {
        this.setState({ showMenu: true });
    };

    closeMenu = () => {
        this.setState({ showMenu: false });
    };

    /**
     * Applies new selections if user dirties any field. If all fields are clean, the menu just emits the previous selections.
     */
    handleApply = (newSelections) => {
        this.setState({ selections: { ...newSelections } });
        this.closeMenu();
    };

    /**
     * Defaults are passed from BalanceSheetOverviewGrid
     */
    handleReset = () => {
        this.handleApply(this.props.defaults);
    };

    /**
     * Each grid item (except Total component) receives a fetch function as a prop. This method utilizes that function to fetch data and hyrdate the component (or set error state).
     * @returns - nothing - performs side effects
     */
    fetchData = () => {
        const { entityCodes, selectedDataLevel } = this.props;

        this.setState({ data: [], loading: true }, async () => {
            try {
                const response = await this.props.request({
                    entityCodes,
                    selectedDataLevel,
                    ...this.getParametersForRequest(),
                });
                this.setState({ data: response });
            } catch (error) {
                this.setState({ error });
                toastr.error(
                    'Request Failed',
                    'An error occurred while requesting data'
                );
            } finally {
                this.setState({ loading: false });
            }
        });
    };

    downloadCsv = () => {
        const { entityCodes, selectedDataLevel } = this.props;
        this.setState({ downloading: true }, async () => {
            try {
                const response = await this.props.request({
                    entityCodes,
                    selectedDataLevel,
                    ...this.getParametersForRequest(),
                    ...{ download: true },
                });

                const blob = await response.blob();
                const filename = getFilenameFromResponse(response);

                saveAs(blob, filename);
            } catch (e) {
                toastr.error(
                    'Download Failed',
                    'An error occurred while downloading the file'
                );
            } finally {
                this.setState({ downloading: false });
            }
        });
    };

    render = () => {
        const { componentToRender: ItemComponent, componentName } = this.props;
        const { data, loading } = this.state;

        return (
            <div>
                {this.state.showMenu && (
                    <BalanceSheetOverviewMenu
                        selections={this.getAllSelectionsOrDefaults()}
                        accountGraph={this.props.accountGraph}
                        handleClose={this.closeMenu}
                        handleApply={this.handleApply}
                        handleReset={this.handleReset}
                        showDisplayBySelector={true}
                        showHideNullValuesSelector={
                            componentName === 'breakdown'
                        }
                    />
                )}
                <ItemComponent
                    data={data}
                    selections={this.getAllSelectionsOrDefaults()}
                    loading={loading}
                    onClick={() => this.setState({ showMenu: true })}
                    downloading={this.state.downloading}
                    downloadCsv={this.downloadCsv}
                    {...pick(this.props, ['data-grid', 'style'])}
                />
            </div>
        );
    };
}

export default BalanceSheetOverviewGridItem;
