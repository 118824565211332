import PropTypes from 'prop-types';
import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import theme from 'config/theme';
import chartBuilder from 'components/charts/chartBuilder';
import { getBSOOvertimeDataset } from 'components/analytics/balance-sheet-overview/charts/utils';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';

charts(fusioncharts);

const chartStyle = {
    yaxisname: 'Gross $',
    xaxisname: 'Period',
    exportFileName: 'Actual Over Time',
    baseFontColor: theme.colors.grays.medium,
    xAxisNameFontColor: theme.colors.grays.medium,
    yAxisNameFontColor: theme.colors.grays.medium,
    paletteColors: theme.colors.barCharts[0],
    animation: '0',
    exportFormats: 'PNG|PDF|JPG|SVG',
};

/**
 * Displays a column Fusion Chart. See getOvertimeDataset for data signature.
 */
const OvertimeChart = ({
    data: balanceSheetOverTime = [],
    periodicity = '',
    displayType,
}) => {
    const metricLabel = (
        displayTypeOptions.find((x) => x.key === displayType) || {
            label: 'Gross',
        }
    ).label;
    const chart = chartBuilder();
    chart.type('column2d');
    chart.height('70%');
    chart.width('100%');
    chart.style({
        ...chartStyle,
        yaxisname: metricLabel,
    });

    chart.data(
        getBSOOvertimeDataset(balanceSheetOverTime, periodicity, metricLabel)
    );

    return (
        <div>
            <ReactFC {...chart.config} data-test="component-overtime-chart" />
        </div>
    );
};

OvertimeChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    periodicity: PropTypes.string,
};

export default OvertimeChart;
