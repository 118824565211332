import PropTypes from 'prop-types';
import React from 'react';
import { map, filter, sortBy } from 'lodash';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectRequest, querySelectors } from 'redux-query';
import { createSearchAction, getSearchSelectors } from 'redux-search';
import { createSelector } from 'reselect';
import { Button, FormControl } from 'react-bootstrap';
import { selectClientId } from 'state/user/selectors';
import { selectUsers } from 'state/users/selectors';
import { requestAllUsers } from 'state/requests';
import LoadingSpinner from 'components/style/LoadingSpinner';
import EditUserModal from 'components/settings/users/EditUserModal';
import CreateUserModal from 'components/settings/users/CreateUserModal';
import UserManagementTable from 'components/settings/users/UserManagementTable';
import styles from 'components/style/Subheader.module.css';
import sharedStyles from 'components/settings/shared.module.css';
import Card from 'components/style/Card';
import { donwloadUsersSummaryList } from 'waypoint-requests';
import { toastr } from 'react-redux-toastr';
import saveAs from 'file-saver';
import { getFilenameFromResponse } from 'waypoint-utils';
import { DownloadButton } from 'waypoint-react';

const spinnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

class UserManagement extends React.Component {
    static propTypes = {
        users: PropTypes.object,
        clientId: PropTypes.number,
        isReady: PropTypes.bool,
        searchUsers: PropTypes.func,
    };

    state = {
        showEditUserModal: false,
        showCreateUserModal: false,
        userId: null,
        isLoading: false,
    };

    componentWillUnmount() {
        this.props.searchUsers('');
    }

    // NOTE: defining open and close modal avoids race conditions with in EditUserModal.
    openEditUserModal = (userId) => {
        this.setState({ userId }, () =>
            this.setState({ showEditUserModal: true })
        );
    };

    closeEditUserModal = () => {
        this.setState({ showEditUserModal: false }, () =>
            this.setState({ userId: null })
        );
    };

    toggleCreateUserModal = () => {
        this.setState({ showCreateUserModal: !this.state.showCreateUserModal });
    };

    onDownloadUserList = async () => {
        this.setState({ isLoading: true });
        try {
            const response = await donwloadUsersSummaryList(
                this.props.clientId
            );
            const blob = await response.blob();
            const filename = getFilenameFromResponse(response);
            saveAs(blob, filename);
            this.setState({ isLoading: false });
        } catch (error) {
            toastr.error('Error downloading user list');
        } finally {
            this.setState({ isLoading: false });
        }
    };

    render() {
        const { isReady } = this.props;
        if (!isReady) {
            return (
                <div className={sharedStyles.pageContainer}>
                    <LoadingSpinner style={spinnerStyle} />
                </div>
            );
        }

        const { users, searchUsers } = this.props;
        const { showEditUserModal, showCreateUserModal, userId } = this.state;

        return (
            <div className={sharedStyles.pageContainer}>
                <h1 className={sharedStyles.majorHeading}>User Management</h1>
                <Card style={{ margin: '0 auto' }} fluid large>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ order: 1 }}>
                            <h1
                                className={sharedStyles.majorHeading}
                                style={{ marginBottom: '12px' }}
                            >
                                User List
                            </h1>
                            <p
                                style={{
                                    color: '#5e5e5e',
                                    fontSize: '14px',
                                    fontWeight: 'regular',
                                }}
                            >
                                Create, edit and remove users and their
                                associated permissions to the Waypoint
                                application.
                            </p>
                        </div>
                        <hr style={{ margin: '5px 0', order: 2 }} />
                        <div style={{ order: 3 }}>
                            <FormControl
                                style={{
                                    height: '37px',
                                    width: '276px',
                                    float: 'left',
                                    // marginTop: '40px',
                                    marginLeft: '15px',
                                    marginBottom: '0px',
                                    border: '1px solid #cfcfcf',
                                    outline: 'none',
                                    fontSize: '17px',
                                    fontFamily: 'Lato',
                                    fontColor: 'black',
                                    borderRadius: '50px',
                                }}
                                className={styles.userSearch}
                                placeholder={'Search Users'}
                                onChange={(e) => searchUsers(e.target.value)}
                            />
                            {/* TODO (Colby): Use InputGroup and InputGroup.Addon from react-bootstrap for inline icons */}
                            <i
                                className="fa-solid fa-search"
                                style={{
                                    height: '27px',
                                    float: 'left',
                                    marginTop: '14px',
                                    // marginTop: '54px',
                                    transform: 'translate3d(-30px, -2px, 0)',
                                }}
                            />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '15px',
                                    float: 'right',
                                }}
                            >
                                <Button
                                    style={{ marginRight: '15px' }}
                                    bsStyle="primary"
                                    onClick={this.toggleCreateUserModal}
                                >
                                    <i
                                        style={{ marginRight: '10px' }}
                                        className="fa-solid fa-plus"
                                    />
                                    Create User
                                </Button>
                                <DownloadButton
                                    disabled={this.state.isLoading}
                                    onClick={() => this.onDownloadUserList()}
                                />
                            </span>
                        </div>
                        <div style={{ order: 4 }}>
                            <UserManagementTable
                                data={users}
                                openEditUserModal={this.openEditUserModal}
                            />
                            {showEditUserModal && (
                                <EditUserModal
                                    openEditUserModal={this.openEditUserModal}
                                    closeEditUserModal={this.closeEditUserModal}
                                    userId={userId}
                                />
                            )}
                            {/* TODO (Daniel, Alex): Create a ticket to tackle these throught the whole codebase*/}
                            {showCreateUserModal && (
                                <CreateUserModal
                                    toggleCreateUserModal={
                                        this.toggleCreateUserModal
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const { result: usersResult, text } = getSearchSelectors({
    resourceName: 'USERS',
    resourceSelector: (resourceName, state) => selectUsers(state),
});

const selectUsersBySearchResult = createSelector(
    [usersResult, selectUsers, text],
    (filteredUsers, allUsers, searchText) => ({
        filteredUsers,
        allUsers,
        searchText,
    })
);

const mapDispatch = (dispatch) => {
    return {
        searchUsers: bindActionCreators(createSearchAction('USERS'), dispatch),
        dispatch,
    };
};

// TODO (Daniel): Look further into this mapState
const mapState = (state) => {
    const clientId = selectClientId(state);
    const { queries } = state;

    const userQueryConfig = requestAllUsers({ clientId });

    const isFinished = querySelectors.isFinished(queries, userQueryConfig);

    const searchResult = selectUsersBySearchResult(state);
    let { filteredUsers } = searchResult;
    const { allUsers } = searchResult;
    filteredUsers = map(filteredUsers, (id) => Number(id));
    const users = sortBy(
        filter(filter(allUsers, (u) => filteredUsers.includes(u.id))),
        ['activeStatus']
    );

    return {
        users,
        clientId,
        userQueryConfig,
        isReady: clientId && isFinished,
    };
};

export default compose(
    connect(mapState, mapDispatch),
    connectRequest(({ clientId, userQueryConfig }) => {
        if (!clientId) {
            return {};
        }
        return [userQueryConfig];
    })
)(UserManagement);
