import React, { Component } from 'react';
import { getPerformances } from 'components/analytics/portfolioSegmentation/requests';
import { getFilenameFromResponse } from 'waypoint-utils';
import { saveAs } from 'file-saver';
import theme from 'config/theme';

class DownloadPerformanceOverviewButton extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            disabled: false,
        };
        this.checkIsDisabled = this.checkIsDisabled.bind(this);
    }

    download() {
        this.setState({ disabled: true });
        this.runDownloadRequest().then(() =>
            this.setState({ disabled: false })
        );
    }

    runDownloadRequest() {
        const { kpiSelected, groupBy, entityCodes, period } = this.props;
        const configtoDownloadCsv = {
            metric: kpiSelected,
            group_by: groupBy,
            httpHeaders: {
                Accept: 'text/csv',
            },
        };
        let filename;
        return getPerformances({ ...configtoDownloadCsv, entityCodes, period })
            .then((response) => {
                filename = getFilenameFromResponse(response);
                return response.blob();
            })
            .then((blob) => saveAs(blob, filename));
    }

    checkIsDisabled() {
        if (
            this.state.disabled ||
            this.props.disabled ||
            !this.props.performanceOverviews
        ) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <i
                className="fa-solid fa-cloud-arrow-down fa-lg"
                data-test-button
                onClick={this.download.bind(this)}
                style={{
                    cursor: this.checkIsDisabled() ? 'not-allowed' : 'pointer',
                    color: this.checkIsDisabled() && theme.colors.grays.medium,
                    pointerEvents: this.checkIsDisabled() ? 'none' : 'auto',
                }}
            />
        );
    }
}

export default DownloadPerformanceOverviewButton;
