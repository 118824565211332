import AntDesignList from 'antd/lib/list';
import theme from 'config/theme';

const { Item } = AntDesignList;

interface ListToolTipProps {
    header: string | number | null;
    items: Array<{ label: string; value: string | number }>;
}

const ListTooltip = ({
    header,
    items,
    ...props
}: ListToolTipProps): JSX.Element => {
    return (
        <AntDesignList
            data-test="list-tooltip"
            size="small"
            style={{ paddingLeft: 10, paddingRight: 10 }}
            header={
                <span style={{ fontWeight: theme.fontWeights.bold }}>
                    {header}
                </span>
            }
            dataSource={items}
            renderItem={(item) => (
                <Item>
                    <span style={{ fontWeight: theme.fontWeights.regular }}>
                        {item.label}:
                    </span>
                    &nbsp;
                    <span style={{ fontWeight: theme.fontWeights.light }}>
                        {item.value}
                    </span>
                </Item>
            )}
            {...props}
        />
    );
};

export default ListTooltip;
