import { isEmpty, some } from 'lodash';
import { css, cx } from 'emotion';
import theme from 'config/theme';
import ColumnSearchInput from './ColumnSearchInput';
import { SearchOutlined } from '@ant-design/icons';

/**
 * This is used when a table is searchable. It decorates with the first column with props required by antd for their search API. All columns besides the first one in the list remain the same.
 * @param {array} columns
 * @param {*} props
 * @returns {array} - array of columns with first column decorated with props required for antd search API
 */
export const decorateFirstColumnWithSearchProps = (columns, props) => {
    const { searchText, setSearchText, expandAllRows, collapseAllRows } = props;
    const columnsCopy = [...columns];
    const firstColumn = columnsCopy.splice(0, 1)[0];
    const firstColumnWithSearchProps = {
        ...firstColumn,
        filterDropdown: () => (
            <ColumnSearchInput
                searchText={searchText}
                handleSearch={setSearchText}
                expandAllRows={expandAllRows}
                collapseAllRows={collapseAllRows}
            />
        ),
        filterIcon: () => (
            <SearchOutlined
                style={{
                    color: !isEmpty(searchText) && theme.colors.blues.primary,
                }}
            />
        ),
    };
    return [firstColumnWithSearchProps, ...columnsCopy];
};

const boldStyle = css`
    color: ${theme.colors.grays.text};
    font-weight: bold;
`;

const parentNodeStyle = css`
    color: ${theme.colors.grays.text};
    font-weight: bold;
    text-transform: uppercase !important;
`;

const totalForRootNodeStyle = css`
    color: ${theme.colors.grays.text};
    font-weight: bold;
    text-transform: uppercase !important;
    background-color: ${theme.colors.grays.background} !important;
`;

const fixedColumnStyle = css`
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background-color: ${theme.colors.grays.background} !important;
    }
`;

const leafNodeStyle = css`
    color: ${theme.colors.grays.mediumDark};
`;
const hightlightStyle = css`
    .ant-table-cell-fix-left,
    .ant-table-cell,
    .ant-table-cell-fix-right {
        background-color: rgb(255, 245, 214) !important;
    }
`;
const hiddenStyle = css`
    display: none;
`;

const doesPassFilter = (searchableKey, record, text) => {
    if (!record[searchableKey]) {
        return false;
    }
    return record[searchableKey]
        .toLowerCase()
        .includes(!isEmpty(text) && text.toLowerCase());
};

/**
 * This conditionally applies class names to a row. Primarily, rows get different class names based on if they pass the search filter or have a signal from decorateAccountGraphForAntDesign (isRootNode, isTotalNode, isTitleNode, isTotalForRootNode).
 * @param {object} param0 - row - analogous to single node in a graph/tree
 * @param {string} param0 - searchText - the value of the search input if the search is active
 * @param {array} param0 = searchableKeys - keys on each node to search against
 * @returns
 */

export const getRowClassNameFor = ({
    row,
    searchText,
    searchableKeys = [],
    highlightRows = [],
}) => {
    const {
        isRootNode,
        isTotalNode,
        isTitleNode,
        isTotalForRootNode,
        children,
    } = row;
    const searchIsActive = !isEmpty(searchText);

    const keysPassed = searchableKeys.filter((searchableKey) =>
        doesPassFilter(searchableKey, row, searchText)
    );

    const searchIsActiveAndDoesNotPassFilter =
        searchIsActive && isEmpty(keysPassed);

    const isLeafNode = !children;
    const isCalculatedField = !children && isRootNode;
    const isTotalField = !children && isTotalNode;
    const isFlaggedRow =
        isLeafNode &&
        !isCalculatedField &&
        !isTotalField &&
        highlightRows.length;

    const passedFilter =
        (searchIsActive && !isEmpty(keysPassed)) || isFlaggedRow;

    const hasDirectChildThatPassesFilter = some(
        row.children,
        (child) =>
            !isEmpty(
                searchableKeys.filter((searchableKey) =>
                    doesPassFilter(searchableKey, child, searchText)
                )
            )
    );

    return cx(
        leafNodeStyle,
        { [parentNodeStyle]: isRootNode },
        { [totalForRootNodeStyle]: isTotalForRootNode },
        { [fixedColumnStyle]: isTotalForRootNode },
        { [boldStyle]: isTitleNode || isTotalNode },
        { [hightlightStyle]: passedFilter },
        {
            [hiddenStyle]:
                !hasDirectChildThatPassesFilter &&
                searchIsActiveAndDoesNotPassFilter,
        }
    );
};
