import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'emotion';
import { Checkbox as AntDesignCheckbox } from 'antd';

const checkBoxText = css`
    .ant-checkbox-input {
        float: left;
        margin: 0;
        width: 0;
        padding: 0;
    }
`;

const Checkbox = ({ checked = false, onChange, children }) => {
    return (
        <AntDesignCheckbox
            className={checkBoxText}
            checked={checked}
            onChange={onChange}
        >
            {children}
        </AntDesignCheckbox>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,
};

export default Checkbox;
