import { toArray } from 'lodash';
export const selectUsers = (state) => state.users;

export const selectUsersById = (state, { userIds }) => {
    return toArray(state.users).filter((u) => userIds.includes(u.id));
};

export const selectUsersByUserName = (state, { userNames }) => {
    return toArray(state.users).filter(
        (u) => userNames.includes(u.userName) || userNames.includes(u.user_name)
    );
};

export const selectUsersByFirstName = (state, { firstName }) => {
    return toArray(state.users).filter((u) => firstName.includes(u.firstname));
};

export const selectUsersByLastName = (state, { lastName }) => {
    return toArray(state.users).filter((u) => lastName.includes(u.lastname));
};
