import { createSelector } from 'reselect';
import { toArray } from 'lodash';

export const selectPropertyTenantStatistics = (state) =>
    state.tenants.propertyTenantStatistics;
export const selectGroupTenantStatistics = (state) =>
    state.tenants.groupTenantStatistics;

const idFilter = (_, props) => props.id;

export const selectPropertyTenantStatisticsById = createSelector(
    [selectPropertyTenantStatistics, idFilter],
    (propertyTenantStatistics, propertyId) => {
        return propertyTenantStatistics[propertyId];
    }
);

export const selectGroupTenantStatisticsById = createSelector(
    [selectGroupTenantStatistics, idFilter],
    (gropuLeaseStatistics, groupId) => {
        return gropuLeaseStatistics[groupId];
    }
);

const groupTenants = (s) => s.tenants.groupTenants;

const propertyTenants = (s) => s.tenants.propertyTenants;

const selectTenantIdsByPropertyGroup = (s) =>
    s.tenants.tenantIdsByPropertyGroup;

export const selectGroupTenants = createSelector(groupTenants, (r) => r);

export const selectPropertyTenants = createSelector(propertyTenants, (r) => r);

export const selectPropertyTenantsByPropertyId = createSelector(
    [selectPropertyTenants, idFilter],
    (tenants, propertyId) => {
        const t = tenants.filter((tf) => {
            return tf.propertyIdArr.includes(propertyId);
        });
        return t;
    }
);

export const selectGroupTenantsByGroupId = createSelector(
    [selectGroupTenants, idFilter],
    (tenants, groupId) => {
        const t = tenants.filter((g) =>
            g.propertyGroupIds.inclues(Number(groupId))
        );
        return t;
    }
);

export const selectTenantIdsByPropertyGroupId = createSelector(
    [selectTenantIdsByPropertyGroup, idFilter],
    (tenantsByPropertGroupId, id) => tenantsByPropertGroupId[id]
);

export const selectPropertyGroupTenants = createSelector(
    [groupTenants, selectTenantIdsByPropertyGroupId],
    (tenants, tenantIdList) => {
        if (tenantIdList === undefined) {
            return [];
        }
        return toArray(tenants).filter((t) => tenantIdList.includes(t.id));
    }
);
