import moment from 'moment';

const ISO_FORMAT = 'YYYY-MM-DD';

/**
 * A function to obtain a date range for a period
 * @param {string} periodicity - ytd | qtd | mtd | trailing_12
 * @param {Date} date - a moment valid date
 * @param {string} format - a date format
 * @param {string} financialYearEnding - month/day
 * @return {array | string} an array containing iso formatted start and end dates for a given period | an error string
 */
const getDateRangeForPeriod = (
    periodicity,
    date,
    format = ISO_FORMAT,
    financialYearEnding = ''
) => {
    let start;
    const momentDate = moment.utc(date);
    if (!momentDate.isValid()) {
        return 'Invalid date';
    }

    switch (periodicity) {
        case 'current_month':
        case 'month':
            start = momentDate.clone().startOf('month');
            break;
        case 'ytd':
            start = financialYearEnding
                ? getFinancialYTDStartDate(
                      momentDate.clone(),
                      financialYearEnding,
                      format
                  )
                : momentDate.clone().startOf('year');
            break;
        case 'qtd':
            start = financialYearEnding
                ? getFinancialQTDStartDate(
                      momentDate.clone(),
                      financialYearEnding,
                      format
                  )
                : momentDate.clone().startOf('quarter');
            break;
        case 'mtd':
            start = momentDate.clone().startOf('month');
            break;
        case 'trailing_12':
            start = momentDate.clone().subtract(11, 'months').startOf('month');
            break;
        default:
            break;
    }

    if (!start) {
        return 'Period start undefined';
    }

    return [start, momentDate.clone().endOf('month')].map((d) =>
        d.format(format)
    );
};

const getFinancialYTDStartDate = (momentDate, financialYearEnding) => {
    if (!financialYearEnding) {
        return false;
    }

    const end = momentDate.clone().endOf('month');
    const yearEndingMonth = moment(financialYearEnding).month();
    const periodEndMonth = end.month();
    const periodEndYear = end.year();

    const fullYearEndingDate =
        financialYearEnding +
        `/${yearEndingMonth >= periodEndMonth ? periodEndYear - 1 : periodEndYear}`;
    const yearEndingDateMoment = moment(fullYearEndingDate);

    const isLeapYearEndingDate =
        yearEndingDateMoment.isLeapYear() && yearEndingDateMoment.month() === 2;

    return yearEndingDateMoment.add(isLeapYearEndingDate ? 2 : 1, 'day');
};

const getFinancialQTDStartDate = (momentDate, financialYearEnding) => {
    if (!financialYearEnding) {
        return false;
    }

    const end = momentDate.clone().endOf('month');
    const yearEndingMonth = moment(financialYearEnding).month();
    const periodEndMonth = end.month();
    const periodEndYear = end.year();

    const fullYearEndingDate =
        financialYearEnding +
        `/${yearEndingMonth >= periodEndMonth ? periodEndYear - 1 : periodEndYear}`;
    const yearEndingDateMoment = moment(fullYearEndingDate);

    const isLeapYearEndingDate =
        yearEndingDateMoment.isLeapYear() && yearEndingDateMoment.month() === 2;

    const financialYearStart = yearEndingDateMoment.add(
        isLeapYearEndingDate ? 2 : 1,
        'day'
    );

    // by a given financial year start month and a date in it, calculate the date's starting quarter date
    const monthsInQuarter = 3;

    let monthsDifference = Math.round(
        financialYearStart.diff(end, 'months', true)
    );
    monthsDifference =
        yearEndingMonth <= periodEndMonth
            ? monthsDifference
            : Math.abs(monthsDifference);
    const quarterQuotient = Math.abs(monthsDifference % monthsInQuarter);
    const monthsToSubtract =
        quarterQuotient > 0 ? quarterQuotient : monthsInQuarter;

    return end
        .clone()
        .subtract(monthsToSubtract, 'months')
        .add(1, 'month')
        .startOf('month');
};

export default getDateRangeForPeriod;
