import {
    Button,
    Card,
    DatePicker,
    Empty,
    Skeleton,
    Tooltip,
    message,
} from 'antd';
import { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import { selectProperties } from 'state/properties/selectors';
import { RootStateOrAny, connect } from 'react-redux';
import { Dictionary } from 'ts-essentials';
import { PropertyType } from 'waypoint-types';
import { Select } from 'waypoint-react';
import {
    useGetLeasingPlans,
    useGetLeasingAsOfDateByEntityCode,
} from 'waypoint-hooks';
import { LeasingPlanTable } from './LeasingPlanTable';
import { LeasingPlanActivityTable } from './LeasingPlanActivityTable';
import {
    createLeasingPlan,
    createLeasingPlanActivity,
    deleteLeasingPlanActivity,
    updateLeasingPlan,
    updateLeasingPlanActivity,
} from 'waypoint-requests';
import { LeasingPlan, LeasingPlanActivity } from 'waypoint-types/leasing-plans';
import { LeasingPlanNotes } from './LeasingPlanNotes';
import {
    LeasingPlanActivityType,
    NOT_RENEWING,
    RENEWING,
    leasingCommissionsTypes,
    renewalRangeOptions,
} from './LeasingPlanUtils';
import { formatInTimeZone } from 'date-fns-tz';
import { dateSort, stringSort } from 'utils/tables/sorters';
import { Dayjs } from 'dayjs';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface LeasingPlanCardProps {
    entityCode: string;
    isPropertyProfilePage: boolean;
    properties: Dictionary<PropertyType>;
    widgetId?: string;
    widgetSettings?: { [key: string]: any };
    dataGridRef?: React.RefObject<DataGrid<any, any>>;
    renewalsGridRef?: React.RefObject<DataGrid<any, any>>;
    newLeasesGridRef?: React.RefObject<DataGrid<any, any>>;
    setIsReadyToExport?: (value: boolean) => void;
    isPDFExport?: boolean;
}

export const LEASING_PLAN_CARD_TYPE = 'leasingPlan';
const LeasingPlanCard = ({
    entityCode,
    isPropertyProfilePage,
    properties,
    widgetId,
    widgetSettings,
    dataGridRef,
    renewalsGridRef,
    newLeasesGridRef,
    setIsReadyToExport,
    isPDFExport,
}: LeasingPlanCardProps) => {
    const isReportWidget = !!widgetId;

    const [planYear, setPlanYear] = useState<number | null>(
        widgetSettings?.planYear ?? null
    );
    const [endYearOffset, setEndYearOffset] = useState<number>(
        widgetSettings?.endYearOffset ?? 0
    );
    const [isRefreshingData, setIsRefreshingData] = useState<boolean>(false);

    const { data, isError, mutate, isLoading } = useGetLeasingPlans(
        [entityCode],
        widgetSettings?.planYear
    );

    const { data: leasingAsOfDate, isLoading: isLoadingAsOfDate } =
        useGetLeasingAsOfDateByEntityCode(entityCode);

    const [leasingPlan, setLeasingPlan] = useState<LeasingPlan>();
    const [filteredLeasingPlanActivities, setFilteredLeasingPlanActivities] =
        useState<LeasingPlanActivity[]>([]);
    const [newLeases, setNewLeases] = useState<LeasingPlanActivity[]>([]);

    useEffect(() => {
        setIsReadyToExport &&
            setIsReadyToExport(!isLoading && !isLoadingAsOfDate);
    }, [isLoading, isLoadingAsOfDate]);

    useEffect(() => {
        setFilteredLeasesByOffset();
    }, [data, endYearOffset, planYear, leasingPlan]);

    useEffect(() => {
        setLeasingPlanOnDataChange();
    }, [data]);

    useEffect(() => {
        assignNotRenewingAndVacantNewLeases();
    }, [filteredLeasingPlanActivities]);

    const setFilteredLeasesByOffset = () => {
        if (!data || !planYear || !leasingPlan) {
            return;
        }

        const endYear = planYear + endYearOffset;

        const leaseExpirationDateLeases = leasingPlan.activities
            .filter((a) => {
                if (!a.lease_expiration_date) {
                    return;
                }

                const expYear = new Date(a.lease_expiration_date).getFullYear();
                return expYear >= planYear && expYear <= endYear;
            })
            .filter((a) => a.tenant_code)
            .sort((a, b) =>
                dateSort(b.lease_expiration_date, a.lease_expiration_date)
            );

        if (widgetSettings?.hideNonRenewingUnits) {
            const renewingUnits = leaseExpirationDateLeases.filter(
                (l) => l.expectation === RENEWING
            );
            setFilteredLeasingPlanActivities(renewingUnits);
            return;
        }

        setFilteredLeasingPlanActivities(leaseExpirationDateLeases);
    };

    const currentYear = new Date().getFullYear();

    const getDefaultPlanYear = (leasingPlans: LeasingPlan[]) => {
        const existingPlanYears = leasingPlans.map((lp) => lp.year);
        if (existingPlanYears.includes(currentYear) || !leasingPlans.length) {
            return currentYear;
        }
        return leasingPlans[0].year;
    };

    const setLeasingPlanOnDataChange = () => {
        if (!data?.length) {
            return;
        }

        if (!planYear) {
            const defaultYear = getDefaultPlanYear(data);
            setPlanYear(defaultYear);
            setLeasingPlan(data?.find((lp) => lp.year === defaultYear));

            return;
        }

        setLeasingPlan(data?.find((lp) => lp.year === planYear));
    };

    const assignNotRenewingAndVacantNewLeases = () => {
        const notRenewingUnits = filteredLeasingPlanActivities.filter(
            (activity) => activity.expectation === NOT_RENEWING
        );
        const vacantUnits = leasingPlan
            ? leasingPlan.activities.filter((activity) => !activity.tenant_code)
            : [];
        const leases = [...notRenewingUnits, ...vacantUnits].sort((a, b) =>
            stringSort(b.unit, a.unit)
        );
        setNewLeases(leases);
    };

    if (!data || !leasingAsOfDate || isError || isRefreshingData) {
        return (
            <Skeleton loading={true} active={true} paragraph={{ rows: 1 }} />
        );
    }

    if (leasingAsOfDate === 'no_dates') {
        return (
            <Card data-test="leasing-plan-card" title={<h2>Leasing Plan</h2>}>
                <Empty description={'No Leasing Plan Data'}></Empty>
            </Card>
        );
    }

    const onChangeLeasingPlanYear = (value: number) => {
        setPlanYear(value);
        setLeasingPlan(data?.find((lp) => lp.year === value));
    };

    const onCreateLeasingPlan = async (year: number) => {
        const leasingPlanData = {
            year,
            entity_code: entityCode,
            year_activity_filter: 2,
            as_of_date: leasingAsOfDate,
        };

        mutate(
            async () => {
                try {
                    await createLeasingPlan(leasingPlanData);

                    setPlanYear(year);

                    message.success(`Leasing Plan added successfully`);
                } catch (e) {
                    message.error(`Failed to add Leasing Plan`);

                    return [...data];
                }
            },
            {
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const onSaveRow = (activity: LeasingPlanActivity): void => {
        mutate(
            async () => {
                try {
                    // set default LC type to % if an amount is set for LC but not a type
                    if (
                        activity.leasing_commissions !== null &&
                        !activity.leasing_commissions_type
                    ) {
                        activity.leasing_commissions_type =
                            leasingCommissionsTypes[1].value;
                    }

                    activity.id
                        ? await updateLeasingPlanActivity(
                              entityCode,
                              activity.id,
                              activity
                          )
                        : await createLeasingPlanActivity(activity);

                    message.success(`Lease saved successfully`);
                } catch (e) {
                    message.error(`Failed to save lease`);
                    return [...data];
                }
            },
            {
                rollbackOnError: true,
                populateCache: false,
                revalidate: true,
            }
        );
    };

    const onDeleteRow = async (id: string) => {
        let updatedLeasingPlans: LeasingPlan[] = [];
        const leasingPlan = data.find((lp) => lp.year === planYear);
        if (leasingPlan) {
            leasingPlan.activities = [
                ...leasingPlan?.activities.filter(
                    (activity) => activity.id !== id
                ),
            ];
            updatedLeasingPlans.push(
                leasingPlan,
                ...data.filter((lp) => lp.id !== leasingPlan.id)
            );
        }

        mutate(
            async () => {
                try {
                    await deleteLeasingPlanActivity(entityCode, id);
                    message.success(`Lease deleted successfully`);
                    return updatedLeasingPlans;
                } catch (e) {
                    message.error(`Failed to delete lease`);
                    return [...data];
                }
            },
            {
                optimisticData: updatedLeasingPlans,
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const onUpdateLeasingPlan = async () => {
        if (!leasingPlan) {
            return;
        }

        setIsRefreshingData(true);
        const updatedLeasingPlans = [...data];
        const selectedLeasingPlanIndex = data.findIndex(
            (lp) => lp.year === planYear
        );

        mutate(
            async () => {
                try {
                    const leasingPlanParamsWithUpdatedDate = {
                        year: leasingPlan.year,
                        year_activity_filter: leasingPlan.year_activity_filter,
                        as_of_date: leasingAsOfDate,
                    };

                    const updatedPlan = await updateLeasingPlan(
                        entityCode,
                        leasingPlan.id,
                        leasingPlanParamsWithUpdatedDate
                    );
                    message.success(`Plan updated successfully`);
                    updatedLeasingPlans[selectedLeasingPlanIndex] = updatedPlan;
                    return updatedLeasingPlans;
                } catch (e) {
                    message.error(`Failed to update plan`);
                    return [...data];
                } finally {
                    onChangeLeasingPlanYear(leasingPlan.year);
                    setIsRefreshingData(false);
                }
            },
            {
                optimisticData: updatedLeasingPlans,
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const renderRefreshButton = () => {
        if (!leasingPlan || !leasingAsOfDate) {
            return <></>;
        }

        const selectedLeasingPlanAsOfDateIsCurrent =
            new Date(leasingAsOfDate).toUTCString() ===
            new Date(leasingPlan.as_of_date).toUTCString();

        if (selectedLeasingPlanAsOfDateIsCurrent) {
            return <></>;
        }

        return (
            <Tooltip
                placement="bottom"
                trigger="hover"
                arrow
                title="Clicking Refresh will update the plan with the latest leasing data loaded to Waypoint. Please note that this action cannot be undone."
            >
                <Button danger title="Refresh" onClick={onUpdateLeasingPlan}>
                    Refresh
                    <i
                        style={{ marginLeft: '5px' }}
                        className="fa-solid fa-refresh"
                    />
                </Button>
            </Tooltip>
        );
    };

    const planYearIsDisabled = (date: Dayjs) => {
        const yearIsPast = date.year() < currentYear;
        const yearPlanExists =
            date.year() < currentYear ||
            (data !== undefined &&
                data.map((lp) => lp.year).includes(date.year()));
        return yearIsPast || yearPlanExists;
    };

    const getYearSelectValue = (data: LeasingPlan[]) => {
        if (planYear) {
            return planYear;
        }

        return getDefaultPlanYear(data);
    };

    const getEmptyStateDecription = () => {
        if (!isReportWidget || !widgetSettings?.planYear) {
            return <span>No data</span>;
        }
        return (
            <>
                <div style={{ marginBottom: '10px' }}>No data</div>
                <Button
                    type="primary"
                    size="middle"
                    onClick={() => onCreateLeasingPlan(widgetSettings.planYear)}
                >
                    {`Create ${widgetSettings.planYear} Leasing Plan`}
                </Button>
            </>
        );
    };

    const cardTitle = () => {
        if (isPDFExport) {
            return null;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700,
                        fontSize: '19px',
                    }}
                >
                    <div>Leasing Plan</div>
                    <div style={{ marginLeft: '10px' }}>
                        <Select
                            options={data?.map((lp: LeasingPlan) => {
                                return {
                                    value: lp.year,
                                    label: lp.year,
                                };
                            })}
                            disabled={isReportWidget}
                            value={getYearSelectValue(data)}
                            onChange={(value: number) =>
                                onChangeLeasingPlanYear(value)
                            }
                            style={{ width: '80px' }}
                        />
                    </div>
                    <span style={{ alignItems: 'center' }}>
                        {leasingPlan ? (
                            <div
                                style={{
                                    fontSize: '10px',
                                    marginTop: '5px',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                            >
                                {`Leasing Data as of ${formatInTimeZone(
                                    new Date(leasingPlan.as_of_date),
                                    'UTC',
                                    'MM-dd-yyyy'
                                )}`}
                            </div>
                        ) : (
                            <></>
                        )}
                    </span>
                    <div>{renderRefreshButton()}</div>
                </div>
                {!isReportWidget ? (
                    <span
                        style={{
                            marginLeft: '10px',
                            textAlign: 'center',
                        }}
                    >
                        <DatePicker
                            data-test="leasing-plan-date-picker"
                            disabledDate={planYearIsDisabled}
                            picker="year"
                            onChange={(value) => {
                                if (value) {
                                    onCreateLeasingPlan(value.year());
                                }
                            }}
                            value={null}
                            placeholder="Add year"
                        />
                    </span>
                ) : (
                    <></>
                )}
            </div>
        );
    };

    const leasingPlanCardContent = () => {
        return data?.length ? (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '200px',
                    }}
                >
                    <LeasingPlanTable
                        leasingPlan={leasingPlan}
                        activities={filteredLeasingPlanActivities}
                        newLeases={newLeases}
                        dataGridRef={dataGridRef}
                        isPDFExport={isPDFExport}
                    />
                    {leasingPlan ? (
                        <LeasingPlanNotes
                            isPDFExport={isPDFExport}
                            entityCode={entityCode}
                            leasingPlanId={leasingPlan.id}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                {widgetSettings?.planSummaryOnly && isPDFExport ? null : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'end',
                            }}
                        >
                            <h3>Renewal Activity</h3>
                            {isPDFExport ? null : (
                                <div style={{ display: 'flex' }}>
                                    <p
                                        style={{
                                            marginTop: '5px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        For:
                                    </p>
                                    <Select
                                        style={{ width: '100px' }}
                                        options={renewalRangeOptions}
                                        value={endYearOffset}
                                        disabled={isReportWidget}
                                        onChange={(value: number) =>
                                            setEndYearOffset(value)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div>
                            <LeasingPlanActivityTable
                                key={`${LeasingPlanActivityType.Renewals}_${
                                    leasingPlan?.id ?? ''
                                }`}
                                tableType={LeasingPlanActivityType.Renewals}
                                data={filteredLeasingPlanActivities}
                                onSaveRow={onSaveRow}
                                dataGridRef={renewalsGridRef}
                                isPDFExport={isPDFExport}
                            />
                        </div>
                        <h3 style={{ marginTop: '35px' }}>
                            New Lease/Expansion Activity
                        </h3>
                        <LeasingPlanActivityTable
                            key={`${LeasingPlanActivityType.NewLeases}_${
                                leasingPlan?.id ?? ''
                            }`}
                            tableType={LeasingPlanActivityType.NewLeases}
                            data={newLeases}
                            onSaveRow={onSaveRow}
                            onDeleteRow={onDeleteRow}
                            dataGridRef={newLeasesGridRef}
                            isPDFExport={isPDFExport}
                        />
                    </>
                )}
            </>
        ) : (
            <Empty description={getEmptyStateDecription()}></Empty>
        );
    };

    return isPDFExport ? (
        <>
            <h3 style={{ marginBottom: '10px' }}>Leasing Plan</h3>
            {leasingPlanCardContent()}
        </>
    ) : (
        <PermissionedWrapper featureKey={AppFeaturePermissions.LeasingPlan}>
            <Card data-test="leasing-plan-card" title={cardTitle()}>
                {leasingPlanCardContent()}
            </Card>
        </PermissionedWrapper>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    return {
        properties,
    };
};

export default connect(mapState)(LeasingPlanCard);
