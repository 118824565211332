export const propertyDetailsAttributes = [
    'name',
    'street_address',
    'city',
    'state',
    'postal_code',
    'country',
    'occupied_area',
    'entity_code',
    'entity_display_code',
    'asset_type',
    'yardipropertycode',
    'financial_year_ending',
];

export enum PropertyDetailAttributeEnum {
    Amenities = 'amenities',
    NumberOfSpaces = 'number_of_spaces',
    ParkingNotes = 'parking_notes',
    PurchasePrice = 'purchase_price',
    PurchaseDate = 'purchase_date',
    KpiPreferences = 'entity_data_normalization_preference',
    ValueDebtPeriodTiming = 'entity_data_timing_preference',
    YearBuilt = 'year_built',
    LastRenovated = 'last_renovated',
    SiteArea = 'site_area',
    Certifications = 'certifications',
    FinancialYearEnding = 'financial_year_ending',
    GavSource = 'entity_gav_source_preference',
    RateType = 'rate_type',
    AcquisitionCapRate = 'acq_cap',
    AcquisitionDiscountRate = 'acq_discount_rate',
    UWIRR = 'uw_irr',
    UWHoldPeriod = 'hold_period',
    Levered = 'levered',
    Lender = 'lender',
    FixedInterestRate = 'fixed_interest_rate',
    DebtMaturityDate = 'maturity_date',
    AssetManager = 'asset_manager',
    InterestRateSpread = 'interest_rate_spread',
    DebtNotes = 'debt_notes',
    PortfolioManager = 'port_manager',
    Floors = 'number_floors',
    Sprinklers = 'sprinklers',
    BuildingDimensions = 'building_dimensions',
    LoanType = 'loan_type',
    IndustrialLoadingDocks = 'ind_loading_docks',
    PropertyManager = 'prop_manager',
    FloatingIndex = 'floating_index',
    BuildingCount = 'number_buildings',
    OwnershipEntity = 'owner_entity',
    SmokeDetectors = 'smoke_detectors',
    Analyst = 'analyst',
    ManagementCompany = 'pm_company',
    ConstructionType = 'construction_type',
}

export enum propertyProfileGavSources {
    BALANCE_SHEET = 'balance_sheet',
    VALUATIONS = 'valuations',
}

export const valuationsGavSource = 'Valuations';
export const balanceSheetGavSource = 'Balance Sheet';
