import React from 'react';
import { Descriptions, Row as AntRow, Col as AntCol } from 'antd';
import { getFormattedLabel, TimingPreference } from '../PropertyProfileUtils';
import { DASH_DASH } from 'config/constants';
import { CLIENT_RATE_TYPE_ENUM } from '../PropertyDetailsEditor';
import {
    formatMoney,
    formatNumberToDecimalPlace,
    formatPercentage,
} from 'utils/formatters';
import { DebtAndValueKpis } from 'waypoint-types';
import { propertyProfileGavSources } from '../PropertyProfileConstants';
import { safeDivision } from 'waypoint-utils';

interface PropertyDebtValue {
    title?: React.ReactNode;
    loanType: string;
    lender: string;
    debtNotes: string;
    debtMaturityDate: string;
    floatingIndex: string;
    interestRateSpread: number | undefined;
    fixedInterestRate: number | undefined;
    valueAndDebtData: DebtAndValueKpis;
    clientTimingPreferences: string;
    clientRateType: string;
    gavSource?: string;
    selectedValuations?: number;
}

interface DescriptionItemProps {
    value: number | undefined;
    formatFunction: (value: number) => string;
    suffix?: string;
}

const PropertyDebt = ({
    loanType,
    lender,
    debtNotes,
    debtMaturityDate,
    floatingIndex,
    interestRateSpread,
    fixedInterestRate,
    valueAndDebtData,
    clientRateType,
    clientTimingPreferences,
    selectedValuations,
    gavSource,
    title,
}: PropertyDebtValue) => {
    const formatDescriptionItem = ({
        value,
        formatFunction,
        suffix = '',
    }: DescriptionItemProps) =>
        value !== undefined ? formatFunction(value) + suffix : DASH_DASH;

    const timePreference =
        clientTimingPreferences === TimingPreference.YTD ? 'YTD' : 'T-12';

    const ltvDivisor =
        gavSource === propertyProfileGavSources.BALANCE_SHEET
            ? valueAndDebtData?.gross_value
            : selectedValuations ?? null;

    const ltvValue =
        ltvDivisor && valueAndDebtData.debt
            ? safeDivision(valueAndDebtData.debt, ltvDivisor)
            : null;

    const floatingInterestRateValue = `${floatingIndex?.length ? floatingIndex : DASH_DASH} + ${interestRateSpread ? `${interestRateSpread}%` : DASH_DASH}`;
    const fixedInterestRateValue = fixedInterestRate
        ? `${fixedInterestRate}%`
        : DASH_DASH;

    const items = [
        {
            label: 'Loan Type',
            value: loanType?.length ? loanType : DASH_DASH,
        },
        {
            label: 'Lender',
            value: lender?.length ? lender : DASH_DASH,
        },
        {
            label: 'Interest Rate',
            value:
                clientRateType === CLIENT_RATE_TYPE_ENUM.FLOATING
                    ? floatingInterestRateValue
                    : fixedInterestRateValue,
        },
        {
            label: 'Maturity Date',
            value: debtMaturityDate?.length ? debtMaturityDate : DASH_DASH,
        },

        {
            label: 'Balance',
            value: valueAndDebtData?.debt
                ? `${formatMoney(valueAndDebtData?.debt)}`
                : DASH_DASH,
        },
        {
            label: `Debt Service (${timePreference})`,
            value: valueAndDebtData.debt_service
                ? formatMoney(valueAndDebtData.debt_service)
                : DASH_DASH,
        },
        {
            label: 'LTV',
            value: ltvValue ? ltvValue * 100 : undefined,
            formatFunction: formatPercentage,
        },

        {
            label: `DSCR (${timePreference})`,
            value:
                valueAndDebtData.debt_service_coverage_ratio !== 0
                    ? valueAndDebtData.debt_service_coverage_ratio
                    : undefined,
            formatFunction: formatNumberToDecimalPlace,
            suffix: 'x',
            span: 2,
        },
        {
            label: 'Debt Notes',
            value: debtNotes ? debtNotes : DASH_DASH,
            span: 2,
        },
    ];

    return (
        <>
            <AntRow align="stretch" wrap={true}>
                <AntCol>
                    <Descriptions
                        title={
                            <div
                                style={{
                                    backgroundColor: 'lightgray',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}
                            >
                                {title}
                            </div>
                        }
                        size="small"
                        bordered={false}
                        layout="horizontal"
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 2,
                            sm: 1,
                            xs: 1,
                        }}
                        contentStyle={{
                            textAlign: 'left',
                            marginRight: 15,
                        }}
                        labelStyle={{ width: 160 }}
                    >
                        {items.map(
                            (data, dataIndex) =>
                                data && (
                                    <Descriptions.Item
                                        label={getFormattedLabel(data.label)}
                                        span={data.span}
                                        key={dataIndex}
                                    >
                                        {data.formatFunction
                                            ? formatDescriptionItem({
                                                  value: data.value,
                                                  formatFunction:
                                                      data.formatFunction,
                                                  suffix: data.suffix,
                                              })
                                            : data.value}
                                    </Descriptions.Item>
                                )
                        )}
                    </Descriptions>
                </AntCol>
            </AntRow>
        </>
    );
};

export default PropertyDebt;
