import {
    BalanceSheetSettingsBuilder,
    CapitalPlanSettingsBuilder,
    EntityProfileSettingsBuilder,
    ExpirationScheduleSettingsBuilder,
    IncomeStatementSettingsBuilder,
    RecurringChargesSettingsBuilder,
    RentRollGridSettingsBuilder,
    CapitalProjectSettingsBuilder,
    OccupancyTrendSettingsBuilder,
    PropertyInformationSettingsBuilder,
    TopTenantsSettingsBuilder,
    LeasingGuidelinesSettingsBuilder,
    ContractsSettingsBuilder,
    UnitMixSettingsBuilder,
    LeasingPlanSettingsBuilder,
} from './';
import { Select } from 'antd';
import { FinancialExportOptions } from '../../constants';
import { Table } from 'waypoint-react';
import { css } from 'emotion';
import { AccountGraphNode } from 'waypoint-types';
import { getAccountGraph } from 'waypoint-requests';
import {
    decorateAccountGraphForAntDesign,
    decorateFinancialsAccountGraphForAntDesign,
} from 'waypoint-utils';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import {
    AMOUNT_AND_PCT,
    COMPARISON_PERIOD,
    COMPARISON_PERIOD_TERTIARY,
    COMPARISON_TYPE,
    FINANCIAL_YEAR_ENDING,
    NONE_VALUE,
    PERIOD_FIELD_NAME_PRIMARY,
    PERIOD_FIELD_NAME_SECONDARY,
    PERIOD_FIELD_NAME_TERTIARY,
    SELECTED_PERIOD_VALUE,
    VARIANCE_COMPARISON,
    VARIANCE_COMPARISON_TERTIARY,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
} from 'components/financials/comparative-income-statement/constants';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import AgedReceivablesSettingsBuilder from './AgedReceivablesSettingsBuilder';
import NarrativeSettingsBuilder from './NarrativeSettingsBuilder';

/* TODO: use widget type enum here, preferably from back end */
const widgetSettingsBuilders = {
    income_statement: IncomeStatementSettingsBuilder,
    balance_sheet: BalanceSheetSettingsBuilder,
    capital_plan: CapitalPlanSettingsBuilder,
    capital_projects: CapitalProjectSettingsBuilder,
    expiration_schedule: ExpirationScheduleSettingsBuilder,
    priorities_and_objectives: EntityProfileSettingsBuilder,
    hold_sell: EntityProfileSettingsBuilder,
    market_overview: EntityProfileSettingsBuilder,
    swot: EntityProfileSettingsBuilder,
    rent_roll: RentRollGridSettingsBuilder,
    recurring_charge_summary: RecurringChargesSettingsBuilder,
    occupancy_trend: OccupancyTrendSettingsBuilder,
    property_information: PropertyInformationSettingsBuilder,
    top_tenants: TopTenantsSettingsBuilder,
    leasing_guidelines: LeasingGuidelinesSettingsBuilder,
    contracts: ContractsSettingsBuilder,
    unit_mix: UnitMixSettingsBuilder,
    aged_receivables: AgedReceivablesSettingsBuilder,
    leasing_plan: LeasingPlanSettingsBuilder,
    narrative: NarrativeSettingsBuilder,
};

export const getWidgetSettingsBuilder = (widgetType: string) => {
    return widgetSettingsBuilders[
        widgetType as keyof typeof widgetSettingsBuilders
    ];
};

export const convertComparativeIncomeStatementComparisonSelections =
    (selections: { [x: string]: any }): ComparisonSelections => {
        const selectionKeys = Object.keys(selections);
        if (
            selections &&
            !selectionKeys.includes('actual_to_actual_period_type')
        ) {
            if (selections.comparison_type.length === 2) {
                selections.comparison_type.push(NONE_VALUE);
            }
            if (!selectionKeys.includes(PERIOD_FIELD_NAME_TERTIARY)) {
                selections[PERIOD_FIELD_NAME_TERTIARY] =
                    selections[PERIOD_FIELD_NAME_PRIMARY];
            }
            if (!selectionKeys.includes(COMPARISON_PERIOD_TERTIARY)) {
                selections[COMPARISON_PERIOD_TERTIARY] = SELECTED_PERIOD_VALUE;
            }
            if (!selectionKeys.includes(VARIANCE_DISPLAY)) {
                selections[VARIANCE_DISPLAY] = AMOUNT_AND_PCT;
            }
            if (!selectionKeys.includes(VARIANCE_DISPLAY_TERTIARY)) {
                selections[VARIANCE_DISPLAY_TERTIARY] = AMOUNT_AND_PCT;
            }
            return selections as ComparisonSelections;
        }

        const comparisonTypeParts = selections[COMPARISON_TYPE].split('_');
        let comparisonPeriod = 'selected_period';
        let periodicity = selections['periodicity_budget'];

        if (comparisonTypeParts[2] === 'actual') {
            if (
                selections['actual_to_actual_period_type'] ===
                'same_period_prior_year'
            ) {
                comparisonPeriod = 'prior_year';
                periodicity = selections['periodicity_actual_prior_year'];
            } else {
                comparisonPeriod = 'prior_period';
                if (selections['periodicity_actual_prior_year'] === 'month') {
                    periodicity = 'mtd';
                } else if (
                    selections['periodicity_actual_prior_year'] === 'quarter'
                ) {
                    periodicity = 'qtd';
                } else {
                    periodicity = selections['periodicity_actual_prior_year'];
                }
            }
        }

        return {
            [PERIOD_FIELD_NAME_PRIMARY]: selections[PERIOD_FIELD_NAME_PRIMARY],
            [PERIOD_FIELD_NAME_SECONDARY]:
                selections[PERIOD_FIELD_NAME_SECONDARY],
            [PERIOD_FIELD_NAME_TERTIARY]: selections[PERIOD_FIELD_NAME_PRIMARY],
            [COMPARISON_TYPE]: [
                comparisonTypeParts[0],
                comparisonTypeParts[2],
                NONE_VALUE,
            ],
            [FINANCIAL_YEAR_ENDING]: selections[FINANCIAL_YEAR_ENDING],
            [COMPARISON_PERIOD]: comparisonPeriod,
            periodicity,
            [VARIANCE_DISPLAY]: AMOUNT_AND_PCT,
            [VARIANCE_COMPARISON]: selections[VARIANCE_COMPARISON],
            [VARIANCE_COMPARISON_TERTIARY]:
                selections[VARIANCE_COMPARISON_TERTIARY],
            [COMPARISON_PERIOD_TERTIARY]: SELECTED_PERIOD_VALUE,
            [VARIANCE_DISPLAY_TERTIARY]: AMOUNT_AND_PCT,
        };
    };

interface AccountGraphSettingsBuilderProps {
    exportSetting: string;
    setExportSetting: (exportSetting: string) => void;
    accountMappingSelection: string[] | null;
    setAccountMappingSelection: (
        accountMappingSelection: string[] | null
    ) => void;
    treeSelectVisible: boolean;
    setTreeSelectVisible: (treeSelectVisible: boolean) => void;
    accountGraphType: string;
}

export const AccountGraphSettingsBuilder = ({
    exportSetting,
    setExportSetting,
    accountMappingSelection,
    setAccountMappingSelection,
    treeSelectVisible,
    setTreeSelectVisible,
    accountGraphType,
}: AccountGraphSettingsBuilderProps) => {
    const accountSelectContainer = css`
        .ant-collapse {
            border: 0;
            border-radius: 0;
        }
        .ant-collapse-header {
            background-color: #fff;
        }
        .ant-collapse > .ant-collapse-item:last-child {
            border-radius: 0;
        }
        .ant-collapse-item {
            border: 0;
        }
        .ant-collapse-content.ant-collapse-content-active {
            border: 1px solid #d9d9d9;
        }
        .ant-collapse-item:last-child > .ant-collapse-content {
            border-radius: 0;
        }
    `;

    const selectStyle = css`
        display: flex;
        justify-content: ${accountGraphType === 'income_statement'
            ? 'start'
            : 'space-between'};
        align-items: center;
    `;

    const [accountGraph, setAccountGraph] = useState<AccountGraphNode[] | null>(
        null
    );

    const fetchAccountGraphData = async () => {
        const data = await getAccountGraph(accountGraphType, {
            stakeholder: null,
            percentageType: null,
        });

        return decorateAccountGraphForAntDesign(data.children);
    };

    const {
        data: accountGraphData,
        error: accountGraphError,
        isValidating: isLoadingAccountGraph,
    } = useSWR(
        `widgetAccountGraph/${accountGraphType}`,
        fetchAccountGraphData,
        {
            revalidateOnFocus: true,
            revalidateOnMount: true,
        }
    );

    useEffect(() => {
        if (accountGraphData) {
            setAccountGraph(
                decorateFinancialsAccountGraphForAntDesign(accountGraphData)
            );
        }
    }, [accountGraphData]);

    const accountTree = () => {
        const hasAccountGraphData = !(
            !accountGraph ||
            isLoadingAccountGraph ||
            accountGraphError
        );
        return !hasAccountGraphData ? (
            <div>Loading...</div>
        ) : (
            <Table
                style={{ marginTop: 10 }}
                size="small"
                columns={[
                    {
                        title: 'Account',
                        dataIndex: 'name',
                        key: 'name',
                    },
                ]}
                height={200}
                expandable={{
                    expandedRowKeys: accountMappingSelection ?? [],
                    expandIconColumnIndex: 0,
                }}
                data={accountGraph}
                onExpand={(expanded, record: any) => {
                    const expandedKeys = new Set(accountMappingSelection);
                    if (expanded) {
                        expandedKeys.add(record.key);
                    } else {
                        expandedKeys.delete(record.key);
                    }
                    setAccountMappingSelection([...expandedKeys]);
                }}
            />
        );
    };
    const defaultSelectedValue =
        exportSetting === '' ? FinancialExportOptions.SUMMARY : exportSetting;
    return (
        <div
            className={accountSelectContainer}
            style={{ marginTop: '20px', marginBottom: '35px' }}
        >
            <div>
                <div className={selectStyle}>
                    <b>Export Level</b>
                    <Select
                        defaultValue={defaultSelectedValue}
                        style={{ width: 120, marginLeft: '10px' }}
                        onChange={(value) => {
                            setExportSetting(value);
                            if (!(value === FinancialExportOptions.CUSTOM)) {
                                setAccountMappingSelection(null);
                            }
                            setTreeSelectVisible(
                                value === FinancialExportOptions.CUSTOM
                            );
                        }}
                    >
                        <Select.Option value={FinancialExportOptions.SUMMARY}>
                            Summary
                        </Select.Option>
                        <Select.Option
                            value={FinancialExportOptions.FULL_DETAIL}
                        >
                            Full Detail
                        </Select.Option>
                        <Select.Option value={FinancialExportOptions.CUSTOM}>
                            Custom
                        </Select.Option>
                    </Select>
                </div>
                <div style={{ marginTop: 5 }}>
                    {treeSelectVisible && (
                        <>
                            <div>
                                Expand the accounts that you want to be exported
                            </div>
                            {accountTree()}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
