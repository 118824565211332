import { PROPERTY_CACHE_KEY } from 'config/constants';
import { clone } from 'lodash';
import { properties as TYPES } from 'state/actionTypes';
import { get } from 'utils/ajax';
import { reject, uniq } from 'lodash';
import Promise from 'bluebird';

// FIXME (Nicholas): Move this file into a reducer file to match all the others
export function getProperties(propIds) {
    if (typeof propIds === 'string') {
        propIds = [propIds];
    }

    return (dispatch, getState) => {
        const { properties } = getState();

        // get all accessible properties if there is no existing data
        const missingIds = uniq(
            reject(propIds, (id) => {
                return (
                    id in properties ||
                    `${PROPERTY_CACHE_KEY + id}` in properties
                );
            })
        );

        // get properties one-by-one to merge any new properties we need
        if (missingIds.length) {
            const requestArr = missingIds.map((id) => {
                return get(
                    'api/v1/ClientUser/propertyDetails/' +
                        id.match(/\d/g).join('')
                );
            });

            Promise.all(requestArr).then(
                (propertiesList) => {
                    const propertiesObj = {};
                    propertiesList.forEach((response) => {
                        const property = response.body.data;
                        properties[PROPERTY_CACHE_KEY + property.id] = property;
                    });
                    dispatch({
                        type: TYPES.MERGE_PROPERTIES,
                        payload: propertiesObj,
                    });
                },
                (err) => {
                    // TODO (Nicholas): Handle this error
                    throw err;
                }
            );
        }

        return undefined;
    };
}

export function setAccessList(state, action) {
    const property = state[PROPERTY_CACHE_KEY + action.payload.id];
    property.usersWithAccess = action.payload.users;

    // Note: Shallow clone because we did the merge by hand above
    return clone(state);
}

function updateEntityPhoto(state, action) {
    const property = state[PROPERTY_CACHE_KEY + action.payload.id];
    property.entityPhoto = action.payload.entityPhoto;
    return clone(state);
}

export default function reducer(state = {}, action) {
    let payload = action.payload;

    if (typeof action.payload === 'string') {
        try {
            payload = JSON.parse(action.payload).data;
        } catch (e) {
            // TODO (Nicholas): remove this try catch, parse before emitting action
        }
    }

    switch (action.type) {
        case TYPES.MERGE:
            return Object.assign({}, state, payload);

        case TYPES.SET_ACCESS_LIST:
            return setAccessList(state, action);

        case TYPES.UPDATE_ENTITY_PHOTO:
            return updateEntityPhoto(state, action);

        default:
            return state;
    }
}
