import React from 'react';
import { Descriptions, Row as AntRow } from 'antd';
import {
    KPIMode,
    getFormattedLabel,
    calculateKPI,
} from '../PropertyProfileUtils';
import { formatMoney } from 'utils/formatters';
import { DASH_DASH } from 'config/constants';
import { PropertyInformationSectionProps } from '../types';
import { usePropertyInformation } from 'waypoint-hooks';

const labelStyle = {
    width: 160,
};

const PropertyPurchaseInformation = ({
    propertyInfo,
    entityCode,
    selectedDataLevel,
}: PropertyInformationSectionProps) => {
    const {
        clientPurchasePrice,
        clientPurchaseDate,
        clientKpiPreferences,
        leasePropertyData,
        holdPeriod,
        acquisitionCapRate,
        acquisitionDiscountRate,
        uwIRR,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    const purchasePriceByKPI = calculateKPI(
        clientKpiPreferences as KPIMode,
        clientPurchasePrice ?? 0,
        leasePropertyData
    );

    const kpiPreference =
        clientKpiPreferences === KPIMode.PSF ? 'PSF' : 'Per Unit';

    const items = [
        {
            label: 'Purchase Price',
            value: clientPurchasePrice
                ? `${formatMoney(clientPurchasePrice)} | ${purchasePriceByKPI} ${kpiPreference}`
                : DASH_DASH,
        },
        {
            label: 'Purchase Date',
            value: clientPurchaseDate.length ? clientPurchaseDate : DASH_DASH,
        },
        {
            label: 'UW Hold Period',
            value: holdPeriod ? `${holdPeriod} Years` : DASH_DASH,
        },
        {
            label: 'Cap Rate',
            value: acquisitionCapRate ? `${acquisitionCapRate}%` : DASH_DASH,
        },
        {
            label: 'Discount Rate',
            value: acquisitionDiscountRate
                ? `${acquisitionDiscountRate}%`
                : DASH_DASH,
        },
        {
            label: 'UW IRR',
            value: uwIRR ? `${uwIRR}%` : DASH_DASH,
        },
    ];

    return (
        <AntRow align="stretch" wrap={true}>
            <Descriptions
                size="small"
                bordered={false}
                title={
                    <div
                        style={{
                            backgroundColor: 'lightgray',
                            padding: '4px 8px',
                            borderRadius: '4px',
                        }}
                    >
                        Purchase Information
                    </div>
                }
                layout="horizontal"
                column={{
                    xxl: 2,
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 1,
                    xs: 1,
                }}
                contentStyle={{
                    textAlign: 'left',
                    marginRight: 15,
                }}
                labelStyle={labelStyle}
            >
                {items.map((item, index) => (
                    <Descriptions.Item
                        key={index}
                        label={getFormattedLabel(item.label)}
                    >
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </AntRow>
    );
};

export default PropertyPurchaseInformation;
