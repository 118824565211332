import { DASH_DASH } from 'config/constants';
import { sumBy, merge } from 'lodash';
import { toCalendarDate } from 'components/dates/utils';
import { formatPercentage, formatNumber } from 'utils/formatters';
import { stringSort, numericalSort, dateSort } from 'utils/tables/sorters';

export type EntityAttribute = any;

interface PropertyAttributesTableColumn {
    title: string;
    dataIndex: string;
    type: FormatterType;
    sorter: (a: EntityAttribute, b: EntityAttribute) => number;
}

export const attributes: PropertyAttributesTableColumn[] = [
    {
        title: 'Entity',
        dataIndex: 'name',
        type: 'text',
        sorter: (a, b) => stringSort(b.name, a.name),
    },
    {
        title: 'Entity Code',
        dataIndex: 'yardipropertycode',
        type: 'text',
        sorter: (a, b) => stringSort(b.yardipropertycode, a.yardipropertycode),
    },
    {
        title: 'Portfolio',
        dataIndex: 'portfolio',
        type: 'text',
        sorter: (a, b) => stringSort(b.portfolio, a.portfolio),
    },
    {
        title: 'Status',
        dataIndex: 'soldstatus',
        type: 'text',
        sorter: (a, b) => stringSort(b.soldstatus, a.soldstatus),
    },
    {
        title: 'Investment Vehicle',
        dataIndex: 'investmentvehicle',
        type: 'text',
        sorter: (a, b) => stringSort(b.investmentvehicle, a.investmentvehicle),
    },
    {
        title: 'Strategy',
        dataIndex: 'strategy',
        type: 'text',
        sorter: (a, b) => stringSort(b.strategy, a.strategy),
    },
    {
        title: 'Address',
        dataIndex: 'street_address',
        type: 'text',
        sorter: (a, b) => stringSort(b.street_address, a.street_address),
    },
    {
        title: 'City',
        dataIndex: 'city',
        type: 'text',
        sorter: (a, b) => stringSort(b.city, a.city),
    },
    {
        title: 'Province',
        dataIndex: 'province',
        type: 'text',
        sorter: (a, b) => stringSort(b.province, a.province),
    },
    {
        title: 'Region',
        dataIndex: 'region',
        type: 'text',
        sorter: (a, b) => stringSort(b.region, a.region),
    },
    {
        title: 'Asset Type',
        dataIndex: 'asset_type',
        type: 'text',
        sorter: (a, b) => stringSort(b.asset_type, a.asset_type),
    },
    {
        title: 'Property Sub Type',
        dataIndex: 'propertysubtype',
        type: 'text',
        sorter: (a, b) => stringSort(b.propertysubtype, a.propertysubtype),
    },
    {
        title: 'Square Footage',
        dataIndex: 'total_area',
        type: 'integer',
        sorter: (a, b) => numericalSort(a.total_area, b.total_area),
    },
    {
        title: 'Properties',
        dataIndex: 'numbuildings',
        type: 'integer',
        sorter: (a, b) => numericalSort(a.numbuildings, b.numbuildings),
    },
    {
        title: 'Tenants',
        dataIndex: 'tenant_count',
        type: 'integer',
        sorter: (a, b) => numericalSort(a.tenant_count, b.tenant_count),
    },
    {
        title: 'Occupancy',
        dataIndex: 'percent_occupied',
        type: 'float',
        sorter: (a, b) => numericalSort(a.percent_occupied, b.percent_occupied),
    },
    {
        title: 'WALE',
        dataIndex: 'wale_years',
        type: 'integer',
        sorter: (a, b) => numericalSort(a.wale_years, b.wale_years),
    },
    {
        title: 'Year Built',
        dataIndex: 'yearbuilt',
        type: 'integer',
        sorter: (a, b) => numericalSort(a.yearbuilt, b.yearbuilt),
    },
    {
        title: 'Certification',
        dataIndex: 'certification',
        type: 'text',
        sorter: (a, b) => stringSort(b.certification, a.certification),
    },
    {
        title: 'Asset Manager Name',
        dataIndex: 'assetmanagername',
        type: 'text',
        sorter: (a, b) => stringSort(b.assetmanagername, a.assetmanagername),
    },
    {
        title: 'Property Manager',
        dataIndex: 'propertymanager',
        type: 'text',
        sorter: (a, b) => stringSort(b.propertymanager, a.propertymanager),
    },
    {
        title: 'Acquisition Date',
        dataIndex: 'acquisitiondate',
        type: 'date',
        sorter: (a, b) => dateSort(a.acquisitiondate, b.acquisitiondate),
    },
    {
        title: 'Source',
        dataIndex: 'source',
        type: 'text',
        sorter: (a, b) => stringSort(b.source, a.source),
    },
];

const fixedColumnConfig = {
    width: 300,
    fixed: 'left',
};

const toDefaultColumnConfig = (attribute: EntityAttribute) => ({
    title: attribute.title,
    dataIndex: attribute.dataIndex,
    type: attribute.type,
    sorter: attribute.sorter,
    width: 200,
    ellipsis: true,
    align:
        attribute.type === 'text' || attribute.type === 'date'
            ? 'left'
            : 'right',
});

export type FormatterType = 'integer' | 'date' | 'float' | 'text';

/**
 * Returns an object with a `render` function that serves as a formatter for
 * Antd table.
 *
 * Possible values for `type`: `integer`, `date` or `percent`.
 *
 * Note, that `type` is an option parameter.
 *
 * The formatter object will render `--`, by default, if the passed in value is
 * `undefined` or `null`.
 *
 * @param {string} [type] The type of formatter for the column value
 * @return {object} The formatter object
 */
export function getFormatterFor<T>(type: FormatterType): {
    render(value: T): string;
} {
    function renderHelper(formatter?: (value: T) => string) {
        return {
            render(value: any): string {
                if (value === undefined || value === null) {
                    return DASH_DASH;
                }
                return formatter
                    ? formatter(type === 'float' ? value * 100 : value)
                    : value;
            },
        };
    }

    const formattersByType = new Map<FormatterType, (value: T) => string>([
        ['date', toCalendarDate],
        ['integer', formatNumber],
        ['float', formatPercentage],
    ]);

    const valueFormatter = formattersByType.get(type);

    return renderHelper(valueFormatter);
}

// NOTE: The goal here is to translate an attribute object into a column config
const attributeToColumnConfig = (attribute: EntityAttribute, index: number) => {
    let config = toDefaultColumnConfig(attribute);
    // now, override as needed based on attribute type or dataIndex
    if (index === 0) {
        config = merge(config, fixedColumnConfig);
    }

    config = merge(config, getFormatterFor(config.type));

    return config;
};

export const propertyAttributeColumns = attributes.map(attributeToColumnConfig);

export const propertyAttributeTableWidth = sumBy(
    propertyAttributeColumns,
    'width'
);

export const fixedColumnsWidth = propertyAttributeColumns.reduce(
    (acc, column: any) => {
        return (column.fixed === 'left' ? column.width : 0) + acc;
    },
    0
);

export const selectedAttributes = attributes.map(({ dataIndex }) => dataIndex);

/**
 * Returns a new list of object with additional `key` property that is required
 * by atnd table.
 *
 * @param {object[]} data A list of objects that represent row data
 * @returns {object[]} Augments list of objects
 */
export function getDataFor<T>(data: T[] = []): (T & { key: number })[] {
    return data.map((datum, index) => {
        return Object.assign(
            {
                key: index,
            },
            datum
        );
    });
}
