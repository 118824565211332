import React from 'react';
import useSWR from 'swr';
import {
    getAttributes,
    getGroupableAndFilterableAttributes,
} from 'waypoint-requests';
import { EntityAttributesContext } from './EntityAttributesContext';
import {
    EntityAttributesProps,
    EntityAttributesContextData,
} from './EntityAttributesTypes';

const useAttributes = (): {
    isLoading: boolean;
    isNetworkError: boolean;
    attributes: EntityAttributesContextData | null;
} => {
    const { data: attributes, error: attributesError } = useSWR(
        '/api/attributes',
        getAttributes,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    const {
        data: groupableAndFilterableAttributes,
        error: groupableAndFilterableError,
    } = useSWR(
        '/api/attributes/groupable-filterable',
        getGroupableAndFilterableAttributes,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !attributes || !groupableAndFilterableAttributes,
        isNetworkError: !!(attributesError ?? groupableAndFilterableError),
        attributes:
            attributes && groupableAndFilterableAttributes
                ? {
                      ...attributes,
                      groupableAndFilterableAttributes,
                  }
                : null,
    };
};

export const EntityAttributesProvider = (
    props: EntityAttributesProps
): JSX.Element => {
    const {
        attributes,
        isLoading: isAttributesLoading,
        isNetworkError,
    } = useAttributes();

    return (
        <EntityAttributesContext.Provider
            value={{
                data: attributes,
                isAttributesLoading,
                isAttributesNetworkError: isNetworkError,
            }}
        >
            {props.children}
        </EntityAttributesContext.Provider>
    );
};
