import { get, find } from 'lodash';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';

export const getDisplayTypeLabel = (displayType) => {
    return get(find(displayTypeOptions, { value: displayType }), 'label');
};

export const getDisplayTypeAbbreviation = (displayType) => {
    return get(
        find(displayTypeOptions, { value: displayType }),
        'abbreviation'
    );
};
