import React, { ReactElement } from 'react';

import { LeasingAsOfDateType } from 'components/app/as-of-date';
import { toCalendarDate } from 'components/dates/utils';
import { DASH_DASH } from 'config/constants';

interface LeasingAsOfDateLableProps {
    date: LeasingAsOfDateType | null;
}

const WIDTH = '430px';

export function LeasingAsOfDateLabel({
    date,
}: LeasingAsOfDateLableProps): ReactElement {
    return (
        <div>
            <span data-test-message style={{ width: WIDTH }}>
                Updated on&nbsp;
                {date ? toCalendarDate(date.last_loaded_at) : DASH_DASH}
            </span>
        </div>
    );
}
