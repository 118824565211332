import { Card, Divider, Skeleton } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import TopTenantsTable from './TopTenantsTable';
import TopTenantsChart from './TopTenantsChart';
import { DataGrid } from 'devextreme-react';
import {
    TenantData,
    TopTenantsMetricsEnum,
    metricOptions,
    sortTenantsByMetric,
    topNOptions,
} from './topTenantsUtils';
import { css } from 'emotion';
import TopTenantsCardHeader from './TopTenantsCardHeader';
import { sanitize } from 'dompurify';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { useTopTenants } from 'waypoint-hooks/data-access/useTopTenants';

const containerStyle = (isPDFExport?: boolean) => css`
    display: block;
    align-items: flex-end;
    flex-wrap: ${isPDFExport ? 'wrap' : 'nowrap'};
    @media screen and (max-width: 1268px) {
        flex-wrap: wrap;
    }
`;

interface TopTenantsCardProps {
    entityCodes?: string[];
    widgetMetric?: string;
    widgetTopNTenants?: string;
    widgetSelectedChargeCode?: string[];
    widgetVisualOption?: string;
    isPDFExport?: boolean;
    hideSelectorsFromTitle?: boolean;
    dataGridRef?: React.RefObject<DataGrid<any, any>>;
    setIsChartReadyToExport?: (isChartReadyToExport: boolean) => void;
    chartExportId?: string;
    gridExportId?: string;
    exportWidth?: string;
    narrativePosition?: string;
    narrativeText?: string;
    setIsLoading?: (isLoading: boolean) => void;
    isPropertyProfilePage?: boolean;
}

const TopTenantsCard = ({
    entityCodes,
    widgetMetric,
    widgetSelectedChargeCode,
    widgetTopNTenants,
    widgetVisualOption,
    hideSelectorsFromTitle,
    dataGridRef,
    isPDFExport,
    chartExportId,
    gridExportId,
    setIsChartReadyToExport,
    exportWidth,
    narrativePosition,
    narrativeText,
    setIsLoading,
    isPropertyProfilePage,
}: TopTenantsCardProps) => {
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>(
        widgetSelectedChargeCode ?? []
    );
    const [selectedMetric, setSelectedMetric] = useState<TopTenantsMetricsEnum>(
        (widgetMetric as TopTenantsMetricsEnum) ?? metricOptions[0].value
    );
    const [topNTenants, setTopNTenants] = useState<string>(
        widgetTopNTenants ?? topNOptions[1].value
    );

    const selectedDataLevel = useSelectedDataLevel();

    const globalEntityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const entityCodesInUse = entityCodes ?? globalEntityCodes;

    useEffect(() => {
        if (selectedMetric === metricOptions[0].value) {
            setSelectedChargeCode([]);
        }
    }, [selectedMetric]);

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode]
    );

    const {
        data = {
            tenants: [] as TenantData[],
            sqFtCalculations: {},
            buckets: {},
        },
        isLoading,
    } = useTopTenants(
        entityCodesInUse,
        selectedDataLevel,
        selectedChargeCode,
        topNOptions[topNOptions.length - 1].value
    );

    useEffect(() => {
        // for PDF exports
        setIsLoading && setIsLoading(isLoading);
    }, [isLoading]);

    const { tenants, sqFtCalculations, buckets } = data;

    const slicedSortedTenants = useMemo(() => {
        return sortTenantsByMetric(selectedMetric, tenants).slice(
            0,
            Number(topNTenants)
        );
    }, [tenants, topNTenants, selectedMetric]);

    const narrativeTextBox = () => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: sanitize(narrativeText ?? ''),
                }}
            />
        );
    };
    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.TopTenants}>
            <Card
                data-test="top-tenants-card"
                bordered={!isPDFExport}
                title={
                    hideSelectorsFromTitle ? null : (
                        <TopTenantsCardHeader
                            selectedMetric={selectedMetric}
                            setSelectedMetric={setSelectedMetric}
                            topNTenants={topNTenants}
                            setTopNTenants={setTopNTenants}
                            isRentRollLoading={isLoading}
                            onChange={onChange}
                            recurringCharges={buckets}
                            selectedChargeCode={selectedChargeCode}
                        />
                    )
                }
            >
                {isLoading ? (
                    <Skeleton paragraph={{ rows: 10 }} />
                ) : (
                    <div className={containerStyle(isPDFExport)}>
                        <div
                            id={chartExportId}
                            data-test="top-tenants-chart-container"
                            style={{ width: '101%' }}
                        >
                            {narrativePosition === 'above'
                                ? narrativeTextBox()
                                : null}
                            <TopTenantsChart
                                tenants={slicedSortedTenants}
                                setIsChartReadyToExport={
                                    setIsChartReadyToExport
                                }
                                isPDFExport={isPDFExport}
                                isPropertyProfilePage={isPropertyProfilePage}
                                exportWidth={exportWidth}
                                chartExportId={chartExportId}
                                metric={selectedMetric}
                                widgetVisualOption={widgetVisualOption}
                            />
                        </div>

                        {!isPDFExport ? (
                            <Divider
                                style={{
                                    margin: '18px auto',
                                }}
                            />
                        ) : null}

                        <div
                            id={gridExportId}
                            data-test="top-tenants-table-container"
                            style={{ overflow: 'auto' }}
                        >
                            <TopTenantsTable
                                data={slicedSortedTenants}
                                sqFtCalculations={sqFtCalculations}
                                dataGridRef={dataGridRef}
                                isPDFExport={isPDFExport}
                                isPropertyProfilePage={isPropertyProfilePage}
                            />
                        </div>
                    </div>
                )}
            </Card>
        </PermissionedWrapper>
    );
};

export default TopTenantsCard;
