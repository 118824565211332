import { isNil, isEqual, get, isEmpty } from 'lodash';
import memoizeOne from 'memoize-one';
import { numericalSort } from 'utils/tables/sorters';
import { Moment } from 'moment';
import {
    AccountMappingChildren,
    DisplayType,
    RankingData,
    PropertyAttributes,
} from 'waypoint-types';
import { formatDateRangeForCopy, getDateRangeForPeriod } from 'waypoint-utils';
import { calculateWeightedAverage } from 'waypoint-utils/analytics/WeightedAverageUtils';
import { getDisplayTypeDataField } from 'components/analytics/ranking/utils/DisplayTypeUtils';
import { DEFAULT_PERIOD_RANGE } from 'config/constants';
import moment from 'moment';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';

interface GroupedProperties {
    [key: string]: PropertyAttributes[];
}

const partition = (data: RankingData[]) => {
    const unrankedProperties = data?.filter((property: RankingData) => {
        return !property.account_mapping.property_rank;
    });

    const rankedProperties = data?.filter(
        (property) => property.account_mapping.property_rank
    );

    return { unrankedProperties, rankedProperties };
};

export const partitionRankedAndUnranked = memoizeOne(partition, isEqual);

export const getDefaultAccountCode = (accountGraph: AccountGraphObjectType) => {
    if (!accountGraph) {
        return;
    }
    return get(accountGraph, '[0]account_mapping_code');
};

export const getRankingSubheadingFrom = (
    selectedPeriod: Moment | Moment[]
): string | null => {
    if (isNil(selectedPeriod)) {
        return null;
    }

    return `${formatDateRangeForCopy(selectedPeriod)}`;
};

const getSum = (array: any[], prop: string): number =>
    array
        .filter((x) => x !== undefined)
        .map((p) => p[prop])
        .reduce((prev, next) => prev + next);

export const groupBy = (
    items: any[] | undefined,
    key: string
): GroupedProperties | undefined =>
    items?.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item],
        }),
        {}
    );

const calculateWeightChildAccounts = (
    rankingItemsArray: RankingData[],
    displayTypeValue: DisplayType
) => {
    const displayDataTypeField: keyof RankingData | undefined =
        getDisplayTypeDataField(displayTypeValue);
    const weightedChildAccounts = [];

    for (const child of rankingItemsArray[0].account_mapping.children) {
        const childAccountMappingCode = child.account_mapping_code;

        const weightedAverage = calculateWeightedAverage(
            rankingItemsArray,
            (x: RankingData) =>
                displayDataTypeField ? Number(x[displayDataTypeField]) : 0,
            // find the same child account for each row
            (x: RankingData) =>
                x.account_mapping.children.find(
                    (x) => x.account_mapping_code === childAccountMappingCode
                )?.property_rank_value ?? 0
        );

        weightedChildAccounts.push({
            name: child.account_mapping_name,
            account_mapping_code: child.account_mapping_code,
            account_mapping_name: child.account_mapping_name,
            property_rank_value: weightedAverage,
            variance_is_good: null,
            property_rank_percentage: child.property_rank_percentage,
        });
    }

    return weightedChildAccounts;
};

const sumAndMergeChildrens = (
    childrenAccountsArray: AccountMappingChildren[]
) => {
    return childrenAccountsArray.reduce(
        (childrensItemMerged: AccountMappingChildren[], childrenItem) => {
            let accountItem: any = childrensItemMerged.find(
                (e: AccountMappingChildren) =>
                    e.account_mapping_code === childrenItem.account_mapping_code
            );

            if (!accountItem) {
                childrensItemMerged.push(Object.assign({}, childrenItem));
            } else {
                accountItem.property_rank_value +=
                    childrenItem.property_rank_value;
            }
            return childrensItemMerged;
        },
        []
    );
};

const processRankingGroupedData = (
    propertyRankingData: RankingData[],
    groupOfProperties: any[] | undefined,
    groupingCriteria: string,
    displayValue: DisplayType | any
) => {
    if (propertyRankingData.length === 0) {
        return [];
    }

    let groupedRankingItem: RankingData[] = [];

    const isGrossDisplayValue = displayValue === DisplayType.Gross;

    const displayDataTypeField: keyof RankingData | undefined =
        getDisplayTypeDataField(displayValue);

    const groupedProperties = groupBy(groupOfProperties, groupingCriteria);

    if (!groupedProperties) {
        return [];
    }

    Object.keys(groupedProperties).forEach((key: string) => {
        const propertiesRankingItems = groupedProperties[key]
            .map(({ entity_code }) => {
                const rankingItem = propertyRankingData?.find(
                    (e) => e.property_id === entity_code
                );
                return rankingItem;
            })
            .filter((x) => x !== undefined) as RankingData[];

        if (!propertiesRankingItems.length) {
            return;
        }

        const occupied_sq_ft = getSum(propertiesRankingItems, 'occupied_sq_ft');
        const total_units = getSum(propertiesRankingItems, 'total_units');
        const occupied_units = getSum(propertiesRankingItems, 'occupied_units');
        const rentable_sq_ft = getSum(propertiesRankingItems, 'rentable_sq_ft');
        // Merge and sum all the childrens account to have only of them
        const property_rank_value_childrens: AccountMappingChildren[] =
            sumAndMergeChildrens(
                propertiesRankingItems
                    .map((p: RankingData) => p.account_mapping.children)
                    .flat()
            );

        const weightedChildAccounts: AccountMappingChildren[] =
            calculateWeightChildAccounts(propertiesRankingItems, displayValue);

        const account_mapping_rank_value = isGrossDisplayValue
            ? propertiesRankingItems
                  .map(
                      (p: RankingData) =>
                          p.account_mapping.property_rank_value ?? 0
                  )
                  .reduce((prev: number, next: number) => prev + next)
            : calculateWeightedAverage(
                  propertiesRankingItems,
                  (x: RankingData) =>
                      displayDataTypeField
                          ? Number(x[displayDataTypeField])
                          : 0,
                  (x: RankingData) => x.account_mapping.property_rank_value ?? 0
              );
        groupedRankingItem.push({
            property_name: key === 'null' ? 'Unassigned' : key,
            property_id: null,
            occupied_sq_ft,
            total_units,
            occupied_units,
            rentable_sq_ft,
            account_mapping: {
                name: propertiesRankingItems[0].account_mapping
                    .account_mapping_name,
                parent_account_name: null,
                parent_variance_is_good: null,
                account_mapping_code:
                    propertiesRankingItems[0].account_mapping
                        .account_mapping_code,
                account_mapping_name:
                    propertiesRankingItems[0].account_mapping
                        .account_mapping_name,
                property_rank_value: account_mapping_rank_value,
                variance_is_good:
                    propertiesRankingItems[0].account_mapping.variance_is_good,
                property_rank: null,
                parent_account_percentage: null,
                parent_account_value: null,
                children: isGrossDisplayValue
                    ? property_rank_value_childrens
                    : weightedChildAccounts,
            },
        });
    });

    return groupedRankingItem.sort((a, b) =>
        numericalSort(
            b.account_mapping.property_rank_value ?? 0,
            a.account_mapping.property_rank_value ?? 0
        )
    );
};

const processRankingGroupedDataFOrTable = (
    selectedAttribute: string,
    propertyRankingData: any,
    attributesByEntitiesCode: any
) => {
    const groupedRankingTable: any[] = [];

    propertyRankingData.forEach((d: any) => {
        const entity = attributesByEntitiesCode?.find(
            (e: any) => e.entity_code === d.property_id
        );
        const updatedObject = {
            ...d,
            attribute: entity?.[selectedAttribute],
        };

        groupedRankingTable.push(updatedObject);
    });

    return groupedRankingTable.sort((a, b) =>
        numericalSort(
            b.account_mapping.property_rank_value ?? 0,
            a.account_mapping.property_rank_value ?? 0
        )
    );
};

export const calculateRankingGroupedData = memoizeOne(
    processRankingGroupedData,
    isEqual
);

export const calculateRankingGroupedDataForTable = memoizeOne(
    processRankingGroupedDataFOrTable,
    isEqual
);

export const getChartData = (
    isPropertyRankingLoading: boolean,
    propertyRankingData: RankingData[]
): RankingData[] => {
    if (isPropertyRankingLoading) {
        return [];
    }

    const { unrankedProperties, rankedProperties } =
        partitionRankedAndUnranked(propertyRankingData);

    const allAreUnranked: boolean = isEmpty(rankedProperties);
    const someAreUnranked: boolean =
        !allAreUnranked && !isEmpty(unrankedProperties);

    if (someAreUnranked) {
        return rankedProperties;
    }

    return propertyRankingData;
};

export const getDefaultGlobalPeriod = (asOfDate: string) => {
    const [start, end] = getDateRangeForPeriod(
        DEFAULT_PERIOD_RANGE,
        moment(asOfDate).toDate()
    );
    return [moment(start), moment(end)];
};
