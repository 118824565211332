import { merge, cloneDeep } from 'lodash';
import { images as TYPES } from 'state/actionTypes';

export const defaultState = {};

function load(state, action) {
    return cloneDeep(merge(state, action.payload));
}

export default function reducer(images = defaultState, action) {
    const { type } = action;

    switch (type) {
        case TYPES.LOAD:
            return load(images, action);
        default:
            break;
    }

    return images;
}
