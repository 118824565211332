import { useMemo, useState, useEffect } from 'react';
import DataGrid, {
    ColumnChooser,
    Export,
    Paging,
    FilterRow,
    HeaderFilter,
    Pager,
    FilterPanel,
    Summary,
    Sorting,
    GroupPanel,
    Grouping,
    Toolbar,
    Item,
    SortByGroupSummaryInfo,
    IColumnProps,
} from 'devextreme-react/data-grid';
import dxDataGrid, {
    ExportingEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
    searchAccountGraphForAccount,
} from 'waypoint-utils';
import {
    RankingData,
    DisplayType,
    AccountMappingChildren,
    AccountGraphObjectType,
} from 'waypoint-types';
import { getRankingColumns } from 'components/analytics/ranking/utils/RankingTableUtils';
import { getDisplayTypeDataField } from 'components/analytics/ranking/utils/DisplayTypeUtils';
import {
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import theme from 'config/theme';
import { useSortByGroupSummaryInfo } from 'waypoint-hooks';

interface RankingTableProps {
    data: RankingData[];
    attributeSelectedName: string;
    isGroupByAttribute: boolean;
    selections: {
        accountMapping: {
            code: string;
        };
        displayType: string;
    };
    accountGraph: AccountGraphObjectType;
}

interface GroupingOptions {
    value: string;
    label: string;
}

const allowedPageSizes = [20, 40, 60, 80, 100];

const RankingTable = ({
    accountGraph,
    data,
    isGroupByAttribute,
    attributeSelectedName,
    selections: { accountMapping, displayType },
}: RankingTableProps) => {
    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Ranking',
            filename: 'Ranking.xlsx',
        });
    };

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        sortVisible,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isExpanded = e.isExpanded && e.rowType !== 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isExpanded) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.backgroundColor = theme.colors.blues.focusRow;
            e.rowElement.style.borderColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
    };

    const selectedAccountMapping: RankingData | null =
        searchAccountGraphForAccount(accountGraph, accountMapping.code);

    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const [rankingGrid, setRankingGrid] = useState<{
        instance: dxDataGrid;
    } | null>(null);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        rankingGrid?.instance.columnOption(0, 'groupIndex', -1);
        if (isGroupByAttribute && rankingGrid) {
            rankingGrid.instance.columnOption(0, 'groupIndex', 0);
        }
    }, [isGroupByAttribute, rankingGrid]);

    const [columns, totalItems, groupItems] = useMemo(
        () =>
            selectedAccountMapping
                ? getRankingColumns(
                      displayType,
                      selectedAccountMapping,
                      attributeSelectedName,
                      isGroupByAttribute
                  )
                : [[], []],
        [
            selectedAccountMapping,
            displayType,
            attributeSelectedName,
            isGroupByAttribute,
        ]
    );

    const calculateCustomSummary = (options: {
        name: string;
        summaryProcess: 'start' | 'calculate' | 'finalize';
        value: RankingData;
        totalValue: number;
        totalDisplayValue: number;
    }) => {
        if (options.name.startsWith('weightedAverageDisplayValue')) {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = 0;
                    options.totalDisplayValue = 0;
                    break;
                }
                case 'calculate': {
                    if (
                        options.value.account_mapping.property_rank_value ===
                        null
                    ) {
                        return;
                    }

                    const accountCode = options.name.substring(
                        options.name.indexOf('_') + 1
                    );

                    const childAccount: AccountMappingChildren | undefined =
                        options.value.account_mapping.children.find(
                            (account) =>
                                account.account_mapping_code === accountCode
                        );

                    const dataField = getDisplayTypeDataField(
                        displayType as DisplayType
                    );

                    options.totalDisplayValue += dataField
                        ? Number(options.value[dataField])
                        : 0;
                    const weight = dataField
                        ? Number(options.value[dataField])
                        : 1;

                    // Add to the total depending on if we found a child account,
                    // or if it is the parent account (childAccount === undefined)
                    const accountRowValue = childAccount
                        ? childAccount?.property_rank_value ?? 0
                        : options.value.account_mapping.property_rank_value;
                    options.totalValue += weight * accountRowValue;
                    break;
                }
                case 'finalize': {
                    options.totalValue /= Math.max(
                        options.totalDisplayValue,
                        1
                    );
                    break;
                }
            }
        }
    };

    const getRankingGrid = (ref: { instance: dxDataGrid } | null): void => {
        setRankingGrid(ref);
    };

    const getSortGroupColumns = (
        columns: IColumnProps[],
        weighted: string | null
    ): GroupingOptions[] => {
        const getValueForGrouping = (column: IColumnProps) => {
            // account_mapping.property_rank_value is the parent account
            if (column.name === 'account_mapping.property_rank_value') {
                return getDisplayTypeDataField(displayType as DisplayType)
                    ? 'weightedAverageDisplayValue_total'
                    : column.name;
            }
            const columnName = column.dataField ?? column.name;
            return weighted ? `${weighted}${columnName}` : columnName;
        };
        return columns.reduce((acc: GroupingOptions[], column) => {
            if (column.dataField === 'property_name') {
                return acc;
            }
            if (column.caption === 'Child Accounts') {
                const weighted = getDisplayTypeDataField(
                    displayType as DisplayType
                )
                    ? 'weightedAverageDisplayValue_'
                    : null;
                const childrenColumns = getSortGroupColumns(
                    column.columns,
                    weighted
                );
                return acc.concat(childrenColumns);
            }

            return acc.concat({
                label: column.caption,
                value: getValueForGrouping(column),
            });
        }, []);
    };
    return (
        <>
            <DataGrid
                ref={getRankingGrid}
                data-test="ranking-table"
                dataSource={data}
                height={'630px'}
                keyExpr={'property_name'}
                elementAttr={{
                    id: 'gridContainer',
                }}
                allowColumnResizing={false}
                allowColumnReordering={true}
                onExporting={onExporting}
                columns={columns}
                columnAutoWidth={true}
                columnResizingMode={'nextColumn'}
                hoverStateEnabled={true}
                renderAsync={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                wordWrapEnabled
                onRowPrepared={onRowPrepared}
                onContentReady={(e) =>
                    onContentReady({ e, toggleFunc: setExpandButtonEnable })
                }
                onOptionChanged={toggleSortSettings}
            >
                <Sorting mode="multiple" />
                <HeaderFilter visible={true} allowSearch={true} />
                <FilterRow visible={true} applyFilter={true} />
                <FilterPanel visible={true} />
                <GroupPanel visible={isGroupByAttribute} />
                <Grouping contextMenuEnabled={false} autoExpandAll={expanded} />
                <Summary
                    totalItems={totalItems}
                    groupItems={groupItems}
                    calculateCustomSummary={calculateCustomSummary}
                />
                <SortByGroupSummaryInfo
                    summaryItem={sortSelection}
                    sortOrder={sortOrderAscending ? 'asc' : 'desc'}
                />

                <Paging enabled={true} defaultPageSize={20} />
                <Pager
                    showPageSizeSelector={true}
                    showInfo={true}
                    allowedPageSizes={allowedPageSizes}
                />

                <ColumnChooser enabled={true} mode={'select'} />
                <Toolbar>
                    <Item location="after" name="exportButton" />
                    <Item location="after" name="columnChooserButton" />
                    <Item location="before" name="groupPanel" />
                    <Item location="before">
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </Item>
                    <Item location="before" visible={sortVisible}>
                        <SortByGroupSummarySortSelect
                            groupingOptions={getSortGroupColumns(columns, null)}
                            sortExcludedColumns={sortExcludedColumns}
                            sortSelection={sortSelection}
                            setSortSelection={setSortSelection}
                            sortOrderAscending={sortOrderAscending}
                            toggleSortOrder={toggleSortOrder}
                        />
                    </Item>
                </Toolbar>
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </>
    );
};

export default RankingTable;
