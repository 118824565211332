import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as searchReducer } from 'redux-search';
import { reducer as formReducer } from 'redux-form';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { reducer as toastrReducer } from 'react-redux-toastr';

import attachments from './attachments/reducer';
import clientCategories from './clientCategories/reducer';
import comments from './comments/reducer';
import dashboardConfigurations from './dashboardConfigurations/reducer';
import explanationTypes from './explanationTypes/reducer';
import favorites from './favorites/reducer';
import images from './images/reducer';
import leases from './leases/reducer';
import opportunities from './opportunities/reducer';
import properties from './properties/reducer';
import suites from './suites/reducer';
import tags from './tags/reducer';
import user from './user/reducer';
import users from './users/reducer';
import tenants from './tenants/reducer';

import { reducer as browser } from './browser';
import { reducer as proto } from './proto';

export default combineReducers({
    attachments,
    browser,
    clientCategories,
    comments, // Opportunities may be commented on
    dashboardConfigurations,
    entities: entitiesReducer, // Ledger Data is stored here by Redux-Query
    explanationTypes,
    favorites,
    form: formReducer, // Redux-Form handles controlled / uncontrolled form components
    images,
    leases,
    // networkErrors: errorsReducer,
    opportunities,
    properties,
    proto, // This is abandoned
    queries: queriesReducer, // Redux-Query uses queries to manage network request deduplication
    routing: routerReducer, // Route information such as query parameters is available here
    search: searchReducer, // Powers the search bars, also causes weird tight coupling bugs
    suites,
    tags, // Anything may be tagged, but only opportunities is currently
    toastr: toastrReducer, // Global Success or Errors which don't go into the UI are tossed into toastr messages
    user, // This is the current user's preferences. It's a kitchen sink
    users, // This is where all user data is held, for example for opportunity assignees
    tenants,
});
