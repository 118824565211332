import { get } from 'lodash';
// https://github.com/treasure-data/redux-search/tree/master/docs#reduxsearch-resourceindexes-resourceselector-searchapi-searchstateselector-
const propertyKeys = [
    'name',
    'display_name',
    'postal_code',
    'city',
    'state',
    'asset_type_name',
];
const reduxSearchConfig = {
    resourceIndexes: {
        // PROPS_ are the original Ying ones and they power the top level search
        PROPS: ['name', 'city'],
        // NOTE: Can add Country lookup and State lookup by converting the country and state codes
        // Must add transform as a schema change when loading properties
        // Check States: https://www.npmjs.com/package/provinces
        // Check Countries: https://github.com/OpenBookPrices/country-data/
        PROPERTY: propertyKeys,
        USERS: ['firstname', 'lastname', 'email'],
        PROPERTY_TENANTS: ['name'],
    },

    // NOTE: We rely heavily on switch fallthrough here, take care
    resourceSelector: (resourceName, state) => {
        switch (resourceName) {
            case 'PROPS':
            case 'PROPERTY':
                return state.properties;
            case 'USERS':
                return state.users;
            case 'PROPERTY_TENANTS':
                return get(state, 'tenants.propertyTenants', undefined);
            default:
        }
        return undefined;
    },
};

export default reduxSearchConfig;
