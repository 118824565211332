import { ActionsCard } from 'waypoint-react';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { ContractsTable } from './ContractsTable';
import { DataGrid } from 'devextreme-react';
import {
    useGetContracts,
    useGetLeasesPerformanceOverview,
} from 'waypoint-hooks';
import { SavedConfiguration } from 'waypoint-types';
import { DisabledDashboard } from 'waypoint-react';
import { TypeOfContractsView } from 'components/contracts/interfaces';
import { getContractsData } from './utils';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface ContractsContainerProps {
    entityCodes: string[];
    typeOfView: TypeOfContractsView;
    widgetStatus?: string[];
    savedConfigData?: SavedConfiguration | null;
    dataGridRef?: React.RefObject<DataGrid<any, any>>;
    isReportWidget?: boolean;
    narrativePosition?: string;
    widgetId?: string;
    isPDFExport?: boolean;
}

export const CONTRACTS_CARD_TYPE = 'contracts';

export const ContractsContainer = React.forwardRef<
    PDFExportable,
    ContractsContainerProps
>(
    (
        {
            entityCodes,
            widgetStatus,
            typeOfView,
            savedConfigData,
            widgetId,
            narrativePosition,
            isReportWidget = false,
            isPDFExport,
        }: ContractsContainerProps,

        ref
    ) => {
        const dataGridRef = useRef<DataGrid<any, any>>(null);
        const {
            data: contractsData,
            isError,
            mutate,
            isLoading: isContractsDataLoading,
        } = useGetContracts(entityCodes);

        const {
            data: leasesPerformanceData,
            isLoading: isLeasesPerformanceDataLoading,
        } = useGetLeasesPerformanceOverview(entityCodes, {
            stakeholder: null,
            percentageType: null,
        });

        const [isReadyForPDFExport, setIsReadyForPDFExport] =
            useState<boolean>(false);

        const data = getContractsData(leasesPerformanceData, contractsData);

        const isLoading =
            isContractsDataLoading ||
            isLeasesPerformanceDataLoading ||
            !savedConfigData;

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading && isReadyForPDFExport;
            },
        }));

        if (!data || !leasesPerformanceData) {
            return <DisabledDashboard text={'No data'} />;
        }

        return (
            <PermissionedWrapper featureKey={AppFeaturePermissions.Contracts}>
                <ActionsCard
                    style={{ boxShadow: 'none' }}
                    title={
                        <div
                            style={{
                                display: 'flex',
                                placeContent: 'space-between',
                                alignItems: 'center',
                                padding: '12px 0',
                            }}
                        >
                            <h2
                                style={{
                                    marginBottom: 0,
                                }}
                            >
                                Service Contracts
                            </h2>
                        </div>
                    }
                    extra={<></>}
                    data-test="contracts-card"
                    loadingData={
                        isContractsDataLoading || isLeasesPerformanceDataLoading
                    }
                >
                    {!isError ? (
                        <ContractsTable
                            dataGridRef={dataGridRef}
                            data={data}
                            mutate={mutate}
                            widgetStatus={widgetStatus}
                            entityCodes={entityCodes}
                            typeOfView={typeOfView}
                            leasesPerformanceData={leasesPerformanceData}
                            configSelectedForWidget={savedConfigData}
                            isReportWidget={isReportWidget}
                            setIsReadyForPDFExport={setIsReadyForPDFExport}
                            isPDFExport={isPDFExport}
                        />
                    ) : (
                        <DisabledDashboard text="There was an error loading Contracts" />
                    )}
                </ActionsCard>
            </PermissionedWrapper>
        );
    }
);
