import { merge, cloneDeep } from 'lodash';
import { suites as SUITES } from 'state/actionTypes';

function loadSuites(state, payload) {
    return cloneDeep(merge(state, payload));
}

export default function reducer(suites = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SUITES.LOAD:
            return loadSuites(suites, payload);
        default:
            return suites;
    }
}
