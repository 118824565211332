import React, { useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import {
    convertSavedFilterToSelectedAttributes,
    convertSelectedAttributesToSavedFilter,
} from 'components/app/global-filter-drawer/globalFilterUtils';
import { SelectedAttributes } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    FilterReferenceTypes,
    SavedFilter,
    SavedFiltersContext,
} from 'contexts';
import { isEqual } from 'lodash';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';

type AppliedFilterType = SavedFilter | null;

interface FilterEditorOperationsProps {
    handleClose?: () => void;
    onFiltersUpdate?: (filter: SelectedAttributes) => void;
}

export const useFilterEditorOperations = ({
    handleClose,
    onFiltersUpdate,
}: FilterEditorOperationsProps) => {
    const savedFilterContext = useContext(SavedFiltersContext);

    const [appliedAttributeFilters, setAppliedGlobalFilter] =
        useClientPrefixedLocalStorage<AppliedFilterType>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const getDefaultFilterState = (): Partial<SavedFilter> => ({
        name: '',
        reference_type: FilterReferenceTypes.USER,
    });

    const [savedFilterPartial, setSavedFilterPartial] = useState<
        Partial<SavedFilter>
    >(getDefaultFilterState());

    const [attributeFilters, setAttributeFilters] =
        useState<SelectedAttributes>({});

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [saveAsVisible, setSaveAsVisible] = useState<boolean>(false);

    const [selectedFilterId, setSelectedFilterId] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        setSelectedFilterId(appliedAttributeFilters?.id);
        if (!appliedAttributeFilters) return;

        const { id, name, reference_type } = appliedAttributeFilters;

        setSavedFilterPartial({ id, name, reference_type });

        setAttributeFilters(
            convertSavedFilterToSelectedAttributes(appliedAttributeFilters)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApplyGlobalFilters = () => {
        const { filters }: SavedFilter =
            convertSelectedAttributesToSavedFilter(attributeFilters);

        const savedFilter = savedFilterPartial.id
            ? savedFilterContext?.savedFilters?.find(
                  (x) => x.id === savedFilterPartial.id
              )
            : undefined;

        if (savedFilter && !isEqual(savedFilter?.filters, filters)) {
            // if a saved filter is set and new filters are applied, remove the filter and apply a new one
            setSelectedFilterId(undefined);
            setAppliedGlobalFilter({
                id: undefined,
                name: '',
                reference_type: FilterReferenceTypes.USER,
                filters,
            });
            handleClose && handleClose();
            return;
        }

        const draftFilter: SavedFilter = {
            id: savedFilterPartial.id,
            name: savedFilterPartial.name ?? '',
            reference_type:
                savedFilterPartial.reference_type ?? FilterReferenceTypes.USER,
            filters,
        };

        setAppliedGlobalFilter(draftFilter);

        handleClose && handleClose();
    };

    //#region Form Actions
    const handleSave = async () => {
        if (!savedFilterContext) {
            throw new Error('Attempting to save outside context');
        }

        const { name, reference_type } = savedFilterPartial;

        if (!name?.trim() || !reference_type) {
            setSaveAsVisible(true);
            return;
        }

        const filterToSave =
            convertSelectedAttributesToSavedFilter(attributeFilters);

        const filterId: string | undefined =
            savedFilterContext.savedFilters?.find((x) => x.name === name)?.id;

        const draftFilter: SavedFilter = {
            ...filterToSave,
            id: filterId,
            name: name.trim(),
            reference_type: reference_type,
        };

        setIsSaving(true);

        try {
            const saveTypeDescription = draftFilter.id ? 'saved' : 'created';

            if (draftFilter.id) {
                await savedFilterContext.updateFilter(draftFilter);
            } else {
                await savedFilterContext.saveNewFilter(draftFilter);
            }

            setIsSaving(false);
            setSaveAsVisible(false);

            notification.success({
                message: `Your filter has been ${saveTypeDescription}`,
            });

            handleClose && handleClose();
        } catch (err) {
            console.error('FilterEditor failed to save', err);

            const failureTypeDescription = draftFilter.id
                ? 'save changes to'
                : 'create';

            notification.error({
                message: `We were unable to ${failureTypeDescription} your filter`,
            });

            setIsSaving(false);
            setSaveAsVisible(false);
        }
    };

    const handleDelete = async () => {
        if (!savedFilterContext || !selectedFilterId) {
            return;
        }

        try {
            await savedFilterContext.deleteFilter(selectedFilterId);

            setAppliedGlobalFilter(null);

            notification.success({
                message: `Your filter has been deleted`,
            });

            handleClose && handleClose();
        } catch (e) {
            console.error('Delete of global filter failed', e);
            notification.error({
                message: 'Unable to delete filter',
            });
        }
    };

    const handleClearFilter = () => {
        setSelectedFilterId(undefined);
        setSavedFilterPartial(getDefaultFilterState());
        setAttributeFilters({});
    };

    //#endregion

    if (onFiltersUpdate && !Object.keys(attributeFilters).length) {
        onFiltersUpdate(attributeFilters);
    }

    const openSaveAs = () => setSaveAsVisible(true);

    const controlsDisabled =
        isSaving || savedFilterContext?.isSavedFiltersLoading;

    const hasAtLeastOneValidFilter = Object.entries(attributeFilters).some(
        ([, filter]) => {
            return filter.attribute && filter.attributeValues.length > 0;
        }
    );

    return {
        attributeFilters,
        setAttributeFilters,
        savedFilterPartial,
        setSavedFilterPartial,
        selectedFilterId,
        setSelectedFilterId,
        openSaveAs,
        controlsDisabled,
        hasAtLeastOneValidFilter,
        handleDelete,
        handleSave,
        handleApplyGlobalFilters,
        saveAsVisible,
        setSaveAsVisible,
        isSaving,
        savedFilterContext,
        handleClearFilter,
    };
};
