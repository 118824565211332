import React from 'react';
import { SelectOptionType } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    KPISortByOptions,
    KPISortDirections,
    PropertyDetailsValuesType,
    removeCardHeaderClass,
} from 'components/leases/constants';
import { cloneDeep } from 'lodash';
import { PropertyDetailsProps, RecurringCharge } from 'waypoint-types';
import ComparisonKpiChart from '../comparison-kpi/ComparisonKpiChart';
import ComparisonKpiSelect from '../comparison-kpi/ComparisonKpiSelect';
import { ActionsCard, TextLoadingBar } from 'waypoint-react';
import { css } from 'emotion';
import { joinPerformanceOverviewWithTotalCharges } from './utils';

interface LeasesPerformanceOverviewChartProps {
    leftAxis: keyof PropertyDetailsValuesType;
    rightAxis: keyof PropertyDetailsValuesType;
    setLeftAxis: (value: keyof PropertyDetailsValuesType) => void;
    setRightAxis: (value: keyof PropertyDetailsValuesType) => void;
    setSortAxis: (value: KPISortByOptions) => void;
    setSortDirection: (value: KPISortDirections) => void;
    sortAxis: KPISortByOptions;
    sortDirection: KPISortDirections;
    propertyDetails: PropertyDetailsProps[];
    options: SelectOptionType[];
    isLeasesPerformanceOverviewLoading: boolean;
    isLeasesPerformanceOverviewError: boolean;
    recurringCharges: RecurringCharge[] | undefined;
}

const cardTitle = css`
    padding: 8px 0;
    margin-bottom: 0;
`;

const textLoadingStyle = {
    height: '12px',
    width: '150px',
    marginTop: '0px',
};

const LeasesPerformanceOverviewChart = ({
    options,
    leftAxis,
    rightAxis,
    setLeftAxis,
    setRightAxis,
    setSortAxis,
    setSortDirection,
    sortAxis,
    sortDirection,
    propertyDetails,
    isLeasesPerformanceOverviewLoading,
    isLeasesPerformanceOverviewError,
    recurringCharges,
}: LeasesPerformanceOverviewChartProps) => {
    const performanceOverviewWithRecurringCharges =
        joinPerformanceOverviewWithTotalCharges(
            propertyDetails,
            recurringCharges ?? []
        );

    return (
        <ActionsCard
            title={
                isLeasesPerformanceOverviewLoading ? (
                    <div className={cardTitle}>
                        <TextLoadingBar style={textLoadingStyle} />
                    </div>
                ) : (
                    <></>
                )
            }
            loadingData={isLeasesPerformanceOverviewLoading}
            error={isLeasesPerformanceOverviewError}
            loadingRows={16}
            extra={<></>}
            data-test="property-lease-kpi-chart-card"
            className={removeCardHeaderClass}
        >
            <div>
                <ComparisonKpiChart
                    data-test="comparison-kpi-chart"
                    leftAxis={leftAxis}
                    rightAxis={rightAxis}
                    propertyDetails={cloneDeep(
                        performanceOverviewWithRecurringCharges ?? []
                    )}
                    sortAxis={sortAxis}
                    sortDirection={sortDirection}
                />
            </div>
            <div>
                <ComparisonKpiSelect
                    sortAxis={sortAxis}
                    sortDirection={sortDirection}
                    setSortAxis={setSortAxis}
                    setSortDirection={setSortDirection}
                    leftAxis={leftAxis}
                    rightAxis={rightAxis}
                    setLeftAxis={setLeftAxis}
                    setRightAxis={setRightAxis}
                    options={options}
                />
            </div>
        </ActionsCard>
    );
};

export default LeasesPerformanceOverviewChart;
