import { API_URL } from 'config/constants';
import { AttributeValue } from 'waypoint-types';

const getClientAndEntityAttributeValues = async (
    attributeCodes: string[],
    isStandardAttribute: boolean
): Promise<AttributeValue[]> => {
    const response = await fetch(`${API_URL}/attributes/attribute-values`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            attribute_codes: attributeCodes,
            is_standard_attribute: isStandardAttribute,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as AttributeValue[];
};

export default getClientAndEntityAttributeValues;
