import React, { ReactElement } from 'react';
import { List, Skeleton } from 'antd';
import { FixedSizeList as VList, ListChildComponentProps } from 'react-window';

import { Checkbox } from 'waypoint-react';
import { DASH_DASH } from 'config/constants';

type OnChecked<T> = (listItem: ListItem<T>) => void;

export interface ListItem<T> {
    id: string;
    value: T;
    checked?: boolean;
}

export interface CheckboxListProps<T> {
    items?: ListItem<T>[];
    onChecked?: OnChecked<T>;
    height: number;
    loading?: boolean;
    loadingRows?: number;
    renderCheckbox?: React.FC<ListChildComponentProps<ListItem<T>>>;
}

function VirtualList<T>(
    items: T[],
    renderItem: React.FC<ListChildComponentProps<T>>,
    height: number,
    width: number = 340,
    itemSize: number = 40
) {
    if (items == null) {
        return <Skeleton paragraph={{ rows: 5 }} />;
    }

    if (items.length === 0) {
        return <p>No matching properties.</p>;
    }

    return (
        <List dataSource={items ?? []}>
            <VList
                height={height}
                width={'100%'}
                itemCount={items.length}
                itemSize={itemSize}
            >
                {renderItem}
            </VList>
        </List>
    );
}

export const CheckboxList: React.FC<CheckboxListProps<string>> = ({
    items,
    height,
    onChecked,
    loading = false,
    loadingRows = 3,
    ...props
}) => {
    if (loading) {
        return <Skeleton paragraph={{ rows: loadingRows ?? 1 }} />;
    }

    if (!items) {
        console.error('CheckboxList items prop was null');
        return <></>;
    }

    const defaultRenderer = ({
        index,
        style,
    }: ListChildComponentProps): ReactElement => {
        const item = items[index];

        if (!item?.value) {
            return <span>{DASH_DASH}</span>;
        }

        return (
            <List.Item key={index} style={style}>
                {item?.value && (
                    <Checkbox
                        checked={item.checked}
                        onChange={() => onChecked && onChecked(item)}
                    >
                        <span>{item.value}</span>
                    </Checkbox>
                )}
            </List.Item>
        );
    };

    return (
        <>
            {VirtualList(
                items,
                // if the parent component wants to override the renderer, they can pass a prop
                props.renderCheckbox ?? defaultRenderer,
                height
            )}
        </>
    );
};
