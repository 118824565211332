import React from 'react';
import { Select } from 'antd';
import {
    cardTitle,
    metricOptions,
    textLoadingStyle,
    topNOptions,
    TopTenantsMetricsEnum,
} from './topTenantsUtils';
import { TextLoadingBar } from 'waypoint-react';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import { RecurringCharge } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';

const { Option } = Select;

interface TopTenantsCardHeaderProps {
    isRentRollLoading: boolean;
    hideSelectorsFromTitle?: boolean;
    topNTenants: string;
    setTopNTenants: (value: string) => void;
    selectedMetric: string;
    setSelectedMetric: (value: TopTenantsMetricsEnum) => void;
    recurringCharges: Dictionary<RecurringCharge[], string>;
    selectedChargeCode: string[];
    onChange: (value: string[]) => void;
}

const TopTenantsCardHeader = ({
    isRentRollLoading,
    hideSelectorsFromTitle,
    topNTenants,
    setTopNTenants,
    selectedMetric,
    setSelectedMetric,
    recurringCharges,
    selectedChargeCode,
    onChange,
}: TopTenantsCardHeaderProps) => {
    if (isRentRollLoading) {
        return (
            <div className={cardTitle}>
                <TextLoadingBar style={textLoadingStyle} />
            </div>
        );
    }

    return (
        <>
            <h2 data-test="top-tenants-header" className={cardTitle}>
                <span
                    style={{
                        marginTop: '5px',
                        marginRight: '10px',
                        marginBottom: '3px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Top{' '}
                    {hideSelectorsFromTitle ? (
                        topNTenants
                    ) : (
                        <Select
                            style={{ width: '75px', marginLeft: 8 }}
                            onChange={(value) => {
                                setTopNTenants(value);
                            }}
                            value={topNTenants}
                        >
                            {topNOptions?.map((option) => (
                                <Option
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                >
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <span style={{ marginLeft: 14 }}>Tenants</span>{' '}
                    <span style={{ marginLeft: 6 }}>By</span>{' '}
                    <div style={{ marginLeft: 6 }}>
                        {hideSelectorsFromTitle ? (
                            metricOptions.find(
                                (metric) => metric.value === selectedMetric
                            )?.label
                        ) : (
                            <>
                                <Select
                                    style={{
                                        width: '200px',
                                        marginRight: '10px',
                                        marginLeft: 8,
                                    }}
                                    onChange={(value) => {
                                        setSelectedMetric(
                                            value as TopTenantsMetricsEnum
                                        );
                                    }}
                                    value={selectedMetric}
                                >
                                    {metricOptions?.map((option) => (
                                        <Option
                                            key={option.value}
                                            value={option.value}
                                            label={option.label}
                                        >
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                                {selectedMetric !== 'rentable_sf' && (
                                    <RecurringChargeSelect
                                        recurringCharges={recurringCharges}
                                        selectedChargeCodes={selectedChargeCode}
                                        onChange={onChange}
                                        style={{
                                            width: 'auto',
                                            minWidth: '200px',
                                            marginRight: 12,
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </span>
            </h2>
        </>
    );
};

export default TopTenantsCardHeader;
