import PropTypes from 'prop-types';
import React from 'react';
import { pick, isEmpty } from 'lodash';
import { DASH_DASH } from 'config/constants';
import { numericalSort } from 'utils/tables/sorters';
import { formatDateRangeForCopy } from 'waypoint-utils';
import { ChartTableSwitchCard, ActionButtons } from 'waypoint-react';
import BreakdownChart from 'components/analytics/financialOverview/charts/breakdown/BreakdownChart';
import BreakdownTable from 'components/analytics/financialOverview/tables/breakdown/BreakdownTable';

const BreakdownCard = (props) => {
    const {
        data = [],
        onClick,
        loading,
        period,
        displayType,
        accountMappingName,
    } = props;
    const gridProps = pick(props, ['data-grid', 'style']);
    const sortedData = data.sort((a, b) =>
        numericalSort(b.actual_amount, a.actual_amount)
    );
    return (
        <ChartTableSwitchCard
            {...gridProps}
            loading={loading}
            extra={<ActionButtons onClick={onClick} loading={loading} />}
            title={`Account Breakdown: ${accountMappingName || DASH_DASH}`}
            subheading={period && formatDateRangeForCopy(period)}
            empty={isEmpty(data)}
            chart={
                <BreakdownChart
                    displayType={displayType}
                    period={period}
                    data={data}
                />
            }
            table={<BreakdownTable data={sortedData} />}
        />
    );
};

BreakdownCard.propTypes = {
    accountMappingName: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    displayType: PropTypes.string,
    downloadCsv: PropTypes.func,
    downloading: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    period: PropTypes.array,
};

export default BreakdownCard;
