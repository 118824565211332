import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ContentRenderer } from 'waypoint-react';
import { Button } from 'antd';
import { css } from 'emotion';
import { toastr } from 'react-redux-toastr';
import KendoEditor from 'waypoint-react/kendo/Editor';
import {
    useGetDocumentUploadListByReferenceParams,
    useGetEntityCode,
} from 'waypoint-hooks';
import { updateImageSrcWithSignedURLs } from 'waypoint-react/kendo/Utils';

const centeredContent = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;

interface HtmlEditorContainerProps {
    items?: any;
    title: string | React.ReactNode;
    height?: number;
    onHtmlEditorSubmit?: (data: any) => void;
    onEditCallback?: () => void;
    style?: React.CSSProperties;
    disabled?: boolean;
    referenceType: string;
    isPDFExport?: boolean;
}

const MAXIMUM_EDITOR_LENGTH = 10000;
const HtmlEditorContainer = ({
    items,
    title,
    onHtmlEditorSubmit,
    height,
    onEditCallback,
    referenceType,
    isPDFExport = false,
    disabled = false,
}: HtmlEditorContainerProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');
    const [oldContent, setOldContent] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const entityCode = useGetEntityCode();
    const { data: uploadedImages, mutate } =
        useGetDocumentUploadListByReferenceParams(referenceType, entityCode);

    useEffect(() => {
        setContent(items?.notes ?? '');
    }, [items?.notes]);

    useEffect(() => {
        if (isEditing) {
            setOldContent(content);
            if (onEditCallback) {
                onEditCallback();
            }
        }
    }, [isEditing]);

    useEffect(() => {
        updateImageSrcWithSignedURLs(
            content,
            uploadedImages ?? [],
            onUpdateContent
        );
    }, [uploadedImages, referenceType]);

    // on page enter, force the images request to get the most recent S3 signed urls
    useLayoutEffect(() => {
        mutate();
    }, []);

    // In case the user stays in the page the S3 15 minute expiration span,
    // refetch the image request to trigger url update before
    useEffect(() => {
        const intervalId = setInterval(() => {
            mutate();
        }, 780 * 1000);

        return () => clearInterval(intervalId);
    }, [mutate]);

    const saveContent = async () => {
        setIsSaving(true);

        if (oldContent === content) {
            setIsEditing(false);
            return;
        }

        if (content.length > MAXIMUM_EDITOR_LENGTH) {
            toastr.error(
                'Error',
                `Executive summary cannot be longer than ${MAXIMUM_EDITOR_LENGTH.toLocaleString()} characters`
            );
            return;
        }

        onHtmlEditorSubmit?.({
            ...items,
            notes: content,
        });

        setIsEditing(false);
        if (onEditCallback) {
            onEditCallback();
        }

        setIsSaving(false);
    };

    const onUpdateContent = (text: string) => {
        setContent(
            text.length > MAXIMUM_EDITOR_LENGTH
                ? text.slice(0, MAXIMUM_EDITOR_LENGTH - 1)
                : text
        );
    };

    const onCancel = () => {
        setContent(oldContent);
        setIsEditing(false);
        if (onEditCallback) {
            onEditCallback();
        }
    };

    const renderEditorSection = () => (
        <>
            <KendoEditor
                height={height ?? 390}
                content={content}
                onChange={onUpdateContent}
                isLoading={isLoading}
                referenceType={referenceType}
                data-test="html-editor"
            />
            <div className={centeredContent}>
                <Button
                    onClick={() => saveContent()}
                    loading={isLoading}
                    style={{ marginRight: 5 }}
                    type="primary"
                    disabled={disabled}
                >
                    Save
                </Button>
                <Button onClick={() => onCancel()} disabled={isSaving}>
                    Cancel
                </Button>
            </div>
        </>
    );

    return (
        <>
            {isEditing ? (
                renderEditorSection()
            ) : (
                <ContentRenderer
                    content={content}
                    data-test="executive-summary-markdown-renderer"
                    isLoading={isLoading}
                    onClick={() => setIsEditing(true)}
                    canInvokeEdit
                    placeholder={`Click to add a ${title}...`}
                    disabled={disabled}
                    isPDFExport={isPDFExport}
                />
            )}
        </>
    );
};

export default HtmlEditorContainer;
