import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { css, cx, keyframes } from 'emotion';
import { Collapse, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import theme from 'config/theme';
import { DASH_DASH } from 'config/constants';
import { connect, RootStateOrAny } from 'react-redux';
import { CommentEditor, Reply, EmptyAvatar } from 'waypoint-react';
import { CommentProps } from 'waypoint-types';
import { dateSort } from 'utils/tables/sorters';
import { sanitize } from 'dompurify';
import { Comment as AntDComment } from '@ant-design/compatible';

const { Panel } = Collapse;

const containerStyle = css`
    position: relative;
    padding: 0 12px;

    .actions {
        display: none;
        position: absolute;
        top: -${theme.space.large}px;
        right: ${theme.space.large}px;
        z-index: 2;
        border-radius: ${theme.radii.medium}px;
        border: 1px solid #e5e5e5;
        background: white;
    }

    .ant-comment-actions {
        display: flex;
        align-items: center;
        li {
            cursor: pointer;
        }
        li:nth-child(3) {
            margin-left: auto;
            color: ${theme.colors.grays.mediumDark};
        }
        li:last-child {
            align-self: flex-end;
        }
    }

    .ant-comment-nested {
        margin-left: 10px;
    }

    &:hover .actions {
        display: block;
    }
`;

const headerCommentStyle = css`
    display: flex;
    align-items: baseline;
    span.edited {
        margin-left: 4px;
        display: block;
        font-size: 12px;
        color: ${theme.colors.grays.mediumDark};
    }
`;

const commentStyle = css`
    min-height: 90px;
    .ant-comment-inner {
        padding: 10px;
    }

    .ant-comment-content-author > span {
        width: 100%;
    }

    .author {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        color: ${theme.colors.grays.text};
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        margin: 6px 0 3px 0;
        display: inline-block;
    }

    .time {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 1px;
        color: ${theme.colors.grays.mediumDark};
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content {
        font-size: 14px;
    }

    .ant-comment-actions {
        li:nth-child(3),
        li:last-child {
            opacity: 0;
            visibility: hidden;
        }
    }

    .ant-comment-inner {
        &:hover {
            background: #2ea4dd10;
            .ant-comment-actions {
                li:nth-child(3),
                li:last-child {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
`;

const editingCommentStyle = css`
    .ant-comment-inner {
        border: 1px solid #2ea4dd;
        &:hover {
            background: inherit !important;
            border: 1px solid #2ea4dd;
        }
    }
`;

const fadeOut = keyframes`
    from {
        background-color: #2ea4dd10;
    }
    to {
        background-color: transparent;
    }
`;

const highlightedCommentStyle = css`
    animation: ${fadeOut} 7s ease-out 1s;
`;

const iconStyle = css`
    color: ${theme.colors.grays.mediumDark} !important;
    font-size: 18px !important;
`;

const Comment = ({
    comment,
    user,
    onSubmit,
    withAvatar,
    activeKey,
    setActiveKey,
    scrollToCommentId,
    onResolveComment,
}: CommentProps) => {
    const [isReplying, setIsReplying] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [localActiveKey, setLocalActiveKey] = useState<string | null>(null);

    useEffect(() => {
        if (isReplying) {
            document?.querySelector('#commentBox')?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }, [isReplying]);

    useEffect(() => {
        scrollToComment();
    }, [scrollToCommentId, localActiveKey]);

    const deleteComment = (
        commentId: string,
        authorId: number,
        isParent = false
    ) => {
        if (user?.id !== authorId) {
            return null;
        }

        return (
            <Popconfirm
                placement="rightTop"
                title={
                    isParent
                        ? 'Are you sure you want to delete this comment and all of its replies?'
                        : 'Are you sure you want to delete this reply?'
                }
                onConfirm={() => comment.onClickDelete(commentId)}
                okText="Yes"
                cancelText="No"
            >
                <DeleteOutlined className={iconStyle} />
            </Popconfirm>
        );
    };

    const editComment = (
        authorId: number,
        editionAction: () => void,
        is_resolved?: boolean
    ) => {
        if (user?.id !== authorId || is_resolved) {
            return null;
        }
        return (
            <EditOutlined
                className={iconStyle}
                onClick={() => {
                    editionAction();
                }}
            />
        );
    };

    const ReplyComponent = (
        <div
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setIsReplying(!isReplying)}
        >
            Reply
        </div>
    );

    const ResolveCommentComponent = (
        commentThread: string,
        commentId: string,
        is_resolved: boolean
    ) => (
        <div
            style={{
                marginLeft: 6,
                textDecoration: 'underline',
                cursor: 'pointer',
            }}
            onClick={() =>
                onResolveComment &&
                onResolveComment(commentThread, commentId, is_resolved)
            }
        >
            {comment.is_resolved ? 'Unresolve' : 'Resolve'}
        </div>
    );

    const replies = comment.replies
        .sort((a, b) =>
            dateSort(
                new Date(a?.timestamps.created_at),
                new Date(b?.timestamps.created_at)
            )
        )
        .map((reply) => (
            <Reply
                key={reply.id}
                withAvatar
                scrollToCommentId={scrollToCommentId}
                onSubmit={onSubmit}
                deleteComment={deleteComment}
                editComment={editComment}
                comment={reply}
            />
        ));

    const scrollToComment = () => {
        if (scrollToCommentId === comment.id) {
            const container = document?.querySelector(
                `#comment-${scrollToCommentId}`
            );

            container?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

            return;
        }

        if (comment.replies.length) {
            if (comment.replies.find((r) => r.id === scrollToCommentId)) {
                setLocalActiveKey(comment.id);
                document
                    ?.querySelector(`#comment-${comment.id}`)
                    ?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                document
                    ?.querySelector(`#comment-${scrollToCommentId}`)
                    ?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                return;
            }
        }
    };

    const setKeyAndChange = (key: string | string[]) => {
        setActiveKey && setActiveKey(key);
    };

    const resolvedText = (
        <span style={{ color: theme.colors.red }}> (Resolved)</span>
    );

    const commentIsEdited =
        new Date(comment.timestamps.updated_at).getTime() >
        new Date(comment.timestamps.created_at).getTime();

    return (
        <div
            id={`comment-${comment.id}`}
            className={containerStyle}
            key={localActiveKey ?? ''}
        >
            <AntDComment
                actions={[
                    ReplyComponent,
                    ResolveCommentComponent(
                        comment.comment_thread_id,
                        comment.id,
                        !comment.is_resolved
                    ),
                    editComment(
                        comment.author.id,
                        () => setIsEditing(!isEditing),
                        comment.is_resolved
                    ),
                    deleteComment(comment.id, comment.author.id, true),
                ]}
                avatar={withAvatar ? comment.avatar || <EmptyAvatar /> : null}
                author={
                    <div className={headerCommentStyle}>
                        <div className="author">
                            {comment.name}
                            {comment.is_resolved ? resolvedText : null}
                        </div>
                        <span className="edited">
                            {commentIsEdited && (
                                <Tooltip
                                    title={`${moment(
                                        comment.timestamps.updated_at
                                    ).fromNow()} by ${comment.name}`}
                                >
                                    Edited
                                </Tooltip>
                            )}
                        </span>
                    </div>
                }
                content={
                    isEditing ? (
                        <CommentEditor
                            onSubmit={(text, mentions, accountMentions) => {
                                onSubmit(
                                    text,
                                    mentions,
                                    accountMentions,
                                    null,
                                    comment.id
                                );

                                setIsEditing(false);
                            }}
                            isClosable
                            onClose={() => setIsEditing(false)}
                            showToolbar={false}
                            buttonCaption="Save"
                            height={'80px'}
                            defaultValue={comment.text}
                        />
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitize(comment.text),
                            }}
                        />
                    )
                }
                datetime={
                    <p className="time">
                        {!!comment.timestamps.created_at
                            ? moment(comment.timestamps.created_at).fromNow()
                            : DASH_DASH}
                    </p>
                }
                className={
                    comment.id === scrollToCommentId
                        ? cx([commentStyle, highlightedCommentStyle])
                        : cx([commentStyle, isEditing && editingCommentStyle])
                }
            >
                {isReplying && (
                    <div id="commentBox">
                        <CommentEditor
                            onSubmit={(text, mentions, accountMentions) => {
                                onSubmit(
                                    text,
                                    mentions,
                                    accountMentions,
                                    comment.id,
                                    null
                                );

                                setIsReplying(false);
                            }}
                            isClosable
                            onClose={() => setIsReplying(false)}
                            showToolbar={false}
                            buttonCaption="Reply"
                            height={'80px'}
                        />
                    </div>
                )}
                {comment.replies.length ? (
                    <Collapse
                        ghost
                        defaultActiveKey={localActiveKey ?? activeKey}
                        onChange={(key) => setKeyAndChange(key)}
                    >
                        <Panel
                            key={comment.id}
                            header={`${comment.replies.length} ${
                                comment.replies.length > 1 ? 'replies' : 'reply'
                            }`}
                        >
                            {replies}
                        </Panel>
                    </Collapse>
                ) : null}
            </AntDComment>
        </div>
    );
};

const mapState = (s: RootStateOrAny) => ({
    user: s.user,
});

export default connect(mapState)(Comment);
