import { each, flow, toArray } from 'lodash';
import { schema } from 'normalizr';
import provinces from 'provinces';
import { rename, strip, camel } from './utils';
// TODO (Nicholas): Choose between singular and plural in this file....
const clientCategoryIgnoredFields = ['model_name'];
export const clientCategory = new schema.Entity(
    'clientCategory',
    {},
    {
        processStrategy: flow(strip(clientCategoryIgnoredFields), camel),
    }
);

export const clientCategoryList = [clientCategory];

const imageGroupIgnoredFields = ['model_name', 'entity_model', 'description'];
export const imageGroup = new schema.Entity(
    'imageGroup',
    {},
    {
        processStrategy: flow(strip(imageGroupIgnoredFields), camel),
    }
);

const userIgnoredFields = ['model_name', 'created_at', 'updated_at'];
export const user = new schema.Entity(
    'user',
    {},
    {
        processStrategy: flow(strip(userIgnoredFields), camel),
    }
);

export const userList = [user];

const opportunityRenameFields = {
    opportunity_status: 'status',
    opportunity_priority: 'priority',
};
const opportunityIgnoredFields = ['model_name', 'boma_coa_line_item_id'];
export const opportunity = new schema.Entity(
    'opportunity',
    {},
    {
        processStrategy: flow(
            rename(opportunityRenameFields),
            strip(opportunityIgnoredFields),
            camel
        ),
    }
);
opportunity.define({
    createdByUser: user,
    assignedToUser: user,
    clientCategoryId: clientCategory,
});

export const opportunityList = new schema.Array(opportunity);

const commentRenameFields = {
    commented_id: 'created_by_user_id',
};
const commentIgnoredFields = [
    // NOTE (PRODUCT): Remove this if we want to turn on comment approvals
    'rate',
    'approved',
    'updated_at',
    'commented_type',
];
export const comment = new schema.Entity(
    'comment',
    {},
    {
        processStrategy: flow(
            (c) => {
                // NOTE: Unpack users from the mentions here
                //       Since we don't delete mentions from a comment,
                //       It is safe to ignore the join ids and such
                c.mentionedUsers = toArray(c.commentMentions).map(
                    (m) => m.user
                );
                delete c.commentMentions;
                return c;
            },
            rename(commentRenameFields),
            strip(commentIgnoredFields),
            camel
        ),
    }
);
comment.define({
    createdByUser: user,
    mentionedUsers: userList,
});

export const commentList = new schema.Array(comment);

const provinceLookup = provinces
    .filter((p) => p.country === 'US')
    .reduce((map, place) => {
        map[place.short] = place.name;
        return map;
    }, {});

function addFullStateName(properties) {
    each(properties, (p) => {
        p.state_display_name = provinceLookup[p.state];
    });
    return properties;
}

function addAssetTypeName(properties) {
    each(properties, (p) => {
        p.asset_type_name = p.assetType.asset_type_name;
    });
    return properties;
}
// TODO (Nicholas): Add properties and groups to the modern ingestion scheme
export const properties = new schema.Entity(
    'properties',
    {},
    {
        processStrategy: flow(addFullStateName, addAssetTypeName),
    }
);

const attachmentIgnoredFields = [
    'uuid',
    'filepath',
    'disk',
    'filename',
    'description',
    'preview_url',
    'metadata',
    'updated_at',
];

// FIXME (Colby): attachment keys must be camel cased
export const attachments = new schema.Entity(
    'attachments',
    {},
    {
        processStrategy: strip(attachmentIgnoredFields),
    }
);

attachments.define({
    created_by_user_id: user,
});

export const attachmentList = new schema.Array(attachments);

// EXPLANATION TYPES
const explanationTypesIgnoreFields = ['model_name'];

export const explanationType = new schema.Entity(
    'explanationTypes',
    {},
    {
        processStrategy: flow(strip(explanationTypesIgnoreFields), camel),
    }
);

export const explanationTypesList = new schema.Array(explanationType);

// SUITES & LEASES
export const suite = new schema.Entity(
    'suites',
    {},
    {
        processStrategy: camel,
    }
);
export const suiteList = new schema.Array(suite);

export const lease = new schema.Entity(
    'leases',
    {},
    { processStrategy: camel }
);
export const leaseList = new schema.Array(lease);

export const propertyLeaseStatistics = new schema.Entity(
    'propertyLeaseStatistics',
    {},
    {
        processStrategy: camel,
    }
);

export const groupLeaseStatistics = new schema.Entity(
    'groupLeaseStatistics',
    {},
    {
        processStrategy: camel,
    }
);

// TENANTS
export const groupTenantStatistics = new schema.Entity(
    'groupTenantStatistics',
    {},
    {
        processStrategy: camel,
    }
);

export const propertyTenantStatistics = new schema.Entity(
    'propertyTenantStatistics',
    {},
    {
        processStrategy: camel,
    }
);

export const groupTenant = new schema.Entity(
    'groupTenants',
    {},
    {
        processStrategy: camel,
    }
);
