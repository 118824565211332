import ResponsiveBox, {
    Row,
    Col,
    Location,
    Item,
} from 'devextreme-react/responsive-box';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import {
    EntityProfileCardsGroup,
    EntityProfileCardsGroupContainer,
    EntityProfileCardsEditorModal,
} from 'waypoint-react/entity-profile-cards';
import {
    Button,
    Card,
    Empty,
    Form,
    message,
    Select,
    Skeleton,
    Space,
    Tooltip,
    Dropdown,
    Menu,
} from 'antd';
import { DatePicker } from 'waypoint-react';
import { ReorderEvent } from 'devextreme/ui/sortable';
import {
    deleteEntityProfileCard,
    updateEntityProfileCard,
    createEntityProfileCard,
    createEntityProfileCardYearMetadata,
    deleteEntityProfileCardYearMetadata,
    updateEntityProfileCardYearMetadata,
    getEntityProfileCardExcelFile,
    updateEntityProfileCardSortIndexes,
} from 'waypoint-requests';
import moment, { Moment } from 'moment';
import {
    PlusOutlined,
    FileExcelOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { getFilenameFromResponse } from 'waypoint-utils';

import { getUniqueArray } from 'waypoint-utils/collection/getUniqueArray';
import { Select as WaypointSelect } from 'waypoint-react';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
    getYearProgress,
    prioritiesOptions,
} from 'components/annualPrioritiesAndObjectives/constants';
import { css } from 'emotion';
import {
    useEntityProfileCard,
    useEntityProfileYearMetadata,
} from 'waypoint-hooks';
import theme from 'config/theme';
import { YearWithMetadata } from 'waypoint-types/properties/types';

export interface AddItemButtonProps {
    getDefaultCard: () => EntityProfileCard;
    addItemTooltip: string;
    addItemTitleLabel: string;
}

export interface EntityProfileCardsGroupInputsType {
    items: EntityProfileCard[];
    title: string;
    addItemButton?: AddItemButtonProps;
    row?: number;
    column?: number;
    colSpan?: number;
}

export type EntityProfileCardsGroupReduceType = {
    [key: string]: EntityProfileCardsGroupInputsType;
};
export type EntityProfileCardsEnabledFields = ('year' | 'title' | 'notes')[];
export type AddItemButtonLocationOptions = ('none' | 'container' | 'group')[];

const exportableMenu = (handler: (e: MenuInfo) => Promise<void>) => (
    <Menu onClick={(e) => handler(e)} mode="inline">
        <Menu.Item key="excelExport" icon={<FileExcelOutlined />}>
            Export to Excel
        </Menu.Item>
    </Menu>
);

const selectStyle = css`
    .ant-select-selection-item {
        display: flex;
        justify-content: center;
    }
    .ant-select-selection-item,
    .ant-select-selector {
        color: #fff !important;
    }
`;

export interface EntityProfileCardContainerDefinition {
    title: string;
    row?: number;
    column: number;
    group: (
        result: EntityProfileCardsGroupReduceType,
        item: EntityProfileCard
    ) => EntityProfileCardsGroupReduceType;
    filter: (item: EntityProfileCard) => boolean;
    sort?: (
        a: EntityProfileCardsGroupInputsType,
        b: EntityProfileCardsGroupInputsType
    ) => number;
    getEmptyGroupState?: () => EntityProfileCardsGroupReduceType;
    getGroupGrid?: () => { rows: number; columns: number };
    getGroupStyle?: (
        groupDef: EntityProfileCardsGroupInputsType
    ) => CSSProperties;
    addItemButton?: AddItemButtonProps;
    addItemButtonLocations?: AddItemButtonLocationOptions;
    shouldHideContainerIfEmpty?: (yearsFilter: number[]) => boolean;
    deleteStrategy?: {
        type: string;
        get: (
            items: EntityProfileCard[],
            yearMetadata: EntityProfileCardYearMetadata[]
        ) => EntityProfileCard | EntityProfileCardYearMetadata | undefined;
    };
    getYearMetadataValue?: (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => EntityProfileCardYearMetadata | undefined;
    renderYearMetadataOptions?: (key: string) => React.ReactNode;
    renderItemMetadataOptions?: () => React.ReactNode;
}

export interface EntityProfileCardsProps {
    title: string;
    type: string;
    entityCode: string;
    columns:
        | number
        | ((
              items: EntityProfileCard[],
              yearMetadata: EntityProfileCardYearMetadata[]
          ) => number);
    rows: number | ((yearMetadata: EntityProfileCardYearMetadata[]) => number);
    containerSort?: (
        containerA: EntityProfileCardContainerDefinition,
        containerB: EntityProfileCardContainerDefinition
    ) => number;
    getContainerConfig: (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => EntityProfileCardContainerDefinition[];
    enabledFields: EntityProfileCardsEnabledFields;
    onItemsRetrieved?: (items: EntityProfileCard[]) => void;
    onYearMetadataRetrieved?: (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => void;
    onYearMetadataUpdated?: (
        year: number,
        result: EntityProfileCardYearMetadata | null
    ) => void;
    onItemUpdate?: () => void;
    enableYearFilter?: boolean;
    enableYearMetadata?: boolean;
    enableExport?: boolean;
    isCollapse?: boolean;
    enableCategories?: boolean;
    categories?: { label: string; value: string }[];
    defaultExpandedCategories?: string;
    getDefaultYearMetadata?: (year: number) => YearWithMetadata;
    setEarlyYear?: (year: string) => void;
    selectedYear?: number[];
    selectedCategories?: string[];
    enableStatusItems?: boolean;
    enableAddYearButton?: boolean;
    isRollupPage?: boolean;
    showReportWidgetOnly?: boolean;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    hideStatus?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

interface CardEditorModalState {
    title: string;
    isVisible: boolean;
    isEditing: boolean;
    isSaving: boolean;
    errorMessage: string;
    item: EntityProfileCard;
    addItemTitleLabel: string;
}

const badgeSelectStyle = {
    minWidth: '100px',
    boxShadow: 'none',
    borderRadius: 8,
    border: 'none',
    color: '#fff',
    textAlign: 'center' as 'center', // typescript being weird
    justifyContent: 'center',
};

const getUniqueYears = (data: { year: number }[]): number[] => {
    return getUniqueArray<number>(data.map((item) => item.year));
};

const getHoldSellSelectBGColor = (value: string) => {
    if (value === 'hold') {
        return theme.colors.red;
    }
    if (value === 'sell') {
        return theme.colors.green;
    }
    return theme.colors.grays.medium;
};

export const EntityProfileCardsAddItemButton = ({
    onAddItemClicked,
    addItemButton,
    circle,
    isPDFExport,
}: {
    onAddItemClicked?: () => void;
    addItemButton: AddItemButtonProps;
    circle?: boolean;
    isPDFExport?: boolean;
}) => {
    if (isPDFExport) {
        return null;
    }

    return circle ? (
        <Tooltip title={addItemButton.addItemTooltip ?? 'Add Item'}>
            <Button
                shape="circle"
                icon={<PlusOutlined />}
                onClick={onAddItemClicked}
            />
        </Tooltip>
    ) : (
        <Button
            onClick={onAddItemClicked}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
        >
            {addItemButton.addItemTooltip}
        </Button>
    );
};

export const EntityProfileCards = ({
    title,
    type,
    entityCode,
    columns,
    rows,
    containerSort,
    getContainerConfig,
    enabledFields,
    onItemsRetrieved,
    onYearMetadataRetrieved,
    onYearMetadataUpdated,
    onItemUpdate,
    enableYearFilter,
    enableYearMetadata,
    isCollapse,
    enableExport,
    enableCategories,
    categories,
    getDefaultYearMetadata,
    defaultExpandedCategories,
    setEarlyYear,
    selectedYear = [],
    selectedCategories = [],
    enableStatusItems,
    enableAddYearButton,
    isRollupPage,
    showReportWidgetOnly,
    isPDFExport,
    includeNotesInPdf,
    hideStatus,
    setIsReadyToExport,
}: EntityProfileCardsProps): JSX.Element => {
    const { items, mutateItems } = useEntityProfileCard(entityCode, type);

    const { yearMetadata, mutateYearMetadata } = useEntityProfileYearMetadata(
        entityCode,
        type
    );

    useEffect(() => {
        if (!yearMetadata || !onYearMetadataRetrieved) {
            return;
        }

        onYearMetadataRetrieved(yearMetadata);
    }, [yearMetadata]);

    useEffect(() => {
        if (!items || !onItemsRetrieved) {
            return;
        }

        onItemsRetrieved(items);
    }, [items]);

    const [itemForm] = Form.useForm();

    const [modalState, setModalState] = useState<CardEditorModalState>({
        title: '',
        isVisible: false,
        isEditing: false,
        isSaving: false,
        errorMessage: '',
        item: {
            id: '',
            title: '',
            notes: '',
            sort_index: 0,
            year: new Date().getFullYear(),
            type,
            section: '',
            metadata: [],
        },
        addItemTitleLabel: '',
    });
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [yearsFilter, setYearsFilter] = useState<number[]>(selectedYear);
    const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
    const [newYearMetadataYear, setNewYearMetadataYear] = useState<
        number | null
    >(null);
    const [hasSelectedYearInMetadata, setHasSelectedYearInMetadata] =
        useState<boolean>(true);

    useEffect(() => {
        if (selectedYear.length) {
            setYearsFilter(selectedYear);
        }
    }, [selectedYear]);

    useEffect(() => {
        if (selectedCategories?.length) {
            setCategoriesFilter(selectedCategories);
        }
    }, [selectedCategories]);

    useEffect(() => {
        const isMarketOverview = type === 'marketOverview';

        if (setEarlyYear && yearMetadata?.length) {
            setEarlyYear(
                isMarketOverview
                    ? new Date().getFullYear().toString()
                    : yearMetadata[0].year.toString() ?? ''
            );
        }

        if (selectedYear.length) {
            setHasSelectedYearInMetadata(
                !!yearMetadata?.filter((ym) => ym.year === selectedYear[0])
                    .length
            );
        }
    }, [yearMetadata]);

    if (!items || !yearMetadata) {
        return <Skeleton />;
    }

    setIsReadyToExport && setIsReadyToExport(true);

    const containers = getContainerConfig(yearMetadata);

    const onHtmlEditorSubmit = async (values: any) => {
        try {
            const savedItem = onSave({
                ...values,
                title: '',
                notes: values.notes,
                yearMoment: moment(values.year.toString()),
            });

            const saveHandler = values.id ? onUpdateItem : onNewItem;

            if (saveHandler) {
                saveHandler(savedItem);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmitForm = async () => {
        let values = null;

        try {
            values = await itemForm.validateFields();
        } catch (e) {}

        if (!values) {
            return;
        }

        try {
            setModalState({
                ...modalState,
                isSaving: true,
            });
            const savedItem = await onSave({
                ...values,
            });
            const saveHandler = modalState.isEditing ? onUpdateItem : onNewItem;

            if (saveHandler) {
                saveHandler(savedItem);
            }

            setModalState({
                ...modalState,
                isVisible: false,
            });
        } catch (e) {
            console.error(e);

            setModalState({
                ...modalState,
                errorMessage: 'There was an error saving your item.',
                isSaving: false,
            });
        }
    };

    const onSave = ({
        title,
        notes,
        yearMoment,
        metadata,
        id,
    }: {
        id?: string;
        title: string;
        notes: string;
        yearMoment: Moment;
        metadata?: { value: string; label: string; color: string }[];
    }): EntityProfileCard => {
        return {
            ...modalState.item,
            id: id ?? modalState.item.id,
            title,
            notes,
            metadata,
            year: yearMoment.year(),
        };
    };

    const onUpdateItem = (updatedItem: EntityProfileCard) => {
        if (!items) {
            return;
        }

        const existingItemIndex = items.findIndex(
            (item) => item.id === updatedItem.id
        );

        if (existingItemIndex === -1) {
            return;
        }

        items[existingItemIndex] = updatedItem;

        mutateItems(
            async () => {
                try {
                    await updateEntityProfileCard(
                        entityCode,
                        updatedItem.id,
                        updatedItem
                    );
                    message.success('Profile card updated');
                } catch (e) {
                    message.error(
                        'An error occurred while updating the profile card'
                    );
                }

                return [...items];
            },
            {
                optimisticData: [...items],
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );

        if (onItemUpdate) {
            onItemUpdate();
        }
    };

    const onNewItem = (newItem: EntityProfileCard) => {
        if (!items) {
            return;
        }

        // Put the item at the end of the list
        const itemsInSectionAndYear = items.filter(
            (item) =>
                item.section === newItem.section && item.year === newItem.year
        );
        newItem.sort_index = itemsInSectionAndYear.length;

        mutateItems(
            async () => {
                const newEntityProfileCard = await createEntityProfileCard(
                    entityCode,
                    type,
                    newItem
                );
                message.success('Profile card created');
                return [...items, newEntityProfileCard];
            },
            {
                optimisticData: [...items, newItem],
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );

        if (onItemUpdate) {
            onItemUpdate();
        }
    };

    const onDeleteItem = (item: EntityProfileCard) => {
        if (!items) {
            return;
        }

        const itemsWithoutDeleted = [...items.filter((i) => i.id !== item.id)];

        mutateItems(
            async () => {
                try {
                    await deleteEntityProfileCard(entityCode, item.id);
                    message.success('Profile card deleted');
                } catch (e) {
                    message.error(
                        'An error occurred while deleting the profile card'
                    );
                    if (onItemUpdate) {
                        onItemUpdate();
                    }
                    return items;
                }

                return itemsWithoutDeleted;
            },
            {
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
                optimisticData: itemsWithoutDeleted,
            }
        );
    };

    const onReorderItem = (event: ReorderEvent, item: EntityProfileCard) => {
        if (!items) {
            return;
        }

        const reorderedItem = items.find(
            (i) =>
                i.sort_index === event.fromIndex &&
                i.section === item.section &&
                i.year === item.year
        );

        if (!reorderedItem) {
            return;
        }

        // Since we're only reordering within a section, within a year, filter down to just those items, without the reordered item
        const itemsBySortIndex = items
            .filter((item) => {
                return (
                    item.id !== reorderedItem.id &&
                    item.year === reorderedItem.year &&
                    item.section === reorderedItem.section
                );
            })
            .sort((a, b) => a.sort_index - b.sort_index);

        // Splice in the reordered item at the new sort index
        itemsBySortIndex.splice(event.toIndex, 0, reorderedItem);

        // Use the new sorting we've created as the new sortIndex
        itemsBySortIndex.forEach((item, index) => {
            item.sort_index = index;
        });

        const sortedItemIds: string[] = itemsBySortIndex.map((item) => item.id);
        const updatedItems: EntityProfileCard[] = [
            ...items.filter((item) => {
                return !sortedItemIds.includes(item.id);
            }),
            ...itemsBySortIndex,
        ];

        mutateItems(
            async () => {
                await updateEntityProfileCardSortIndexes(entityCode, {
                    sorted_entity_profile_card_ids: sortedItemIds,
                });
                return [...updatedItems];
            },
            {
                optimisticData: [...updatedItems],
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
            }
        );
    };

    const openFormModal = (
        item: EntityProfileCard,
        isEditing: boolean,
        addItemTitleLabel: string
    ) => {
        setModalState({
            isVisible: true,
            isEditing,
            isSaving: false,
            title,
            errorMessage: '',
            item: item,
            addItemTitleLabel,
        });

        if (!item) {
            itemForm.resetFields();
            return;
        }

        itemForm.setFieldsValue({
            title: item.title,
            notes: item.notes,
            yearMoment: moment().year(item.year),
            metadata: item.metadata,
        });
    };

    const getGrid = () => {
        if (!containers.length || !hasSelectedYearInMetadata) {
            return (
                <div style={{ padding: '10px' }}>
                    {(enableAddYearButton || showReportWidgetOnly) &&
                    !hasSelectedYearInMetadata ? (
                        <Empty
                            description={
                                <span>
                                    {isRollupPage || showReportWidgetOnly
                                        ? 'No data for this year'
                                        : 'You have not added a recommendation yet.'}
                                </span>
                            }
                        >
                            <Button
                                onClick={() => addYearMetadata(selectedYear[0])}
                                type="primary"
                                shape="round"
                                icon={<PlusOutlined />}
                            >
                                {isRollupPage || showReportWidgetOnly
                                    ? 'Add data for this year'
                                    : 'Add Hold/Sell Recommendation'}
                            </Button>
                        </Empty>
                    ) : (
                        <Empty />
                    )}
                </div>
            );
        }

        const notContainerAndItemsForThatYear =
            !containers.filter((container) =>
                container.title.includes(`${selectedYear[0]}`)
            ).length &&
            !items.filter((item) => item.year === selectedYear[0]).length;

        if (isRollupPage && notContainerAndItemsForThatYear) {
            return (
                <Empty
                    description={
                        <span>
                            {isRollupPage
                                ? 'No data for this year'
                                : 'You have not added a recommendation yet.'}
                        </span>
                    }
                >
                    <Button
                        onClick={() => addYearMetadata(selectedYear[0])}
                        type="primary"
                        shape="round"
                        icon={<PlusOutlined />}
                    >
                        {isRollupPage
                            ? 'Add data for this year'
                            : 'Add Hold/Sell Recommendation'}
                    </Button>
                </Empty>
            );
        }

        return (
            <ResponsiveBox>
                {Array.from({
                    length:
                        typeof rows === 'number' ? rows : rows(yearMetadata),
                }).map(() => {
                    return <Row />;
                })}

                {Array.from({
                    length:
                        typeof columns === 'number'
                            ? columns
                            : columns(items, yearMetadata),
                }).map(() => {
                    return <Col />;
                })}

                {getContainers()}
            </ResponsiveBox>
        );
    };

    const getContainers = () => {
        const filteredItems = items.filter((item) => {
            if (yearsFilter.length) {
                return yearsFilter.includes(item.year);
            }

            return true;
        });

        return containers
            .sort((containerA, containerB) => {
                if (containerSort) {
                    return containerSort(containerA, containerB);
                }

                return 0;
            })
            .map(
                (
                    {
                        title: containerTitle,
                        row,
                        column,
                        group,
                        filter,
                        sort,
                        addItemButton,
                        addItemButtonLocations = ['container'],
                        getEmptyGroupState,
                        getGroupGrid,
                        getGroupStyle,
                        shouldHideContainerIfEmpty,
                        deleteStrategy,
                        getYearMetadataValue,
                        renderYearMetadataOptions,
                        renderItemMetadataOptions,
                    },
                    sortedIndex
                ) => {
                    const itemsForContainer = filteredItems.filter(filter);

                    const shouldHide =
                        !itemsForContainer.length &&
                        yearsFilter.length &&
                        shouldHideContainerIfEmpty &&
                        shouldHideContainerIfEmpty(yearsFilter);

                    if (shouldHide) {
                        return null;
                    }

                    const emptyGroupState = getEmptyGroupState
                        ? getEmptyGroupState()
                        : ({} as EntityProfileCardsGroupReduceType);

                    const groupedItemsForContainer = itemsForContainer.reduce(
                        group,
                        emptyGroupState
                    );

                    const hasCategoriesEnabledAndWorking =
                        categories?.length &&
                        enableCategories &&
                        categoriesFilter.length > 0;

                    const isSWOT = type === 'swot';

                    const filteredGroupedItemsForContainer =
                        hasCategoriesEnabledAndWorking || isSWOT
                            ? Object.keys(groupedItemsForContainer)
                                  .filter((key) => {
                                      if (isSWOT) {
                                          return (
                                              categoriesFilter.length === 0 ||
                                              categoriesFilter.includes(key)
                                          );
                                      }
                                      return categoriesFilter.includes(key);
                                  })
                                  .reduce(
                                      (obj, key) =>
                                          Object.assign(obj, {
                                              [key]: groupedItemsForContainer[
                                                  key
                                              ],
                                          }),
                                      {}
                                  )
                            : {};

                    const filteredGroupsForCategories =
                        hasCategoriesEnabledAndWorking || isSWOT
                            ? filteredGroupedItemsForContainer
                            : groupedItemsForContainer;

                    const itemMetadataSelects = (item: EntityProfileCard) => (
                        <div className={selectStyle}>
                            <Select
                                size="small"
                                bordered={false}
                                showArrow={false}
                                value={
                                    (item &&
                                        item.metadata &&
                                        item.metadata[0].value) ??
                                    'Open'
                                }
                                defaultValue={prioritiesOptions[0].value}
                                onClick={(event) => event.stopPropagation()}
                                style={{
                                    ...badgeSelectStyle,
                                    minWidth: '110px',
                                    backgroundColor:
                                        (item.metadata &&
                                            item.metadata[0].color) ??
                                        prioritiesOptions[0].color,
                                }}
                                onChange={(value: string) => {
                                    if (!value) {
                                        return;
                                    }

                                    const metadataToSend =
                                        prioritiesOptions.find(
                                            (option) => option.value === value
                                        );

                                    onUpdateItem({
                                        ...item,
                                        metadata: metadataToSend && [
                                            metadataToSend,
                                        ],
                                    });
                                }}
                            >
                                {renderItemMetadataOptions
                                    ? renderItemMetadataOptions()
                                    : null}
                            </Select>
                        </div>
                    );

                    const yearMetadataValue = getYearMetadataValue
                        ? getYearMetadataValue(yearMetadata)
                        : undefined;

                    const groups = Object.keys(filteredGroupsForCategories)
                        .sort((aKey: string, bKey: string) => {
                            if (!sort) {
                                return aKey.localeCompare(bKey);
                            }

                            const groupA = groupedItemsForContainer[aKey];
                            const groupB = groupedItemsForContainer[bKey];

                            if (!groupA || !groupB) {
                                return 0;
                            }

                            return sort(groupA, groupB);
                        })
                        .map((groupKey, index) => {
                            const {
                                row = -1,
                                column = -1,
                                colSpan = 1,
                                addItemButton: groupAddItemButton,
                                items,
                                title,
                            } = groupedItemsForContainer[groupKey];

                            let actualRow = Math.max(0, row);
                            let actualColumn = column === -1 ? index : column;

                            const addItemTitleLabel =
                                groupAddItemButton?.addItemTitleLabel ??
                                addItemButton?.addItemTitleLabel ??
                                'Item';

                            const Container = getGroupGrid
                                ? Item
                                : React.Fragment;

                            return (
                                <Container key={groupKey}>
                                    {getGroupGrid ? (
                                        <Location
                                            col={actualColumn}
                                            row={actualRow}
                                            colspan={colSpan}
                                        />
                                    ) : null}

                                    <EntityProfileCardsGroup
                                        key={title}
                                        style={
                                            getGroupStyle
                                                ? getGroupStyle(
                                                      groupedItemsForContainer[
                                                          groupKey
                                                      ]
                                                  )
                                                : {}
                                        }
                                        itemMetadataSelects={
                                            itemMetadataSelects
                                        }
                                        hideStatus={hideStatus}
                                        enableStatusItems={enableStatusItems}
                                        items={items}
                                        title={title}
                                        addItemButton={
                                            addItemButtonLocations &&
                                            addItemButtonLocations.includes(
                                                'group'
                                            )
                                                ? groupAddItemButton
                                                : undefined
                                        }
                                        onReorderItem={onReorderItem}
                                        enabledFields={enabledFields}
                                        onAddItemButtonClicked={() =>
                                            addItemHandler(groupAddItemButton)
                                        }
                                        onStartEdit={(item) => {
                                            openFormModal(
                                                item,
                                                true,
                                                addItemTitleLabel
                                            );
                                        }}
                                        onConfirmDelete={onDeleteItem}
                                        cardType={type}
                                        onHtmlEditorSubmit={onHtmlEditorSubmit}
                                        yearMetadataValue={yearMetadataValue}
                                        isPDFExport={isPDFExport}
                                        includeNotesInPdf={includeNotesInPdf}
                                    />
                                </Container>
                            );
                        });

                    const isHoldSellRecommendationSelect =
                        yearMetadataValue?.type === 'holdSell' &&
                        yearMetadataValue?.metadata[0]?.key ===
                            'recommendation';

                    const yearMetadataSelects = yearMetadataValue?.metadata.map(
                        (row, rowIndex) => {
                            return isHoldSellRecommendationSelect ? (
                                <span onClick={(e) => e.stopPropagation()}>
                                    <Select
                                        className={selectStyle}
                                        bordered={false}
                                        showArrow={false}
                                        defaultValue={row.value}
                                        value={row.value}
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                        style={{
                                            ...badgeSelectStyle,
                                            backgroundColor:
                                                getHoldSellSelectBGColor(
                                                    row.value
                                                ),
                                            color: '#FFFFFF',
                                        }}
                                        onChange={(value: string) => {
                                            if (!yearMetadataValue) {
                                                return;
                                            }

                                            updateYearMetadata(
                                                yearMetadataValue,
                                                { ...row, value },
                                                rowIndex
                                            );
                                        }}
                                    >
                                        {renderYearMetadataOptions
                                            ? renderYearMetadataOptions(row.key)
                                            : undefined}
                                    </Select>
                                    <span style={{ margin: '0 10px 0 50px' }}>
                                        Targeted Disposition
                                    </span>
                                    <DatePicker
                                        disabledDate={(date) =>
                                            date.year() < yearMetadataValue.year
                                        }
                                        picker="year"
                                        onChange={(value) => {
                                            if (!yearMetadataValue) {
                                                return;
                                            }

                                            const targetedSaleYear = {
                                                targetedSaleYear: value
                                                    ? moment(value).format(
                                                          'yyyy'
                                                      )
                                                    : null,
                                            };
                                            updateYearMetadata(
                                                yearMetadataValue,
                                                { ...row, ...targetedSaleYear },
                                                rowIndex
                                            );
                                        }}
                                        defaultValue={
                                            row.targetedSaleYear
                                                ? moment(
                                                      new Date().setFullYear(
                                                          row.targetedSaleYear
                                                      )
                                                  )
                                                : undefined
                                        }
                                        placeholder="Select Year"
                                    />
                                </span>
                            ) : (
                                <Select
                                    defaultValue={row.value}
                                    value={row.value}
                                    onClick={(event) => event.stopPropagation()}
                                    style={{ minWidth: '130px' }}
                                    onChange={(value: string) => {
                                        if (!yearMetadataValue) {
                                            return;
                                        }

                                        updateYearMetadata(
                                            yearMetadataValue,
                                            { ...row, value },
                                            rowIndex
                                        );
                                    }}
                                >
                                    {renderYearMetadataOptions
                                        ? renderYearMetadataOptions(row.key)
                                        : undefined}
                                </Select>
                            );
                        }
                    );

                    const yearProgress = enableStatusItems
                        ? getYearProgress(
                              items.filter(
                                  (item) =>
                                      item.year === yearMetadataValue?.year
                              )
                          )
                        : null;

                    return (
                        <Item>
                            <Location row={row ?? sortedIndex} col={column} />

                            <EntityProfileCardsGroupContainer
                                defaultKey={defaultExpandedCategories}
                                commentThreadId={
                                    yearMetadataValue?.reportMetadata
                                        .comment_thread_id
                                }
                                typeTitle={title}
                                title={containerTitle}
                                key={containerTitle}
                                isCollapse={
                                    showReportWidgetOnly ? false : isCollapse
                                }
                                extraFunctions={[yearMetadataSelects]}
                                onDelete={
                                    deleteStrategy
                                        ? () => {
                                              const foundItem =
                                                  deleteStrategy.get(
                                                      items,
                                                      yearMetadata
                                                  );

                                              if (!foundItem) {
                                                  return;
                                              }

                                              if (
                                                  deleteStrategy.type ===
                                                  'yearMetadata'
                                              ) {
                                                  deleteYearMetadata(
                                                      foundItem.year
                                                  );
                                              }
                                          }
                                        : undefined
                                }
                                getGroupGrid={getGroupGrid}
                                addItemButton={
                                    addItemButtonLocations &&
                                    addItemButtonLocations.includes('container')
                                        ? addItemButton
                                        : undefined
                                }
                                onAddItemClicked={() => {
                                    if (!addItemButton) {
                                        return;
                                    }

                                    openFormModal(
                                        addItemButton.getDefaultCard(),
                                        false,
                                        addItemButton.addItemTitleLabel
                                    );
                                }}
                                enableStatusItems={enableStatusItems}
                                itemsCompleted={yearProgress?.itemsCompleted}
                                allItems={yearProgress?.allItems}
                                isPDFExport={isPDFExport}
                                includeNotesInPdf={includeNotesInPdf}
                                cardType={type}
                            >
                                {groups}
                            </EntityProfileCardsGroupContainer>
                        </Item>
                    );
                }
            );
    };

    const addItemHandler = (addItemButton?: AddItemButtonProps) => {
        if (!addItemButton) {
            return;
        }

        openFormModal(
            addItemButton.getDefaultCard(),
            false,
            addItemButton.addItemTitleLabel
        );
    };

    const getOptionalFunctions = () => {
        const disabledYears = yearMetadata.map(
            (yearMetadata) => yearMetadata.year
        );

        return (
            <Space>
                {containers.length >= 1 && enableCategories && (
                    <WaypointSelect
                        placeholder={'Select priority category'}
                        style={{ width: 240, marginLeft: 6 }}
                        mode={'multiple'}
                        options={categories ?? []}
                        allowClear={true}
                        value={categoriesFilter}
                        onChange={setCategoriesFilter}
                        maxTagCount={1}
                        showArrow
                    />
                )}

                {enableYearFilter && (
                    <WaypointSelect
                        placeholder={'Select years'}
                        maxTagCount={2}
                        style={{ width: 220, marginLeft: 2 }}
                        mode={'multiple'}
                        value={yearsFilter.sort((a, b) => a - b)}
                        options={getUniqueYears([...items, ...yearMetadata])
                            .sort((a, b) => a - b)
                            .map((year) => ({
                                label: year.toString(),
                                value: year,
                            }))}
                        onChange={setYearsFilter}
                        allowClear={true}
                        showArrow
                    />
                )}
                {enableYearMetadata && (
                    <div style={{ position: 'relative', bottom: 1.5 }}>
                        <DatePicker
                            disabledDate={(date) =>
                                disabledYears.includes(date.year())
                            }
                            picker="year"
                            onChange={(value) => {
                                if (value) {
                                    addYearMetadata(value.year());
                                    setNewYearMetadataYear(null);
                                }
                            }}
                            value={
                                newYearMetadataYear
                                    ? moment(
                                          new Date().setFullYear(
                                              newYearMetadataYear
                                          )
                                      )
                                    : null
                            }
                            placeholder="Add year"
                        />
                    </div>
                )}

                {enableExport && (
                    <div style={{ position: 'relative', bottom: 1.5 }}>
                        <Dropdown
                            overlay={exportableMenu(downloadHandler)}
                            placement="bottomLeft"
                            disabled={isDownloading}
                        >
                            <Button
                                disabled={isDownloading}
                                loading={isDownloading}
                            >
                                <ExportOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                )}
            </Space>
        );
    };

    const addYearMetadata = async (year: number) => {
        const newYearMetadata = getDefaultYearMetadata
            ? getDefaultYearMetadata(year)
            : {
                  year,
                  metadata: [],
              };

        await mutateYearMetadata(
            async () => {
                try {
                    const result = await createEntityProfileCardYearMetadata(
                        entityCode,
                        type,
                        newYearMetadata
                    );

                    message.success('Year added successfully!');

                    setNewYearMetadataYear(null);

                    if (onYearMetadataUpdated) {
                        onYearMetadataUpdated(result.year, result);
                    }

                    return [...yearMetadata, result];
                } catch (e) {
                    message.error('Error adding year');

                    return [...yearMetadata];
                }
            },
            {
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const deleteYearMetadata = (year: number) => {
        mutateYearMetadata(
            async () => {
                try {
                    await deleteEntityProfileCardYearMetadata(
                        entityCode,
                        type,
                        year
                    );

                    message.success('Year deleted successfully!');

                    const itemsWithoutDeleted = [
                        ...items.filter((i) => i.year !== year),
                    ];

                    mutateItems(
                        async () => {
                            return itemsWithoutDeleted;
                        },
                        {
                            optimisticData: itemsWithoutDeleted,
                            rollbackOnError: true,
                            populateCache: true,
                            revalidate: false,
                        }
                    );

                    if (onYearMetadataUpdated) {
                        onYearMetadataUpdated(year, null);
                    }

                    return [...yearMetadata.filter((ym) => ym.year !== year)];
                } catch (e) {
                    message.error('Error deleting year');

                    return [...yearMetadata];
                }
            },
            {
                optimisticData: [
                    ...yearMetadata.filter((ym) => ym.year !== year),
                ],
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const updateYearMetadata = (
        yearMetadataValue: EntityProfileCardYearMetadata,
        updatedRow: { key: string; value: string },
        updatedRowIndex: number
    ) => {
        const updatedYearMetadata = [...yearMetadata];

        const valueToUpdate = updatedYearMetadata.find(
            (ym) => ym.year === yearMetadataValue.year
        );

        if (!valueToUpdate) {
            return;
        }

        const updatedMetadataRows = [...valueToUpdate.metadata];

        updatedMetadataRows[updatedRowIndex] = { ...updatedRow };
        valueToUpdate.metadata = updatedMetadataRows;

        mutateYearMetadata(
            async () => {
                try {
                    await updateEntityProfileCardYearMetadata(
                        entityCode,
                        valueToUpdate.year,
                        valueToUpdate,
                        type
                    );
                    message.success('Year updated!');

                    if (onYearMetadataUpdated) {
                        onYearMetadataUpdated(
                            valueToUpdate.year,
                            valueToUpdate
                        );
                    }

                    return [...updatedYearMetadata];
                } catch (e) {
                    message.error('Error updating year');

                    return [...yearMetadata];
                }
            },
            {
                optimisticData: [...updatedYearMetadata],
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            }
        );
    };

    const downloadHandler = async (e: MenuInfo) => {
        if (!items.length) {
            message.error('No data to download');

            return;
        }

        setIsDownloading(true);
        if (e.key === 'excelExport') {
            const response = await getEntityProfileCardExcelFile(
                entityCode,
                type,
                yearsFilter
            );
            const blob = await response.blob();
            const filename = getFilenameFromResponse(response);

            saveAs(blob, filename);
            setIsDownloading(false);

            return;
        }
    };

    return (
        <Card
            title={!showReportWidgetOnly ? title : null}
            id={`${type}-entityProfileCards`}
            style={{ margin: isRollupPage ? '0' : '20px 0' }}
            bodyStyle={{ padding: '5px' }}
            data-testid={`${type}-entityProfileCards`}
            extra={!showReportWidgetOnly ? getOptionalFunctions() : null}
        >
            <EntityProfileCardsEditorModal
                title={modalState.title}
                isVisible={modalState.isVisible}
                onCancel={() => {
                    setModalState({
                        ...modalState,
                        isVisible: false,
                    });
                }}
                enabledFields={enabledFields}
                entityProfileCard={modalState.item}
                errorMessage={modalState.errorMessage}
                formRef={itemForm}
                isSaving={modalState.isSaving}
                onSubmitForm={onSubmitForm}
                titleLabel={modalState.addItemTitleLabel}
            />
            {getGrid()}
        </Card>
    );
};
