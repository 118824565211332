const downloadGroupTenants = async ({ groupId }) => {
    const response = await fetch(
        `/api/v1/leases/tenants/entity-group/${groupId}`,
        {
            headers: {
                Accept: 'text/csv',
            },
        }
    );
    if (!response.ok) {
        throw new Error('   FAILED');
    }

    return response;
};

export default downloadGroupTenants;
