import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import { CrosstabOptions } from 'components/financials/crosstab/CrosstabTypes';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RowSelection } from '../CrosstabRowSelection';
import { Badge, Button, Checkbox, Dropdown, MenuProps } from 'antd';
import theme from 'config/theme';
import { DownOutlined } from '@ant-design/icons';

const activeSettingsButton = css`
    border-color: #424242 !important;
    background: #424242 !important;
    color: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const CrosstabQuickSettings = ({
    options,
    onApplyOptions,
    rowSelection,
}: {
    options: CrosstabOptions;
    onApplyOptions: (options: CrosstabOptions) => void;
    rowSelection: RowSelection;
}) => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [settingsActiveCount, setSettingsActiveCount] = useState<number>(0);

    useEffect(() => {
        const activeCount = Object.values(options).filter((o) => o).length;
        setSettingsActiveCount(activeCount);
    }, [options]);

    const createQuickSettingsCheckbox = (
        key: string,
        label: string,
        onChange: (value: boolean, e: CheckboxChangeEvent) => void,
        checked: boolean
    ) => (
        <Checkbox
            key={key}
            onChange={(e) => {
                e.stopPropagation();
                onChange(e.target.checked, e);
            }}
            checked={checked}
        >
            {label}
        </Checkbox>
    );

    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: 'Data Settings',
            children: [
                {
                    key: '1-1',
                    label: createQuickSettingsCheckbox(
                        '1',
                        'Show in Outline Form',
                        (value) => {
                            onShowInOutlineFormChanged(value);
                        },
                        options.showInOutlineForm
                    ),
                },
            ],
        },
        {
            key: '2',
            type: 'group',
            label: 'Column Settings',
            children: [
                {
                    key: '2-1',
                    label: createQuickSettingsCheckbox(
                        '2',
                        'Show Totals at End of Group',
                        (value) => {
                            onShowTotalsAtEndOfGroupChanged(value);
                        },
                        options.showTotalsAtEndOfGroup
                    ),
                },
                rowSelection !== RowSelection.SummaryColumns && {
                    key: '2-2',
                    label: createQuickSettingsCheckbox(
                        '3',
                        'Hide Column Subtotals',
                        (value) => {
                            onHideColumnSubtotalsChanged(value);
                        },
                        options.hideColumnSubtotals
                    ),
                },
            ].filter(Boolean) as MenuProps['items'],
        },
    ];

    const onHideColumnSubtotalsChanged = (value: boolean) => {
        onApplyOptions({ ...options, hideColumnSubtotals: value });
    };

    const onShowTotalsAtEndOfGroupChanged = (value: boolean) => {
        onApplyOptions({
            ...options,
            showTotalsAtEndOfGroup: value,
        });
    };

    const onShowInOutlineFormChanged = (value: boolean) => {
        onApplyOptions({ ...options, showInOutlineForm: value });
    };

    const handleDropdownOpen = (openedDropdown: boolean) => {
        setDropdownIsOpen(openedDropdown);
    };

    const onClearFilters = () => {
        setDropdownIsOpen(false);
        onApplyOptions({
            ...options,
            showTotalsAtEndOfGroup: false,
            showInOutlineForm: false,
            hideColumnSubtotals: false,
        });
        setSettingsActiveCount(0);
    };

    return (
        <>
            <Dropdown
                onOpenChange={handleDropdownOpen}
                open={dropdownIsOpen}
                menu={{ items }}
                trigger={['click']}
                placement="bottomLeft"
                arrow
            >
                <Button
                    data-test="quick-settings-button"
                    type="primary"
                    style={{
                        backgroundColor: '#424242',
                        border: '1px #424242 solid',
                    }}
                    className={activeSettingsButton}
                >
                    Quick Settings <DownOutlined style={{ fontSize: '10px' }} />
                    {settingsActiveCount > 0 ? (
                        <Badge
                            style={{
                                backgroundColor: theme.colors.blues.primary,
                                marginLeft: '10px',
                            }}
                            size="small"
                            count={settingsActiveCount}
                        />
                    ) : null}
                </Button>
            </Dropdown>

            {settingsActiveCount > 0 && (
                <Button
                    data-test="quick-settings-button"
                    type="text"
                    style={{
                        borderLeft: '2px solid lightgray',
                        borderRadius: '0px',
                        marginLeft: '6px',
                    }}
                    onClick={() => onClearFilters()}
                >
                    Clear Settings
                </Button>
            )}
        </>
    );
};

export default CrosstabQuickSettings;
