import {
    assign,
    capitalize,
    reduce,
    toArray,
    mapKeys,
    values,
    camelCase,
    forIn,
    keys,
    get,
    merge,
    uniq,
} from 'lodash';
import { normalize } from 'normalizr';
import { batchActions } from 'redux-batched-actions';
import {
    load as loadUsers,
    loadUser,
    deactivateUser as deactivate,
    activateUser as activate,
} from 'state/users/actionCreators';
import {
    load as loadComments,
    remove as removeComments,
} from 'state/comments/actionCreators';
import { load as loadOpportunities } from 'state/opportunities/actionCreators';
import { load as loadAttachments } from 'state/attachments/actionCreators';
import { load as loadClientCategories } from 'state/clientCategories/actionCreators';
import { setAccessList } from 'state/properties/actionCreators';
import { remove as removeAttachment } from 'state/attachments/actionCreators';
import { load as loadSuites } from 'state/suites/actionCreators';
import {
    loadLeases,
    loadPropertyLeaseStatistics,
} from 'state/leases/actionCreators';
import {
    user as userSchema,
    userList as userListSchema,
    commentList as commentListSchema,
    opportunity as opportunitySchema,
    opportunityList as opportunityListSchema,
    attachmentList as attachmentListSchema,
    clientCategoryList as categoryListSchema,
    suiteList as suiteListSchema,
    leaseList as leaseListSchema,
    propertyLeaseStatistics as propertyLeaseStatisticsSchema,
} from 'state/schema';
import {
    loadGroupTenants,
    loadPropertyTenants,
    loadGroupTenantStatistics,
    loadPropertyTenantStatistics,
    loadTenantIdsByPropertyGroup,
    loadTenantIdsByProperty,
} from 'state/tenants/actionCreators';
import { loadDashboardConfiguration } from 'state/dashboardConfigurations/actionCreators';
import store from 'state';
import pluralize from 'pluralize';
import { API_URL } from 'config/constants';
// Advanced Variance Reports
// NOTE: This form of building URLs is too fragile
//       It is too easy to create a malFormed url
//       So Make a more flexible but self documenting
//       system for url generation.
//
// Solution: https://www.npmjs.com/package/safe-url-assembler
export const baseUrl = ({ clientId }) => {
    return `/api/v1/ClientUser/clients/${clientId}`;
};

export const baseLedgerUrl = ({ clientId }) => {
    return `/api/v1/ledger/clients/${clientId}`;
};

export function basePropertyUrl({ propertyId, ...otherIds }) {
    return baseUrl(otherIds) + `/properties/${propertyId}`;
}

export function baseGroupUrl({ groupId, ...otherIds }) {
    return baseUrl(otherIds) + `/propertyGroups/${groupId}`;
}

export function baseUsersUrl({ clientId, ids }) {
    return `/api/v1/ClientUser/clients/${clientId}/users/${ids.join(',')}`;
}

const commentConfig = {
    transform(body) {
        const entities = normalize(body.data, commentListSchema).entities;
        const { comment, user } = entities;
        store.dispatch(batchActions([loadComments(comment), loadUsers(user)]));

        return {};
    },
    update: {},
};

export const requestComments = (url) => {
    return assign({}, commentConfig, { url });
};

// NOTE: Type etc come from the URL used
export const createComment = (url, { payload, mentions, badge }) => {
    return assign({}, commentConfig, {
        url,
        options: {
            method: 'POST',
        },
        body: {
            comment: payload,
            mentions,
            badge,
        },
        transform(body) {
            // NOTE: This conditional is necessary to upload comments into
            //       store if they are from new api. Old comment API
            //       still works, but will be deprecated.
            if (keys(body.data).length > 1) {
                const { id } = body.data;
                body.data = { [id]: body.data };
            }
            const entities = normalize(body.data, commentListSchema).entities;
            return entities;
        },
        update: {
            comment(_, newComment) {
                const commentId = Object.keys(newComment)[0];
                // FIX ME: mentions should come back in payload from successful comment post
                if (mentions.length) {
                    newComment[commentId].mentionedUsers = mentions;
                }

                store.dispatch(loadComments(newComment));
            },
        },
    });
};
// NOTE: this deleteComment will be deprecated
export const deleteComment = (url, { id, commentableType, commentableId }) => {
    return assign({}, commentConfig, {
        url: `${url}/${id}`,
        options: {
            method: 'DELETE',
        },
        transform(body) {
            const deletedId = parseInt(body.data);
            return { comment: { id: deletedId } };
        },
        update: {
            comment(old, toDelete) {
                store.dispatch(
                    removeComments({
                        ids: [toDelete.id],
                        commentableType,
                        commentableId,
                    })
                );
            },
        },
    });
};

export const deleteCommentNewAPI = (
    url,
    { commentableType, commentableId }
) => {
    return assign({}, commentConfig, {
        url,
        options: {
            method: 'DELETE',
        },
        transform(body) {
            const deletedId = parseInt(body.data);
            return { comment: { id: deletedId } };
        },
        update: {
            comment(old, toDelete) {
                store.dispatch(
                    removeComments({
                        ids: [toDelete.id],
                        commentableType,
                        commentableId,
                    })
                );
            },
        },
    });
};

export const requestAttachments = ({ clientId, modelId, modelName }) => {
    if (!clientId || !modelId || !modelName) {
        return {};
    }

    return {
        url: `/api/v1/clients/${clientId}/${pluralize(
            modelName
        ).toLowerCase()}/${modelId}/attachments`,
        transform: ({ data }) => {
            return {
                attachments: normalize(data, attachmentListSchema).entities
                    .attachments,
            };
        },
        update: {
            attachments(old, attachments) {
                store.dispatch(loadAttachments(attachments));
            },
        },
    };
};

export const requestClientCategories = () => ({
    url: '/api/v1/tasks/categories',
    queryKey: '/api/v1/tasks/categories',
    transform: (body) => {
        return normalize(body.data, categoryListSchema).entities;
    },
    update: {
        clientCategory: (old, categories) => {
            store.dispatch(loadClientCategories({ ...old, ...categories }));
        },
    },
});

export const requestUser = ({ id }) => {
    if (!id) {
        return {};
    }
    return {
        url: `${API_URL}/users/me/${id}`,
        options: {
            credentials: 'include',
        },
        queryKey: `${API_URL}/users/me/${id}`,
        transform: (body) => {
            return {
                users: normalize(body.data, userListSchema).entities.user,
            };
        },
        update: {
            users: (old, newUsers) => {
                store.dispatch(loadUsers(newUsers));
            },
        },
    };
};

export const requestAllUsers = ({ clientId }) => {
    return {
        url: `${API_URL}/client/${clientId}/usersSummary`,
        options: {
            credentials: 'include',
        },
        force: true,
        transform: ({ data }) => {
            return { users: normalize(data, userListSchema).entities.user };
        },
        update: {
            users: (old, users) => store.dispatch(loadUsers(users)),
        },
    };
};

export const createUser = ({ body }) => {
    return {
        url: `${API_URL}/users`,
        options: {
            method: 'POST',
            credentials: 'include',
        },
        body,
        transform: ({ data }) => {
            const id = Object.keys(data)[0];
            const processedUser = values(
                normalize(data[id], userSchema).entities.user
            )[0];
            return { user: processedUser };
        },
        update: {
            user: (old, user) => {
                store.dispatch(loadUser(user));
            },
        },
    };
};

export const inviteUser = ({ userId, clientId }) => {
    return {
        url: `${API_URL}/user-invitations/${userId}/invite`,
        options: {
            method: 'POST',
            credentials: 'include',
        },
        transform: ({ data }) => {
            const processedUser = values(
                normalize(data, userSchema).entities.user
            )[0];
            return {
                user: processedUser,
            };
        },
        update: {
            user: (old, user) => {
                store.dispatch(loadUser(user));
            },
        },
    };
};

export const editUserDetail = (body, id) => {
    return {
        url: `${API_URL}/users/admin/${id}/details`,
        body,
        options: {
            method: 'PUT',
            credentials: 'include',
        },
        transform: ({ data }) => {
            const processedUser = values(
                normalize(data, userSchema).entities.user
            )[0];
            return { user: processedUser };
        },
        update: {
            user: (old, user) => {
                store.dispatch(loadUser(user));
            },
        },
    };
};

export const deactivateUser = (id) => {
    return {
        url: `${API_URL}/users/${id}/deactivate`,
        options: {
            method: 'POST',
            credentials: 'include',
        },
        transform: ({ data }) => {
            return { user: data };
        },
        update: {
            user: (_, user) => {
                store.dispatch(deactivate(user));
            },
        },
    };
};

export const activateUser = (id) => {
    return {
        url: `${API_URL}/users/${id}/activate`,
        options: {
            method: 'POST',
            credentials: 'include',
        },
        transform: ({ data }) => {
            return { user: data };
        },
        update: {
            user: (_, user) => {
                store.dispatch(activate(user));
            },
        },
    };
};

export const requestUsers = ({ ids, clientId }) => {
    if (ids.length < 1) {
        return {};
    }
    return {
        url: baseUsersUrl({ clientId, ids }),
        transform: (body) => {
            return {
                users: normalize(body.data, userListSchema).entities.user,
            };
        },
        update: {
            users: (old, newUsers) => {
                store.dispatch(loadUsers(newUsers));
            },
        },
    };
};

export const requestOpportunities = () => ({
    force: true,
    url: `/api/v1/tasks`,
    transform: (body) => {
        return normalize(body.data, opportunityListSchema).entities;
    },
    update: {
        user: (old, users) => {
            // TODO: Submit PR to allow updating of single state slices
            store.dispatch(loadUsers({ ...old, ...users }));
        },
        opportunity: (old, opportunities) => {
            store.dispatch(loadOpportunities({ ...old, ...opportunities }));
        },
    },
});
// TODO (Consistency, Nicholas): Add request Opportunity Function and add it to Opportunity.jsx
export const mutateOpportunity = ({ payload, opportunityId }) => ({
    url: `/api/v1/tasks/${opportunityId}`,
    options: {
        method: 'PUT',
    },
    body: payload,
    transform(body) {
        const { data } = body;
        const newTask = { ...data, ...payload };
        return normalize(newTask, opportunitySchema).entities;
    },
    update: {
        opportunity: (old, newState) => {
            // TODO (Nicholas): only update what has changed
            store.dispatch(loadOpportunities(newState));
        },
    },
});

export const deleteOpportunity = ({ opportunityId }) => {
    return {
        url: `/api/v1/tasks/${opportunityId}`,
        options: {
            method: 'DELETE',
        },
        transform(body) {
            const deletedId = parseInt(body.data);
            return { opportunity: { id: deletedId } };
        },
        // TODO (Nicholas): Update store after an opportunity is deleted
        //                  there were race conditions w/ the opportunities page
    };
};

export const getAccessListsForProperties = ({ clientId, propertyIds }) => {
    const idsQuery = propertyIds.reduce((queryString, value) => {
        return queryString.length > 0
            ? `${queryString},${value}`
            : value.toString();
    }, '');

    const url =
        propertyIds.length > 0
            ? `/api/v1/clients/${clientId}/property_access_lists?properties=${idsQuery}`
            : `/api/v1/clients/${clientId}/property_access_lists`;

    return {
        url,
        transform: (body) => {
            const accessLists = Object.entries(body.data).reduce(
                (list, entry) => {
                    const [propertyId, propertyUsers] = entry;

                    // NOTE (daniel): Payload comes in the form PropertyAccessList_<id> so we clean it
                    const cleanKey = propertyId.replace(/\w+_/, '');

                    return {
                        ...list,
                        [cleanKey]: propertyUsers,
                    };
                },
                {}
            );

            return {
                accessLists,
            };
        },

        update: {
            accessLists: (_, lists) => {
                // NOTE (daniel): For every property on the object, we set its access list
                Object.entries(lists).forEach((entry) => {
                    const [propertyId, userIds] = entry;
                    store.dispatch(setAccessList(propertyId, userIds));
                });
            },
        },
    };
};

export const getAccessListForProperty = ({ propertyId }) => {
    return {
        url: `/api/v1/ClientUser/propertyDetails/${propertyId}/users`,
        transform: (body) => {
            return normalize(body.data, userListSchema).entities;
        },
        update: {
            user: (old, users) => {
                store.dispatch(loadUsers({ ...old, ...users }));
                store.dispatch(
                    setAccessList(
                        propertyId,
                        toArray(users).map((u) => u.id)
                    )
                );
            },
        },
    };
};

export const deleteAttachment = ({ clientId, key }) => {
    return {
        url: `/api/v1/clients/${clientId}/attachments/${key}`,
        options: {
            method: 'DELETE',
        },
        transform({ data: { id } }) {
            return { attachment: { id } };
        },
        update: {
            attachment(_, { id }) {
                store.dispatch(removeAttachment(id));
            },
        },
    };
};

export const getSuites = ({ id, clientId }) => {
    return {
        url: `${baseUrl({ clientId })}/properties/${id}/suiteDetails`, // NOTE: Amend when group lease rollup is implemented
        transform({ data, metadata }) {
            let { suites } = normalize(data, suiteListSchema).entities;
            metadata.id = id;
            metadata.propertyId = id;
            const { propertyLeaseStatistics } = normalize(
                metadata,
                propertyLeaseStatisticsSchema
            ).entities;

            const leaseCollection = reduce(
                suites,
                (collection, suite) => {
                    const leaseDetails = get(suite, 'leaseDetails');
                    const { leases } = normalize(
                        leaseDetails,
                        leaseListSchema
                    ).entities;
                    collection = merge(collection, leases);
                    return collection;
                },
                {}
            );

            // NOTE: This removes lease detail objects from each suite and adds an array of lease ids.
            suites = forIn(suites, (suite) => {
                const leaseIds = toArray(suite.leaseDetails).map((l) => l.id);
                suite.leaseIds = leaseIds;
                delete suite.leaseDetails;
                return suite;
            });

            store.dispatch(
                batchActions([
                    store.dispatch(
                        loadPropertyLeaseStatistics(propertyLeaseStatistics)
                    ),
                    store.dispatch(loadSuites(suites)),
                    store.dispatch(loadLeases(leaseCollection)),
                ])
            );
            return {};
        },
        update: {},
    };
};

const transformTenantsData = (entityOrGroupId, isGroup, response) => {
    const { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent } =
        response.data.reduce(
            (
                { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent },
                leaseDataRow
            ) => {
                const key = `${leaseDataRow.tenant_name}__${leaseDataRow.tenant_industry}`;
                const tenant = tenantsByKey.get(key) || {
                    name: leaseDataRow.tenant_name,
                    active_total_square_footage: 0,
                    avg_annual_in_place_rent:
                        leaseDataRow.average_annual_in_place_rent,
                    avg_annual_in_place_rent_per_sqft:
                        leaseDataRow.average_annual_in_place_rent_persqft,
                    total_annual_in_place_rent: 0,
                    id: uniqueTenants.size + 1,
                    property_id_arr: [],
                    industry: leaseDataRow.tenant_industry,
                    percentOccupiedSqFtOfPortfolioSqFt: 0,
                    occupancySqftPercentByGroup:
                        leaseDataRow.occupancy_sqft_percent_by_group * 100,
                    weightedAverageLeaseExpiration: leaseDataRow.wale_years,
                };

                tenant.active_total_square_footage +=
                    leaseDataRow.tenant_lease_area;

                tenant.total_annual_in_place_rent +=
                    leaseDataRow.total_annual_in_place_rent;

                tenant.percentOccupiedSqFtOfPortfolioSqFt +=
                    leaseDataRow.occupancy_sqft_percent * 100;

                tenant.property_id_arr = uniq([
                    ...tenant.property_id_arr,
                    leaseDataRow.entity_code,
                ]);

                totalAnnualInPlaceRent +=
                    leaseDataRow.total_annual_in_place_rent;

                tenantsByKey.set(key, tenant);
                uniqueTenants.set(tenant.name, true);

                return { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent };
            },
            {
                tenantsByKey: new Map(),
                uniqueTenants: new Map(),
                totalAnnualInPlaceRent: 0,
            }
        );

    const tenantStats = {
        [entityOrGroupId]: {
            portfolioNumTenants: uniqueTenants.size,
            portfolioWeightedAverageLeaseExpiration:
                response.metadata.wale_year,
            portfolioAnnualInPlaceMonthlyRent: totalAnnualInPlaceRent,
            tenantStatsByIndustry: response.metadata.tenantStatsByIndustry.map(
                (item) => {
                    return {
                        ...item,
                        tenant_industry: capitalize(item.tenant_industry),
                    };
                }
            ),
        },
    };

    const tenants = Array.from(tenantsByKey.values()).map((d) =>
        mapKeys(d, (v, k) => camelCase(k))
    );
    const tenantIds = tenants.map((t) => t.id);
    const tenantIdsByEntityOrGroupId = {
        [entityOrGroupId]: tenantIds,
    };

    return {
        tenants,
        tenantIdsByEntityOrGroupId,
        tenantStats,
    };
};

export const requestGroupTenants = ({ groupId }) => {
    // TODO (Daniel): refactor this to import the store and read clientId
    return {
        url: `/api/v1/leases/tenants/entity-group/${groupId}`,
        update: {},
        options: {
            method: 'GET',
        },
        transform(response) {
            if (response.success) {
                const { tenants, tenantIdsByEntityOrGroupId, tenantStats } =
                    transformTenantsData(groupId, true, response);
                return store.dispatch(
                    batchActions([
                        loadGroupTenants(tenants),
                        loadGroupTenantStatistics(tenantStats),
                        loadTenantIdsByPropertyGroup(
                            tenantIdsByEntityOrGroupId
                        ),
                    ])
                );
            }

            return response.errors;
        },
    };
};

export const downloadGroupTenants = async ({ groupId }) => {
    const response = await fetch(
        `/api/v1/leases/tenants/entity-group/${groupId}`,
        {
            headers: {
                Accept: 'text/csv',
            },
        }
    );
    if (!response.ok) {
        throw new Error('FAILED');
    }

    return response;
};

export const downloadPropertyTenants = async ({ pureId }) => {
    const response = await fetch(`/api/v1/leases/tenants/entity/${pureId}`, {
        headers: {
            Accept: 'text/csv',
        },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    return response;
};

// IMPORTANT IMPORTANT IMPORTANT: REFACTOR TO MATCH GROUP REQUEST
export const requestPropertyTenants = ({ pureId: propertyId }) => {
    // TODO (Daniel): refactor this to import the store and read clientId
    return {
        url: `/api/v1/leases/tenants/entity/${propertyId}`,
        update: {},
        headers: {
            Accept: 'text/csv',
        },
        options: {
            method: 'GET',
        },
        transform(response) {
            if (response.success) {
                const { tenants, tenantIdsByEntityOrGroupId, tenantStats } =
                    transformTenantsData(propertyId, false, response);

                return store.dispatch(
                    batchActions([
                        loadPropertyTenants(tenants),
                        loadPropertyTenantStatistics(tenantStats),
                        loadTenantIdsByProperty(tenantIdsByEntityOrGroupId),
                    ])
                );
            }
            return response.errors;
        },
    };
};

export const setNumberFormat = ({
    clientId,
    decimalDisplay,
    negativeValue,
}) => {
    return {
        url: `/api/v1/clients/${clientId}/updateClientSettings`,
        force: true,
        update: {},
        body: {
            DECIMAL_DISPLAY: decimalDisplay,
            NEGATIVE_VALUE_SYMBOLS: negativeValue,
        },
        options: {
            method: 'PUT',
        },
    };
};

export const getDashboardConfiguration = ({
    clientId,
    dashboardConfigurationId,
    module, // This variable is needed to store dashboards in module specific state slices (analytics, leases, etc)
}) => {
    const url = `${API_URL}/dashboard-config/${dashboardConfigurationId}`;

    return {
        url,
        options: {
            credentials: 'include',
        },
        transform: (response) => {
            const { data } = response;
            return {
                dashboardConfiguration: data,
            };
        },
        update: {
            dashboardConfiguration: (_, dashboardConfiguration) => {
                store.dispatch(
                    loadDashboardConfiguration({
                        module,
                        configuration: dashboardConfiguration,
                    })
                );
            },
        },
    };
};

// FOR PROPERTY PROFILE, NOT CAPEX MODULE
const getCapexProjectsUrl = ({ pureId }) =>
    `api/v1/entities/${pureId}/capex-projects`;

export const getCapexProjects = ({ pureId, httpHeaders }) => {
    const url = getCapexProjectsUrl({ pureId });
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    const updatedHttpHeaders = merge(defaultHttpHeaders, httpHeaders);
    return fetch(url, { headers: updatedHttpHeaders });
};
