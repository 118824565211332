import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { css } from 'emotion';
import React, { useContext } from 'react';

import {
    HandleUpdateFilterAttributeType,
    SelectedAttributes,
} from 'components/app/global-filter-drawer/types/globalFilterTypes';
import { removeInvalidAttributeSelections } from 'components/app/global-filter-drawer/globalFilterUtils';
import AttributeFilterField from 'components/app/global-filter-drawer/attribute-filter-field/AttributeFilterField';
import theme from 'config/theme';
import { EntityAttributesContext } from 'contexts';
import { createUUID } from 'waypoint-utils';

const filtersContainerStyle = css`
    padding: 20px 30px;
    background-color: ${theme.colors.grays.background};
    border-radius: 4px;
    border: 1px solid ${theme.colors.grays.light};
    margin-bottom: 16px;
`;

interface AttributeFilterFormProps {
    disabled?: boolean;
    attributeFilters: SelectedAttributes;
    setAttributeFilters: (attrs: SelectedAttributes) => void;
}

export const AttributeFilterForm = ({
    disabled,
    attributeFilters,
    setAttributeFilters,
}: AttributeFilterFormProps): JSX.Element => {
    const entityAttributesContext = useContext(EntityAttributesContext);

    // TODO: need to handle the possibility that there are less than 5 options to select
    const handleAddNewAttributeFilter = () => {
        const newUUID = createUUID();
        const newAttributeFilters = { ...attributeFilters };

        newAttributeFilters[newUUID] = {
            uuid: newUUID,
            attribute: '',
            attributeDataIndex: '',
            attributeValues: [],
        };

        setAttributeFilters(newAttributeFilters);
    };

    const handleUpdateAttributeFilter: HandleUpdateFilterAttributeType = ({
        uuid,
        attribute,
        attributeDataIndex,
        attributeValues,
    }) => {
        const newAttributeFilters = { ...attributeFilters };

        newAttributeFilters[uuid] = {
            uuid,
            attribute,
            attributeDataIndex,
            attributeValues,
        };

        const validFilterSelections = removeInvalidAttributeSelections(
            entityAttributesContext?.data?.attributes || [],
            entityAttributesContext?.data?.entities || [],
            newAttributeFilters
        );

        setAttributeFilters(validFilterSelections);
    };

    const handleRemoveAttributeFilter = (id: string) => {
        const newAttributeFilters = { ...attributeFilters };

        delete newAttributeFilters[id];
        setAttributeFilters(newAttributeFilters);
    };

    return (
        <div className={filtersContainerStyle}>
            <h2 style={{ marginTop: 0, marginBottom: 20 }}>Filter By</h2>

            {attributeFilters && Object.keys(attributeFilters).length === 0 ? (
                <span data-testid="filter-editor-empty-state">
                    No filters applied
                </span>
            ) : (
                Object.keys(attributeFilters).map((uuid) => {
                    return (
                        <AttributeFilterField
                            uuid={uuid}
                            key={uuid}
                            disabled={disabled}
                            currentSelections={attributeFilters}
                            handleRemoveAttributeFilter={
                                handleRemoveAttributeFilter
                            }
                            handleUpdateAttributeFilter={
                                handleUpdateAttributeFilter
                            }
                        />
                    );
                })
            )}

            <Button
                data-testid="add-new-filter-button"
                style={{ marginTop: 20, display: 'block' }}
                onClick={handleAddNewAttributeFilter}
                disabled={
                    disabled ||
                    // TODO: disable if all possible attribute titles have been selected (edge case if entities have less than 5 attributes)
                    Object.keys(attributeFilters).length >= 5
                }
            >
                <PlusOutlined />
                Add New Filter
            </Button>
        </div>
    );
};
