import { Table } from 'antd';
import { currencyRenderer } from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    convertColumnsToAntd,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { LeasingPlanActivity } from 'waypoint-types/leasing-plans';
import {
    calculateEffectiveRent,
    calculateNetEffectiveRentPerSFYear,
    calculateTotalCapital,
    calculateTotalTenantImprovements,
    leaseTypeOptions,
} from 'components/leasingPlan/LeasingPlanUtils';
import { formatInTimeZone } from 'date-fns-tz';

const cellStyle = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    paddingLeft: 8,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
};

const dateFormatter = (dateString: string | null) => {
    if (!dateString) {
        return '';
    }
    return formatInTimeZone(new Date(dateString), 'UTC', 'M/d/yyyy');
};

interface LeasingPlanActivityWithCalculatedFields extends LeasingPlanActivity {
    effective_rent: number;
    total_tenant_improvements: number;
    total_capital: number;
    ner_psf_year: number;
}

export const leasingPlanActivityBaseColumns = (isNewLeases: boolean) => {
    const baseColumns = [
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            align: 'left',
            dataType: 'string',
            cssClass: 'noWrap',
        },
        {
            title: 'RSF',
            dataIndex: 'rentable_sf',
            key: 'rentable_sf',
            align: 'center',
            dataType: 'number',
        },
        {
            title: 'Tenant',
            dataIndex: 'tenant_name',
            key: 'tenant_name',
            align: 'left',
            dataType: 'string',
        },
        {
            title: 'LXD',
            dataIndex: 'lease_expiration_date',
            key: 'lease_expiration_date',
            align: 'center',
            dataType: 'date',
            render: dateFormatter,
        },

        {
            title: 'LCD',
            dataIndex: 'commencement_date',
            key: 'commencement_date',
            align: 'center',
            dataType: 'date',
            render: dateFormatter,
        },
        {
            title: 'Term (months)',
            dataIndex: 'term',
            key: 'term',
            align: 'center',
            dataType: 'number',
        },
        {
            title: 'Base Rent (PSF / Year)',
            dataIndex: 'base_rent_sf',
            key: 'base_rent_sf',
            align: 'center',
            dataType: 'number',
        },
        {
            title: 'Free Rent (months)',
            dataIndex: 'free_rent',
            key: 'free_rent',
            align: 'center',
            dataType: 'number',
        },
        {
            title: 'Eff. Rent (PSF / Year)',
            dataIndex: 'effective_rent',
            key: 'effective_rent',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'Market Ready TIs PSF',
            dataIndex: 'market_ready_tenant_improvements',
            key: 'market_ready_tenant_improvements',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'TIA PSF',
            dataIndex: 'tenant_improvements_sf',
            key: 'tenant_improvements_sf',
            align: 'center',
            dataType: 'number',
        },
        {
            title: 'Total TI',
            dataIndex: 'total_tenant_improvements',
            key: 'total_tenant_improvements',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'LC Type',
            dataIndex: 'leasing_commissions_type',
            key: 'leasing_commissions_type',
            align: 'center',
            dataType: 'string',
        },
        {
            title: 'LC',
            dataIndex: 'leasing_commissions',
            key: 'leasing_commissions',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'Total LC',
            dataIndex: 'total_leasing_commisions',
            key: 'total_leasing_commisions',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'Total Capital',
            dataIndex: 'total_capital',
            key: 'total_capital',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'NER PSF/Year',
            dataIndex: 'ner_psf_year',
            key: 'ner_psf_year',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
    ];

    const conditionalColumn = isNewLeases
        ? {
              title: 'Type',
              dataIndex: 'activity_type',
              dataType: 'string',
              key: 'activity_type',
              align: 'center',
              render: (value: string) =>
                  leaseTypeOptions.find((o) => o.value === value)?.label ?? '',
          }
        : {
              title: 'Renewal Expectation',
              dataIndex: 'expectation',
              key: 'expectation',
              align: 'left',
              dataType: 'string',
              render: (value: string) => {
                  if (value === 'renewing') {
                      return (
                          <span
                              style={{
                                  ...cellStyle,
                                  backgroundColor: '#00c85480',
                              }}
                          >
                              Renewing
                          </span>
                      );
                  }

                  return (
                      <span
                          style={{ ...cellStyle, backgroundColor: '#df010161' }}
                      >
                          Not Renewing
                      </span>
                  );
              },
          };

    baseColumns.splice(4, 0, conditionalColumn);
    return baseColumns;
};

const addCalculatedActivityFields = (activityData: LeasingPlanActivity[]) => {
    return activityData.reduce((activityList, ad) => {
        const activityDataWithCalculatedFields = {
            ...ad,
            effective_rent: calculateEffectiveRent(ad),
            total_tenant_improvements: calculateTotalTenantImprovements(ad),
            total_capital: calculateTotalCapital(ad),
            ner_psf_year: calculateNetEffectiveRentPerSFYear(ad),
        };
        activityList.push(activityDataWithCalculatedFields);
        return activityList;
    }, [] as LeasingPlanActivityWithCalculatedFields[]);
};

export const LeasingPlanActivityExportableGrid = ({
    data,
    isNewLeases,
}: {
    data: LeasingPlanActivity[];
    isNewLeases: boolean;
}) => {
    const activityData = addCalculatedActivityFields(data);
    const { gridColumns } = convertColumnsToAntd(
        leasingPlanActivityBaseColumns(isNewLeases) as ColumnDescriptor[],
        true,
        null
    );

    return (
        <Table
            dataSource={activityData}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
        />
    );
};
