import { LeaseExpirationScheduleProps, RentRollProps } from '../waypoint-types';
import { useEffect, useMemo, useState } from 'react';
import {
    expirationGroupingByOptions,
    getFormattedLeaseExpirationByGroupingOption,
    groupByMonths,
    groupByYear,
    isMonthYearSelected,
    isYearSelected,
} from '../components/leases/expirations/cards/leases-expiration/utils';
import { Dictionary } from 'ts-essentials';

export interface UseLeasesExpirationProps {
    rentRoll?: RentRollProps[];
    groupByOption?: expirationGroupingByOptions;
    yearToGroup?: number;
    monthsToGroup?: number;
}
export const useLeasesExpiration = ({
    rentRoll = [],
    groupByOption = expirationGroupingByOptions.year,
    yearToGroup,
    monthsToGroup,
}: UseLeasesExpirationProps): LeaseExpirationScheduleProps[] => {
    const [yearToGroupValidation, setYearToGroupValidation] = useState<
        number | undefined
    >(yearToGroup);

    useEffect(() => {
        if (yearToGroup !== yearToGroupValidation) {
            setYearToGroupValidation(yearToGroup);
        }
    }, [yearToGroup, yearToGroupValidation]);

    useEffect(() => {
        if (groupByOption !== expirationGroupingByOptions.year) {
            setYearToGroupValidation(undefined);
        }
    }, [groupByOption]);

    /* 
    because of a couple data edge cases where there are multiple entries with non-zero rent amounts
    for a single leasable space code, we've been filtering out small amounts of rent data 
    because we were totaling based on unique leaseable space codes (this doesn't seem to be a new issue,
    as it's affecting prod as well). so instead of using the unique filtered list, 
    here we get totals for active leases and use them for the relevant calculations below
    */
    const activeLeasesAdjustedTotalsByLeasableSpaceCode = useMemo(() => {
        if (!rentRoll) {
            return {};
        }

        return rentRoll
            .filter((rr) => rr.space_occupancy_status === 'OCCUPIED')
            .reduce(
                (dict, al) => {
                    if (Object.keys(dict).includes(al.leasable_space_code)) {
                        dict[al.leasable_space_code] +=
                            al.in_place_base_rent ?? 0;
                    } else {
                        dict[al.leasable_space_code] =
                            al.in_place_base_rent ?? 0;
                    }
                    dict.totalRent += al.in_place_base_rent ?? 0;
                    return dict;
                },
                { totalRent: 0 } as Dictionary<number>
            );
    }, [rentRoll]);

    const leaseExpirationsData = useMemo(() => {
        const leaseExpirations =
            rentRoll &&
            getFormattedLeaseExpirationByGroupingOption(
                rentRoll,
                activeLeasesAdjustedTotalsByLeasableSpaceCode,
                groupByOption
            );

        if (isYearSelected(groupByOption)) {
            return groupByYear(leaseExpirations ?? [], yearToGroupValidation);
        }

        if (isMonthYearSelected(groupByOption)) {
            return groupByMonths(leaseExpirations ?? [], monthsToGroup);
        }

        return leaseExpirations ?? [];
    }, [
        rentRoll,
        groupByOption,
        yearToGroupValidation,
        monthsToGroup,
        activeLeasesAdjustedTotalsByLeasableSpaceCode,
    ]);

    return leaseExpirationsData as LeaseExpirationScheduleProps[];
};
