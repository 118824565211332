import { WORKFLOW_REVIEWER } from 'components/financials/comparative-income-statement/constants';
import { getApprovalsWithStatus } from 'components/reports/ReportApprovalUtils';
import {
    ReportWorkflowReviewStatus,
    WorkflowRoleReferenceType,
} from 'components/reports/constants';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { Dictionary } from 'ts-essentials';
import {
    useGetDocumentUploadByReferenceParams,
    useGetEntityReportPublishingHistory,
    useGetReportById,
} from 'waypoint-hooks';
import { getDocumentUploadById } from 'waypoint-requests';
import {
    DocumentUpload,
    EntityReportApproval,
    EntityReportPublished,
    EntityReportWidgetListItem,
    ReportMetadata,
    ReportWorkflowReview,
} from 'waypoint-types';

interface UseEntityReportApprovalsParams {
    entityCode: string;
    entityReportId: string;
    reportId: string;
    propertyName: string;
}

export const useEntityReportApprovals = ({
    entityCode,
    entityReportId,
    reportId,
    propertyName,
}: UseEntityReportApprovalsParams) => {
    // current: the published report that is currently in review or approved;
    // the published report that will be shown on the reviewer / approval view page by default
    const [currentEntityReportPublished, setCurrentEntityReportPublished] =
        useState<EntityReportPublished | null>(null);
    const [
        currentEntityReportPublishedReportMetadata,
        setCurrentEntityReportPublishedReportMetadata,
    ] = useState<ReportMetadata | null>(null);
    const [
        currentEntityReportPublishedReviews,
        setCurrentEntityReportPublishedReviews,
    ] = useState<ReportWorkflowReview[] | null>(null);

    // selected: set when user clicks a published report in the submission history panel;
    // determines the published report that will appear in the reviewer view drawer
    const [selectedEntityReportPublished, setSelectedEntityReportPublished] =
        useState<EntityReportPublished | null>(null);
    const [
        selectedEntityReportPublishedReportMetadata,
        setSelectedEntityReportPublishedReportMetadata,
    ] = useState<ReportMetadata | null>(null);

    const [entityReportApprovals, setEntityReportApprovals] =
        useState<Dictionary<EntityReportApproval> | null>(null);

    const [reviewerViewDrawerIsOpen, setReviewerViewDrawerIsOpen] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [currentPublishedPDF, setCurrentPublishedPDF] = useState<
        string | undefined
    >(undefined);

    const [currentDocumentFetchError, setCurrentDocumentFetchError] =
        useState<boolean>(false);

    const [entityReportApprovalStatus, setEntityReportApprovalStatus] =
        useState<string | null>(null);

    const [reportWidgetList, setReportWidgetList] = useState<
        EntityReportWidgetListItem[]
    >([]);

    const { data: report, isValidating } = useGetReportById(reportId);

    const {
        data: publishedEntityReports,
        mutate: mutatePublishedEntityReports,
    } = useGetEntityReportPublishingHistory(entityCode, entityReportId);

    const {
        data: currentPublishedEntityReportPDF,
        isError: currentPublishedEntityReportPDFError,
    } = useGetDocumentUploadByReferenceParams(
        WorkflowRoleReferenceType.EntityReportPublished,
        currentEntityReportPublished?.id ?? ''
    );

    const setPublishedPDFUpload = async (
        publishedEntityReportPDF: DocumentUpload | undefined,
        setPublishedPDF: (value: string | undefined) => void,
        setDocumentFetchError: (value: boolean) => void
    ) => {
        if (!publishedEntityReportPDF) {
            return;
        }
        try {
            const fileBuffer = await getDocumentUploadById(
                publishedEntityReportPDF.id
            );
            const pdfDoc = await PDFDocument.load(fileBuffer);
            pdfDoc.setTitle(`${propertyName} - ${report?.name ?? ''}`);

            const rawPDF = await pdfDoc.save();
            const docUrl = URL.createObjectURL(
                new Blob([rawPDF], { type: 'application/pdf' })
            );
            setPublishedPDF(docUrl);
            setDocumentFetchError(false);
        } catch {
            setDocumentFetchError(true);
        }
    };

    useEffect(() => {
        if (currentPublishedEntityReportPDF?.id) {
            setPublishedPDFUpload(
                currentPublishedEntityReportPDF,
                setCurrentPublishedPDF,
                setCurrentDocumentFetchError
            );
        }
    }, [currentPublishedEntityReportPDF]);

    useEffect(() => {
        if (!publishedEntityReports || !report || !entityReportId) {
            return;
        }

        const { entityReportPublished, reportsMetadata, entityReportReviews } =
            publishedEntityReports;

        const lastPublishedEntityReport = entityReportPublished.length
            ? entityReportPublished.slice(-1)[0]
            : null;

        entityReportPublished.length &&
            setCurrentEntityReportPublished(lastPublishedEntityReport);

        const entityReport = report.entityReports?.find(
            (entityReport) =>
                entityReport.id === lastPublishedEntityReport?.entity_report_id
        );

        const currentReviewerIds =
            entityReport?.workflowRole
                .filter((role) => role.report_role === WORKFLOW_REVIEWER)
                .map((role) => role.user_id) ?? [];

        const lastPublishedEntityReportReportMetadata = reportsMetadata.find(
            (rm) => rm.filter_hash === lastPublishedEntityReport?.id ?? ''
        );

        lastPublishedEntityReportReportMetadata &&
            setCurrentEntityReportPublishedReportMetadata(
                lastPublishedEntityReportReportMetadata
            );

        const approvalsParams = entityReportPublished.reduce<
            EntityReportApproval[]
        >((approvalsList, erp) => {
            const reportMetadata =
                reportsMetadata.find((rm) => rm.filter_hash === erp.id) ??
                ({} as ReportMetadata);
            const approvalParams = {
                entityReportId,
                entityReportPublished: erp,
                entityReportPublishedReportMetadata: reportMetadata,
                reportId: report?.id ?? '',
                reviews: entityReportReviews.filter(
                    (review) =>
                        review.report_metadata_id === reportMetadata?.id &&
                        currentReviewerIds.includes(review.user_id)
                ),
            };
            approvalsList.push(approvalParams);
            return approvalsList;
        }, []);

        const entityReportWidgets =
            report.entityReports?.find((er) => er.id === entityReportId)
                ?.entityReportWidgets ?? [];

        const widgetsWithUpdatedStatus = entityReportWidgets.map((widget) => {
            const newStatus = reportWidgetList.find(
                (rw) => rw.entityReportWidgetId === widget.id
            )?.status;

            if (!newStatus) {
                return widget;
            }

            return {
                ...widget,
                status: newStatus,
            };
        });

        const approvals = getApprovalsWithStatus(
            approvalsParams,
            [report],
            widgetsWithUpdatedStatus
        );

        const approvalsDict = approvals.reduce((dict, approval) => {
            if (!!approval?.entityReportPublished) {
                dict[approval.entityReportPublished.id] = approval;
            }
            return dict;
        }, {} as Dictionary<EntityReportApproval>);

        setEntityReportApprovals(approvalsDict);
        const currentApprovals = lastPublishedEntityReport
            ? approvalsDict[lastPublishedEntityReport.id]
            : null;
        if (currentApprovals) {
            const currentApprovalStatus =
                currentApprovals?.status ?? ReportWorkflowReviewStatus.Open;
            setEntityReportApprovalStatus(currentApprovalStatus);
            const currentReviews = currentApprovals?.reviews ?? [];
            setCurrentEntityReportPublishedReviews(currentReviews);
        }
    }, [publishedEntityReports, report, entityReportId, reportWidgetList]);

    return {
        publishedEntityReports,
        mutatePublishedEntityReports,
        selectedEntityReportPublished,
        setSelectedEntityReportPublished,
        selectedEntityReportPublishedReportMetadata,
        setSelectedEntityReportPublishedReportMetadata,
        currentEntityReportPublished,
        currentEntityReportPublishedReportMetadata,
        isSubmitting,
        setIsSubmitting,
        entityReportApprovals,
        reviewerViewDrawerIsOpen,
        setReviewerViewDrawerIsOpen,
        currentPublishedPDF,
        currentDocumentFetchError,
        entityReportApprovalStatus,
        currentPublishedEntityReportPDFError,
        report,
        isValidating,
        reportWidgetList,
        setReportWidgetList,
        currentEntityReportPublishedReviews,
        setPublishedPDFUpload,
    };
};
