import {
    Card,
    Divider,
    Popconfirm,
    Space,
    Table,
    Tooltip,
    message,
} from 'antd';
import { css } from 'emotion';
import React from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
    useGetFeaturePermissions,
    useGetPermissionGroups,
} from 'waypoint-hooks';
import {
    createPermissionGroup,
    deletePermissionGroup,
    updatePermissionGroup,
} from 'waypoint-requests';
import { RootStateOrAny, connect } from 'react-redux';
import { selectClientId, selectCurrentUser } from 'state/user/selectors';
import theme from 'config/theme';
import { useForm } from 'antd/es/form/Form';

const { TextArea } = Input;

const cardContainer = css`
    height: calc(100% - 100px);
    padding-bottom: 56px;
    display: flex;
    .ant-card-body {
        flex: 1;
        display: flex;
        padding-bottom: 0;
        #newPermission {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }

    #newPermission {
`;

const footer = css`
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 12px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const fullWidthFormControl = css`
    .ant-form-item-control {
        max-width: 100%;
    }
`;

type FieldType = {
    name: string;
    description: string;
    permission_ids: string[];
};

interface RouteParams {
    id: string;
}

interface PermissionsGroupFormProps {
    clientId: number;
    userId: number;
}

const REMAINING_HEIGHT = 596;

const PermissionsGroupForm = ({
    clientId,
    userId,
}: PermissionsGroupFormProps) => {
    const history = useHistory();
    const permissionGroup = useParams<RouteParams>();

    const { data: featurePermissions, isLoading: isLoadingFeaturePermissions } =
        useGetFeaturePermissions(clientId, userId);
    const {
        data: permissionGroups,
        isLoading: isLoadingPermissionGroups,
        mutate: mutatePermissionGroups,
    } = useGetPermissionGroups(clientId, userId);

    const haveInitialValues = Object.keys(permissionGroup)?.includes('id');

    const initialValues = haveInitialValues
        ? permissionGroups?.find((group) => group.id === permissionGroup?.id)
        : undefined;

    const createPermissionGroupHandler = async (values: FieldType) => {
        await createPermissionGroup({ ...values, clientId });
        message.success('Permission group created successfully');
    };

    const onDeletePermissionGroup = async () => {
        try {
            await deletePermissionGroup({
                permissionGroupId: permissionGroup?.id,
                clientId,
            });
            message.success(`Row deleted successfully`);

            const newData = permissionGroups?.filter(
                (permissionGroup) => permissionGroup.id !== initialValues?.id
            );

            mutatePermissionGroups(newData, false);
            history.goBack();
        } catch (e) {
            message.error(`Failed to delete Leasing Guidelines`);
        }
    };

    const updatePermissionGroupHandler = async (values: FieldType) => {
        await updatePermissionGroup({
            body: {
                name: values.name,
                description: values.description,
                permission_ids: values.permission_ids,
            },
            permissionGroupId: permissionGroup?.id,
            clientId,
        });
        message.success('Permission group updated successfully');
    };

    const onFinishCreatePermissionGroup = async (values: FieldType) => {
        if (!values.permission_ids.length) {
            message.error('Please select at least one permission group.');
            return;
        }

        try {
            haveInitialValues
                ? updatePermissionGroupHandler(values)
                : createPermissionGroupHandler(values);
            history.goBack();
        } catch {
            message.error('Failed to create permission group');
        }
    };

    const renderDeleteButton = () => {
        if (!initialValues) {
            return null;
        }

        if ((initialValues?.users?.length ?? 0) > 0) {
            return (
                <Tooltip title="Permission Groups with active users cannot be deleted">
                    <i
                        style={{
                            color: theme.colors.grays.disabled,
                            fontSize: '18px',
                            marginLeft: 14,
                        }}
                        className="fa-solid fa-trash"
                    />
                </Tooltip>
            );
        }

        return (
            <Popconfirm
                title="Delete this permission group"
                description="Are you sure you want to delete this permission group?"
                onConfirm={() => onDeletePermissionGroup()}
                okText="Yes"
                cancelText="No"
            >
                <i
                    style={{
                        color: theme.colors.red,
                        cursor: 'pointer',
                        fontSize: '18px',
                        marginLeft: 14,
                    }}
                    className="fa-solid fa-trash"
                />
            </Popconfirm>
        );
    };

    const columns = [
        {
            dataIndex: 'id',
            render: (text: string, record: { id: string; label: string }) => (
                <Checkbox value={record.id} />
            ),
            width: '50px',
        },
        {
            title: 'Feature',
            dataIndex: 'label',
        },
        {
            title: 'Category',
            dataIndex: 'category',
        },
        {
            title: 'Feature Description',
            dataIndex: 'description',
        },
    ];

    const remainingHeight = window.innerHeight - REMAINING_HEIGHT;

    return (
        <Card className={cardContainer}>
            <Form<FieldType>
                name="newPermission"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
                layout="vertical"
                onFinish={onFinishCreatePermissionGroup}
                disabled={
                    isLoadingPermissionGroups || isLoadingFeaturePermissions
                }
                initialValues={
                    haveInitialValues
                        ? {
                              name: initialValues?.name,
                              description: initialValues?.description,
                              permission_ids:
                                  initialValues?.feature_permissions.map(
                                      (featurePermission) =>
                                          featurePermission.id
                                  ),
                          }
                        : undefined
                }
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Form.Item<FieldType>
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Permission group name is required',
                                max: 50,
                            },
                        ]}
                        className={fullWidthFormControl}
                        style={{ width: '66.6%' }}
                    >
                        <Input
                            placeholder="Add permission group name"
                            maxLength={50}
                            style={{ maxWidth: '100%' }}
                        />
                    </Form.Item>
                    {renderDeleteButton()}
                </div>

                <Form.Item<FieldType>
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <TextArea placeholder="Add description" rows={1} />
                </Form.Item>

                <Divider />

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please select at least one permission',
                        },
                    ]}
                    name="permission_ids"
                    style={{
                        flex: 1,
                        display: 'flex',
                    }}
                    className={fullWidthFormControl}
                >
                    <Checkbox.Group>
                        <Card title={'Select Permissions'}>
                            <Table
                                style={{ width: '100%' }}
                                dataSource={featurePermissions ?? []}
                                columns={columns}
                                pagination={false}
                                scroll={{
                                    y: remainingHeight,
                                }}
                            />
                        </Card>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item className={footer} wrapperCol={{ span: 8 }}>
                    <Space>
                        <Button
                            type="default"
                            htmlType="button"
                            onClick={() => history.goBack()}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    );
};

const mapToState = (state: RootStateOrAny) => {
    const clientId = selectClientId(state);
    const userId = selectCurrentUser(state);
    return {
        clientId,
        userId,
    };
};

export default connect(mapToState)(PermissionsGroupForm);
