import { Table } from 'antd';
import {
    booleanRenderer,
    currencyRenderer,
    integerRenderer,
} from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    SavedConfigColumn,
    applySavedConfigSortingToGridData,
    applySavedFiltersToGridData,
    convertColumnsToAntd,
    getColumnTotalCells,
    getTableOrGroupSummaryData,
    groupHeaderRowClass,
    headerClass,
    rowClass,
    sortDataByColumnKey,
} from './GridExportConversionUtils';
import { Contract, SavedConfiguration } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { ClientSelectableOptions } from 'components/contracts/interfaces';

interface ServiceContractsExportGridProps {
    contractsData: Contract[];
    savedConfig: SavedConfiguration | null;
    setIsReadyForPDFExport: (value: boolean) => void;
    propertyOptions: ClientSelectableOptions[];
}

export interface ContractWithGroupHeaderFlag extends Contract {
    isGroupHeader?: boolean;
    children?: ContractWithGroupHeaderFlag[];
}

const dateFormatter = (dateString: string | null) => {
    if (!dateString) {
        return '';
    }
    return formatInTimeZone(new Date(dateString), 'UTC', 'MM/dd/yyyy');
};

export const serviceContractsTableBaseColumns = (
    propertyOptions: ClientSelectableOptions[]
) => {
    return [
        {
            title: 'Type of Service',
            dataIndex: 'service_type',
            key: 'service_type',
            align: 'left',
            dataType: 'string',
            minWidth: 200,
            summaryType: 'count',
            showInGroupedRow: true,
        },
        {
            title: 'Property',
            dataIndex: 'entity_code',
            key: 'vendor',
            align: 'left',
            dataType: 'string',
            render: (
                value: number | string,
                rowData: ContractWithGroupHeaderFlag
            ) => {
                return rowData?.isGroupHeader && typeof value === 'number'
                    ? ''
                    : propertyOptions?.find(
                          (po: ClientSelectableOptions) => po.value === value
                      )?.name ?? '';
            },
        },
        {
            title: 'RSF',
            dataIndex: 'total_rentable_sq_ft_per_property',
            key: 'total_rentable_sq_ft_per_property',
            align: 'center',
            dataType: 'number',
            summaryType: 'custom',
            showInGroupedRow: true,
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            align: 'left',
            dataType: 'string',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            dataType: 'string',
            render: (value: string) => capitalize(value),
        },
        {
            title: 'Minority Owned',
            dataIndex: 'minority_owned',
            key: 'minority_owned',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: "Mgr's Std Contract",
            dataIndex: 'managers_standard_contract',
            key: 'managers_standard_contract',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: "Nat'l / Port. Disc.",
            dataIndex: 'national_or_portfolio_discount',
            key: 'national_or_portfolio_discount',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: 'Last Bid Date',
            dataIndex: 'last_bid_date',
            key: 'last_bid_date',
            align: 'center',
            dataType: 'date',
            render: dateFormatter,
        },
        {
            title: 'Current Comm. Date',
            dataIndex: 'current_commencement_date',
            key: 'current_commencement_date',
            align: 'center',
            dataType: 'date',
            render: dateFormatter,
        },
        {
            title: 'Cleared OFAC',
            dataIndex: 'cleared_ofac',
            key: 'cleared_ofac',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: 'Annual Cost',
            dataIndex: 'annual_cost',
            key: 'annual_cost',
            align: 'center',
            dataType: 'number',
            summaryType: 'sum',
            showInGroupedRow: true,
            render: currencyRenderer,
        },
        {
            title: 'Cost/RSF',
            dataIndex: 'annual_cost_rsf',
            key: 'annual_cost_rsf',
            align: 'center',
            dataType: 'number',
            summaryType: 'custom',
            showInGroupedRow: true,
            render: currencyRenderer,
        },
        {
            title: 'Auto Renew',
            dataIndex: 'contract_auto_renew',
            key: 'contract_auto_renew',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: 'Cancel Prov.',
            dataIndex: 'cancel_provision',
            key: 'cancel_provision',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: 'GL Ins. Exp. Date',
            dataIndex: 'general_liability_insurance_expiration_date',
            key: 'general_liability_insurance_expiration_date',
            align: 'center',
            dataType: 'date',
            render: dateFormatter,
        },
        {
            title: 'Ins. Auto Renew',
            dataIndex: 'insurance_auto_renew',
            key: 'insurance_auto_renew',
            align: 'center',
            dataType: 'boolean',
            render: booleanRenderer,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            align: 'left',
            dataType: 'string',
        },
    ];
};

const defaultVisibleColumns = [
    'service_type',
    'total_rentable_sq_ft_per_property',
    'vendor',
    'status',
    'minority_owned',
    'managers_standard_contract',
    'national_or_portfolio_discount',
    'last_bid_date',
    'current_commencement_date',
    'cleared_ofac',
    'annual_cost',
    'annual_cost_rsf',
    'contract_auto_renew',
    'cancel_provision',
    'general_liability_insurance_expiration_date',
    'insurance_auto_renew',
    'notes',
];

const serviceContractsSummaryFormatters = {
    service_type: {
        summaryType: 'count',
        render: (value: number) => {
            return `${value} Contracts`;
        },
    },
    total_rentable_sq_ft_per_property: {
        summaryType: 'custom',
        render: integerRenderer,
    },
    annual_cost: {
        summaryType: 'sum',
        render: currencyRenderer,
    },
    annual_cost_rsf: {
        summaryType: 'custom',
        render: currencyRenderer,
    },
};

const weightedAnnualCostRSFCalculation = (
    data: Contract[],
    metricKey: keyof Contract
) => {
    const scaleKey = 'total_rentable_sq_ft_per_property';
    const uniqueKeys: (keyof Contract)[] = [
        'entity_code',
        'total_rentable_sq_ft_per_property',
    ];

    const totalValue = data.reduce(
        (acc, row) => {
            const scaleKeySum = isNaN(Number(row[scaleKey]))
                ? 0
                : Number(row[scaleKey]);

            const uniqueKey = uniqueKeys.map((key) => row[key]).join('_');

            if (!acc.uniqueKeys.has(uniqueKey)) {
                acc.sum += scaleKeySum;
            }

            acc.uniqueKeys.add(uniqueKey);
            if (row[metricKey] === null || row[scaleKey] === null) {
                return acc;
            }

            const scaleValue = Number(row[scaleKey]) ?? 1;
            const metricValue = Number(row[metricKey] ?? 0);
            acc.weighted += scaleValue * metricValue;
            return acc;
        },
        {
            sum: 0,
            weighted: 0,
            uniqueKeys: new Set<string>(),
        }
    );

    return totalValue.weighted / Math.max(totalValue.sum, 1);
};

const calculateCustomSummary = (field: keyof Contract, data: Contract[]) => {
    if (field === 'annual_cost_rsf') {
        return weightedAnnualCostRSFCalculation(data, field);
    } else if (field === 'total_rentable_sq_ft_per_property') {
        const reducerBase = {
            totalValue: 0,
            uniqueRSF: new Set<string>(),
        };

        const totalRSF = data.reduce((dict, row) => {
            const uniqueRSFKey = `${row.entity_code}_${row.total_rentable_sq_ft_per_property}`;
            if (!dict.uniqueRSF.has(uniqueRSFKey)) {
                dict.totalValue += row.total_rentable_sq_ft_per_property ?? 0;
            }
            dict.uniqueRSF.add(uniqueRSFKey);
            return dict;
        }, reducerBase);

        return totalRSF.totalValue;
    }
    return 0;
};

const serviceContractsGroupTreeBuilder = (
    contractsData: Contract[],
    gridColumns: ColumnDescriptor[],
    groupingKeys: (keyof Contract)[],
    blankFields: string[] = [],
    savedConfigColumns?: Dictionary<SavedConfigColumn>
): ContractWithGroupHeaderFlag[] => {
    if (!groupingKeys.length) {
        return contractsData;
    }

    const groupingKey = groupingKeys[0];
    const groupedContracts = contractsData.reduce(
        (dict, contract: Contract) => {
            let contractField = contract[groupingKey] as string;
            if (contractField === null || contractField === undefined) {
                contractField = '';
            }
            if (!Object.keys(dict).includes(contractField)) {
                dict[contractField] = [contract];
                return dict;
            }
            dict[contractField].push(contract);
            return dict;
        },
        {} as Dictionary<Contract[]>
    );

    const groupedContractsData = [];
    for (const entry of Object.entries(groupedContracts)) {
        const groupRow = {
            [groupingKey]: entry[0],
            isGroupHeader: true,
        };

        const groupTotals = getTableOrGroupSummaryData(
            entry[1],
            gridColumns,
            serviceContractsSummaryFormatters,
            calculateCustomSummary
        );

        const innerRowBlankFields = blankFields.reduce((dict, bf) => {
            dict[bf] = '';
            return dict;
        }, {} as Dictionary<string>);

        groupedContractsData.push({
            ...groupTotals,
            ...innerRowBlankFields,
            ...groupRow,
            children:
                groupingKeys.length > 1
                    ? serviceContractsGroupTreeBuilder(
                          entry[1],
                          gridColumns,
                          groupingKeys.slice(1),
                          groupingKeys,
                          savedConfigColumns
                      )
                    : entry[1].map((contract: Contract) => {
                          return {
                              ...contract,
                              ...innerRowBlankFields,
                          };
                      }),
        });
    }

    const dataType =
        gridColumns.find((gc) => gc.dataIndex === groupingKey)?.dataType ??
        'string';

    const sortOrder = savedConfigColumns
        ? savedConfigColumns[groupingKey]?.sortOrder ?? 'asc'
        : 'asc';

    return sortDataByColumnKey(
        dataType,
        groupingKey,
        sortOrder,
        groupedContractsData as ContractWithGroupHeaderFlag[]
    ) as ContractWithGroupHeaderFlag[];
};

const contractsDataWithConvertedAnnualCosts = (contractsData: Contract[]) => {
    return contractsData.map((contract) => {
        if (contract.annual_cost !== null) {
            contract.annual_cost = Number(contract.annual_cost);
        }
        return contract;
    });
};

export const ServiceContractsExportableGrid = ({
    contractsData,
    savedConfig,
    setIsReadyForPDFExport,
    propertyOptions,
}: ServiceContractsExportGridProps): JSX.Element => {
    const [filteredContractsData, setFilteredContractsData] = useState<
        Contract[] | null
    >(null);

    // get the base column structure for antd and apply any relevant saved config settings
    const { gridColumns, groupedColumnNames, columnsForFilteringAndSorting } =
        convertColumnsToAntd(
            serviceContractsTableBaseColumns(
                propertyOptions
            ) as ColumnDescriptor[],
            false,
            savedConfig,
            defaultVisibleColumns
        );

    // apply saved config filters to data
    useMemo(async () => {
        const contractsDataWithAnnualCosts =
            contractsDataWithConvertedAnnualCosts(contractsData);
        if (!savedConfig?.filters_json?.grid_config?.filterValue) {
            setFilteredContractsData(contractsDataWithAnnualCosts);
            return;
        }
        const filteredData = await applySavedFiltersToGridData(
            contractsDataWithAnnualCosts,
            savedConfig,
            columnsForFilteringAndSorting
        );
        setFilteredContractsData(filteredData);
    }, [contractsData]);

    if (!filteredContractsData) {
        return <></>;
    }

    // apply saved config sorting to data
    const sortedContractsData = applySavedConfigSortingToGridData(
        filteredContractsData,
        columnsForFilteringAndSorting,
        savedConfig
    );
    // apply saved config grouping to data
    // P.S. yes, this looks weird passing groupedColumnNames twice,
    // but it's needed bc of the recursion in this function

    const processedGridData = serviceContractsGroupTreeBuilder(
        sortedContractsData as Contract[],
        columnsForFilteringAndSorting,
        groupedColumnNames as (keyof Contract)[],
        groupedColumnNames,
        savedConfig?.filters_json?.grid_config?.columns
    );

    setIsReadyForPDFExport(true);

    return (
        <Table
            dataSource={processedGridData as ContractWithGroupHeaderFlag[]}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={(row) => {
                return row?.isGroupHeader ? groupHeaderRowClass : rowClass;
            }}
            expandable={{
                defaultExpandAllRows:
                    savedConfig?.filters_json?.local_config?.expanded ?? false,
                expandIcon: () => {
                    return null;
                },
            }}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    sortedContractsData,
                    serviceContractsSummaryFormatters,
                    calculateCustomSummary
                )
            }
        />
    );
};
