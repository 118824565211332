import { Card, Col, Descriptions, Divider, Modal, Row, Tooltip } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

import { EditOutlined } from '@ant-design/icons';
import { DebtAndValueKpis, PropertyType } from 'waypoint-types';
import UploadPropertyPicture from 'components/propertyProfile/UploadPropertyPicture';
import PropertyDetailMap from '../PropertyProfileMap';
import { usePropertyInformation, useSelectedDataLevel } from 'waypoint-hooks';
import PropertyDetailsEditor from '../PropertyDetailsEditor';
import moment from 'moment';
import { DatePicker } from 'waypoint-react';
import { hasDebtAndValuePropertyInfo } from 'state/user/selectors';
import { css, cx } from 'emotion';
import theme from 'config/theme';
import { AppFeaturePermissions, usePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

import PropertyCoreInformation from 'components/propertyProfile/property-sections/PropertyCoreInformation';
import PropertyPurchaseInformation from 'components/propertyProfile/property-sections/PropertyPurchaseInformation';
import PropertySiteInformation from 'components/propertyProfile/property-sections/PropertySiteInformation';
import PropertyAmenitiesAndCertifications from 'components/propertyProfile/property-sections/PropertyAmenitiesAndCertifications';
import PropertyValue from 'components/propertyProfile/property-sections/PropertyValue';
import PropertyTeam from 'components/propertyProfile/property-sections/PropertyTeam';
import PropertyDebt from '../property-sections/PropertyDebt';
import { useState } from 'react';

const entityImage = css`
    border-radius: 7px;
    object-fit: cover;
    position: relative;
    height: 100%;
`;

const clickableTile = css`
    overflow: hidden;
    cursor: pointer;
    &:hover {
        box-shadow:
            rgba(0, 0, 0, 0.19) 0 10px 20px,
            rgba(0, 0, 0, 0.23) 0 6px 6px;
    }
`;

const imageModal = css`
    .ant-modal-body {
        padding: 15px;
        object-fit: scale-down;
        height: 90vh;
    }
`;

const datePickerStyle = css`
    padding: 0;
    cursor: pointer;
    .ant-picker-suffix {
        color: ${theme.colors.blues.antBlue};
    }
    .ant-picker-input {
        input {
            width: 115px;
            color: ${theme.colors.blues.antBlue};
            cursor: pointer;
        }
    }
`;
const mapAndModalBox = css`
    position: relative;
    @media screen and (max-width: 1198px) {
        margin-bottom: 20px;
        height: 180px;
    }
    .editButton {
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        .editButton {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const editButtonClass = css`
    position: absolute;
    top: 5px;
    right: 25px;
    width: 15px;
    height: 15px;
    z-index: 5;
    cursor: pointer;
    input[type='file'] {
        opacity: 0;
        cursor: pointer;
        width: 15px;
        height: 15px;
        font-size: 0;
        position: absolute;
    }
`;

const labelStyle = {
    width: 160,
};

export const PropertyProfileInformation = ({
    entity_code: entityCode,
    propertyInfo,
    hasDebtAndValueEnabled,
    onChangePeriodSelected,
    periodSelected,
    valueAndDebtData,
}: {
    entity_code: string;
    propertyInfo: PropertyType;
    hasDebtAndValueEnabled: boolean;
    onChangePeriodSelected: (date: Date) => void;
    periodSelected: Date | null;
    valueAndDebtData: DebtAndValueKpis;
}) => {
    const [selectedValuations, setSelectedValuations] = useState<
        number | undefined
    >(undefined);

    const selectedDataLevel = useSelectedDataLevel();
    const { featurePermissions } = usePermissions();
    const {
        isImageError,
        isImageFocused,
        setImageFocused,
        entityPhoto,
        photoIsUploading,
        localImage,
        handleChange,
        selectedAmenityValues,
        selectedCertificationValues,
        isLoadingValueAndDebt,
        asOfDate,
        levered,
        clientKpiPreferences,
        leasePropertyData,
        gavSource,
        loanType,
        lender,
        debtNotes,
        debtMaturityDate,
        floatingIndex,
        interestRateSpread,
        fixedInterestRate,
        clientRateType,
        clientTimingPreferences,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PropertyInformation}
        >
            <Card
                id="property-info"
                data-test="property-detail-summary-card"
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <h2
                            style={{
                                margin: '0 12px 0 0',
                                padding: 0,
                            }}
                        >
                            Property Information
                        </h2>
                        <div>
                            <PropertyDetailsEditor
                                selectedDataLevel={selectedDataLevel}
                                entityCode={entityCode}
                                propertyInfo={propertyInfo}
                                hasDebtAndValuePropertyInfo={
                                    !!hasDebtAndValuePropertyInfo
                                }
                            />
                        </div>
                    </div>
                }
            >
                <Row align="stretch" gutter={16} wrap>
                    <Col>
                        <Row gutter={16}>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                <PropertyCoreInformation
                                    propertyInfo={propertyInfo}
                                    entityCode={entityCode}
                                    selectedDataLevel={selectedDataLevel}
                                />
                            </Col>
                            <Col
                                span={6}
                                xxl={6}
                                xl={{ span: 6, order: 2 }}
                                md={12}
                                sm={24}
                                xs={{ span: 24, order: 1 }}
                                className={mapAndModalBox}
                            >
                                {isImageError ? (
                                    <UploadPropertyPicture
                                        handleChange={handleChange}
                                        photoIsUploading={photoIsUploading}
                                    />
                                ) : (
                                    <>
                                        <div
                                            className={cx([
                                                'editButton',
                                                editButtonClass,
                                            ])}
                                        >
                                            <Tooltip
                                                title={
                                                    'Click to Change Property Photo'
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <input
                                                    type="file"
                                                    disabled={photoIsUploading}
                                                    id="avatar"
                                                    name="avatar"
                                                    accept="image/jpg, image/jpeg"
                                                    onChange={handleChange}
                                                />

                                                <EditOutlined
                                                    style={{
                                                        fontSize: 22,
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                        {photoIsUploading ? (
                                            <UploadPropertyPicture
                                                handleChange={handleChange}
                                                photoIsUploading={
                                                    photoIsUploading
                                                }
                                            />
                                        ) : (
                                            <img
                                                className={cx([
                                                    entityImage,
                                                    clickableTile,
                                                ])}
                                                onClick={() => {
                                                    setImageFocused(true);
                                                }}
                                                data-test="property-detail-photo"
                                                src={`${entityPhoto}?${new Date().getTime()}`}
                                                alt={`Property ${propertyInfo.name}`}
                                                style={{
                                                    margin: '0 auto',
                                                    width: '100%',
                                                    maxHeight: '180px',
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col
                                span={6}
                                xxl={6}
                                xl={{ span: 6, order: 3 }}
                                md={12}
                                sm={24}
                                xs={{ span: 24, order: 2 }}
                                className={mapAndModalBox}
                            >
                                <PropertyDetailMap
                                    entityCode={entityCode}
                                    height="180px"
                                />
                            </Col>
                        </Row>
                        <Divider
                            style={{
                                marginBottom: '16px',
                                marginTop: '12px',
                            }}
                        />
                        <Row align="stretch" wrap={true} gutter={16}>
                            <Col>
                                <Descriptions
                                    size="small"
                                    bordered={false}
                                    title={
                                        <div
                                            style={{
                                                backgroundColor: 'lightgray',
                                                padding: '4px 8px',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            Site Information
                                        </div>
                                    }
                                    column={{
                                        xxl: 4,
                                        xl: 4,
                                        lg: 2,
                                        md: 1,
                                        sm: 1,
                                        xs: 1,
                                    }}
                                    contentStyle={{
                                        textAlign: 'left',
                                        marginRight: 15,
                                    }}
                                    labelStyle={labelStyle}
                                />
                            </Col>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                <PropertySiteInformation
                                    propertyInfo={propertyInfo}
                                    entityCode={entityCode}
                                    selectedDataLevel={selectedDataLevel}
                                    hideTitle={true}
                                />
                            </Col>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                <PropertyAmenitiesAndCertifications
                                    selectedAmenityValues={
                                        selectedAmenityValues
                                    }
                                    selectedCertificationValues={
                                        selectedCertificationValues
                                    }
                                    labelStyle={labelStyle}
                                    showCertifications={true}
                                    showAmenities={true}
                                />
                            </Col>
                        </Row>

                        <Divider
                            style={{
                                marginBottom: '16px',
                                marginTop: '12px',
                            }}
                        />

                        <Row align="stretch" wrap={true} gutter={16}>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                <PropertyPurchaseInformation
                                    propertyInfo={propertyInfo}
                                    entityCode={entityCode}
                                    selectedDataLevel={selectedDataLevel}
                                />
                            </Col>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                <PropertyTeam
                                    propertyInfo={propertyInfo}
                                    entityCode={entityCode}
                                    selectedDataLevel={selectedDataLevel}
                                />
                            </Col>
                        </Row>

                        <Divider
                            style={{ marginBottom: '16px', marginTop: '12px' }}
                        />

                        <Row align="stretch" gutter={16} wrap={true}>
                            <Col
                                span={12}
                                xxl={12}
                                xl={{ span: 12, order: 1 }}
                                md={24}
                                sm={24}
                                xs={{ span: 24, order: 3 }}
                            >
                                {hasDebtAndValueEnabled &&
                                featurePermissions?.includes(
                                    AppFeaturePermissions.PropertyValueDebt
                                ) ? (
                                    <>
                                        <PropertyValue
                                            entityCode={entityCode}
                                            propertyInfo={propertyInfo}
                                            valueAndDebtData={valueAndDebtData}
                                            clientKpiPreferences={
                                                clientKpiPreferences
                                            }
                                            leasePropertyData={
                                                leasePropertyData
                                            }
                                            gavSource={gavSource}
                                            selectedDataLevel={
                                                selectedDataLevel
                                            }
                                            periodSelected={periodSelected}
                                            asOfDate={asOfDate}
                                            setSelectedValuations={
                                                setSelectedValuations
                                            }
                                            title={
                                                <>
                                                    <span>
                                                        Value
                                                        <span
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                As of:
                                                            </span>{' '}
                                                            <DatePicker
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    if (e) {
                                                                        onChangePeriodSelected(
                                                                            e.toDate()
                                                                        );
                                                                    }
                                                                }}
                                                                bordered={false}
                                                                allowClear={
                                                                    false
                                                                }
                                                                disabled={
                                                                    isLoadingValueAndDebt
                                                                }
                                                                picker="month"
                                                                placeholder="Select Period"
                                                                value={
                                                                    !periodSelected
                                                                        ? moment(
                                                                              asOfDate
                                                                          )
                                                                        : moment(
                                                                              periodSelected
                                                                          )
                                                                }
                                                                format={
                                                                    'MMMM YYYY'
                                                                }
                                                                suffixIcon={
                                                                    <CaretDownFilled
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                }
                                                                className={
                                                                    datePickerStyle
                                                                }
                                                            />
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                        />
                                    </>
                                ) : null}
                            </Col>

                            {levered && (
                                <Col
                                    span={12}
                                    xxl={12}
                                    xl={{ span: 12, order: 1 }}
                                    md={24}
                                    sm={24}
                                    xs={{ span: 24, order: 3 }}
                                >
                                    {hasDebtAndValueEnabled &&
                                    featurePermissions?.includes(
                                        AppFeaturePermissions.PropertyValueDebt
                                    ) ? (
                                        <>
                                            <PropertyDebt
                                                loanType={loanType}
                                                lender={lender}
                                                debtNotes={debtNotes}
                                                debtMaturityDate={
                                                    debtMaturityDate
                                                }
                                                floatingIndex={floatingIndex}
                                                interestRateSpread={
                                                    interestRateSpread
                                                }
                                                fixedInterestRate={
                                                    fixedInterestRate
                                                }
                                                valueAndDebtData={
                                                    valueAndDebtData
                                                }
                                                clientRateType={clientRateType}
                                                clientTimingPreferences={
                                                    clientTimingPreferences
                                                }
                                                selectedValuations={
                                                    selectedValuations
                                                }
                                                gavSource={gavSource}
                                                title={
                                                    <>
                                                        <span>
                                                            Debt
                                                            <span
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    As of:
                                                                </span>{' '}
                                                                <DatePicker
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (e) {
                                                                            onChangePeriodSelected(
                                                                                e.toDate()
                                                                            );
                                                                        }
                                                                    }}
                                                                    bordered={
                                                                        false
                                                                    }
                                                                    allowClear={
                                                                        false
                                                                    }
                                                                    disabled={
                                                                        isLoadingValueAndDebt
                                                                    }
                                                                    picker="month"
                                                                    placeholder="Select Period"
                                                                    value={
                                                                        !periodSelected
                                                                            ? moment(
                                                                                  asOfDate
                                                                              )
                                                                            : moment(
                                                                                  periodSelected
                                                                              )
                                                                    }
                                                                    format={
                                                                        'MMMM YYYY'
                                                                    }
                                                                    suffixIcon={
                                                                        <CaretDownFilled
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                        />
                                                                    }
                                                                    className={
                                                                        datePickerStyle
                                                                    }
                                                                />
                                                            </span>
                                                        </span>
                                                    </>
                                                }
                                            />
                                        </>
                                    ) : null}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Card>

            {!isImageError && (
                <Modal
                    title={`${propertyInfo.name}`}
                    footer={null}
                    centered
                    wrapClassName={imageModal}
                    open={isImageFocused}
                    onOk={() => setImageFocused(false)}
                    onCancel={() => setImageFocused(false)}
                    data-test="property-detail-image-modal"
                    width="auto"
                >
                    <img
                        src={
                            localImage ||
                            `${entityPhoto}?${new Date().getTime()}`
                        }
                        width="100%"
                        height="100%"
                        data-test="property-detail-fullscreen-image"
                        alt={`Property ${propertyInfo.name}`}
                    />
                </Modal>
            )}
        </PermissionedWrapper>
    );
};
