import { createStore, applyMiddleware, compose } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import { reduxSearch } from 'redux-search';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import { routerMiddleware } from 'react-router-redux';
import { queryMiddleware } from 'redux-query';
import rootReducer from 'state/rootReducer';
import reduxSearchConfig from 'state/reduxSearchConfig';
import { handle401 } from 'utils/authentication';

export default function configureStore(history, initialState = {}) {
    // TODO (Nicholas): Consider adding batchDispatchMiddleware if batching interferes with other middleware
    const middleware = [
        applyMiddleware(thunk),
        applyMiddleware(
            queryMiddleware(
                (state) => state.queries,
                (state) => state.entities
            )
        ),
        applyMiddleware(routerMiddleware(history)),
        createResponsiveStoreEnhancer({
            throttleTime: 1000,
            calculateStateInitially: true,
        }),
        reduxSearch(reduxSearchConfig),
        applyMiddleware(handle401),
    ];

    // This flag should allow us to turn off redux devtools
    if (window.devToolsExtension) {
        middleware.push(window.devToolsExtension());
    }

    // http://redux.js.org/docs/api/createStore.html
    const store = createStore(
        enableBatching(rootReducer),
        initialState,
        // Compose.apply allows us to use a dynamic middleware array
        compose.apply(compose, middleware)
    );

    if (module.hot) {
        // enable hot reload
        module.hot.accept('state/rootReducer', () =>
            store.replaceReducer(rootReducer)
        );
    }

    return store;
}
