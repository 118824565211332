import { cloneDeep, merge } from 'lodash';
import { attachments as TYPES } from 'state/actionTypes';

export const defaultState = {};

export function load(state, payload) {
    return cloneDeep(merge(state, payload));
}

export function remove(state, payload) {
    const newState = cloneDeep(state);
    delete newState[payload];
    return newState;
}

export default function reducer(attachments = defaultState, action) {
    const { type } = action;

    switch (type) {
        case TYPES.LOAD:
            return load(attachments, action.payload);

        case TYPES.DELETE:
            return remove(attachments, action.payload);

        default:
    }

    return attachments;
}
