import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { User } from 'waypoint-types';
import { css } from 'emotion';
import UploadPropertyPicture from 'components/propertyProfile/UploadPropertyPicture';
import { uploadUserProfilePicture } from 'waypoint-requests';

const profilePictureStyle = (profilePicture: string | null) => css`
    background-image: url(${profilePicture});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
    border-radius: 50%;
`;

const inputStyle = (isLoading: boolean) => css`
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
        opacity: ${isLoading ? 0 : 1};
        visibility: ${isLoading ? 'hidden' : 'visible'};
    }
    #upload-picture-picker {
        opacity: ${isLoading ? 1 : 0};
        visibility: ${isLoading ? 'visible' : 'hidden'};
    }

    &:hover {
        img {
            opacity: 0;
            visibility: hidden;
        }
        #upload-picture-picker {
            opacity: 1;
            visibility: visible;
        }
    }
`;

interface ImageSelectorProps {
    user: User;
    submitForm: (photoUrl: string) => void;
    isLoading: boolean;
    isLoadingHandle: (value: boolean) => void;
}

const ImageSelector = ({
    user,
    isLoadingHandle,
    isLoading,
    submitForm,
}: ImageSelectorProps) => {
    const [localImage, setLocalImage] = useState<string | null>(null);

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ?? [];

        if (!file.length) {
            return;
        }

        const isLighterThan20M =
            file[0].size && file[0].size / 1024 / 1024 < 25;

        if (!isLighterThan20M) {
            toastr.error('File size too large', 'Must be less than 25MB');
            return;
        }

        const photo = new FormData();
        photo.append('upload', file[0]);
        photo.append('name', file[0].name);

        isLoadingHandle(true);
        setLocalImage(null);

        try {
            const img = file[0];
            const profilePictureUrl = await uploadUserProfilePicture(
                user.id,
                photo
            );
            submitForm(profilePictureUrl);
            setLocalImage(URL.createObjectURL(img));
        } catch (error) {
            toastr.error('Error', 'Something went wrong uploading the picture');
            isLoadingHandle(false);
        } finally {
            isLoadingHandle(false);
        }
    };

    return (
        <div className={'imageSelector'}>
            {user?.profile_image_url || localImage || isLoading ? (
                <div className={inputStyle(isLoading)}>
                    <UploadPropertyPicture
                        handleChange={handleChange}
                        photoIsUploading={isLoading}
                        placeholderText="Click to Upload a Profile Picture"
                        height={200}
                    />
                    <img
                        className={profilePictureStyle(
                            user?.profile_image_url || localImage
                        )}
                        src={localImage ?? user?.profile_image_url}
                        alt={`${user?.firstname} ${user?.lastname}`}
                    />
                </div>
            ) : (
                <UploadPropertyPicture
                    handleChange={handleChange}
                    photoIsUploading={isLoading}
                    placeholderText="Click to Upload a Profile Picture"
                    height={200}
                />
            )}
        </div>
    );
};

export default ImageSelector;
