import React, { useState } from 'react';
import { css, cx, keyframes } from 'emotion';
import { Tooltip } from 'antd';
import { DASH_DASH } from 'config/constants';
import moment from 'moment';
import { CommentProps } from 'waypoint-types';
import { EmptyAvatar, CommentEditor } from 'waypoint-react';
import theme from 'config/theme';
import { sanitize } from 'dompurify';
import { Comment as AntDComment } from '@ant-design/compatible';

const headerCommentStyle = css`
    display: flex;
    align-items: baseline;
    span.edited {
        margin-left: 4px;
        display: block;
        font-size: 12px;
        color: ${theme.colors.grays.mediumDark};
    }
`;

const commentStyle = css`
    min-height: 90px;
    .ant-comment-inner {
        padding: 10px;
    }

    .ant-comment-content-author > span {
        width: 100%;
    }

    .author {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        color: ${theme.colors.grays.text};
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: wrap;
        overflow: hidden;
        margin: 6px 0 3px 0;
        display: inline-block;
    }

    .time {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 1px;
        color: ${theme.colors.grays.mediumDark};
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content {
        font-size: 14px;
    }

    .ant-comment-actions {
        opacity: 0;
        visibility: hidden;
    }

    .ant-comment-inner {
        &:hover {
            background: #2ea4dd10;
            .ant-comment-actions {
                visibility: visible;
                opacity: 1;
            }
        }
    }
`;

const fadeOut = keyframes`
    from {
        background-color: #2ea4dd10;
    }
    to {
        background-color: transparent;
    }
`;

const highlightedCommentStyle = css`
    animation: ${fadeOut} 7s ease-out 1s;
`;

const editingCommentStyle = css`
    .ant-comment-inner {
        border: 1px solid #2ea4dd;
        &:hover {
            background: inherit !important;
            border: 1px solid #2ea4dd;
        }
    }
`;

const styleReply = css`
    .ant-comment-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

const Reply = ({
    comment,
    withAvatar,
    deleteComment,
    scrollToCommentId,
    editComment,
    onSubmit,
}: CommentProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const commentIsEdited =
        new Date(comment.timestamps.updated_at).getTime() >
        new Date(comment.timestamps.created_at).getTime();

    return (
        <div className={styleReply} id={`comment-${comment.id}`}>
            <AntDComment
                actions={[
                    editComment &&
                        editComment(comment.author.id, () =>
                            setIsEditing(!isEditing)
                        ),
                    deleteComment &&
                        deleteComment(comment.id, comment.author.id, false),
                ]}
                avatar={withAvatar ? comment.avatar ?? <EmptyAvatar /> : null}
                author={
                    <div className={headerCommentStyle}>
                        <span className="author">{comment.name}</span>
                        <span className="edited">
                            {commentIsEdited && (
                                <Tooltip
                                    title={`${moment(
                                        comment.timestamps.updated_at
                                    ).fromNow()} by ${comment.name}`}
                                >
                                    Edited
                                </Tooltip>
                            )}
                        </span>
                    </div>
                }
                content={
                    isEditing ? (
                        <CommentEditor
                            onSubmit={(text, mentions, accountMentions) => {
                                onSubmit(
                                    text,
                                    mentions,
                                    accountMentions,
                                    comment.parent_comment_id ?? null,
                                    comment.id
                                );
                                setIsEditing(false);
                            }}
                            isClosable
                            onClose={() => setIsEditing(false)}
                            showToolbar={false}
                            buttonCaption="Save"
                            height={'80px'}
                            defaultValue={comment.text}
                        />
                    ) : (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitize(comment.text),
                            }}
                        ></div>
                    )
                }
                datetime={
                    <p className="time">
                        {!!comment.timestamps.created_at
                            ? moment(comment.timestamps.created_at).fromNow()
                            : DASH_DASH}
                    </p>
                }
                className={
                    comment.id === scrollToCommentId
                        ? cx([commentStyle, highlightedCommentStyle])
                        : cx([commentStyle, isEditing && editingCommentStyle])
                }
            />
        </div>
    );
};

export default Reply;
