import React, { useContext } from 'react';
import DeleteFilterButton from 'components/app/global-filter-drawer/DeleteFilterButton';
import { AttributeFilterForm } from 'components/app/global-filter-drawer/fitler-editor/AttributeFilterForm';
import FilterEditorSaveAs from 'components/app/global-filter-drawer/fitler-editor/FilterEditorSaveAs';
import { Divider, Select, Button } from 'antd';
import {
    SavedFilter,
    SavedFiltersContext,
    SavedFiltersContextType,
} from 'contexts';
import {
    SelectedAttributes,
    SelectOptionType,
} from '../types/globalFilterTypes';
import { convertSavedFilterToSelectedAttributes } from 'components/app/global-filter-drawer/globalFilterUtils';

function getOptionsFromSavedFilters(
    savedFilters: SavedFilter[] | null | undefined
): SelectOptionType[] {
    if (!savedFilters) {
        return [];
    }

    return savedFilters.map(({ id, name }) => ({
        value: id ?? '',
        label: name ?? '',
    }));
}

interface FilterEditorFormProps {
    handleClose?: () => void;
    handleClearFilter: () => void;
    controlsDisabled: boolean | undefined;
    handleDelete: () => void;
    attributeFilters: SelectedAttributes;
    setAttributeFilters: (attributeFilters: SelectedAttributes) => void;
    saveAsVisible: boolean;
    savedFilterPartial: Partial<SavedFilter>;
    setSaveAsVisible: (saveAsVisible: boolean) => void;
    handleSave: () => void;
    selectedFilterId: string | undefined;
    savedFilterContext: SavedFiltersContextType | null;
    onFiltersUpdate?: (attributeFilters: SelectedAttributes) => void;
    hasAtLeastOneValidFilter: boolean;
    setSavedFilterPartial: (savedFilterPartial: Partial<SavedFilter>) => void;
    setSelectedFilterId: (selectedFilterId: string | undefined) => void;
    canDeleteFilters?: boolean;
    styles?: React.CSSProperties;
}

const FilterEditorForm = ({
    selectedFilterId,
    handleClearFilter,
    controlsDisabled,
    handleDelete,
    attributeFilters,
    setAttributeFilters,
    saveAsVisible,
    savedFilterPartial,
    setSaveAsVisible,
    handleSave,
    onFiltersUpdate,
    setSavedFilterPartial,
    setSelectedFilterId,
    hasAtLeastOneValidFilter,
    canDeleteFilters = true,
    styles,
}: FilterEditorFormProps) => {
    const savedFilterContext = useContext(SavedFiltersContext);

    const handleFilterDropdownChanged = (id?: string) => {
        const savedFilter = id
            ? savedFilterContext?.savedFilters?.find((x) => x.id === id)
            : undefined;

        if (savedFilter) {
            const { id, name, reference_type } = savedFilter;

            setSavedFilterPartial({ id, name, reference_type });

            setSelectedFilterId(id);
            setAttributeFilters(
                convertSavedFilterToSelectedAttributes(savedFilter)
            );
        } else {
            handleClearFilter();
        }
    };

    if (onFiltersUpdate && !Object.keys(attributeFilters).length) {
        onFiltersUpdate(attributeFilters);
    }

    return (
        <div style={styles ? styles : {}}>
            <Select
                style={{ width: '50%' }}
                dropdownStyle={{ zIndex: 999999 }}
                value={selectedFilterId}
                options={getOptionsFromSavedFilters(
                    savedFilterContext?.savedFilters ?? []
                )}
                onClear={handleClearFilter}
                onSelect={handleFilterDropdownChanged}
                placeholder="Select a saved filter"
                allowClear={true}
            />

            {canDeleteFilters && selectedFilterId && (
                <DeleteFilterButton
                    disabled={controlsDisabled}
                    handleDelete={handleDelete}
                />
            )}
            <Divider />

            <AttributeFilterForm
                disabled={controlsDisabled}
                attributeFilters={attributeFilters}
                setAttributeFilters={setAttributeFilters}
            />

            {onFiltersUpdate ? (
                <div>
                    <Button
                        style={{
                            marginRight: 6,
                            marginLeft: 'auto',
                            float: 'right',
                        }}
                        onClick={() =>
                            onFiltersUpdate && onFiltersUpdate(attributeFilters)
                        }
                        disabled={controlsDisabled || !hasAtLeastOneValidFilter}
                    >
                        Update
                    </Button>
                </div>
            ) : (
                <FilterEditorSaveAs
                    visible={saveAsVisible}
                    onConfirm={(name, referenceType) => {
                        savedFilterPartial.name = name;
                        savedFilterPartial.reference_type = referenceType;
                        handleSave();
                    }}
                    onCancel={() => setSaveAsVisible(false)}
                />
            )}
        </div>
    );
};

export default FilterEditorForm;
