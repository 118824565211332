import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import store from 'state';
import { Userpilot } from 'userpilot';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Routes from 'routes/Routes';

Sentry.init({
    dsn: 'https://e758ebb0f92940089b0a1ca6f28cf360@o1060372.ingest.sentry.io/6049850',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
});

if (process.env.NODE_ENV !== 'development') {
    Userpilot.initialize('NX-da05204b');
}

const el = React.createElement(Routes, {
    store,
});

ReactDOM.render(el, document.getElementById('root'));

serviceWorker.unregister();
