import React, { useEffect } from 'react';
import { css } from 'emotion';
import { AccountGraphCalculations } from 'waypoint-types';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import { CommentsButton } from 'waypoint-react/comment/CommentsButton';
import IncomeStatementMenu from 'components/financials/comparative-income-statement/menu/IncomeStatementMenu';
import { QuickSettings } from 'components/financials/comparative-income-statement/menu/QuickSettings';

const bannerStyle = css`
    width: 100%;
    border: none;
    box-shadow: none !important;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const selectionsBox = css`
    margin-left: 10px;
    display: flex;
    width: 100%;

    @media (max-width: 886px) {
        margin-left: 0;
    }
`;

interface ComparisonSelectionsBannerProps {
    selections: any;
    resetSelections: () => void;
    entityCodes: string[];
    isBannerCollapsed: boolean;
    thresholdCalculations: AccountGraphCalculations | null;
    isOpenSettings: boolean;
    setIsOpenSettings: (open: boolean) => void;
}

const ComparisonSelectionsBanner = ({
    selections,
    resetSelections,
    entityCodes,
    thresholdCalculations,
    isOpenSettings,
    setIsOpenSettings,
}: ComparisonSelectionsBannerProps): JSX.Element => {
    const { onClearFilters, setSelections } = useIncomeStatement();

    const isSingleEntity = entityCodes.length === 1;

    useEffect(() => {
        onClearFilters();
    }, [entityCodes]);

    return (
        <div data-test="selections-banner" className={bannerStyle}>
            <div className={selectionsBox}>
                <IncomeStatementMenu
                    key={`${isOpenSettings}`}
                    isOpen={isOpenSettings}
                    entityCodes={entityCodes}
                    selections={selections}
                    resetSelections={resetSelections}
                    setSelections={setSelections}
                    onClose={() => setIsOpenSettings(false)}
                    onClearFilters={onClearFilters}
                />
                <QuickSettings
                    thresholdCalculations={thresholdCalculations}
                    isSingleEntity={isSingleEntity}
                />
                {isSingleEntity ? (
                    <CommentsButton
                        buttonStyle={{
                            marginRight: '10px',
                            marginLeft: 'auto',
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ComparisonSelectionsBanner;
