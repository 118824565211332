import React from 'react';
import theme from 'config/theme';
import {
    varianceRenderer,
    textRenderer,
    currencyRenderer,
} from 'utils/tables/renderers';
import { AccountGraphNode, CommentMention, Note } from 'waypoint-types';
import {
    AccountMappingService,
    getDateRangeForPeriod,
    safeDivision,
} from 'waypoint-utils';
import { formatMoney, formatPercentage } from 'utils/formatters';
import { TableNotes } from 'components/notes/TableNotes';
import ActionsCell from 'components/financials/actionCell/ActionsCell';
import { Tooltip } from 'antd';
import {
    AMOUNT_AND_PCT,
    AMOUNT_ONLY,
    PCT_ONLY,
    SELECTED_PERIOD_VALUE,
    THRESHOLD_FIXED_AND_PERCENTAGES,
    THRESHOLD_FIXED_ONLY,
    THRESHOLD_FIXED_OR_PERCENTAGES,
    THRESHOLD_PERCENTAGES_ONLY,
} from 'components/financials/comparative-income-statement/constants';
import { KeyedMutator } from 'swr';
import { WorkflowRolesResponse } from 'waypoint-hooks/data-access/useGetWorkflowRoles';
import { DASH_DASH } from 'config/constants';
import { PredicateNode } from 'waypoint-utils/account-graphs/AccountMappingService';
import { ComparisonSelections } from '../ComparisonIncomeStatementTypes';
import { css } from 'emotion';

interface CISDecoratedAccountGraphNode extends AccountGraphNode {
    isRootNode: boolean;
    isTotalNode: boolean;
    isTitleNode: boolean;
    isCollapsed: boolean;
}

interface SelectionColumns {
    header: JSX.Element;
    predicates: {
        period_start: string;
        period_end: string;
        mode: string;
    };
    mtd?: boolean;
}

const accountMappingService = new AccountMappingService();

const iconFontSize = '15px';
const placeholderElement = (
    <span style={{ width: '12px', display: 'inline-block' }} />
);

export const headerStyle = css`
    text-align: right;
`;

export const tableContainerStyle = (isPDFExport?: boolean) => css`
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    scrollbar-height: none;
    display: flex;
    flex-direction: column;
    .ant-table-wrapper {
        flex: 1;
        overflow-y: hidden;
    }
    .ant-dropdown-trigger ant-table-filter-trigger {
        display: ${isPDFExport ? 'none !important' : 'flex'};
    }
`;

const nestedVarianceStyle = css`
    text-align: right;
    white-space: pre-wrap;
`;

export const COMPARATIVE_INCOME_STATEMENT_TABLE_HEIGHT = 480;
export const COMPARATIVE_INCOME_STATEMENT_TABLE_MIN_WIDTH = 1280;

export const SelectedPeriodDisplayName = {
    selected_period: 'Selected Period',
    prior_period: 'Prior Period',
    prior_year: 'Prior Year',
    year_total: 'Year Total',
    next_year: 'Next Year',
    next_period: 'Next Period',
};

interface VarianceColumn {
    title: JSX.Element;
    align: string;
    width: string;
    render: (_: null, node: CISDecoratedAccountGraphNode) => JSX.Element;
}

export const getVarianceComparisonColumnIndexes = (
    varianceComparison: string,
    isTertiary = false
) => {
    if (!varianceComparison) {
        return isTertiary ? [0, 2] : [0, 1];
    }

    const parts = varianceComparison?.split('[');
    const firstColumnIndex: number =
        parts.length === 2 ? parseInt(parts[1][0]) : 0;
    const secondColumnIndex: number =
        parts.length === 2 ? parseInt(parts[1][2]) : 1;
    if (isNaN(firstColumnIndex) || isNaN(secondColumnIndex)) {
        return isTertiary ? [0, 2] : [0, 1];
    }

    return [firstColumnIndex, secondColumnIndex];
};

const statusRenderer = (
    node: CISDecoratedAccountGraphNode,
    includeStatus: boolean,
    hasComment: boolean,
    setAccountFilterSelection: (value: string) => void,
    openComments: () => void,
    isSingleProperty: boolean
) => {
    const thresholdIsExceeded =
        accountMappingService.getIsThresholdExceededFor(node);
    const calculations = accountMappingService.getCalculationsFor(node);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{node.name}</span>
            <span>
                {isSingleProperty && thresholdIsExceeded && includeStatus ? (
                    <ThresholdInformationTooltip
                        amount={calculations.threshold_amount}
                        type={calculations.threshold_type}
                        percent={calculations.threshold_percent}
                        periodicity={calculations.threshold_periodicity}
                    >
                        <i
                            className="fa-solid fa-flag"
                            style={{
                                fontSize: iconFontSize,
                                color: theme.colors.red,
                            }}
                        />
                    </ThresholdInformationTooltip>
                ) : (
                    placeholderElement
                )}
                {isSingleProperty && hasComment && (
                    <i
                        className="fa-regular fa-commenting"
                        onClick={() => {
                            setAccountFilterSelection(
                                node.account_mapping_code
                            );
                            openComments();
                        }}
                        style={{
                            fontSize: '18px',
                            marginLeft: '5px',
                            cursor: 'pointer',
                        }}
                    />
                )}
            </span>
        </div>
    );
};

export const getComparativeIncomeStatementTableColumns = (
    selectionColumns: SelectionColumns[] = [],
    includeStatus: boolean,
    includeNotes: boolean,
    includeActionColumn: boolean,
    showPerSF: boolean,
    showPerUnit: boolean,
    isCurrentPeriodsColumns: boolean,
    totalRSF: number,
    totalUnits: number,
    varianceDisplay: string,
    userId: number,
    mutateReportNotes: KeyedMutator<Note[]>,
    isNotesModalVisible: boolean,
    setIsNotesModalVisible: (value: boolean) => void,
    setNoteId: (value: string | null) => void,
    setNoteText: (text: string) => void,
    setSelectedAccountMappingId: (value: string | null) => void,
    openComments: () => void,
    setAccountFilterSelection: (value: string) => void,
    isSingleProperty: boolean,
    notes?: Note[] | null,
    expandedRows: string[] = [],
    commentThreadAccountMentions: CommentMention[] = [],
    isAccountHistoryModalVisible?: boolean,
    setIsAccountHistoryModalVisible?: (value: boolean) => void,
    hideAccountHistoryButton?: boolean,
    hideNotesOption?: boolean,
    hideCommentsOption?: boolean,
    isPDFExport?: boolean,
    workflowRoles?: WorkflowRolesResponse,
    isAdmin?: boolean,
    varianceDisplayTertiary?: string,
    excludeAccountColumns?: boolean,
    comparisonSelections?: ComparisonSelections | null,
    comparisonTypeOptions?: { value: string; label: string }[],
    calculationsField = 'calculations',
    tertiaryCalculationsField = 'tertiary_calculations'
) => {
    let tertiaryColumn: SelectionColumns | undefined;
    if (selectionColumns.length === 3) {
        tertiaryColumn = selectionColumns.pop();
    }

    const isExpanded = (
        expandedRows: string[],
        node: CISDecoratedAccountGraphNode
    ) => {
        return expandedRows.includes(node.account_mapping_code);
    };

    const hasComments = (
        comments: CommentMention[],
        node: CISDecoratedAccountGraphNode
    ) => {
        return (
            comments.filter(
                (comment: CommentMention) =>
                    comment.reference_id === node.account_mapping_code
            ).length > 0
        );
    };

    const calculatePercentageColumnWidth = (
        varianceDisplay: string | undefined
    ) => {
        if (isPDFExport) {
            return '10%';
        }
        if (varianceDisplay === PCT_ONLY) {
            return '100px';
        }
        return '75px';
    };

    const getSelectionAndPerMetricsColumns = (
        column: SelectionColumns,
        index: number
    ) => {
        const selectionAndPerMetricsColumns = [
            {
                title: column.header,
                align: 'right',
                colSpan: column.header ? 1 : 0,
                width: isPDFExport ? '15%' : '150px',
                key: `${index} ${column.predicates.period_start}`, // NEEDED FOR TWO COLUMNS THAT READ THE SAME FIELD
                render: (_: null, node: CISDecoratedAccountGraphNode) => {
                    if (node.isTitleNode && isExpanded(expandedRows, node)) {
                        return <span />;
                    }

                    const predicate: PredicateNode = {
                        period_start: column.predicates.period_start,
                        period_end: column.predicates.period_end,
                        mode: column.predicates.mode,
                    };

                    if (column.mtd) {
                        const rangePeriod = getDateRangeForPeriod(
                            'mtd',
                            new Date(column.predicates.period_end)
                        );
                        predicate.period_start = rangePeriod[0];
                    }

                    const amount = accountMappingService.getEntryAmountFor(
                        node,
                        predicate
                    );
                    return <div>{currencyRenderer(amount)}</div>;
                },
            },
        ];

        if (showPerSF) {
            selectionAndPerMetricsColumns.push({
                title: <span>$/SF</span>,
                align: 'right',
                colSpan: column.header ? 1 : 0,
                width: isPDFExport ? '10%' : '75px',
                key: `${index}_psf`, // NEEDED FOR TWO COLUMNS THAT READ THE SAME FIELD
                render: (_: null, node: CISDecoratedAccountGraphNode) => {
                    if (node.isTitleNode && isExpanded(expandedRows, node)) {
                        return <span />;
                    }

                    const predicate: PredicateNode = {
                        period_start: column.predicates.period_start,
                        period_end: column.predicates.period_end,
                        mode: column.predicates.mode,
                    };

                    if (column.mtd) {
                        const rangePeriod = getDateRangeForPeriod(
                            'mtd',
                            new Date(column.predicates.period_end)
                        );
                        predicate.period_start = rangePeriod[0]; // use the start of month for MTD columns
                    }

                    const amount = accountMappingService.getEntryAmountFor(
                        node,
                        predicate
                    );
                    return (
                        <div>
                            {amount
                                ? currencyRenderer(
                                      safeDivision(Number(amount), totalRSF)
                                  )
                                : DASH_DASH}
                        </div>
                    );
                },
            });
        }

        if (showPerUnit) {
            selectionAndPerMetricsColumns.push({
                title: <span>$/Unit</span>,
                align: 'right',
                colSpan: column.header ? 1 : 0,
                width: isPDFExport ? '12%' : '125px',
                key: `${index}_per_unit`, // NEEDED FOR TWO COLUMNS THAT READ THE SAME FIELD
                render: (_: null, node: CISDecoratedAccountGraphNode) => {
                    if (node.isTitleNode && isExpanded(expandedRows, node)) {
                        return <span />;
                    }

                    const predicate: PredicateNode = {
                        period_start: column.predicates.period_start,
                        period_end: column.predicates.period_end,
                        mode: column.predicates.mode,
                    };

                    if (column.mtd) {
                        const rangePeriod = getDateRangeForPeriod(
                            'mtd',
                            new Date(column.predicates.period_end)
                        );
                        predicate.period_start = rangePeriod[0]; // use the start of month for MTD columns
                    }

                    const amount = accountMappingService.getEntryAmountFor(
                        node,
                        predicate
                    );
                    return (
                        <div>
                            {amount
                                ? currencyRenderer(
                                      safeDivision(Number(amount), totalUnits)
                                  )
                                : DASH_DASH}
                        </div>
                    );
                },
            });
        }
        return selectionAndPerMetricsColumns;
    };

    const accountAndSelectionColumns = excludeAccountColumns
        ? [
              ...selectionColumns.flatMap((column, index) => {
                  return getSelectionAndPerMetricsColumns(column, index);
              }),
          ]
        : [
              {
                  title: 'Account',
                  dataIndex: 'name',
                  render: (_: null, node: CISDecoratedAccountGraphNode) => {
                      return statusRenderer(
                          node,
                          includeStatus,
                          hasComments(commentThreadAccountMentions, node),
                          setAccountFilterSelection,
                          openComments,
                          isSingleProperty
                      );
                  },
                  ellipsis: true,
                  width: isPDFExport ? '35%' : '300px',
                  fixed: 'left',
              },
              {
                  title: 'Account\nCode',
                  dataIndex: 'account_code',
                  render: textRenderer,
                  ellipsis: false,
                  width: isPDFExport ? '12%' : '75px',
              },
              ...selectionColumns.flatMap((column, index) => {
                  return getSelectionAndPerMetricsColumns(column, index);
              }),
          ];

    const deltaColumn = {
        title: <span>&Delta;</span>,
        align: 'right',
        width: isPDFExport ? '13%' : '125px',
        render: (_: null, node: CISDecoratedAccountGraphNode) => {
            if (node.isTitleNode && isExpanded(expandedRows, node)) {
                return <span />;
            }

            const calculation = accountMappingService.getVarianceAmountFor(
                node,
                calculationsField
            );
            const varianceIsGood = accountMappingService.getVarianceIsGoodFor(
                node,
                calculationsField
            );
            return varianceRenderer(formatMoney(calculation), {
                variance_is_good: varianceIsGood,
            });
        },
    };

    const percentageColumn = {
        title: <span>%</span>,
        align: 'right',
        width: calculatePercentageColumnWidth(varianceDisplay),
        render: (_: null, node: CISDecoratedAccountGraphNode) => {
            if (node.isTitleNode && isExpanded(expandedRows, node)) {
                return <span />;
            }
            const calculation = accountMappingService.getVariancePercentFor(
                node,
                calculationsField
            );
            const varianceIsGood = accountMappingService.getVarianceIsGoodFor(
                node,
                calculationsField
            );

            return varianceRenderer(formatPercentage(calculation * 100), {
                variance_is_good: varianceIsGood,
            });
        },
    };

    const deltaColumnTertiary = {
        title: <span>&Delta;</span>,
        align: 'right',
        width: isPDFExport ? '13%' : '125px',
        render: (_: null, node: CISDecoratedAccountGraphNode) => {
            if (node.isTitleNode && isExpanded(expandedRows, node)) {
                return <span />;
            }
            const calculation = accountMappingService.getVarianceAmountFor(
                node,
                tertiaryCalculationsField
            );
            const varianceIsGood = accountMappingService.getVarianceIsGoodFor(
                node,
                tertiaryCalculationsField
            );
            return varianceRenderer(formatMoney(calculation), {
                variance_is_good: varianceIsGood,
            });
        },
    };

    const percentageColumnTertiary = {
        title: <span>%</span>,
        align: 'right',
        width: calculatePercentageColumnWidth(varianceDisplayTertiary),
        render: (_: null, node: CISDecoratedAccountGraphNode) => {
            if (node.isTitleNode && isExpanded(expandedRows, node)) {
                return <span />;
            }
            const calculation = accountMappingService.getVariancePercentFor(
                node,
                tertiaryCalculationsField
            );
            const varianceIsGood = accountMappingService.getVarianceIsGoodFor(
                node,
                tertiaryCalculationsField
            );

            return varianceRenderer(formatPercentage(calculation * 100), {
                variance_is_good: varianceIsGood,
            });
        },
    };

    const initializeBaseColumns = () => {
        let columns: { [x: string]: any }[] = [...accountAndSelectionColumns];

        if (selectionColumns.length > 1 && varianceDisplay !== 'disabled') {
            const nestedVariance: {
                title: JSX.Element;
                children: VarianceColumn[];
            } = {
                title: (
                    <div className={nestedVarianceStyle}>
                        {comparisonSelections?.variance_comparison
                            ? getVarianceComparisonHeaderMerge(
                                  comparisonSelections?.variance_comparison
                              )
                            : ''}
                    </div>
                ),
                children: [],
            };

            if (varianceDisplay === AMOUNT_AND_PCT) {
                nestedVariance.children.push(deltaColumn, percentageColumn);
            }

            if (varianceDisplay === AMOUNT_ONLY) {
                nestedVariance.children.push(deltaColumn);
            }

            if (varianceDisplay === PCT_ONLY) {
                nestedVariance.children.push(percentageColumn);
            }

            columns = [...columns, nestedVariance];
        }

        if (
            !tertiaryColumn ||
            !varianceDisplayTertiary ||
            !selectionColumns.length
        ) {
            return columns;
        }

        let baseColumnsAndTertiaries = [
            ...columns,
            ...getSelectionAndPerMetricsColumns(tertiaryColumn, 2),
        ];

        if (varianceDisplayTertiary === 'disabled') {
            return baseColumnsAndTertiaries;
        }

        const nestedVariance: {
            title: JSX.Element;
            children: VarianceColumn[];
        } = {
            title: (
                <div className={nestedVarianceStyle}>
                    {comparisonSelections?.variance_comparison_tertiary
                        ? getVarianceComparisonHeaderMerge(
                              comparisonSelections.variance_comparison_tertiary,
                              true
                          )
                        : ''}
                </div>
            ),
            children: [],
        };

        if (varianceDisplayTertiary === AMOUNT_AND_PCT) {
            nestedVariance.children.push(
                deltaColumnTertiary,
                percentageColumnTertiary
            );
        }

        if (varianceDisplayTertiary === AMOUNT_ONLY) {
            nestedVariance.children.push(deltaColumnTertiary);
        }

        if (varianceDisplayTertiary === PCT_ONLY) {
            nestedVariance.children.push(percentageColumnTertiary);
        }

        baseColumnsAndTertiaries = [
            ...baseColumnsAndTertiaries,
            nestedVariance,
        ];

        return baseColumnsAndTertiaries;
    };

    const getVarianceComparisonHeaderMerge = (
        varianceComparison: string,
        isTertiary = false
    ): JSX.Element => {
        if (!comparisonSelections?.comparison_type) {
            return <div />;
        }

        let periodGrouping =
            comparisonSelections.periodicity === 'trailing_12'
                ? 'T12'
                : comparisonSelections.periodicity;
        periodGrouping =
            periodGrouping === 'custom'
                ? 'Custom'
                : periodGrouping.toUpperCase();

        const secondaryComparison =
            comparisonSelections.comparison_period === SELECTED_PERIOD_VALUE
                ? periodGrouping
                : comparisonSelections.comparison_period
                      .split('_')
                      .map((w) => w[0].toUpperCase());

        const tertiaryComparison =
            comparisonSelections.comparison_period_tertiary ===
            SELECTED_PERIOD_VALUE
                ? periodGrouping
                : comparisonSelections.comparison_period_tertiary
                      .split('_')
                      .map((w) => w[0].toUpperCase());

        const columnPeriods = [
            isCurrentPeriodsColumns ? 'MTD' : periodGrouping,
            isCurrentPeriodsColumns ? 'MTD' : secondaryComparison,
            tertiaryComparison,
        ];

        const getColumnShortName = (index: number) =>
            comparisonTypeOptions
                ?.find(
                    (o) =>
                        o.value === comparisonSelections?.comparison_type[index]
                )
                ?.label.substring(0, 3);

        const [firstColumnIndex, secondColumnIndex] =
            getVarianceComparisonColumnIndexes(varianceComparison, isTertiary);
        const [shortColName, shortColTertiaryName] = [
            getColumnShortName(firstColumnIndex),
            getColumnShortName(secondColumnIndex),
        ];

        return (
            <div>
                <div>
                    {shortColName}. ({columnPeriods[firstColumnIndex]}) vs
                </div>
                <div>
                    {' '}
                    {shortColTertiaryName}. ({columnPeriods[secondColumnIndex]})
                </div>
            </div>
        );
    };

    const baseColumns = initializeBaseColumns();

    const actionColumn =
        includeActionColumn && !isPDFExport
            ? [
                  {
                      title: 'Actions',
                      align: 'center',
                      width: '65px',
                      render: (_: null, node: CISDecoratedAccountGraphNode) => {
                          if (
                              node.isTitleNode &&
                              isExpanded(expandedRows, node)
                          ) {
                              return <span />;
                          }
                          return (
                              <ActionsCell
                                  isVisible={isNotesModalVisible}
                                  setIsVisible={setIsNotesModalVisible}
                                  accountMappingId={node.account_mapping_code}
                                  setSelectedAccountMappingId={
                                      setSelectedAccountMappingId
                                  }
                                  isAccountHistoryModalVisible={
                                      isAccountHistoryModalVisible
                                  }
                                  setIsAccountHistoryModalVisible={
                                      setIsAccountHistoryModalVisible
                                  }
                                  hideAccountHistoryButton={
                                      hideAccountHistoryButton
                                  }
                                  hideNotesOption={hideNotesOption}
                                  hideCommentsOption={hideCommentsOption}
                              />
                          );
                      },
                      fixed: 'right',
                  },
              ]
            : [];

    return includeNotes
        ? [
              ...baseColumns,
              {
                  title: 'Notes',
                  align: 'left',
                  width: isPDFExport ? '30%' : '300px',
                  render: (_: null, node: CISDecoratedAccountGraphNode) => {
                      if (node.isTitleNode && isExpanded(expandedRows, node)) {
                          return <span />;
                      }
                      return TableNotes(
                          node,
                          notes ?? null,
                          mutateReportNotes,
                          userId,
                          isNotesModalVisible,
                          setIsNotesModalVisible,
                          setNoteId,
                          setNoteText,
                          setSelectedAccountMappingId,
                          isPDFExport,
                          workflowRoles,
                          isAdmin
                      );
                  },
              },
              ...actionColumn,
          ]
        : [...baseColumns, ...actionColumn];
};

interface ThresholdInformationTooltipProps {
    amount: number;
    type: string;
    percent: number;
    children: React.ReactNode;
    periodicity: string;
}

export const ThresholdInformationTooltip = ({
    amount,
    type,
    percent,
    children,
    periodicity,
}: ThresholdInformationTooltipProps) => {
    const thresholdPercent = percent * 100;
    const thresholdAmount = formatMoney(amount);
    const thresholdPeriodicity = ['ytd', 'qtd', 'mtd'].includes(periodicity)
        ? periodicity?.toUpperCase()
        : periodicity;

    const thresholdTextInformation = (type: string) => {
        if (type === THRESHOLD_FIXED_AND_PERCENTAGES) {
            return (
                <div>
                    Over {thresholdPeriodicity} Threshold: <br />
                    {thresholdAmount} AND {thresholdPercent}%
                </div>
            );
        }

        if (type === THRESHOLD_FIXED_OR_PERCENTAGES) {
            return (
                <div>
                    Over {thresholdPeriodicity} Threshold: <br />
                    {thresholdAmount} OR {thresholdPercent}%
                </div>
            );
        }

        if (type === THRESHOLD_PERCENTAGES_ONLY) {
            return (
                <div>
                    Over {thresholdPeriodicity} Threshold: <br />
                    {thresholdPercent}%
                </div>
            );
        }

        if (type === THRESHOLD_FIXED_ONLY) {
            return (
                <div>
                    Over {thresholdPeriodicity} Threshold: <br />
                    {thresholdAmount}
                </div>
            );
        }

        return <span />;
    };

    return (
        <Tooltip
            data-test="threshold-information-tooltip"
            title={thresholdTextInformation(type)}
        >
            {children}
        </Tooltip>
    );
};

export const workflowStatusOptions = [
    {
        value: 'open',
        label: 'Open',
        color: theme.colors.workflowReportStatus.open,
    },
    {
        value: 'in_progress',
        label: 'In Progress',
        color: theme.colors.workflowReportStatus.in_progress,
    },
    {
        value: 'ready_for_review',
        label: 'Ready for Review',
        color: theme.colors.workflowReportStatus.ready_for_review,
    },
    {
        value: 'approved',
        label: 'Approved',
        color: theme.colors.workflowReportStatus.approved,
    },
];
