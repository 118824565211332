import React, { useEffect, useMemo, useState } from 'react';
import { AttributeFromAPI, FilterReferenceTypes } from 'contexts';
import {
    ActionsCard,
    DisabledDashboard,
    ExpandAndCollapseButton,
} from 'waypoint-react';
import { onContentReady } from 'waypoint-utils';
import DataGrid, {
    GroupItem,
    SearchPanel,
    ColumnChooser,
    Export,
    Paging,
    FilterRow,
    HeaderFilter,
    Pager,
    StateStoring,
    Grouping,
    GroupPanel,
    FilterPanel,
    Summary,
    TotalItem,
    Toolbar,
    Item,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import {
    Column,
    ExportingEvent,
    InitializedEvent,
} from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import { useGridConfigSettings } from 'waypoint-hooks';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import Skeleton from 'antd/lib/skeleton';
import { Dictionary } from 'ts-essentials';
import { SavedConfigFilterType, SavedConfigurationState } from 'waypoint-types';
import { css } from 'emotion';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';

const CARD_HEIGHT_OFFSET = 150;
const DATA_GRID_HEIGHT_OFFSET = 225;
const DASHBOARD_MARGIN = 20;

const cardTitle = css`
    padding: 35px 0px 35px 0px;
    margin-bottom: 0;
    margin-top: 0px;
    display: flex;
`;

interface AttributesCardProps {
    attributes: { columns?: AttributeFromAPI[] | any; data?: any[] };
    isLoading: boolean;
    error: string | null;
}

export const AttributesCard = ({
    attributes,
    isLoading,
    error,
}: AttributesCardProps): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] =
        useState<boolean>(false);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.Attributes);

    useEffect(() => {
        setLocalConfig({ expanded });
    }, [expanded]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            setLocalConfig(config);
            return;
        }
        setExpanded(false);
        setLocalConfig({ expanded });
    }, [selectedConfiguration, configKey]);

    const dataSource: DataSource = useMemo<DataSource>(() => {
        const getDataSource = (): DataSource => {
            const data: { [key: string]: {} }[] =
                attributes.data?.map((attributeRow) => {
                    return (
                        attributes.columns?.reduce(
                            (
                                resultColumn: { [key: string]: {} },
                                columnDef: AttributeFromAPI
                            ) => {
                                resultColumn[columnDef.title] =
                                    attributeRow[columnDef.dataIndex];

                                return resultColumn;
                            },
                            {}
                        ) ?? {}
                    );
                }) ?? [];

            return new DataSource(data);
        };

        return getDataSource();
    }, [attributes, selectedConfiguration]);

    const gridColumns: Column[] = useMemo(() => {
        const isFixed = (column: AttributeFromAPI): boolean =>
            column.key === 'name' || column.key === 'entity_display_code';

        const columnOrdersByKey: Dictionary<number> = {
            name: 0,
            entity_display_code: 1,
        };

        const renamedTitlesByKey: Dictionary<string> = {
            name: 'Property',
            entity_display_code: 'Property Code',
        };

        const configColumns =
            selectedConfiguration?.filters_json?.grid_config?.columns ?? {};

        return (
            attributes.columns?.map((column: AttributeFromAPI) => ({
                fixed: isFixed(column),
                visibleIndex:
                    columnOrdersByKey[column.key] ??
                    configColumns[column.title]?.visibleIndex, // okay to be undefined - DevExtreme auto assigns
                allowGrouping:
                    column.key !== 'name' &&
                    column.key !== 'entity_display_code',
                allowFiltering: true,
                allowReordering: !isFixed(column),
                allowHiding: !isFixed(column),
                sortOrder: column.key === 'name' ? 'asc' : undefined,
                dataField: column.title,
                caption: renamedTitlesByKey[column.key] ?? column.title, // Change column title for the name column
            })) ?? []
        );
    }, [attributes.columns, selectedConfiguration]);

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Attributes',
            filename: 'Attributes.xlsx',
        });
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const saveState = (state: SavedConfigurationState) => {
        const config = createSavedConfigurationPayload(state);
        config && setGridConfig(config);
    };

    if (error) {
        return <DisabledDashboard text={error} />;
    }

    const WINDOW_HEIGHT = window.innerHeight;
    const WINDOW_WIDTH = window.innerWidth;

    return (
        <ActionsCard
            style={{
                height: WINDOW_HEIGHT - CARD_HEIGHT_OFFSET,
                maxWidth: WINDOW_WIDTH - DASHBOARD_MARGIN * 2,
                margin: DASHBOARD_MARGIN,
            }}
            title={
                <>
                    <h2 className={cardTitle}>
                        <span
                            style={{
                                marginTop: '5px',
                                marginLeft: '5px',
                                marginRight: '10px',
                            }}
                        >
                            Attributes
                        </span>
                        <SavedGridConfigSelect
                            savedConfigurationsData={savedConfigurationsData}
                            selectedConfiguration={selectedConfiguration}
                            setSelectedConfiguration={setSelectedConfiguration}
                        />
                        <SavedConfigManageDropdown
                            onUpdateConfig={onUpdateConfig}
                            onDeleteConfig={onDeleteConfig}
                            resetSelectedConfiguration={
                                resetSelectedConfiguration
                            }
                            hasUserAccess={
                                !isAdmin &&
                                selectedConfiguration?.reference_type !==
                                    FilterReferenceTypes.USER
                            }
                            allowDeleteOnly={saveConfigDisabled}
                            defaultConfigSelected={!selectedConfiguration}
                            setIsEditorOpen={setIsEditorOpen}
                        />
                        {isEditorOpen && (
                            <SavedConfigEditor
                                handleClose={() => setIsEditorOpen(false)}
                                onSave={onSaveConfig}
                                isAdmin={isAdmin}
                                existingConfigNames={existingConfigNames}
                            />
                        )}
                    </h2>
                </>
            }
            id="popup"
            extra={<></>}
        >
            {isLoading ? (
                <Skeleton
                    paragraph={{ rows: Math.round(WINDOW_HEIGHT / 70) || 7 }}
                />
            ) : (
                <DataGrid
                    key={`${selectedConfiguration?.id ?? ''}_${
                        configKey ?? ''
                    }`}
                    dataSource={dataSource}
                    height={WINDOW_HEIGHT - DATA_GRID_HEIGHT_OFFSET}
                    keyExpr={'name'}
                    elementAttr={{
                        id: 'gridContainer',
                    }}
                    columns={gridColumns}
                    onExporting={onExporting}
                    columnAutoWidth={true}
                    columnMinWidth={150}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    showRowLines={true}
                    showBorders={true}
                    showColumnLines={true}
                    hoverStateEnabled={true}
                    onContentReady={(e) =>
                        onContentReady({ e, toggleFunc: setExpandButtonEnable })
                    }
                    onInitialized={(e: InitializedEvent) => {
                        if (selectedConfiguration && e?.component) {
                            e.component.clearSorting();
                            applyStoredConfiguration(
                                e.component,
                                selectedConfiguration
                            );
                        }
                    }}
                >
                    <Toolbar>
                        <Item location="after" name="columnChooserButton" />
                        <Item name="exportButton" />
                        <Item name="searchPanel" />
                        <Item name="groupPanel" />
                        <Item location="before">
                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </Item>
                    </Toolbar>
                    <HeaderFilter
                        visible={true}
                        allowSearch={true}
                        height={500}
                    />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    <Grouping
                        autoExpandAll={expanded}
                        contextMenuEnabled={true}
                    />
                    <GroupPanel visible={'auto'} />
                    <Summary>
                        <TotalItem
                            column="Property"
                            summaryType="count"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                            displayFormat="{0} Properties"
                        />
                        <GroupItem
                            column="Property"
                            summaryType="count"
                            alignByColumn={false}
                            showInGroupFooter={false}
                            displayFormat="{0} Properties"
                        />
                    </Summary>
                    <Paging enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[20, 40, 60, 80, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} items)"
                    />

                    <SearchPanel
                        visible={true}
                        highlightCaseSensitive={false}
                    />

                    <Export enabled={true} allowExportSelectedData={false} />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        height={500}
                        allowSearch={true}
                    />
                    <StateStoring
                        enabled={true}
                        savingTimeout="100"
                        type="custom"
                        customSave={saveState}
                    />
                </DataGrid>
            )}
        </ActionsCard>
    );
};
