import React from 'react';
import { css, cx } from 'emotion';
import theme from 'config/theme';

const downloadIconStyle = css`
    font-size: 24px;
    color: ${theme.colors.grays.dark};
    margin: 6px;
    cursor: pointer;
`;
const disabledStyle = css`
    opacity: 0.5;
    cursor: not-allowed;
`;

interface DownloadButtonProps {
    className?: string | undefined;
    disabled: boolean;
    onClick: () => Promise<void>;
}

const DownloadButton = ({
    className,
    disabled,
    onClick,
}: DownloadButtonProps) => {
    const downloadClass = `fa-solid fa-cloud-arrow-down ${className}`;
    return (
        <i
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
            style={{ fontSize: '2em' }}
            className={cx(downloadIconStyle, downloadClass, {
                [disabledStyle]: disabled,
            })}
        />
    );
};

export default DownloadButton;
