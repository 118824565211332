import { omit, mapKeys, camelCase } from 'lodash';
// Rename fields to be front end nice
export function rename(map) {
    return (v) => {
        return mapKeys(v, (_, k) => {
            return map.hasOwnProperty(k) ? map[k] : k;
        });
    };
}

// Remove ignored fields
export function strip(toIgnore) {
    return (v) => omit(v, toIgnore);
}

// Convention is: camelCase is local, snake_case is server
export function camel(obj) {
    return mapKeys(obj, (_, k) => camelCase(k));
}
