import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/formatters';
import theme from 'config/theme';

const section = {
    display: 'flex',
    width: 'auto',
    minWidth: '200px',
    justifyContent: 'space-between',
};

/**
 * This is used to override the native Fusion Charts tooltip. Only renders a title and value.
 * metricLabel
 */
const BalanceSheetOverviewTooltip = ({ title, value, metricLabel }) => {
    return (
        <div data-test="component-bso-tooltip">
            <h3
                style={{
                    textAlign: 'center',
                    marginTop: 0,
                }}
                data-test="tooltip-title"
            >
                {title}
            </h3>
            <div style={section}>
                <div style={{ color: theme.colors.grays.text }}>
                    {metricLabel || 'Gross value'}:
                </div>
                <div
                    style={{
                        fontWeight: 'bold',
                        color: theme.colors.grays.text,
                    }}
                    data-test="tooltip-value"
                >
                    {formatMoney(value)}
                </div>
            </div>
        </div>
    );
};

BalanceSheetOverviewTooltip.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
};

export default BalanceSheetOverviewTooltip;
