import { CollapseChartButton, SelectionsBanner } from 'waypoint-react';
import theme from 'config/theme';
import { DatePicker } from 'waypoint-react';
import { datePickerStyle } from 'components/financials/common/styles';
import moment from 'moment';
import { toEndOfMonth, toISO } from 'components/dates/utils';
import { css } from 'emotion';
import { Button } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';

const chartButtonStyle = css`
    color: ${theme.colors.grays.light} !important;
    border-color: ${theme.colors.grays.light} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const chartButtonStyleActive = css`
    border-color: ${theme.colors.blues.focusRow} !important;
    background: ${theme.colors.blues.focusRow} !important;
    color: ${theme.colors.blues.antBlue} !important;
    text-shadow: none !important;
`;

interface VarianceReportStatusBannerProps {
    periodEnd: string;
    setPeriodEnd: (value: string) => void;
    isCollapsed: boolean;
    setIsCollapsed: (value: boolean) => void;
}
const VarianceReportStatusBanner = ({
    periodEnd,
    setPeriodEnd,
    isCollapsed,
    setIsCollapsed,
}: VarianceReportStatusBannerProps) => {
    return (
        <div style={{ height: '100%' }}>
            <SelectionsBanner
                data-test="variance-report-status-banner"
                className={''}
                isCollapsed={false}
            >
                <div
                    style={{
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginLeft: '3px',
                                marginBottom: '3px',
                            }}
                        >
                            Period Ending
                        </div>
                        <DatePicker
                            picker={'month'}
                            allowClear={false}
                            placeholder="Select month"
                            format={'MMMM YYYY'}
                            className={datePickerStyle}
                            style={{
                                height: '32px',
                                width: '175px',
                                fontSize: '14px',
                            }}
                            defaultValue={moment(periodEnd)}
                            onChange={(value) =>
                                setPeriodEnd(toISO(toEndOfMonth(value)))
                            }
                            suffixIcon={
                                <i
                                    className="fa-regular fa-calendar"
                                    style={{
                                        color: theme.colors.grays.dark,
                                        marginBottom: '2px',
                                    }}
                                />
                            }
                        />
                    </div>

                    <div style={{ marginLeft: 'auto', marginRight: 12 }}>
                        <CollapseChartButton
                            isCollapsed={isCollapsed}
                            collapseTrigger={setIsCollapsed}
                        />
                    </div>
                </div>
            </SelectionsBanner>
        </div>
    );
};

export default VarianceReportStatusBanner;
