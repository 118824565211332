import PropTypes from 'prop-types';
import React from 'react';
import { pick, isEmpty } from 'lodash';
import { DASH_DASH } from 'config/constants';
import { dateSort } from 'utils/tables/sorters';
import { formatDateRangeForCopy } from 'waypoint-utils';
import { ChartTableSwitchCard, ActionButtons } from 'waypoint-react';
import { OvertimeChart } from 'components/analytics/financialOverview/charts';
import { OvertimeTable } from 'components/analytics/financialOverview/tables';
import { getPeriodicityLabelFor } from 'components/analytics/balance-sheet-overview/cards/overtime/utils';

const OvertimeCard = (props) => {
    const {
        data = [],
        onClick,
        loading,
        period,
        displayType,
        accountMappingName,
        onSelectPeriodicity,
        periodicity,
    } = props;
    const gridProps = pick(props, ['data-grid', 'style']);
    const sortedData = data.sort((a, b) =>
        dateSort(b.period_end, a.period_end)
    );
    const periodLabel = formatDateRangeForCopy(period);
    const periodicityLabel = getPeriodicityLabelFor(periodicity);
    return (
        <ChartTableSwitchCard
            {...gridProps}
            loading={loading}
            extra={<ActionButtons onClick={onClick} loading={loading} />}
            title={`Actual v. Budget Over Time: ${
                accountMappingName || DASH_DASH
            }`}
            subheading={`${periodLabel} by ${periodicityLabel}`}
            empty={isEmpty(data)}
            table={
                <OvertimeTable data={sortedData} periodicity={periodicity} />
            }
            chart={
                <OvertimeChart
                    data={sortedData}
                    period={period}
                    displayType={displayType}
                    periodicity={periodicity}
                    onClick={onClick}
                    onSelectPeriodicity={onSelectPeriodicity}
                />
            }
        />
    );
};

OvertimeCard.propTypes = {
    accountMappingName: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    displayType: PropTypes.string,
    downloadCsv: PropTypes.func,
    downloading: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    period: PropTypes.array,
};

export default OvertimeCard;
