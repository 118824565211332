import React from 'react';
import { get } from 'lodash';
import { renderToString } from 'react-dom/server';
import { BalanceSheetOverviewTooltip } from 'components/analytics/balance-sheet-overview/tooltip';
import { getOvertimeDataLabelFor } from 'components/analytics/utils';

/**
 * Nodes in Fusion Charts are required to be strings.
 * @param {object} - contains title and value fields
 * @returns {string} - stringified BalanceSheetOverviewTooltip
 */
export const generateBalanceSheetOverviewTooltip = ({
    title,
    value,
    metricLabel,
}) => {
    return renderToString(
        <BalanceSheetOverviewTooltip
            title={title}
            value={value}
            metricLabel={metricLabel}
        />
    );
};

/**
 * This takes an overtime dataset and converts it into the needed Fusion Charts format for datasets
 * @param {array} overtime
 * @param {string} periodicity
 * @param {string} metricLabel
 * @returns {array}
 */
export const getBSOOvertimeDataset = (overtime, periodicity, metricLabel) =>
    overtime.map((i) => {
        const label = getOvertimeDataLabelFor(i.period_end, periodicity);
        return {
            label,
            value: i.actual_amount,
            toolText: generateBalanceSheetOverviewTooltip({
                title: label,
                value: get(i, 'actual_amount'),
                metricLabel,
            }),
        };
    });
