import { Card, Skeleton } from 'antd';
import { css } from 'emotion';
import React from 'react';
import { selectPropertiesById } from 'state/properties/selectors';
import { hasOwnershipPercentageEnabled } from 'state/user/selectors';
import { getPureId } from 'state/routing/selectors';
import { PropertyType } from 'waypoint-types';
import PropertyProfileExecutiveSummary from './property-profile-components/PropertyProfileExecutiveSummary';
import { PropertyProfileAttributesTable } from './property-profile-components/PropertyProfileAttributesTable';
import { PropertyProfileInformation } from './property-profile-components/PropertyProfileInformation';
import { usePropertyInformation, useSelectedDataLevel } from 'waypoint-hooks';
import { connect, RootStateOrAny } from 'react-redux';
import { hasDebtAndValuePropertyInfo } from 'state/user/selectors';
import { PropertyProfileOwnership } from './property-profile-components/PropertyProfileOwnership';
import { AppFeaturePermissions } from 'contexts';

const globalDescriptionsStyle = css`
    .ant-descriptions-header {
        margin-bottom: 8px;
        font-size: 18px;
    }
    .ant-descriptions-item-content {
        justify-content: flex-start;
    }
`;

interface PropertyProfileSectionProps {
    entityCode: string;
    featurePermissions: string[];
    propertyInfo: PropertyType;
    hasDebtAndValueEnabled: boolean;
    hasOwnershipPercentageEnabled: boolean;
}

const PropertyProfileSection = ({
    entityCode,
    featurePermissions,
    propertyInfo,
    hasDebtAndValueEnabled,
    hasOwnershipPercentageEnabled,
}: PropertyProfileSectionProps) => {
    const selectedDataLevel = useSelectedDataLevel();

    const {
        entity,
        allAttributes,
        isLoadingValueAndDebt,
        leasePropertyData,
        isLoadingPropertyDetails,
        onChangePeriodSelected,
        periodSelected,
        valueAndDebtData,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    if (
        !entity ||
        !propertyInfo ||
        isLoadingValueAndDebt ||
        (!leasePropertyData && isLoadingPropertyDetails)
    ) {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    <Skeleton active paragraph={{ rows: 0 }} />
                </div>

                <Card>
                    <Skeleton paragraph={{ rows: 5 }} />
                </Card>

                <Card style={{ marginTop: '20px' }}>
                    <Skeleton paragraph={{ rows: 5 }} />
                </Card>

                <Card style={{ marginTop: '20px' }}>
                    <Skeleton paragraph={{ rows: 5 }} />
                </Card>
            </>
        );
    }

    return (
        <div
            className={globalDescriptionsStyle}
            style={{ marginTop: '20px', padding: '0 24px' }}
        >
            {featurePermissions.includes(
                AppFeaturePermissions.PropertyInformation
            ) && (
                <PropertyProfileInformation
                    entity_code={entityCode}
                    propertyInfo={propertyInfo}
                    hasDebtAndValueEnabled={hasDebtAndValueEnabled}
                    onChangePeriodSelected={onChangePeriodSelected}
                    periodSelected={periodSelected}
                    valueAndDebtData={valueAndDebtData}
                />
            )}

            {hasOwnershipPercentageEnabled && (
                <PropertyProfileOwnership entityCode={entityCode} />
            )}

            {featurePermissions.includes(
                AppFeaturePermissions.ExecutiveSummary
            ) && <PropertyProfileExecutiveSummary entity_code={entityCode} />}

            {featurePermissions.includes(AppFeaturePermissions.Attributes) && (
                <PropertyProfileAttributesTable
                    entityAttributes={allAttributes}
                    entity={entity}
                />
            )}
        </div>
    );
};

const mapState = (s: RootStateOrAny) => {
    const entityCode = getPureId(s);
    const properties = selectPropertiesById(s, { propertyIds: [entityCode] });
    const hasDebtAndValueEnabled = hasDebtAndValuePropertyInfo(s);

    return {
        propertyInfo: properties[Object.keys(properties)[0]],
        hasDebtAndValueEnabled,
        hasOwnershipPercentageEnabled: hasOwnershipPercentageEnabled(s),
    };
};

export default connect(mapState)(PropertyProfileSection);
