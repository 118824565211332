import { useEffect, useState } from 'react';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import DataGrid, {
    Editing,
    Column,
    Grouping,
    NumericRule,
} from 'devextreme-react/data-grid';
import { RangeSelectConfirm, Select } from 'waypoint-react';
import PlanningCapitalProjectsCategorySelect from './PlanningCapitalProjectCategorySelect';
import { Input, Checkbox, Tabs, Modal, Form, Button } from 'antd';
import type { TabsProps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { css } from 'emotion';
import {
    CapitalProject,
    CapitalProjectClientDefinedType,
    CapitalProjectMonthlyCost,
    UnpackedMonthlyCosts,
} from 'waypoint-types';
import moment from 'moment';
import theme from 'config/theme';
import {
    createYearArray,
    defaultMonthObjects,
    filterMonthlyCostsByProjectDateRange,
    getDateRangeLimits,
    monthlyCostCellRender,
    packMonthlyCosts,
    unpackMonthlyCosts,
} from './PlanningCapitalProjectUtils';
import { RowUpdatedEvent } from 'devextreme/ui/data_grid';
import { currencyRenderer } from 'utils/tables/renderers';
import { Dictionary } from 'ts-essentials';
import { monthDisplayNames } from 'utils/consts';

interface EditorPrepEvent {
    index: number;
    row: {
        data: CapitalProjectMonthlyCost;
        rowType: string;
    };
    editorOptions: {
        disabled: boolean;
        width: number;
    };
}

const { TabPane } = Tabs;

interface PlanningCapitalProjectModalProps {
    selectedRowData: CapitalProject | null;
    setIsVisible: (value: boolean) => void;
    propertyOptions: {
        value: string;
        label: string;
    }[];
    isVisible: boolean;
    capitalProjectsData: CapitalProject[];
    onAddCapitalProject: (capitalProject: CapitalProject) => void;
    onUpdateCapitalProject: (capitalProject: CapitalProject) => void;
}

const gridLayout = {
    columns: 4,
    rows: 5,
};

const modalStyle = css`
    width: 1100px !important;
    height: 800px !important;
    @media screen and (max-width: 1280px) {
        max-width: 85% !important;
    }
    .ant-form-item-label {
        font-size: 14px;
        font-family: Lato;
    }
`;

const tabStyle = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible !important;
    .ant-tabs-tab.ant-tabs-tab-active {
        color: ${theme.colors.blues.primary} !important;
    }

    .ant-tabs-ink-bar {
        background: ${theme.colors.blues.primary} !important;
    }

    .ant-tabs-extra-content {
        line-height: 43px;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active,
    .ant-tabs-top.ant-tabs-line.ant-tabs-no-animation {
        height: 100% !important;
    }
`;

const capitalProjectModalSelectId = 'capital-project-select-id';

const PlanningCapitalProjectModal = ({
    selectedRowData,
    setIsVisible,
    isVisible,
    propertyOptions,
    capitalProjectsData,
    onAddCapitalProject,
    onUpdateCapitalProject,
}: PlanningCapitalProjectModalProps) => {
    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);
    const [periodRange, setPeriodRange] = useState<[Moment, Moment] | null>(
        null
    );
    const [energySavingsEnabled, setEnergySavingsEnabled] = useState<boolean>(
        selectedRowData?.energy_saving ?? false
    );

    const [, setIsEscalatable] = useState<boolean>(
        selectedRowData?.is_escalatable ?? false
    );
    const [monthlyCostsData, setMonthlyCostsData] =
        useState<UnpackedMonthlyCosts>({
            budget: [],
            actual: [],
        });
    const [typesToSend, setTypesToSend] = useState<
        Dictionary<CapitalProjectClientDefinedType | null>
    >({
        category: null,
        status: null,
        subcategory: null,
        priority: null,
    });
    const [form] = Form.useForm();

    const initializeDateRange = () => {
        const startDate = selectedRowData?.estimated_start_date ?? null;
        const endDate = selectedRowData?.estimated_completion_date ?? null;
        if (startDate && endDate) {
            setPeriodRange([moment(startDate), moment(endDate)]);
        }
    };

    useEffect(() => {
        if (
            selectedRowData &&
            selectedRowData.estimated_start_date &&
            selectedRowData.estimated_completion_date
        ) {
            initializeDateRange();
        }

        if (selectedRowData) {
            setTypesToSend({
                ...typesToSend,
                category: selectedRowData?.category,
                status: selectedRowData?.status,
                subcategory: selectedRowData?.subcategory,
                priority: selectedRowData?.priority,
            });
        }

        if (selectedRowData?.monthly_costs) {
            setMonthlyCostsData(unpackMonthlyCosts(selectedRowData));
        }
    }, [selectedRowData]);

    const handleClose = () => {
        setIsVisible(false);
    };

    const monthlySum = (rowData: { [key: string]: number }, type: string) => {
        if (periodRange) {
            const { startYear, endYear, startMonth, endMonth } =
                getDateRangeLimits(periodRange);

            const monthlyCostData = filterMonthlyCostsByProjectDateRange(
                rowData,
                startYear,
                endYear,
                startMonth,
                endMonth
            );

            return currencyRenderer(
                Object.values(monthlyCostData).reduce(
                    (a, b) => Number(a) + Number(b),
                    0
                )
            );
        }
        return currencyRenderer(0);
    };

    const handleSubmit = (data: CapitalProject) => {
        const capitalProjectData = {
            ...data,
            estimated_start_date: periodRange
                ? periodRange[0].format('YYYY-MM-DD')
                : null,
            estimated_completion_date: periodRange
                ? periodRange[1].format('YYYY-MM-DD')
                : null,
            energy_estimated_savings: Number(data.energy_estimated_savings),
            ...typesToSend,
            monthly_costs: packMonthlyCosts(
                monthlyCostsData,
                selectedRowData?.id
            ),
        };
        selectedRowData
            ? onUpdateCapitalProject({
                  ...capitalProjectData,
                  id: selectedRowData.id,
              })
            : onAddCapitalProject(capitalProjectData);
        handleClose();
        return;
    };

    const createDefaultMonthlyCostsForGrid = (
        years: number[],
        range: [Moment, Moment] | null
    ) => {
        const costsData = selectedRowData
            ? unpackMonthlyCosts(selectedRowData, range)
            : monthlyCostsData;
        const costs = {
            budget: years.map((year) => ({
                year,
                ...defaultMonthObjects,
            })) as Dictionary<number>[],
            actual: years.map((year) => ({
                year,
                ...defaultMonthObjects,
            })) as Dictionary<number>[],
        };

        Object.keys(costs).map((costType) => {
            const type = costType as keyof typeof costs;
            for (let i = 0; i < costs[type].length; i++) {
                const yearCosts = costs[type][i];
                const matchingYear = costsData[type].find(
                    (m) => Number(m.year) === Number(yearCosts.year)
                );
                if (matchingYear) {
                    costs[type][i] = matchingYear;
                }
            }
        });

        return costs;
    };

    const onEditorPrep = (e: EditorPrepEvent) => {
        if (periodRange) {
            const startYear = periodRange[0].year();
            const endYear = periodRange[1].year();
            const startMonth = periodRange[0].month();
            const endMonth = periodRange[1].month();

            const rowType = e?.row?.rowType ?? '';
            const monthIndex = e?.index ?? null;

            if (rowType === 'data' && monthIndex) {
                const rowDataYear = e.row.data.year;
                if (
                    (rowDataYear === startYear &&
                        monthIndex < startMonth + 1) ||
                    (rowDataYear === endYear && monthIndex > endMonth + 1)
                ) {
                    e.editorOptions.disabled = true;
                } else {
                    e.editorOptions.width = 100;
                    e.editorOptions.disabled = false;
                }
            }
        }
    };

    const EditCostsGrid = ({
        data,
        selectedTab,
    }: {
        data: UnpackedMonthlyCosts;
        selectedTab: string;
    }) => {
        const clientDefinedTypes =
            selectedTab === 'actual' ? ['Actual'] : ['Budgeted'];

        return clientDefinedTypes.map((t) => {
            return (
                <div style={{ marginBottom: '5px' }}>
                    <div
                        style={{
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            marginBottom: '3px',
                        }}
                    >{`${t} Costs`}</div>
                    <DataGrid
                        dataSource={
                            data[selectedTab as keyof UnpackedMonthlyCosts]
                        }
                        onRowUpdated={(e: RowUpdatedEvent) => {
                            data?.[
                                selectedTab as keyof UnpackedMonthlyCosts
                            ].map((year: Dictionary<string | number>) => {
                                if (year.year === e.data.year) {
                                    const updatedRow = e.data;
                                    return updatedRow;
                                }

                                return year;
                            });
                        }}
                        onEditorPreparing={(e) =>
                            onEditorPrep(e as unknown as EditorPrepEvent)
                        }
                        showBorders={true}
                        showColumnLines={true}
                    >
                        <Column
                            dataField="year"
                            width={50}
                            caption="Year"
                            alignment="left"
                            allowEditing={false}
                        />
                        {[...monthDisplayNames].map((month) => {
                            return (
                                <Column
                                    key={month.toLowerCase()}
                                    dataField={month.toLowerCase()}
                                    caption={month.slice(0, 3)}
                                    alignment="left"
                                    format="currency"
                                    cellRender={(e) =>
                                        monthlyCostCellRender(e, periodRange)
                                    }
                                >
                                    <NumericRule message="Please enter a numeric value" />
                                </Column>
                            );
                        })}
                        <Column
                            caption="Total"
                            calculateCellValue={(e: {
                                [key: string]: number;
                            }) => monthlySum(e, t)}
                            alignment="left"
                            format="currency"
                            allowEditing={false}
                        />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={false}
                            selectTextOnEditStart={true}
                        />
                        <Grouping autoExpandAll={true} />
                    </DataGrid>
                </div>
            );
        });
    };

    const renderCostGrid = (selectedTab: string) => {
        return EditCostsGrid({
            data: monthlyCostsData,
            selectedTab,
        });
    };

    const tabsItem: TabsProps['items'] = [
        { label: 'Budgeted', key: '1', children: renderCostGrid('budget') },
        {
            label: 'Actual',
            key: '2',
            children: renderCostGrid('actual'),
        },
    ];

    const setDateRange = (range: [Moment, Moment]) => {
        setPeriodRange(range);
        const startYear = moment(range[0]).year();
        const endYear = moment(range[1]).year();
        const yearArray = createYearArray(startYear, endYear);
        setMonthlyCostsData(createDefaultMonthlyCostsForGrid(yearArray, range));
    };

    const disablePropertySelect = propertyOptions.length === 1;

    return (
        <Modal
            centered
            zIndex={5}
            title={
                selectedRowData ? 'Edit Capital Project' : 'Add Capital Project'
            }
            className={modalStyle}
            open={isVisible}
            footer={null}
            onCancel={handleClose}
            data-test="capital-project-modal"
        >
            <Form
                scrollToFirstError
                form={form}
                style={{
                    maxHeight: '650px',
                    overflowY: 'scroll',
                }}
                layout="vertical"
                name="capital-tracker-form"
                initialValues={
                    selectedRowData
                        ? {
                              ...selectedRowData,
                              estimated_start_date: moment(
                                  selectedRowData.estimated_start_date
                              ),
                              estimated_completion_date: moment(
                                  selectedRowData.estimated_completion_date
                              ),
                              category: selectedRowData.category?.value,
                              status: selectedRowData.status?.value,
                              subcategory: selectedRowData.subcategory?.value,
                              priority: selectedRowData.priority?.value,
                          }
                        : {
                              entity_code: disablePropertySelect
                                  ? propertyOptions[0].value
                                  : null,
                              category: null,
                              status: null,
                              subcategory: null,
                              priority: null,
                          }
                }
                onFinish={(values) => {
                    handleSubmit(values);
                }}
            >
                <ResponsiveBox>
                    {Array.from({ length: gridLayout.rows }).map((_, index) => {
                        return <Row />;
                    })}

                    {Array.from({ length: gridLayout.columns }).map(() => {
                        return <Col />;
                    })}
                    <Item>
                        <Location row={0} col={0} colspan={2} />
                        <div style={{ marginTop: '5px', paddingRight: 5 }}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required value',
                                    },
                                ]}
                                name="entity_code"
                                label="Property"
                            >
                                <Select
                                    placeholder={'Property Name'}
                                    value={
                                        disablePropertySelect
                                            ? propertyOptions[0].value
                                            : selectedRowData?.entity_code
                                    }
                                    options={propertyOptions}
                                    disabled={disablePropertySelect}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={0} col={2} colspan={2} />
                        <div style={{ marginTop: '5px' }}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required value',
                                    },
                                ]}
                                name="name"
                                label="Project"
                            >
                                <Input
                                    style={{ height: '32px', width: '100%' }}
                                    defaultValue={selectedRowData?.name}
                                    maxLength={50}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={1} col={0} colspan={4} />
                        <div style={{ marginTop: '5px' }}>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea
                                    defaultValue={selectedRowData?.description}
                                    showCount
                                    rows={3}
                                    maxLength={500}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={2} col={0} colspan={2} />
                        <div style={{ marginBottom: '15px' }}>
                            <Form.Item label="Category" name="category">
                                <PlanningCapitalProjectsCategorySelect
                                    isAllowedToEdit={false}
                                    type="category"
                                    rowData={selectedRowData}
                                    tableData={capitalProjectsData}
                                    setTypesToSend={setTypesToSend}
                                    typesToSend={typesToSend}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={2} col={2} colspan={2} />
                        <div style={{ marginBottom: '15px' }}>
                            <Form.Item label="Subcategory" name="subcategory">
                                <PlanningCapitalProjectsCategorySelect
                                    isAllowedToEdit={false}
                                    type="subcategory"
                                    rowData={selectedRowData}
                                    tableData={capitalProjectsData}
                                    setTypesToSend={setTypesToSend}
                                    typesToSend={typesToSend}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={3} col={0} colspan={2} />
                        <div
                            style={{
                                marginTop: '5px',
                                marginBottom: '15px',
                            }}
                        >
                            <Form.Item name="status" label="Status">
                                <PlanningCapitalProjectsCategorySelect
                                    type="status"
                                    rowData={selectedRowData}
                                    tableData={capitalProjectsData}
                                    setTypesToSend={setTypesToSend}
                                    typesToSend={typesToSend}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={3} col={2} colspan={2} />
                        <div
                            style={{
                                marginTop: '5px',
                                marginBottom: '15px',
                            }}
                        >
                            <Form.Item name="priority" label="Priority">
                                <PlanningCapitalProjectsCategorySelect
                                    isAllowedToEdit={false}
                                    type="priority"
                                    rowData={selectedRowData}
                                    tableData={capitalProjectsData}
                                    setTypesToSend={setTypesToSend}
                                    typesToSend={typesToSend}
                                />
                            </Form.Item>
                        </div>
                    </Item>
                    <Item>
                        <Location row={4} col={0} colspan={1} />
                        <Form.Item
                            name="underwriting"
                            valuePropName="checked"
                            label="Incl. in UW"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Item>

                    <Item>
                        <Location row={4} col={1} colspan={1} />
                        <Form.Item
                            name="is_escalatable"
                            valuePropName="checked"
                            label="Escalatable"
                        >
                            <Checkbox />
                        </Form.Item>
                    </Item>

                    <Item>
                        <Location row={4} col={2} colspan={1} />
                        <Form.Item
                            name="energy_saving"
                            valuePropName="checked"
                            label="Energy Savings"
                        >
                            <Checkbox
                                onChange={(e) =>
                                    setEnergySavingsEnabled(e.target.checked)
                                }
                            />
                        </Form.Item>
                    </Item>
                    <Item>
                        <Location row={4} col={3} colspan={1} />
                        {energySavingsEnabled ? (
                            <div
                                style={{
                                    width: '175px',
                                }}
                            >
                                <Form.Item
                                    name="energy_estimated_savings"
                                    label="Est. Savings"
                                >
                                    <Input
                                        type="number"
                                        defaultValue={
                                            selectedRowData?.energy_estimated_savings
                                        }
                                        maxLength={25}
                                    />
                                </Form.Item>
                            </div>
                        ) : null}
                    </Item>
                </ResponsiveBox>
                <Form.Item
                    label="Project Timing"
                    name="range_of_years"
                    tooltip={{
                        title: 'Project timing is needed to enter budgeted and actual costs',
                        icon: <InfoCircleOutlined />,
                    }}
                >
                    <div
                        id={capitalProjectModalSelectId}
                        style={
                            !periodRange
                                ? { marginBottom: '25px', display: 'flex' }
                                : { display: 'flex' }
                        }
                    >
                        <RangeSelectConfirm
                            open={rangeSelectIsOpen}
                            pickerPeriod={'month'}
                            value={periodRange}
                            onFocus={() => setRangeSelectIsOpen(true)}
                            onCancel={() => setRangeSelectIsOpen(false)}
                            onConfirm={(values) => {
                                setRangeSelectIsOpen(false);
                                if (values?.length === 2) {
                                    const [start, end] = values;
                                    if (start && end) {
                                        setDateRange([start, end]);
                                    }
                                } else {
                                    setPeriodRange(null);
                                }
                            }}
                            getCalendarContainer={() =>
                                document.getElementById(
                                    capitalProjectModalSelectId
                                )
                            }
                        />
                        {periodRange && (
                            <Button
                                danger
                                style={{ marginLeft: '15px', marginTop: '1px' }}
                                size="middle"
                                type="primary"
                                onClick={() => setPeriodRange(null)}
                            >
                                Clear Date Range
                            </Button>
                        )}
                    </div>
                </Form.Item>
                {periodRange && (
                    <div style={{ paddingBottom: 48 }}>
                        <Tabs
                            className={tabStyle}
                            animated={false}
                            defaultActiveKey="1"
                            items={tabsItem}
                        />
                    </div>
                )}

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                        marginTop: '15px',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        padding: '12px 24px',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #e9e9e9',
                        borderBottomRightRadius: '8px',
                        borderBottomLeftRadius: '8px',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="middle"
                            onClick={() => {
                                handleClose();
                            }}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button size="middle" type="primary" htmlType="submit">
                            {selectedRowData ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default PlanningCapitalProjectModal;
