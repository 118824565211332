import React from 'react';
import { CROSSTAB_TITLE } from './CrosstabConstants';
import { Button } from 'antd';
import { CrosstabComparisonModal } from './CrosstabComparisonModal';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { CrosstabAccountNode } from './accounts';
import { Moment } from 'moment';

export const CrosstabTitle = ({
    dataSource,
    rootAccount,
    modeSelection,
    setModeSelection,
    isLoading,
    setIsOpenSettings,
    isOpenSettings,
    periodRange,
    setPeriodRange,
}: {
    dataSource: PivotGridDataSource;
    rootAccount: CrosstabAccountNode;
    modeSelection: string[];
    setModeSelection: (value: string[]) => void;
    isLoading: boolean;
    setIsOpenSettings: (value: boolean) => void;
    isOpenSettings: boolean;
    periodRange: [Moment, Moment] | null;
    setPeriodRange: (value: [Moment, Moment] | null) => void;
}) => {
    const notAvailable = !rootAccount || isLoading || !dataSource;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '15px',
                marginRight: '15px',
            }}
        >
            <h2 style={{ marginBottom: 0, marginTop: 0 }}>{CROSSTAB_TITLE}</h2>
            <Button
                disabled={notAvailable}
                style={{ marginLeft: '14px' }}
                type="primary"
                data-test="new-comparison-button-modal"
                onClick={() => setIsOpenSettings(true)}
            >
                New Comparison
            </Button>
            <CrosstabComparisonModal
                modeSelection={modeSelection}
                setModeSelection={setModeSelection}
                setIsOpenSettings={setIsOpenSettings}
                isOpenSettings={isOpenSettings}
                periodRange={periodRange}
                setPeriodRange={setPeriodRange}
            />
        </div>
    );
};
