import React, { CSSProperties } from 'react';
import {
    EntityProfileCards,
    AddItemButtonLocationOptions,
    EntityProfileCardsGroupInputsType,
    EntityProfileCardsGroupReduceType,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import { SectionType } from 'waypoint-types';
import {
    containerSort,
    getRows,
    getUniqueYears,
} from 'waypoint-react/entity-profile-cards/EntityProfileCardUtils';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface SWOTAnalysisProps {
    entityCode: string;
    title?: string;
    selectedYear?: number[];
    selectedCategories?: string[];
    showReportWidgetOnly?: boolean;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    displayVertically?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

export const SWOT_CARD_TYPE = 'swot';

const sections: SectionType = {
    strengths: {
        section: 'strengths',
        title: 'Strengths',
        addButtonTooltip: 'Add a Strength',
        addItemTitleLabel: 'Strength',
    },
    weaknesses: {
        section: 'weaknesses',
        title: 'Weaknesses',
        addButtonTooltip: 'Add a Weakness',
        addItemTitleLabel: 'Weakness',
    },
    opportunities: {
        section: 'opportunities',
        title: 'Opportunities',
        addButtonTooltip: 'Add an Opportunity',
        addItemTitleLabel: 'Opportunity',
    },
    threats: {
        section: 'threats',
        title: 'Threats',
        addButtonTooltip: 'Add a Threat',
        addItemTitleLabel: 'Threat',
    },
};

const rowColumnConfiguration = (key: string, displayVertically?: boolean) => {
    if (displayVertically) {
        const rowIndex = Object.keys(sections).indexOf(key);
        return { row: rowIndex, column: 0, colSpan: 2 };
    }

    switch (key) {
        case 'strengths':
            return { row: 0, column: 0, colSpan: 1 };
        case 'weaknesses':
            return { row: 0, column: 1, colSpan: 1 };
        case 'opportunities':
            return { row: 1, column: 0, colSpan: 1 };
        case 'threats':
            return { row: 1, column: 1, colSpan: 1 };
        default:
            return { row: 0, column: 1, colSpan: 1 };
    }
};

export const SWOTAnalysis = ({
    entityCode,
    title,
    selectedYear,
    selectedCategories,
    showReportWidgetOnly = false,
    isPDFExport,
    includeNotesInPdf = false,
    displayVertically = false,
    setIsReadyToExport,
}: SWOTAnalysisProps): JSX.Element => {
    const getContainerConfig = (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => {
        const uniqueYears = getUniqueYears(yearMetadata);

        return uniqueYears.map((year) => {
            return {
                title: `${year} ${'SWOT Analysis'}`,
                column: 0,
                deleteStrategy: {
                    type: 'yearMetadata',
                    get: (
                        items: EntityProfileCard[],
                        yearMetadata: EntityProfileCardYearMetadata[]
                    ) => {
                        return yearMetadata.find((ym) => ym.year === year);
                    },
                },
                shouldHideContainerIfEmpty: (
                    yearsFilter: number[]
                ): boolean => {
                    return !yearsFilter.includes(year);
                },
                getGroupGrid: () => {
                    return {
                        columns: 2,
                        rows: displayVertically ? 4 : 2,
                    };
                },
                getEmptyGroupState: (): EntityProfileCardsGroupReduceType => {
                    const result: EntityProfileCardsGroupReduceType = {};

                    Object.keys(sections).forEach((sectionKey) => {
                        const { title, addButtonTooltip } =
                            sections[sectionKey];
                        result[sectionKey] = {
                            title,
                            ...rowColumnConfiguration(
                                sectionKey,
                                displayVertically
                            ),
                            items: [],
                            addItemButton: {
                                addItemTitleLabel: title,
                                addItemTooltip: `${addButtonTooltip}`,
                                getDefaultCard: () => {
                                    return {
                                        id: '',
                                        title: '',
                                        section: sectionKey,
                                        notes: '',
                                        sort_index: 0,
                                        year: year,
                                        type: SWOT_CARD_TYPE,
                                        metadata: [],
                                    };
                                },
                            },
                        };
                    });
                    return result;
                },
                group: (
                    result: EntityProfileCardsGroupReduceType,
                    item: EntityProfileCard
                ) => {
                    result[item.section].items.push(item);

                    return result;
                },
                filter: (item: EntityProfileCard) => {
                    return item.year === year;
                },
                addItemButtonLocations: [
                    'group',
                ] as AddItemButtonLocationOptions,
                renderYearMetadataOptions: (key: string) => {
                    return [];
                },
                getYearMetadataValue: (
                    yearMetadata: EntityProfileCardYearMetadata[]
                ): EntityProfileCardYearMetadata | undefined => {
                    return yearMetadata.find((ym) => ym.year === year);
                },
                getGroupStyle: (
                    groupDef: EntityProfileCardsGroupInputsType
                ): CSSProperties => {
                    const marginValue = '5px';

                    if (
                        groupDef.column === 0 &&
                        groupDef.row === 0 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginRight: marginValue,
                        };
                    }

                    if (
                        groupDef.column === 1 &&
                        groupDef.row === 0 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginLeft: marginValue,
                        };
                    }

                    if (
                        groupDef.column === 1 &&
                        groupDef.row === 1 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginLeft: marginValue,
                        };
                    }

                    if (
                        groupDef.column === 0 &&
                        groupDef.row === 1 &&
                        groupDef.colSpan === 1
                    ) {
                        return {
                            marginRight: marginValue,
                        };
                    }

                    return {};
                },
                sort: (groupA: EntityProfileCardsGroupInputsType) => {
                    if (groupA.title === sections['strengths'].title) {
                        return -1;
                    }

                    if (groupA.title === sections['weaknesses'].title) {
                        return 0;
                    }

                    if (groupA.title === sections['opportunities'].title) {
                        return 1;
                    }

                    return 0;
                },
            };
        });
    };

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.Swot}>
            <EntityProfileCards
                isCollapse
                enableYearMetadata
                enableYearFilter
                enableExport
                title={title ?? 'SWOT Analysis'}
                type={SWOT_CARD_TYPE}
                entityCode={entityCode}
                columns={1}
                rows={getRows}
                selectedCategories={selectedCategories}
                getContainerConfig={getContainerConfig}
                containerSort={containerSort}
                enabledFields={['title']}
                selectedYear={selectedYear}
                showReportWidgetOnly={showReportWidgetOnly}
                data-test="swot-analysis-card"
                isPDFExport={isPDFExport}
                includeNotesInPdf={includeNotesInPdf}
                setIsReadyToExport={setIsReadyToExport}
            />
        </PermissionedWrapper>
    );
};
