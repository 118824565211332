import { Table } from 'antd';
import { processValidFilters } from 'components/saved-configurations/utils';
import DataSource from 'devextreme/data/data_source';
import { css } from 'emotion';
import { Dictionary } from 'ts-essentials';
import { dateSort, numericalSort, stringSort } from 'utils/tables/sorters';
import {
    DelinquencyData,
    LeaseExpirationScheduleProps,
    RecurringChargeRollUp,
    RentRollPropsWithBuckets,
    SavedConfiguration,
    TransformedUnitMix,
} from 'waypoint-types';
import theme from 'config/theme';
import { RentRollWithGroupHeaderFlag } from './RentRollExportableGrid';
import { ContractWithGroupHeaderFlag } from './ServiceContractsExportableGrid';
import { CapitalProjectGridWithGroupHeaderFlag } from './PlanningCapitalProjectPlanExportableGrid';
import { TenantDataPrettified } from './TopTenantsExportableGrid';
import { safeDivision } from 'waypoint-utils';

export const headerClass = css`
    .ant-table-container {
        border-start-start-radius: 0px !important;
        border-start-end-radius: 0px !important;
    }
    .ant-table-thead .ant-table-cell {
        font-size: 12px !important;
        font-weight: bold !important;
        border-start-start-radius: 0px !important;
        border-start-end-radius: 0px !important;
    }
    .ant-table-summary {
        .ant-table-cell {
            font-size: 12px !important;
            font-weight: bold !important;
            background-color: #fafafa;
        }
    }
`;

export const rowClass = css`
    font-size: 10px;
`;

export const groupHeaderRowClass = css`
    background-color: ${theme.colors.grays.background} !important;
    font-weight: ${theme.fontWeights.bold} !important;
    font-size: 10px;
`;

export interface ColumnDescriptor {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'left' | 'right' | 'center';
    cssClass?: string;
    dataType?: string;
    render?: (value: any, rowData?: any) => string | number | JSX.Element;
    width?: string;
    hidden?: boolean;
    children?: ColumnDescriptor[];
}

export interface ExportableGridSummaryFormatter {
    summaryType: string;
    render: (value: any, rowData?: any) => string | number | JSX.Element;
}

export interface SavedConfigColumn {
    visible: boolean;
    visibleIndex: number;
    groupIndex?: number;
    sortIndex?: number;
    sortOrder?: string;
}

export type GridDataRow =
    | RentRollWithGroupHeaderFlag
    | RentRollPropsWithBuckets
    | ContractWithGroupHeaderFlag
    | CapitalProjectGridWithGroupHeaderFlag
    | TransformedUnitMix
    | TenantDataPrettified
    | DelinquencyData
    | LeaseExpirationScheduleProps
    | RecurringChargeRollUp;

export const applySavedFiltersToGridData = async <T,>(
    gridData: T[],
    savedConfig: SavedConfiguration | null,
    gridColumns: ColumnDescriptor[],
    useDotColumnNames = false
) => {
    const savedFilters = savedConfig?.filters_json?.grid_config?.filterValue;
    if (!savedFilters) {
        return gridData;
    }

    // capital project and plan tables need to have the .value fields (category etc.) changed to
    // _value for antd exports, but here we need the dot versions to match what's in saved configs
    const validFilterColumnNames = useDotColumnNames
        ? gridColumns.map((col) => col.dataIndex.replace('_value', '.value'))
        : gridColumns.map((col) => col.dataIndex);

    const validFilters = processValidFilters(
        savedFilters,
        validFilterColumnNames,
        true
    );

    // convert to array if it's a single filter
    const adjustedValidFilters = Array.isArray(validFilters[0])
        ? validFilters
        : [validFilters];

    // 'anyof', 'noneof', and 'between' are filter ops that a user can save,
    // but they aren't supported in the filter option we're using in the devextreme
    // DataSource we're creating below. This converts any saved filters using those ops
    // into a format that works here
    const convertedFilters = adjustedValidFilters.map((validFilter) => {
        if (!Array.isArray(validFilter)) {
            return validFilter;
        }

        // for filter ops like 'not and' or 'not or', the first element of the
        // filter array is '!' and the actual filter array is the second element
        const hasNotCondition = validFilter[0] === '!';
        const vf = hasNotCondition ? validFilter[1] : validFilter;

        if (vf.length >= 2 && vf[1] === 'anyof' && Array.isArray(vf[2])) {
            const adjustedFilter = [];
            const filterValues = vf[2];
            for (let i = 0; i < filterValues.length; i++) {
                adjustedFilter.push([vf[0], '=', filterValues[i]]);
                if (i < filterValues.length - 1) {
                    adjustedFilter.push('or');
                }
            }
            return hasNotCondition ? ['!', [adjustedFilter]] : adjustedFilter;
        }
        if (vf.length >= 2 && vf[1] === 'noneof' && Array.isArray(vf[2])) {
            const adjustedFilter = [];
            const filterValues = vf[2];
            for (let i = 0; i < filterValues.length; i++) {
                adjustedFilter.push([vf[0], '<>', filterValues[i]]);
                if (i < filterValues.length - 1) {
                    adjustedFilter.push('and');
                }
            }
            return hasNotCondition ? ['!', [adjustedFilter]] : adjustedFilter;
        }
        if (
            vf.length >= 2 &&
            vf[1] === 'between' &&
            Array.isArray(vf[2]) &&
            vf[2].length === 2
        ) {
            const adjustedFilter = [];
            const filterValues = vf[2];
            adjustedFilter.push([vf[0], '>=', filterValues[0]]);
            adjustedFilter.push('and');
            adjustedFilter.push([vf[0], '<=', filterValues[1]]);
            return hasNotCondition ? ['!', [adjustedFilter]] : adjustedFilter;
        }
        return hasNotCondition ? ['!', [vf]] : vf;
    });

    const dataSource = new DataSource({
        store: gridData,
        filter: convertedFilters,
        paginate: false,
    });

    return await dataSource.load();
};

export const sortDataByColumnKey = <T,>(
    dataType: string,
    sortingColumnName: string,
    sortOrder: string,
    gridData: T[]
) => {
    const sortingKey = sortingColumnName as keyof T;
    if (dataType === 'string' || dataType === 'boolean') {
        return gridData.sort((a, b) =>
            sortOrder === 'asc'
                ? stringSort(String(b[sortingKey]), String(a[sortingKey]))
                : stringSort(String(a[sortingKey]), String(b[sortingKey]))
        );
    }
    if (dataType === 'number') {
        return gridData.sort((a, b) =>
            sortOrder === 'asc'
                ? numericalSort(Number(a[sortingKey]), Number(b[sortingKey]))
                : numericalSort(Number(b[sortingKey]), Number(a[sortingKey]))
        );
    }
    if (dataType === 'date') {
        return gridData.sort((a, b) =>
            sortOrder === 'asc'
                ? dateSort(
                      new Date(String(b[sortingKey])),
                      new Date(String(a[sortingKey]))
                  )
                : dateSort(
                      new Date(String(a[sortingKey])),
                      new Date(String(b[sortingKey]))
                  )
        );
    }
    return gridData;
};

export const applySavedConfigSortingToGridData = <T,>(
    gridData: T[],
    gridColumns: ColumnDescriptor[],
    savedConfig: SavedConfiguration | null
) => {
    const savedConfigColumns = savedConfig?.filters_json?.grid_config
        ?.columns as Dictionary<SavedConfigColumn>;
    if (!savedConfigColumns) {
        return gridData;
    }

    const sortColumnNames = Object.entries(savedConfigColumns)
        .reduce((list, col) => {
            if (
                col[1]?.sortIndex !== undefined &&
                col[1]?.sortOrder !== undefined
            ) {
                list.push(col[0]);
            }
            return list;
        }, [] as string[])
        .sort((a: string, b: string) =>
            numericalSort(
                savedConfigColumns[a].sortIndex,
                savedConfigColumns[b].sortIndex
            )
        );

    let sortedData = [...gridData];
    for (const col of sortColumnNames) {
        const dataType =
            gridColumns.find((c) => c.dataIndex === col)?.dataType ?? 'string';
        const sortOrder = savedConfigColumns[col]?.sortOrder ?? 'asc';
        sortedData = sortDataByColumnKey(dataType, col, sortOrder, sortedData);
    }
    return sortedData;
};

export const getTableOrGroupSummaryData = <T,>(
    data: T[],
    gridColumns: ColumnDescriptor[],
    summaryFormatters: Dictionary<ExportableGridSummaryFormatter>,
    calculateCustomSummary?: (field: keyof T, data: T[]) => number
) => {
    const columnsInOrderForTotals = gridColumns.flatMap((col) => {
        if (col.children) {
            return col.children;
        }
        return col;
    });

    const totalsReducerBaseObject = columnsInOrderForTotals.reduce(
        (dict, col) => {
            dict[col.dataIndex] =
                col?.dataType === 'number' ||
                summaryFormatters[col.dataIndex]?.summaryType === 'count'
                    ? 0
                    : '';
            if (summaryFormatters[col.dataIndex]?.summaryType === 'avg') {
                dict[`${col.dataIndex}_count`] = 0;
                dict[`${col.dataIndex}_total`] = 0;
            }
            return dict;
        },
        {} as Dictionary<string | number>
    );

    const columnTotals = data.reduce((dict, d) => {
        for (const col of columnsInOrderForTotals) {
            const fieldTotalInfo = summaryFormatters[col.dataIndex];
            if (!fieldTotalInfo) {
                continue;
            }
            const dataIndex = col.dataIndex as keyof GridDataRow;
            if (fieldTotalInfo.summaryType === 'sum') {
                if (totalsReducerBaseObject[dataIndex] === '') {
                    totalsReducerBaseObject[dataIndex] = d[dataIndex] ?? 0;
                } else {
                    totalsReducerBaseObject[dataIndex] += d[dataIndex] ?? 0;
                }
            } else if (
                fieldTotalInfo.summaryType === 'avg' &&
                d[dataIndex] !== null &&
                d[dataIndex] !== undefined &&
                d[dataIndex] !== ''
            ) {
                dict[`${dataIndex}_count`] =
                    Number(dict[`${dataIndex}_count`]) + 1;
                dict[`${dataIndex}_total`] += d[dataIndex];
                dict[dataIndex] = safeDivision(
                    Number(dict[`${dataIndex}_total`]),
                    Number(dict[`${dataIndex}_count`])
                );
            } else if (
                fieldTotalInfo.summaryType === 'count' &&
                d[dataIndex] !== null &&
                d[dataIndex] !== undefined &&
                d[dataIndex] !== ''
            ) {
                dict[dataIndex] = Number(dict[dataIndex]) + 1;
            } else if (
                fieldTotalInfo.summaryType === 'max' &&
                d[dataIndex] !== null &&
                d[dataIndex] !== undefined &&
                d[dataIndex] !== ''
            ) {
                dict[dataIndex] = Math.max(
                    Number(dict[dataIndex]) ?? -Infinity,
                    d[dataIndex]
                );
            }
        }
        return dict;
    }, totalsReducerBaseObject);

    const customFields = Object.entries(summaryFormatters)
        .filter((formatter) => formatter[1].summaryType === 'custom')
        .map((formatter) => formatter[0]);

    for (const total of Object.entries(columnTotals)) {
        if (customFields.includes(total[0]) && !!calculateCustomSummary) {
            columnTotals[total[0]] = calculateCustomSummary(
                total[0] as keyof T,
                data
            );
        }
    }

    return columnTotals;
};

export const convertColumnsToAntd = (
    baseColumns: ColumnDescriptor[],
    hasHeaderColumns: boolean,
    savedConfig: SavedConfiguration | null,
    defaultVisibleColumns?: string[]
) => {
    const columnsForFilteringAndSorting = baseColumns.flatMap((col) => {
        if (col.children && col.children.length) {
            return col.children;
        }
        return col;
    });
    const savedConfigColumns = savedConfig?.filters_json?.grid_config
        ?.columns as Dictionary<SavedConfigColumn>;
    if (!savedConfigColumns) {
        // For saved config widgets, where not all base columns are visible by default. We still need to include
        // default hidden columns in the base column object so we have descriptors if they're visible in a saved config.
        // NOTE: this would need to be updated to work for a nested column table if the need ever arises in the future
        if (defaultVisibleColumns) {
            const defaultBaseColumns = baseColumns.filter((col) => {
                return defaultVisibleColumns.includes(col.dataIndex);
            });
            return {
                gridColumns: defaultBaseColumns,
                groupedColumnNames: [],
                columnsForFilteringAndSorting,
            };
        }

        return {
            gridColumns: baseColumns,
            groupedColumnNames: [],
            columnsForFilteringAndSorting,
        };
    }

    const mappedSavedConfigColumns = Object.entries(savedConfigColumns).reduce(
        (dict, col) => {
            const columnKey = col[0].replace('.', '_');
            dict[columnKey] = col[1];
            return dict;
        },
        {} as Dictionary<SavedConfigColumn>
    );

    const visibleColumns = Object.entries(mappedSavedConfigColumns).reduce(
        (list, col) => {
            if (col[1]?.visible) {
                list.push(col[0]);
            }
            return list;
        },
        [] as string[]
    );

    const groupedColumnNames = Object.entries(mappedSavedConfigColumns)
        .reduce((list, col) => {
            if (col[1]?.groupIndex !== undefined) {
                list.push(col[0]);
            }
            return list;
        }, [] as string[])
        .sort((a: string, b: string) =>
            numericalSort(
                mappedSavedConfigColumns[a].groupIndex,
                mappedSavedConfigColumns[b].groupIndex
            )
        );

    const groupedColumns: ColumnDescriptor[] = [];

    const filteredBaseColumns = baseColumns
        .filter((col) => {
            if (groupedColumnNames.includes(col.dataIndex)) {
                groupedColumns.push(col);
            }
            return (
                visibleColumns.includes(col.dataIndex) &&
                !groupedColumnNames.includes(col.dataIndex)
            );
        })
        .sort((a: ColumnDescriptor, b: ColumnDescriptor) =>
            numericalSort(
                mappedSavedConfigColumns[a.dataIndex].visibleIndex,
                mappedSavedConfigColumns[b.dataIndex].visibleIndex
            )
        );

    if (hasHeaderColumns) {
        filteredBaseColumns.map((col) => {
            const childColumns = col?.children ?? ([] as ColumnDescriptor[]);
            col.children = childColumns
                .filter((cc) => {
                    if (groupedColumnNames.includes(cc.dataIndex)) {
                        groupedColumns.push(cc);
                    }
                    return (
                        visibleColumns.includes(cc.dataIndex) &&
                        !groupedColumnNames.includes(cc.dataIndex)
                    );
                })
                .sort((a: ColumnDescriptor, b: ColumnDescriptor) =>
                    numericalSort(
                        mappedSavedConfigColumns[a.dataIndex].visibleIndex,
                        mappedSavedConfigColumns[b.dataIndex].visibleIndex
                    )
                );
        });
    }

    // step 3: place visible columns in specified order (grouped columns move to the front)
    const sortedGroupedColumns = groupedColumns.sort(
        (a: ColumnDescriptor, b: ColumnDescriptor) =>
            numericalSort(
                mappedSavedConfigColumns[a.dataIndex]?.groupIndex ?? 0,
                mappedSavedConfigColumns[b.dataIndex]?.groupIndex ?? 0
            )
    );

    return {
        gridColumns: [...sortedGroupedColumns, ...filteredBaseColumns],
        groupedColumnNames,
        columnsForFilteringAndSorting,
    };
};

export const getColumnTotalCells = <T,>(
    gridColumns: ColumnDescriptor[],
    data: T[],
    tableSummaryFormatters: Dictionary<ExportableGridSummaryFormatter>,
    calculateCustomSummary?: (field: keyof T, data: T[]) => number
) => {
    const columnDataFieldsInOrderForTotals = gridColumns.flatMap((col) => {
        if (col.children && col.children.length) {
            return col.children.map((ch: ColumnDescriptor) => ch.dataIndex);
        }
        return col.dataIndex;
    });

    const columnsInOrderForTotals = gridColumns.flatMap((col) => {
        if (col.children && col.children.length) {
            return col.children;
        }
        return col;
    });

    const columnTotals = getTableOrGroupSummaryData(
        data,
        gridColumns,
        tableSummaryFormatters,
        calculateCustomSummary
    );

    const getFormattedSummaryCell = (index: number) => {
        const columnInfo = columnsInOrderForTotals[index];
        const total = columnTotals[columnInfo.dataIndex];
        const renderer = tableSummaryFormatters[columnInfo.dataIndex]?.render;
        if (renderer) {
            return renderer(total);
        }

        return total;
    };

    const getAlignment = (index: number) => {
        return columnsInOrderForTotals[index]?.align ?? 'left';
    };

    return (
        <Table.Summary fixed>
            <Table.Summary.Row>
                {columnDataFieldsInOrderForTotals.map((_, index) => (
                    <Table.Summary.Cell
                        index={index}
                        align={getAlignment(index)}
                    >
                        {getFormattedSummaryCell(index)}
                    </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
        </Table.Summary>
    );
};
