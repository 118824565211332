import React, { useEffect, useState } from 'react';
import { Card, Space, Button, Checkbox, Table, Tooltip } from 'antd';
import { css } from 'emotion';
import { Category, NotificationPreferencesResponse } from 'waypoint-types';
import { updateUserNotificationPreferences } from 'waypoint-requests';
import { KeyedMutator } from 'swr';

const cardStyle = css`
    .ant-card-body {
        padding: 0;
        .ant-table-tbody > tr > td {
            border-bottom: none;
            padding: 3px;
        }

        .ant-table-tbody > tr > td {
            text-align: center;
            padding: 3px 16px;
        }

        .ant-table-thead > tr > th {
            text-align: center;
            font-weight: 700;
            background: none;
            border: none;
            &:before {
                width: 0px !important;
            }
        }

        .ant-table-thead > tr > th:first-child {
            text-align: left;
        }

        .ant-table-tbody > tr > td:first-child {
            text-align: left;
        }
    }
`;

interface DataType {
    key: string;
    notificationType: string;
    email: boolean;
    inApp: boolean;
}

enum NotificationType {
    CommentMentions = 'Comment Mentions',
    AddedAsAnAssignee = 'Added as an Assignee',
    AddedAsAReviewer = 'Added as a Reviewer',
    ReportStatusChange = 'Report Status Change',
}

const defaultData: DataType[] = [
    {
        key: '1',
        notificationType: NotificationType.CommentMentions,
        email: false,
        inApp: true,
    },
    {
        key: '2',
        notificationType: NotificationType.AddedAsAnAssignee,
        email: false,
        inApp: true,
    },
    {
        key: '3',
        notificationType: NotificationType.AddedAsAReviewer,
        email: false,
        inApp: true,
    },
    {
        key: '4',
        notificationType: NotificationType.ReportStatusChange,
        email: false,
        inApp: true,
    },
];

interface NotificationSettingsProps {
    onClose: () => void;
    preferences: NotificationPreferencesResponse | undefined;
    preferencesMutate: KeyedMutator<NotificationPreferencesResponse>;
    isLoadingPreferences: boolean;
    isNotificationPreferencesVisible: boolean;
}

const NotificationSettings = ({
    onClose,
    preferences,
    preferencesMutate,
    isLoadingPreferences,
    isNotificationPreferencesVisible,
}: NotificationSettingsProps) => {
    const [localPreferences, setLocalPreferences] =
        useState<DataType[]>(defaultData);

    const handleCheckChange = (record: DataType, channel: 'email') => {
        setLocalPreferences((prevData) =>
            prevData.map((item) =>
                item.key === record.key
                    ? { ...item, [channel]: !item[channel] }
                    : item
            )
        );
    };

    useEffect(() => {
        setSortedLocalPreferences();
    }, [preferences]);

    const columns = [
        {
            title: 'Notification Type',
            dataIndex: 'notificationType',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (_: unknown, record: DataType) => (
                <Checkbox
                    disabled={isLoadingPreferences}
                    checked={record.email}
                    onChange={() => handleCheckChange(record, 'email')}
                />
            ),
        },
        {
            title: 'In App',
            dataIndex: 'inApp',
            render: (_: unknown, record: DataType) => (
                <Checkbox checked={record.inApp} disabled />
            ),
        },
    ];

    const prepareUpdateData = (data: DataType[]) => {
        const categorySlugs: { [key: string]: string } = {
            'Comment Mentions': 'comments',
            'Added as an Assignee': 'assignee',
            'Added as a Reviewer': 'reviewer',
            'Report Status Change': 'status_changes',
        };

        const categories = data.map((item) => {
            const slug = categorySlugs[item.notificationType];
            return {
                slug,
                channels: [
                    {
                        slug: 'email',
                        enabled: item.email,
                    },
                ],
            };
        });

        return { notification_preferences: { categories } };
    };

    const setSortedLocalPreferences = () => {
        const order: string[] = [
            NotificationType.CommentMentions,
            NotificationType.AddedAsAnAssignee,
            NotificationType.AddedAsAReviewer,
            NotificationType.ReportStatusChange,
        ];

        const mappedData =
            preferences?.notification_preferences?.categories
                .map((category: Category, index: number) => ({
                    key: (index + 1).toString(),
                    notificationType: category.label,
                    email:
                        category.channels.find(
                            (ch: { slug: string }) => ch.slug === 'email'
                        )?.enabled ?? false,
                    inApp:
                        category.channels.find(
                            (ch: { slug: string }) => ch.slug === 'in_app'
                        )?.enabled ?? false,
                }))
                .sort(
                    (a, b) =>
                        order.indexOf(a.notificationType as string) -
                        order.indexOf(b.notificationType as string)
                ) ?? [];

        setLocalPreferences(mappedData);
    };

    const saveChanges = async () => {
        await updateUserNotificationPreferences(
            prepareUpdateData(localPreferences)
        );
        preferencesMutate();
        onClose();
    };

    return (
        <Card
            className={cardStyle}
            title={'Preferences'}
            style={{
                minWidth: '475px',
                maxHeight: '500px',
                overflowY: 'auto',
            }}
            extra={
                <Tooltip
                    placement="bottomRight"
                    title={
                        isNotificationPreferencesVisible
                            ? 'Notifications'
                            : 'Notification Preferences'
                    }
                >
                    <i
                        style={{ padding: 8, cursor: 'pointer' }}
                        className={
                            isNotificationPreferencesVisible
                                ? 'fa-solid fa-close fa-lg'
                                : 'fa-solid fa-cog fa-lg'
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    />
                </Tooltip>
            }
        >
            <div>
                <Table
                    bordered={false}
                    pagination={false}
                    columns={columns}
                    dataSource={localPreferences}
                />
            </div>
            <div
                style={{
                    borderTop: '1px solid #f0f0f0',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 12,
                    marginTop: 16,
                }}
            >
                <Space>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                        disabled={isLoadingPreferences}
                        loading={isLoadingPreferences}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoadingPreferences}
                        loading={isLoadingPreferences}
                        onClick={() => saveChanges()}
                        type="primary"
                    >
                        Save
                    </Button>
                </Space>
            </div>
        </Card>
    );
};

export default NotificationSettings;
