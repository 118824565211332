import React from 'react';
import { Skeleton } from 'antd';
import { css } from 'emotion';
import { Moment } from 'moment';
import { RangeSelectConfirm } from 'waypoint-react';

const dateRangeContainer = css`
    width: 100%;
    .ant-picker-range {
        width: 100%;
    }
`;

const CROSSTAB_RANGE_SELECT_ID = 'crosstab-range-select';

type CrosstabRangeSelectProps = {
    onChange: (range: [Moment, Moment] | null) => void;
    periodRange: [Moment, Moment] | null;
    setIsOpen: (value: boolean) => void;
    isOpen: boolean;
};

export const CrosstabRangeSelect = ({
    onChange,
    periodRange,
    setIsOpen,
    isOpen = false,
}: CrosstabRangeSelectProps) => {
    return (
        <div className={dateRangeContainer} id={CROSSTAB_RANGE_SELECT_ID}>
            {!periodRange ? (
                <Skeleton
                    loading={true}
                    active={true}
                    title={false}
                    paragraph={{ rows: 1 }}
                />
            ) : (
                <RangeSelectConfirm
                    open={isOpen}
                    data-test="crosstab-range-select"
                    onFocus={() => setIsOpen(true)}
                    onConfirm={(values) => {
                        setIsOpen(false);
                        if (values.length === 2) {
                            const [start, end] = values;
                            onChange([
                                start.clone().startOf('month'),
                                end.clone().endOf('month'),
                            ]);
                        } else {
                            console.warn(
                                'Crosstab date range did not update as there were',
                                values.length,
                                'values present'
                            );
                        }
                    }}
                    onCancel={() => setIsOpen(false)}
                    value={periodRange}
                    getCalendarContainer={() =>
                        document.getElementById(CROSSTAB_RANGE_SELECT_ID)
                    }
                />
            )}
        </div>
    );
};
