import React, { Component } from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import {
    PROPERTY_ATTRIBUTES_ERROR_TEXT,
    PERFORMANCE_OVERVIEW_TITLE,
    PERFORMANCE_OVERVIEW_EMPTY_TEXT,
} from 'components/analytics/portfolioSegmentation/constants';
import {
    PERFORMANCE_OVERVIEW_CHARTS,
    BAR_CHART,
    PIE_CHART,
    TABLE_CHART,
} from 'components/analytics/constants';
import PieChart from 'components/analytics/portfolioSegmentation/performanceOverview/charts/PieChart';
import BarChart from 'components/analytics/portfolioSegmentation/performanceOverview/charts/BarChart';
import PerformanceTable from 'components/analytics/portfolioSegmentation/performanceOverview/tables/PerformanceTable';
import {
    getGroupLabel,
    getMetricLabel,
} from 'components/analytics/portfolioSegmentation/utils';
import EmptyState from 'components/style/EmptyState';
import LoadingTable from 'components/style/LoadingTable';

const emptyContainter = css`
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translate(-50%, 50%);
`;
const topBarStyle = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
`;

const chartsContainerStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const topBarTexts = css`
    font-size: 1em;
    line-height: 12px;
    margin-bottom: 10px;
    color: ${theme.colors.grays.text};
    strong {
        color: #000;
    }
`;

const switchButtonStyle = css`
    font-size: 12px;
    font-family: 'Lato';
    line-height: 15px;
    color: ${theme.colors.blues.primary};
    padding: 6.5px 18px;
    border: 1px solid ${theme.colors.blues.primary};
    text-decoration: none;
    &.active,
    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: ${theme.colors.blues.primary};
    }
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: none;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
    }
`;
class PerformanceOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chart: PIE_CHART,
        };
        this.changeChart = this.changeChart.bind(this);
    }

    changeChart(chart) {
        this.setState({
            chart: chart.key,
        });
    }

    renderChart(chart) {
        const { config, data } = this.props;
        switch (chart) {
            case PIE_CHART: {
                return (
                    <PieChart
                        data={data}
                        group_by={config.group_by}
                        metric={config.metric}
                    />
                );
            }
            case BAR_CHART:
                return (
                    <BarChart
                        data={data}
                        group_by={config.group_by}
                        metric={config.metric}
                    />
                );
            case TABLE_CHART: {
                return (
                    <PerformanceTable
                        data={data}
                        group_by={config.group_by}
                        metric={config.metric}
                    />
                );
            }
            default:
                return (
                    <PerformanceTable data={data} groupBy={config.group_by} />
                );
        }
    }

    render() {
        const { performanceOverviews, config, error, isLoading } = this.props;
        const { chart } = this.state;

        if (error) {
            return <EmptyState headerText={PROPERTY_ATTRIBUTES_ERROR_TEXT} />;
        }

        if (isLoading) {
            return (
                <div data-test-loading-table>
                    <LoadingTable
                        rowCount={Math.round(window.innerHeight / 120) || 7}
                    />
                </div>
            );
        }

        if (!performanceOverviews) {
            return (
                <div className={emptyContainter}>
                    <EmptyState
                        icon={'./assets/img/overview_empty.png'}
                        headerText={PERFORMANCE_OVERVIEW_TITLE}
                        subheaderText={PERFORMANCE_OVERVIEW_EMPTY_TEXT}
                    />
                </div>
            );
        }

        return (
            <div>
                <div className={topBarStyle}>
                    <div>
                        <p className={topBarTexts}>
                            <strong>Metric:</strong>{' '}
                            {getMetricLabel(config.metric)}
                        </p>
                        <p className={topBarTexts}>
                            <strong>Group by:</strong>{' '}
                            {getGroupLabel(config.group_by)}
                        </p>
                    </div>
                    <div>
                        {PERFORMANCE_OVERVIEW_CHARTS.map((chartButton) => (
                            <a
                                onClick={() => this.changeChart(chartButton)}
                                key={chartButton.key}
                                className={`${switchButtonStyle} ${
                                    chart === chartButton.key ? 'active' : ''
                                }`}
                            >
                                {chartButton.name}
                            </a>
                        ))}
                    </div>
                </div>
                <div className={chartsContainerStyle}>
                    {this.renderChart(chart)}
                </div>
            </div>
        );
    }
}

export default PerformanceOverview;
