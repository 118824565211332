import React, { useMemo, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    Export,
    Grouping,
    GroupPanel,
    HeaderFilter,
    Scrolling,
    Summary,
    TotalItem,
    Item as ToolbarItem,
    Toolbar,
    Pager,
    Paging,
    ColumnChooser,
} from 'devextreme-react/data-grid';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import {
    CellInfoType,
    EntityProfileCard,
    PropertyInfoSummary,
} from 'waypoint-types';
import { prioritiesOptions } from 'components/annualPrioritiesAndObjectives/constants';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import { ExpandAndCollapseButton } from 'waypoint-react';
import {
    groupCell,
    getGroupedItemCount,
} from '../capital-projects/PlanningCapitalProjectUtils';
import { ExportingEvent } from 'devextreme/ui/data_grid';

interface PrioritiesObjectivesItemViewProps {
    properties: PropertyInfoSummary[];
    prioritiesObjectivesData: EntityProfileCard[] | undefined;
    entityCodes: string[];
}

const onExporting = async (e: ExportingEvent) => {
    await exportExcelFromDevExtremeDataGrid(e, {
        worksheetName: 'Priorities and Objectives',
        filename: 'PrioritiesAndObjectives.xlsx',
    });
};

const PrioritiesObjectivesItemView = ({
    properties,
    prioritiesObjectivesData,
}: PrioritiesObjectivesItemViewProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] =
        useState<boolean>(false);

    const getEntityNameByCode = (entityCode: string) =>
        properties?.find(
            (property: PropertyInfoSummary) => property.code === entityCode
        )?.name ?? '';

    const renderStatus = (status: string) => {
        const statusOption = prioritiesOptions.find(
            (option) => option.label === status
        );

        return (
            <div
                style={{
                    backgroundColor: statusOption?.color,
                    padding: 8,
                    borderRadius: 8,
                    color: '#FFF',
                    boxShadow: 'none',
                    textAlign: 'center',
                    fontSize: 14,
                }}
            >
                {statusOption?.label}
            </div>
        );
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const categoriesDict = {
        operations: 'Operations',
        financingAndOther: 'Financing and Other',
        leasing: 'Leasing',
        capitalProjects: 'Capital Projects',
        energyAndSustainability: 'Energy and Sustainability',
    };

    const statusDict = {
        open: 'Open',
        complete: 'Complete',
        atRisk: 'At Risk',
        inProgress: 'In Progress',
    };

    const tableHeight = window.innerHeight - 160;

    const groupCellPropertyName = (options: CellInfoType) => {
        return (
            <div>
                {options.value} ({getGroupedItemCount(options)} items)
            </div>
        );
    };

    const groupCellCategory = (options: CellInfoType) => {
        return (
            <div>
                {options.value} ({getGroupedItemCount(options)} items)
            </div>
        );
    };

    const dataReady = useMemo(
        () =>
            prioritiesObjectivesData?.map((item) => {
                return {
                    ...item,
                    entity_code: getEntityNameByCode(item.entity_code ?? ''),
                    section:
                        categoriesDict[
                            item.section as keyof typeof categoriesDict
                        ],
                    status:
                        statusDict[
                            item?.metadata?.[0].value as keyof typeof statusDict
                        ] ?? 'Open',
                };
            }),
        [prioritiesObjectivesData]
    );

    return (
        <ResponsiveBox>
            <Row />

            <Col ratio={1} />

            <Item>
                <Location row={0} col={0} colspan={1} />
                <DataGrid
                    dataSource={dataReady}
                    height={tableHeight}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    paging={{ enabled: false }}
                    columnMinWidth={50}
                    onContentReady={(e) =>
                        onContentReady({ e, toggleFunc: setExpandButtonEnable })
                    }
                    onExporting={onExporting}
                >
                    <HeaderFilter
                        visible={true}
                        allowSearch={true}
                        height={250}
                    />

                    <Column
                        dataField="entity_code"
                        caption="Property"
                        allowHeaderFiltering={true}
                        width={200}
                        groupCellRender={groupCellPropertyName}
                    />

                    <Column
                        dataField="year"
                        caption="Year"
                        allowHeaderFiltering={true}
                        width={75}
                        groupCellRender={(data) => groupCell(data, 'items')}
                    />

                    <Column
                        dataField="section"
                        caption="Category"
                        allowHeaderFiltering={true}
                        width={150}
                        groupCellRender={groupCellCategory}
                    />

                    <Column
                        caption="Item"
                        dataField="title"
                        alignment="left"
                        allowFiltering={false}
                        allowGrouping={false}
                    />

                    <Column
                        dataField="status"
                        caption="Status"
                        allowFiltering={true}
                        width={120}
                        cellRender={(data) => renderStatus(data.value)}
                        allowHeaderFiltering={true}
                        groupCellRender={(data) => groupCell(data, 'items')}
                    />

                    <Summary>
                        <TotalItem
                            column="Item"
                            summaryType="count"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                            displayFormat="{0} Items"
                        />
                    </Summary>
                    <Toolbar>
                        <ToolbarItem
                            location="after"
                            name="columnChooserButton"
                        />
                        <ToolbarItem name="exportButton" />
                        <ToolbarItem name="searchPanel" />
                        <ToolbarItem name="groupPanel" />
                        <ToolbarItem location="before">
                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </ToolbarItem>
                    </Toolbar>

                    <Grouping
                        autoExpandAll={expanded}
                        contextMenuEnabled={true}
                    />
                    <HeaderFilter allowSearch={true} visible={true} />

                    <Export enabled={true} allowExportSelectedData={false} />

                    <GroupPanel visible={true} />

                    <Paging enabled={true} />

                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[20, 40, 60, 80, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} items)"
                    />

                    <Export enabled={true} allowExportSelectedData={false} />

                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        height={500}
                        allowSearch={true}
                    />
                    <Scrolling />
                </DataGrid>
            </Item>
        </ResponsiveBox>
    );
};

export default PrioritiesObjectivesItemView;
