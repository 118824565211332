import { createSelector } from 'reselect';
import { get } from 'lodash';
import { selectClientDashboardConfiguration } from 'state/user/selectors';
import * as Sentry from '@sentry/react';

const idFilter = (_, props) => props.id;
const moduleFilter = (_, props) => props.module;
const selectDashboardConfigurations = (state) => state.dashboardConfigurations;

export const selectDashboardConfigurationById = createSelector(
    [idFilter, moduleFilter, selectDashboardConfigurations],
    (id, module, configs) => {
        const moduleConfigs = get(configs, module);
        if (!moduleConfigs) {
            Sentry.captureMessage(`Possible error with module specific dashboard selection or id. Provided props: id=${id}, module = ${module}, all dashboard configs = ${configs}.
            If module is undefined, you may not have passed the module prop to the selector.`);
        }
        return get(moduleConfigs, id);
    }
);

export const selectDefaultBalanceSheetOverviewDashboardId = createSelector(
    [selectClientDashboardConfiguration],
    (clientConfig) =>
        get(clientConfig, 'DEFAULT_BALANCE_SHEET_DASHBOARD_CONFIGURATION_ID')
);

export const selectDefaultFinancialOverviewDashboardId = createSelector(
    [selectClientDashboardConfiguration],
    (clientConfig) =>
        get(
            clientConfig,
            'DEFAULT_FINANCIAL_OVERVIEW_DASHBOARD_CONFIGURATION_ID'
        )
);
