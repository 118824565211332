import React, { Component } from 'react';
import { downloadPropertiesCSV } from 'components/analytics/portfolioSegmentation/requests';
import { selectedAttributes } from 'components/analytics/portfolioSegmentation/propertyAttributes/utils';
import { getFilenameFromResponse } from 'waypoint-utils';
import { saveAs } from 'file-saver';
import theme from 'config/theme';
import { toastr } from 'react-redux-toastr';

// TODO: Refactor this to use Download button in profile once merged. Also, ensure this works for performance overview.
export default class DownloadPortfolioSegmentationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
        };
    }

    download() {
        this.setState({ disabled: true });
        this.runDownloadRequest().then(() =>
            this.setState({ disabled: false })
        );
    }

    runDownloadRequest() {
        let filename;
        return downloadPropertiesCSV(this.props.tab, {
            attributes: selectedAttributes, // Ignored if on property values tab
            entityCodes: this.props.entityCodes,
            period: this.props.period,
        })
            .then((response) => {
                filename = getFilenameFromResponse(response);
                return response.blob();
            })
            .then((blob) => saveAs(blob, filename))
            .catch(() => toastr.error('Download Failed'));
    }

    render() {
        return (
            <i
                data-test-button
                className={'fa-solid fa-cloud-arrow-down'}
                size="2x"
                onClick={this.download.bind(this)}
                style={{
                    cursor:
                        this.state.disabled || this.props.disabled
                            ? 'not-allowed'
                            : 'pointer',
                    color:
                        (this.state.disabled || this.props.disabled) &&
                        theme.colors.grays.medium,
                    pointerEvents:
                        this.state.disabled || this.props.disabled
                            ? 'none'
                            : 'auto',
                }}
            />
        );
    }
}
