import React, {
    Children,
    cloneElement,
    FC,
    isValidElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { MapProps } from 'components/property-search/PropertyMap';
import { useDeepCompareEffectForMaps } from 'components/property-search/PropertyMapUtils';

export const GoogleMap: FC<MapProps> = ({
    onClick,
    onIdle,
    bounds,
    children,
    style,
    ...options
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ['click', 'idle'].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener('click', onClick);
            }

            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    const tryGetZoom = (map: google.maps.Map) => {
        try {
            return map.getZoom();
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        if (map && bounds) {
            map.fitBounds(bounds, {});
            map.setZoom(Math.min(12, tryGetZoom(map) ?? 5));
        }
    }, [map, bounds]);

    return (
        <>
            <div ref={ref} style={style} />
            {Children.map(children, (child) => {
                if (isValidElement(child)) {
                    // set the map prop on the child component
                    return cloneElement(child, { map });
                }
            })}
        </>
    );
};

export default GoogleMap;
