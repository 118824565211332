import { PROPERTY_CACHE_KEY, PROPERTY_GROUP_CACHE_KEY } from 'config/constants';
import { curry, isString, replace } from 'lodash';

export const addOldCacheKey = curry((s, id) => `${s}_${id}`);

export const removeOldCacheKey = (s) => {
    return isString(s) ? parseInt(s.split('_')[1]) : s;
};
export const addPropertyKey = addOldCacheKey(
    replace(PROPERTY_CACHE_KEY, '_', '')
);

export const addGroupKey = addOldCacheKey(
    replace(PROPERTY_GROUP_CACHE_KEY, '_', '')
);
