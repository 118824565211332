import { MoreOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Dropdown,
    MenuProps,
    Popconfirm,
    Tooltip,
    message,
} from 'antd';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useState } from 'react';
import {
    useGetGenericEntityReportMetadataNotes,
    useGetUserData,
} from 'waypoint-hooks';
import { deleteNote } from 'waypoint-requests';
import { Note, NoteReferenceType } from 'waypoint-types';
import LeasingPlanNotesModal from './LeasingPlanNotesModal';
import { stringSort } from 'utils/tables/sorters';

interface LeasingPlanNotesProps {
    entityCode: string;
    leasingPlanId: string;
    isPDFExport?: boolean;
}

export const LeasingPlanNotes = ({
    entityCode,
    leasingPlanId,
    isPDFExport,
}: LeasingPlanNotesProps) => {
    const [isNotesModalVisible, setIsNotesModalVisible] =
        useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);

    const { userId, isAdmin } = useGetUserData();

    const { data: notes, mutate: mutateNotes } =
        useGetGenericEntityReportMetadataNotes(
            entityCode,
            NoteReferenceType.LeasingPlan,
            leasingPlanId
        );

    const getFormattedNoteDate = (dateString: string) => {
        return formatInTimeZone(new Date(dateString), 'UTC', 'MM/dd/yyyy');
    };

    const onDeleteNote = async (noteId: string) => {
        if (!noteId || !notes) {
            return;
        }

        const updatedNotes = [...notes.filter((n) => n.id !== noteId)];

        await mutateNotes(
            async () => {
                try {
                    await deleteNote(noteId);
                    return updatedNotes;
                } catch (e) {
                    message.error('An error occurred while deleting note');

                    return notes;
                }
            },
            {
                optimisticData: updatedNotes,
                rollbackOnError: true,
            }
        );
    };

    const notesRender = () => {
        const getItems = (note: Note): MenuProps['items'] => {
            return [
                {
                    label: (
                        <div
                            onClick={() => {
                                setSelectedNote(note);
                                setIsNotesModalVisible(true);
                            }}
                        >
                            <div>Edit</div>
                        </div>
                    ),
                    key: '1',
                },
                {
                    label: (
                        <Popconfirm
                            placement="top"
                            title={'Are you sure you want to delete this item?'}
                            okType="danger"
                            okText="Delete"
                            onConfirm={() => onDeleteNote(note.id)}
                            cancelText="Cancel"
                        >
                            <div>Delete</div>
                        </Popconfirm>
                    ),
                    key: '2',
                },
            ];
        };

        return notes
            ?.sort((a: Note, b: Note) =>
                stringSort(a.timestamps.updated_at, b.timestamps.updated_at)
            )
            .map((note: Note) => {
                const canDeleteOrEdit = isAdmin || note?.author.id === userId;
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tooltip
                            placement="topLeft"
                            title={`Updated by ${
                                note?.author?.firstname ?? ''
                            } ${
                                note?.author?.lastname ?? ''
                            } on ${getFormattedNoteDate(
                                note.timestamps.updated_at
                            )}`}
                        >
                            <p
                                style={{
                                    textAlign: 'left',
                                    marginBottom: 0,
                                    padding: '4px 0',
                                    fontSize: '12px',
                                }}
                            >
                                {note.text}
                            </p>
                        </Tooltip>
                        {canDeleteOrEdit ? (
                            <Dropdown
                                placement={'bottomRight'}
                                menu={{ items: getItems(note) }}
                            >
                                <Button
                                    style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        ) : null}
                    </div>
                );
            });
    };

    if (isPDFExport) {
        return null;
    }

    return (
        <div style={{ flex: '1 1 auto', marginLeft: '15px' }}>
            <LeasingPlanNotesModal
                selectedNote={selectedNote}
                setSelectedNote={setSelectedNote}
                isVisible={isNotesModalVisible}
                setIsVisible={setIsNotesModalVisible}
                leasingPlanId={leasingPlanId}
                entityCode={entityCode}
                mutateReportNotes={mutateNotes}
            />
            <Card
                style={{
                    height: '177px',
                    border: '1px solid #e0e0e0',
                    borderRadius: 0,
                }}
                data-test="leasing-plan-notes"
                size="small"
                title="Notes"
                extra={
                    <Button
                        type="primary"
                        size="small"
                        onClick={() =>
                            setIsNotesModalVisible(!isNotesModalVisible)
                        }
                        style={{ fontSize: '12px' }}
                    >
                        <i
                            className="fa-solid fa-plus"
                            style={{ marginRight: '3px' }}
                        />
                        Note
                    </Button>
                }
            >
                <div style={{ maxHeight: '121px', overflowY: 'auto' }}>
                    {notesRender()}
                </div>
            </Card>
        </div>
    );
};
