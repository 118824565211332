import { Route } from 'react-router-dom';

import UserSettings from 'components/settings/UserSettings';
import Personal from 'components/settings/personal/Personal';

const UserRoutes = () => (
    <>
        <Route
            path="/settings/user/personal"
            render={() => (
                <UserSettings>
                    <Personal />
                </UserSettings>
            )}
        />
    </>
);

export default UserRoutes;
