import React, { useState, useEffect } from 'react';
import { Drawer, Divider } from 'antd';
import { SavedFiltersProvider, EntityAttributesProvider } from 'contexts';
import { PropertyCheckboxList } from './property-checkbox-list/PropertyCheckboxList';
import EditFilterButton from 'components/app/global-filter-drawer/EditFilterButton';
import FilterEditor from 'components/app/global-filter-drawer/fitler-editor/FilterEditor';
import FilterInfo from 'components/app/global-filter-drawer/FilterInfo';

const DEFAULT_LIST_HEIGHT = 400;

const MINIMUM_LIST_HEIGHT = 100;

const LIST_OFFSET_HEIGHT = 540;

interface GlobalFilterDrawerProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (value: boolean) => void;
}

const GlobalFilterDrawer = ({
    isDrawerOpen,
    setIsDrawerOpen,
}: GlobalFilterDrawerProps): JSX.Element => {
    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false);

    const [DYNAMIC_PROPERTY_LIST_HEIGHT, setDynamaicPropertyListHeight] =
        useState(DEFAULT_LIST_HEIGHT);

    const windowScreenHeight = window.innerHeight;

    useEffect(() => {
        setDynamaicPropertyListHeight(windowScreenHeight - LIST_OFFSET_HEIGHT);
    }, [windowScreenHeight]);

    return (
        <Drawer
            placement="left"
            zIndex={9999}
            width="25vw"
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen || false}
            closable={false}
            forceRender
        >
            <SavedFiltersProvider>
                <EntityAttributesProvider>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>Property Filters</h2>
                        <FilterInfo />
                        <EditFilterButton
                            handleOnClick={() => {
                                setIsEditorOpen(true);
                            }}
                        />
                        <Divider />
                    </div>
                    <PropertyCheckboxList
                        height={Math.max(
                            MINIMUM_LIST_HEIGHT,
                            DYNAMIC_PROPERTY_LIST_HEIGHT
                        )}
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                    />
                    {isEditorOpen && (
                        <FilterEditor
                            handleClose={() => {
                                setIsEditorOpen(false);
                            }}
                        />
                    )}
                </EntityAttributesProvider>
            </SavedFiltersProvider>
        </Drawer>
    );
};

export default GlobalFilterDrawer;
