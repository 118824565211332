import { SmileOutlined } from '@ant-design/icons';
import { ConfigProvider, Select } from 'antd';
import React from 'react';

interface MentionOptions {
    value: string | number;
    label: string;
}
interface CommentsFilterProps {
    selectAccountOptions: MentionOptions[];
    selectAccountValue: string | null;
    setSelectAccountValue: (value: string) => void;
    selectUserOptions?: MentionOptions[];
    selectUserValue?: number;
    setSelectUserValue: (value: number) => void;
}
export const CommentFilter = ({
    selectAccountOptions,
    selectAccountValue,
    setSelectAccountValue,
    selectUserOptions,
    selectUserValue,
    setSelectUserValue,
}: CommentsFilterProps) => {
    const emptyUserSelect = () => (
        <div style={{ textAlign: 'center' }}>
            <SmileOutlined style={{ fontSize: 20 }} />
            <p>No Users Mentioned</p>
        </div>
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    overflowX: 'scroll',
                }}
            >
                <ConfigProvider renderEmpty={emptyUserSelect}>
                    <Select
                        style={{
                            flexBasis: '50%',
                            flexGrow: '1',
                            borderBottom: '1px solid lightgrey',
                        }}
                        options={selectUserOptions}
                        value={selectUserValue}
                        bordered={false}
                        onChange={setSelectUserValue}
                        dropdownMatchSelectWidth={false}
                        placement={'bottomRight'}
                        placeholder="Filter by User"
                        allowClear
                    />
                </ConfigProvider>

                {selectAccountOptions.length ? (
                    <Select
                        style={{
                            flexBasis: '50%',
                            flexGrow: '1',
                            borderBottom: '1px solid lightgrey',
                        }}
                        options={selectAccountOptions}
                        value={selectAccountValue ?? undefined}
                        bordered={false}
                        onChange={setSelectAccountValue}
                        dropdownMatchSelectWidth={false}
                        placement={'bottomRight'}
                        placeholder="Filter by Account"
                        allowClear
                    />
                ) : null}
            </div>
        </>
    );
};
