import superagent from 'superagent';
// NOTE: Beware trailing slashes when writing API calls

export function get(url, disableAutoFill) {
    if (!url.includes('api/v') && !disableAutoFill) {
        url = 'api/v1/' + url;
    }

    return superagent.get(url).on('error', (err) => {
        throw err;
    });
}

/* eslint-disable camelcase */
// TODO (Nicholas): Send front end timings to google analytics
export const groupAPI = {
    createGroup(name) {
        return new Promise((resolve, reject) => {
            superagent
                .post('api/v1/entity-group')
                .type('application/json')
                .send({ name })
                .end((err, response) => {
                    return err ? reject(err) : resolve(response);
                });
        });
    },

    deleteGroup(entityGroupId) {
        return new Promise((resolve, reject) => {
            superagent
                .del(`/api/v1/entity-group/${entityGroupId}`)
                .end((err, response) => {
                    return err ? reject(err) : resolve(response);
                });
        });
    },

    renameGroup(groupId, name) {
        return new Promise((resolve, reject) => {
            superagent
                .put(`api/v1/entity-group/${groupId}`)
                .type('application/json')
                .send({ name })
                .end((err, response) => {
                    const { body } = response;

                    if (body.success !== true) {
                        reject(body);
                    }

                    return err ? reject(err) : resolve(body.data);
                });
        });
    },

    addEntityToGroup(entity_code, entity_group_id) {
        return new Promise((resolve, reject) => {
            superagent
                .post(`api/v1/entity-group/${entity_group_id}`)
                .type('application/json')
                .send({ entity_code })
                .end((err, response) => {
                    return err ? reject(err) : resolve(response);
                });
        });
    },

    removePropertyFromGroup(entity_code, entity_group_id) {
        return new Promise((resolve, reject) => {
            superagent
                .del(`api/v1/entity-group/${entity_group_id}/${entity_code}`)
                .end((err, response) => {
                    return err ? reject(err) : resolve(response);
                });
        });
    },
};
