import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import LeasingPlanCard from 'components/leasingPlan/LeasingPlanCard';

interface LeasingPlanEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
    narrativePosition?: string;
    narrativeText?: string;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const LeasingPlanEntityReportWidget = React.forwardRef<
    PDFExportable,
    LeasingPlanEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            isPDFExport,
        }: LeasingPlanEntityReportWidgetParams,
        ref
    ) => {
        const [isReadyToExport, setIsReadyToExport] = useState<boolean>(false);
        const leasingPlanGridRef = useRef<DataGrid<any, any>>(null);
        const renewalsGridRef = useRef<DataGrid<any, any>>(null);
        const newLeasesGridRef = useRef<DataGrid<any, any>>(null);

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return isReadyToExport;
            },
        }));

        return (
            <div style={{ marginRight: '10px' }}>
                <LeasingPlanCard
                    key={`${JSON.stringify(widgetSettings)}_${widgetId}`}
                    entityCode={entityCode}
                    isPropertyProfilePage={true}
                    dataGridRef={leasingPlanGridRef}
                    renewalsGridRef={renewalsGridRef}
                    newLeasesGridRef={newLeasesGridRef}
                    widgetSettings={widgetSettings}
                    widgetId={widgetId}
                    setIsReadyToExport={setIsReadyToExport}
                    isPDFExport={isPDFExport}
                />
            </div>
        );
    }
);
