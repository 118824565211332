import { css, cx } from 'emotion';
import { EntityReportWorkflowStatus } from 'waypoint-types';
import { workflowStatusOptions } from '../table/ComparativeIncomeStatementTableUtils';
import { Select, Tooltip } from 'antd';
import {
    APPROVED,
    OPEN,
    WORKFLOW_ASSIGNEE,
} from 'components/financials/comparative-income-statement/constants';
import { format } from 'date-fns';

const reportStatusSelect = css`
    .ant-select-selection-item {
        color: #fff !important;
    }
`;

interface WorkflowStatusProps {
    userWorkflowRole: () => string | null;
    displayReportStatus: boolean;
    workflowReportStatus?: EntityReportWorkflowStatus;
    updateReportStatus: (value: string) => void;
    getUserDisplayNameById: (value: number) => string;
    className?: string;
}

export const WorkflowStatus = ({
    userWorkflowRole,
    displayReportStatus,
    workflowReportStatus,
    updateReportStatus,
    getUserDisplayNameById,
    className,
}: WorkflowStatusProps) => {
    const renderWorkflowStatusOptions = (role: string) => {
        return workflowStatusOptions.map((statusOption) => (
            <Select.Option
                key={statusOption.value}
                value={statusOption.value}
                disabled={
                    role === WORKFLOW_ASSIGNEE &&
                    statusOption.value === APPROVED
                }
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'center',
                    }}
                >
                    <span>{statusOption.label}</span>
                </div>
            </Select.Option>
        ));
    };

    const getStatusSelectOption = (value?: string) => {
        const optionValue =
            value ?? workflowReportStatus?.report_status ?? OPEN;
        return workflowStatusOptions.filter((w) => w.value === optionValue)[0];
    };

    const userRole = userWorkflowRole();

    const getTooltipTitleElement = () => {
        if (!workflowReportStatus) {
            return null;
        }
        if (!workflowReportStatus.report_prior_status) {
            return <span>No activity on this report yet.</span>;
        }
        const userName = workflowReportStatus.last_updated_by
            ? getUserDisplayNameById(
                  Number(workflowReportStatus.last_updated_by)
              )
            : '';
        const currentStatusOption = getStatusSelectOption(
            workflowReportStatus.report_status
        );
        const priorStatusOption = getStatusSelectOption(
            workflowReportStatus.report_prior_status
        );

        if (userName) {
            return (
                <span>
                    {userName
                        ? `${userName} changed the status from `
                        : 'Status was changed from '}
                    <span style={{ color: priorStatusOption.color }}>
                        {priorStatusOption.label}
                    </span>
                    {` to `}
                    <span style={{ color: currentStatusOption.color }}>
                        {currentStatusOption.label}
                    </span>
                    {` on ${format(
                        new Date(
                            workflowReportStatus.timestamps.updated_at as Date
                        ),
                        'MMMM d, yyyy'
                    )}`}
                </span>
            );
        }
    };

    return (
        <>
            {displayReportStatus && workflowReportStatus && (
                <div
                    className={cx([className])}
                    data-test="workflow-report-status-test"
                >
                    <Tooltip
                        placement="top"
                        trigger="hover"
                        arrow
                        title={
                            !userRole
                                ? 'User must be an assignee or reviewer to update report status.'
                                : getTooltipTitleElement()
                        }
                        overlayStyle={{ fontSize: '12px' }}
                    >
                        <Select
                            key={reportStatusSelect}
                            bordered={false}
                            showArrow={false}
                            disabled={!userRole}
                            className={reportStatusSelect}
                            style={{
                                width: '160px',
                                boxShadow: 'none',
                                borderRadius: 8,
                                border: 'none',
                                color: '#fff',
                                backgroundColor: getStatusSelectOption().color,
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                            value={workflowReportStatus.report_status}
                            onChange={(value: string) =>
                                updateReportStatus(value)
                            }
                        >
                            {renderWorkflowStatusOptions(userRole ?? '')}
                        </Select>
                    </Tooltip>
                </div>
            )}
        </>
    );
};
