import { Select } from 'antd';
import React, { useState } from 'react';
import { EntityProfileCards } from 'waypoint-react';
import {
    AddItemButtonLocationOptions,
    EntityProfileCardsGroupInputsType,
    EntityProfileCardsGroupReduceType,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import { prioritiesOptions } from 'components/annualPrioritiesAndObjectives/constants';
import { SectionType } from 'waypoint-types';
import {
    containerSort,
    getRows,
    getUniqueYears,
} from 'waypoint-react/entity-profile-cards/EntityProfileCardUtils';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

interface AnnualPrioritiesAndObjectivesProps {
    entityCode: string;
    title?: string;
    enableYearFilter?: boolean;
    enableYearMetadata?: boolean;
    selectedYear?: number[];
    selectedCategories?: string[];
    isCollapse?: boolean;
    enableAddYearButton?: boolean;
    isRollupPage?: boolean;
    showReportWidgetOnly?: boolean;
    onYearMetadataUpdated?: (
        year: number,
        updatedYearMetadata: EntityProfileCardYearMetadata | null
    ) => void;
    onItemUpdate?: () => void;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    displayVertically?: boolean;
    hideStatus?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

export const ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE =
    'annualPrioritiesAndObjectives';

const prioritiesSuffix = 'Priorities & Objectives';

const categories = [
    { value: 'operations', label: 'Operations' },
    { value: 'leasing', label: 'Leasing' },
    { value: 'capitalProjects', label: 'Capital Projects' },
    { value: 'energyAndSustainability', label: 'Energy & Sustainability' },
    { value: 'financingAndOther', label: 'Financing and Other' },
];

const sections: SectionType = {
    operations: {
        title: 'Operations',
    },
    leasing: {
        title: 'Leasing',
    },
    capitalProjects: {
        title: 'Capital Projects',
    },
    energyAndSustainability: {
        title: 'Energy & Sustainability',
    },
    financingAndOther: {
        title: 'Financing & Other',
    },
};

export const AnnualPrioritiesAndObjectives = ({
    entityCode,
    title,
    enableYearFilter = true,
    enableYearMetadata = true,
    selectedYear,
    selectedCategories,
    isCollapse = true,
    enableAddYearButton = false,
    isRollupPage = false,
    showReportWidgetOnly = false,
    onYearMetadataUpdated,
    onItemUpdate,
    isPDFExport,
    includeNotesInPdf = false,
    hideStatus,
    setIsReadyToExport,
}: AnnualPrioritiesAndObjectivesProps) => {
    const [earlyYear, setEarlyYear] = useState<string>('');

    const getContainerConfig = (
        yearMetadata: EntityProfileCardYearMetadata[]
    ) => {
        const uniqueYears = getUniqueYears(yearMetadata);

        return uniqueYears.map((year) => {
            return {
                title: `${year} ${prioritiesSuffix}`,
                column: 0,
                deleteStrategy: {
                    type: 'yearMetadata',
                    get: (
                        items: EntityProfileCard[],
                        yearMetadata: EntityProfileCardYearMetadata[]
                    ) => {
                        return yearMetadata.find((ym) => ym.year === year);
                    },
                },

                shouldHideContainerIfEmpty: (
                    yearsFilter: number[]
                ): boolean => {
                    return !yearsFilter.includes(year);
                },
                getEmptyGroupState: (): EntityProfileCardsGroupReduceType => {
                    const result: EntityProfileCardsGroupReduceType = {};

                    Object.keys(sections).forEach((sectionKey) => {
                        const { title } = sections[sectionKey];
                        result[sectionKey] = {
                            title,
                            colSpan: 2,
                            items: [],
                            addItemButton: {
                                addItemTitleLabel: title,
                                addItemTooltip: `Add ${title}`,
                                getDefaultCard: () => {
                                    return {
                                        id: '',
                                        title: '',
                                        section: sectionKey,
                                        notes: '',
                                        sort_index: 0,
                                        year: year,
                                        type: ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE,
                                        metadata: [prioritiesOptions[0]],
                                    };
                                },
                            },
                        };
                    });
                    return result;
                },
                group: (
                    result: EntityProfileCardsGroupReduceType,
                    item: EntityProfileCard
                ) => {
                    result[item.section].items.push(item);

                    return result;
                },
                filter: (item: EntityProfileCard) => {
                    return item.year === year;
                },
                addItemButtonLocations: [
                    'group',
                ] as AddItemButtonLocationOptions,
                renderYearMetadataOptions: () => {
                    return [];
                },
                renderItemMetadataOptions: () => {
                    return prioritiesOptions.map((priorityOption) => (
                        <Select.Option value={priorityOption.value}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    justifyContent: 'center',
                                }}
                            >
                                <span>{priorityOption.label}</span>
                            </div>
                        </Select.Option>
                    ));
                },
                getYearMetadataValue: (
                    yearMetadata: EntityProfileCardYearMetadata[]
                ): EntityProfileCardYearMetadata | undefined => {
                    return yearMetadata.find((ym) => ym.year === year);
                },
                sort: (groupA: EntityProfileCardsGroupInputsType) => {
                    if (groupA.title === sections['operations'].title) {
                        return -1;
                    }

                    if (
                        groupA.title === sections['leasing'].title ||
                        groupA.title ===
                            sections['energyAndSustainability'].title
                    ) {
                        return 0;
                    }

                    return 1;
                },
            };
        });
    };

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PrioritiesAndObjectives}
        >
            <EntityProfileCards
                enableCategories
                enableStatusItems
                isRollupPage={isRollupPage}
                enableExport
                isCollapse={isCollapse}
                enableYearFilter={enableYearFilter}
                enableYearMetadata={enableYearMetadata}
                enableAddYearButton={enableAddYearButton}
                onYearMetadataUpdated={onYearMetadataUpdated}
                onItemUpdate={onItemUpdate}
                selectedYear={selectedYear}
                categories={categories}
                selectedCategories={selectedCategories}
                title={title ?? 'Priorities and Objectives'}
                defaultExpandedCategories={`${earlyYear} ${prioritiesSuffix}`}
                type={ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE}
                entityCode={entityCode}
                columns={1}
                rows={getRows}
                containerSort={containerSort}
                getContainerConfig={getContainerConfig}
                enabledFields={['title']}
                setEarlyYear={setEarlyYear}
                showReportWidgetOnly={showReportWidgetOnly}
                data-test="annual-priorities-and-objectives-card"
                isPDFExport={isPDFExport}
                includeNotesInPdf={includeNotesInPdf}
                hideStatus={hideStatus}
                setIsReadyToExport={setIsReadyToExport}
            />
        </PermissionedWrapper>
    );
};
