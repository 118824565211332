import { Button, message, Skeleton } from 'antd';
import { css } from 'emotion';
import { BudgetUploadEntry } from 'waypoint-types';
import { DisabledDashboard, FinancialTreeTable } from 'waypoint-react';
import {
    container,
    decorateBudgetUploadForAntD,
    getBudgetColumns,
} from '../BudgetUploadUtils';
import { useGetAccountGraph } from 'waypoint-hooks';
import { useGetLatestEntityBudget } from 'waypoint-hooks/data-access/useGetLatestEntityBudget';
import { submitBudget } from 'waypoint-requests';
import { useState } from 'react';
import { filterAccountGraph } from 'waypoint-utils';
import theme from 'config/theme';
import { HAS_BUDGET_DIFFERENCE } from 'waypoint-utils/account-graphs/constants';

interface BudgetUploadComparisonCardProps {
    entityCodes: string[];
    selectedEntityCode: string | null;
    setSelectedEntityCode: (value: string | null) => void;
    selectedSourceCode: string | null;
    setSelectedSourceCode: (value: string | null) => void;
    selectedBudgetYear: string | null;
    setSelectedBudgetYear: (value: string | null) => void;
    currentUploadBudget: BudgetUploadEntry[] | null;
    setCurrentUploadBudget: (value: BudgetUploadEntry[] | null) => void;
    currentUploadBudgetId: string | null;
    setCurrentUploadBudgetId: (value: string | null) => void;
}

const tableStyle = css`
    .ant-table-tbody > tr > td {
        color: ${theme.colors.black} !important;
    }
}
`;

const BudgetUploadComparisonCard = ({
    entityCodes,
    selectedEntityCode,
    setSelectedEntityCode,
    selectedSourceCode,
    setSelectedSourceCode,
    selectedBudgetYear,
    setSelectedBudgetYear,
    currentUploadBudget,
    setCurrentUploadBudget,
    currentUploadBudgetId,
    setCurrentUploadBudgetId,
}: BudgetUploadComparisonCardProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { data: accountGraphData, isLoading: isLoadingAccountGraph } =
        useGetAccountGraph({
            accountGraphCode: 'income_statement',
            entityCodes,
            selectedDataLevel: {
                stakeholder: null,
                percentageType: null,
            },
        });

    const { data: priorEntityBudget, isLoading: isLoadingLatestEntityBudget } =
        useGetLatestEntityBudget(
            selectedEntityCode,
            selectedSourceCode,
            selectedBudgetYear
        );

    const shouldShowComparisonGrid =
        currentUploadBudget &&
        selectedEntityCode &&
        selectedSourceCode &&
        selectedBudgetYear;

    const clearBudgetUpload = () => {
        setSelectedEntityCode(null);
        setSelectedSourceCode(null);
        setSelectedBudgetYear(null);
        setCurrentUploadBudget(null);
        setCurrentUploadBudgetId(null);
    };

    const onCancelClick = async () => {
        clearBudgetUpload();
    };
    const onSubmitClick = async () => {
        try {
            setIsSubmitting(true);
            await submitBudget(selectedEntityCode, currentUploadBudgetId);
            message.success('Budget submitted successfully');
        } catch (e) {
            message.error('Error submitting budget');
        } finally {
            clearBudgetUpload();
            setIsSubmitting(false);
        }
    };

    const renderBudgetGrid = () => {
        if (isLoadingAccountGraph || isLoadingLatestEntityBudget) {
            return <Skeleton />;
        }

        if (!accountGraphData || !priorEntityBudget) {
            return <DisabledDashboard />;
        }

        const isSmallScreen =
            window.innerWidth < 1024 || window.innerHeight < 900;
        const screenSizeOffset = isSmallScreen ? 50 : 75;
        const tableHeight = window.innerHeight - (400 + screenSizeOffset);

        return (
            <FinancialTreeTable
                size="small"
                bordered={false}
                height={tableHeight}
                data={filterAccountGraph(
                    decorateBudgetUploadForAntD(
                        accountGraphData?.children,
                        currentUploadBudget,
                        priorEntityBudget.entityBudgetUploadEntries
                    ),
                    [HAS_BUDGET_DIFFERENCE]
                )}
                expandAllRows={true}
                searchableKeys={['name', 'account_mapping_code']}
                columns={getBudgetColumns(
                    priorEntityBudget,
                    currentUploadBudgetId
                )}
                data-test="budget-upload-table"
                hideExpandableButtons={true}
                isEnablePagination={false}
                isReportWidget={false}
                isPDFExport={false}
                styleProps={tableStyle}
            />
        );
    };

    return (
        <div className={container}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid lightgrey',
                    padding: '0 -10px',
                }}
            >
                <h3 style={{ margin: '10px 0', padding: '0 10px' }}>Preview</h3>
                <div style={{ padding: '0 10px' }}>
                    <Button
                        disabled={!shouldShowComparisonGrid || isSubmitting}
                        style={{ marginRight: '5px' }}
                        onClick={onCancelClick}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSubmitClick}
                        disabled={!shouldShowComparisonGrid || isSubmitting}
                        type="primary"
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </div>
            </div>
            <div
                style={{ marginTop: '20px', padding: '0 10px', height: '100%' }}
            >
                {shouldShowComparisonGrid && renderBudgetGrid()}
            </div>
        </div>
    );
};

export default BudgetUploadComparisonCard;
