import { Wrapper } from '@googlemaps/react-wrapper';
import { Modal } from 'antd';
import { css } from 'emotion';
import React, { useState } from 'react';
import { connect, RootStateOrAny } from 'react-redux';

import { GoogleMap } from 'components/maps';
import { PropertyMapMarker } from 'components/property-search/PropertyMapMarker';
import { getPureId } from 'state/routing/selectors';
import { selectPropertiesById } from 'state/properties/selectors';
import { PropertyType } from 'waypoint-types';

const mapModal = css`
    .ant-modal-body {
        padding: 0;
    }
`;

const clickableTile = css`
    overflow: hidden;
    cursor: pointer;
    &:hover {
        box-shadow:
            rgba(0, 0, 0, 0.19) 0px 10px 20px,
            rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
`;

const PropertyProfileMap = ({
    property,
    height,
    widgetProperty,
}: {
    height: string;
    entityCode: string;
    property?: PropertyType;
    widgetProperty?: PropertyType;
}) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const propertyInfo = widgetProperty ?? property;
    if (
        typeof propertyInfo?.latitude !== 'number' ||
        typeof propertyInfo?.longitude !== 'number'
    ) {
        return null;
    }

    const mapPosition = {
        lat: propertyInfo.latitude,
        lng: propertyInfo.longitude,
    };

    const fullscreenModalHeight = Math.max(
        200,
        Math.floor(window.innerHeight * 0.95)
    );
    const fullscreenModalWidth = Math.max(
        800,
        Math.floor(window.innerWidth * 0.95)
    );

    return (
        <Wrapper apiKey={window.___GOOGLE_API_KEY}>
            <div
                className={clickableTile}
                style={{
                    height: `${height}`,
                    borderRadius: '7px',
                }}
            >
                <GoogleMap
                    style={{
                        height: `${height}`,
                        borderRadius: '7px',
                    }}
                    data-test="property-detail-mini-map-container"
                    center={mapPosition}
                    zoom={widgetProperty ? 14 : 10}
                    zoomControl={false}
                    fullscreenControl={false}
                    streetViewControl={false}
                    mapTypeControl={false}
                    draggable={false}
                    draggableCursor={'pointer'}
                    scrollwheel={false}
                    disableDoubleClickZoom={false}
                    onClick={() => setIsFullscreen(true)}
                >
                    <PropertyMapMarker
                        position={mapPosition}
                        title={propertyInfo.display_name}
                        data-test="property-detail-map-marker"
                    />
                </GoogleMap>
            </div>
            <Modal
                title=""
                footer={null}
                centered
                wrapClassName={mapModal}
                open={isFullscreen}
                onOk={() => setIsFullscreen(false)}
                onCancel={() => setIsFullscreen(false)}
                width={fullscreenModalWidth}
                data-test="property-detail-map-modal"
            >
                <GoogleMap
                    style={{
                        height: `${fullscreenModalHeight}px`,
                    }}
                    data-test="property-detail-map-modal-container"
                    center={mapPosition}
                    zoom={14}
                    zoomControl={true}
                    fullscreenControl={false}
                    streetViewControl={true}
                    mapTypeControl={true}
                >
                    <PropertyMapMarker
                        position={mapPosition}
                        title={propertyInfo.display_name}
                    />
                </GoogleMap>
            </Modal>
        </Wrapper>
    );
};

const mapState = (s: RootStateOrAny) => {
    const entityCode = getPureId(s);
    const properties = selectPropertiesById(s, { propertyIds: [entityCode] });

    return {
        property: properties[Object.keys(properties)[0]],
    };
};

export default connect(mapState)(PropertyProfileMap);
