import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import PlanningCapitalProjectsContainer from 'components/planning/capital-projects/PlanningCapitalProjectsContainer';
import { useGetSavedConfigurationById } from 'waypoint-hooks';

interface PlanningCapitalProjectsProps {
    entityCode: string;
    widgetActiveTab: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    narrativePosition?: string;
    isPDFExport?: boolean;
}

export const CapitalProjectContainerWidget = React.forwardRef<
    PDFExportable,
    PlanningCapitalProjectsProps
>(
    (
        {
            entityCode,
            widgetActiveTab,
            widgetId,
            widgetSettings,
            narrativePosition,
            isPDFExport,
        }: PlanningCapitalProjectsProps,
        ref
    ) => {
        const [isReadyForPDFExport, setIsReadyForPDFExport] =
            useState<boolean>(false);
        const dataGridRef = useRef<DataGrid<any, any>>(null);

        const { data: savedConfigData } = useGetSavedConfigurationById(
            widgetSettings?.savedConfigId ?? ''
        );

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !!savedConfigData && isReadyForPDFExport;
            },
        }));

        return (
            <PlanningCapitalProjectsContainer
                key={`${widgetId}_${savedConfigData?.id ?? ''}`}
                entityCode={entityCode}
                widgetActiveTab={widgetActiveTab}
                yearsSelectedForWidget={widgetSettings?.yearsSelected}
                savedConfigData={savedConfigData ?? null}
                dataGridRef={isPDFExport ? dataGridRef : undefined}
                hideRangePickerAndViews={true}
                setIsReadyForPDFExport={setIsReadyForPDFExport}
                isPDFExport={isPDFExport}
            />
        );
    }
);
