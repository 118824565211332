import React from 'react';
import { Select } from 'antd';
import { stringSort } from 'utils/tables/sorters';

interface SortSelectOptions {
    label: string;
    value: string;
}

interface SortByGroupSummarySortSelectProps {
    groupingOptions: SortSelectOptions[];
    sortExcludedColumns: string[];
    sortSelection: string | null;
    setSortSelection: (value: string | null) => void;
    sortOrderAscending: boolean;
    toggleSortOrder: () => void;
}

const SortByGroupSummarySortSelect = ({
    groupingOptions,
    sortExcludedColumns,
    sortSelection,
    setSortSelection,
    sortOrderAscending,
    toggleSortOrder,
}: SortByGroupSummarySortSelectProps) => {
    const validOptions = groupingOptions
        .filter((o) => !sortExcludedColumns.includes(o.value))
        .sort((a, b) => stringSort(b.label, a.label));

    if (sortSelection && !validOptions.find((o) => o.value === sortSelection)) {
        setSortSelection(null);
    }

    return (
        <div data-test="sort-by-group-summary-select">
            <Select
                dropdownMatchSelectWidth={false}
                style={{
                    marginRight: '10px',
                    marginLeft: '15px',
                }}
                options={validOptions}
                placeholder="Sort Grouped Data by"
                value={sortSelection ?? undefined}
                onChange={(value: string) => setSortSelection(value)}
            />
            <i
                className={
                    sortOrderAscending
                        ? 'fa-solid fa-sort-alpha-asc'
                        : 'fa-solid fa-sort-alpha-desc'
                }
                style={{ fontSize: '1.5em' }}
                onClick={toggleSortOrder}
            />
        </div>
    );
};

export default SortByGroupSummarySortSelect;
