import moment from 'moment';
import { toUnix, toISO, toEndOfMonth } from 'components/dates/utils';

export const getRangeOfMonthTimestamps = ({
    start,
    end,
}: {
    start: string;
    end: string;
}) => {
    const startMonth = moment(start);
    const endMonth = moment(end);
    const months = [];
    while (startMonth <= endMonth) {
        months.push(toUnix(toISO(toEndOfMonth(startMonth))));
        startMonth.add(1, 'month');
    }
    return months;
};
