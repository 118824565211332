import { Button, Modal, Select, Checkbox } from 'antd';
import { css } from 'emotion';
import moment, { Moment } from 'moment';
import { useGetMentionableUsers } from 'waypoint-hooks';
import {
    MentionableDataSource,
    Report,
    ReportSetting,
    UpdatedEntityReport,
} from 'waypoint-types';
import { DatePicker } from 'waypoint-react';
import {
    APPROVALS_SETTINGS_TYPE,
    REPORT_APPROVAL_TYPES,
    approvalTitles,
} from './report-settings/constants';
import { useEffect, useMemo, useState } from 'react';
import theme from 'config/theme';

interface ReportModalProps {
    onSave: () => void;
    onCancel: () => void;
    onDueDateChange: (date: moment.Moment | null) => void;
    onAssigneeChange: (value: string[]) => void;
    onReviewerChange: (value: string[], hierarchyIndex?: number) => void;
    onSendAssigneeNotificationChange: (value: boolean) => void;
    onSendReviewerNotificationChange: (value: boolean) => void;
    batchValues: UpdatedEntityReport;
    selectedEntityCodes: string[];
    selectedReport: Report | undefined;
    isWorkflowSteps: boolean;
}

const showCheckBox = (visible: boolean) => css`
    height: ${visible ? '32px' : '0px'} !important;
    opacity: ${visible ? '1' : '0'} !important;
    transition: 300ms all ease-out !important;
    margin-top: ${visible ? '6px' : '0px'} !important;
`;

const wrapper = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

const title = css`
    font-weight: bold;
    margin-right: 16px;
`;

export const ReportModal = ({
    onSave,
    onCancel,
    onDueDateChange,
    onAssigneeChange,
    onReviewerChange,
    onSendAssigneeNotificationChange,
    onSendReviewerNotificationChange,
    batchValues,
    selectedEntityCodes,
    selectedReport,
    isWorkflowSteps,
}: ReportModalProps) => {
    const mentionableUsers = useGetMentionableUsers(selectedEntityCodes, true);

    const userWorkflowRoleOptions = useMemo(() => {
        return mentionableUsers ?? [];
    }, [mentionableUsers]);

    const [availableUserOptions, setAvailableUserOptions] = useState<
        MentionableDataSource[]
    >(userWorkflowRoleOptions);

    useEffect(() => {
        const filteredOptions = userWorkflowRoleOptions.filter(
            (opt: MentionableDataSource) =>
                !batchValues.reviewer?.includes(opt.id as string)
        );
        setAvailableUserOptions(filteredOptions);
    }, [userWorkflowRoleOptions, batchValues.reviewer]);

    const approvalSettings = selectedReport?.reportSettings?.find(
        (settings: ReportSetting) =>
            settings.settings_type === APPROVALS_SETTINGS_TYPE
    );

    const approvalIsNotHierarchicalType =
        approvalSettings?.settings?.type !==
        REPORT_APPROVAL_TYPES.HierarchicalApproval;

    const approvalLevels: number[] = [
        ...Array(approvalSettings?.settings?.required_levels).keys(),
    ];

    const disabledDate = (current: Moment | null): boolean => {
        if (!selectedReport?.due_date) {
            return false;
        }

        return !!(
            current && current > moment(selectedReport?.due_date).endOf('day')
        );
    };

    const renderApprovals = () => {
        if (!approvalSettings || approvalIsNotHierarchicalType) {
            return (
                <div className={wrapper}>
                    <span className={title}>Reviewer</span>
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select reviewer"
                        style={{ width: '100%', marginTop: 6 }}
                        value={batchValues.reviewer}
                        onChange={(value) => onReviewerChange(value)}
                        filterOption={(input, option) => {
                            const optionToFilter = `${option?.label}` ?? '';
                            return optionToFilter
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }}
                        options={userWorkflowRoleOptions.map(
                            (user: MentionableDataSource) => ({
                                label: user.text,
                                value: user.id,
                            })
                        )}
                        data-test="report-reviewer-select"
                    />

                    {!isWorkflowSteps && (
                        <Checkbox
                            className={showCheckBox(
                                batchValues.reviewer.length > 0
                            )}
                            onChange={(e) =>
                                onSendReviewerNotificationChange(
                                    e.target.checked
                                )
                            }
                            data-test="report-reviewer-checkbox"
                        >
                            Notify Reviewers
                        </Checkbox>
                    )}
                </div>
            );
        }

        return (
            <>
                <h2 style={{ fontSize: '15px', padding: '13px 0 10px 0' }}>
                    Approvers (Hierarchy -{' '}
                    {approvalSettings?.settings?.required_levels} Required)
                </h2>
                {approvalLevels.map((approvalLevel: number) => {
                    return (
                        <div
                            key={`approval-level-${approvalLevel}`}
                            className={wrapper}
                        >
                            <span className={title}>
                                {approvalLevel + 1 ===
                                approvalSettings?.settings?.required_levels
                                    ? 'Final'
                                    : approvalTitles[approvalLevel]}{' '}
                                Approval
                                <span
                                    style={{
                                        color: theme.colors.red,
                                        visibility:
                                            !!batchValues.reviewer?.filter(
                                                (r) => r !== undefined
                                            ).length
                                                ? 'visible'
                                                : 'hidden',
                                    }}
                                >
                                    {' '}
                                    *
                                </span>
                            </span>
                            <Select
                                allowClear
                                showSearch
                                placeholder="(none)"
                                style={{ width: '100%', marginTop: 6 }}
                                value={batchValues.reviewer[approvalLevel]}
                                onChange={(value) =>
                                    onReviewerChange([value], approvalLevel)
                                }
                                filterOption={(input, option) => {
                                    const optionToFilter =
                                        `${option?.label}` ?? '';
                                    return optionToFilter
                                        .toLowerCase()
                                        .includes(input.toLowerCase());
                                }}
                                options={availableUserOptions.map(
                                    (user: MentionableDataSource) => ({
                                        label: user.text,
                                        value: user.id,
                                    })
                                )}
                                data-test="report-reviewer-select"
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <Modal
            title="Edit properties"
            open={true}
            onOk={onSave}
            closeIcon={<></>}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    disabled={
                        approvalSettings?.settings?.type ===
                            REPORT_APPROVAL_TYPES.HierarchicalApproval &&
                        !!batchValues.reviewer?.filter((r) => r !== undefined)
                            .length &&
                        batchValues.reviewer?.filter((r) => r !== undefined)
                            .length < approvalSettings.settings?.required_levels
                    }
                    onClick={onSave}
                >
                    Save
                </Button>,
            ]}
            data-testid="report-edition-modal"
        >
            <div className={wrapper}>
                <span className={title}>Due Date</span>
                <DatePicker
                    value={batchValues.dueDate}
                    style={{ width: '100%', marginTop: 6 }}
                    onChange={onDueDateChange}
                    disabledDate={disabledDate}
                    data-test="report-due-date-picker"
                />
            </div>

            <div className={wrapper}>
                <span className={title}>Assignee</span>
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select assignee"
                    style={{ width: '100%', marginTop: 6 }}
                    onChange={onAssigneeChange}
                    value={batchValues.assignee}
                    filterOption={(input, option) => {
                        const optionToFilter = `${option?.label}` ?? '';
                        return optionToFilter
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }}
                    options={userWorkflowRoleOptions.map(
                        (user: MentionableDataSource) => ({
                            label: user.text,
                            value: user.id,
                        })
                    )}
                    data-test="report-assignee-select"
                />

                {!isWorkflowSteps && (
                    <Checkbox
                        className={showCheckBox(
                            batchValues.assignee.length > 0
                        )}
                        onChange={(e) =>
                            onSendAssigneeNotificationChange(e.target.checked)
                        }
                        data-test="report-assignee-checkbox"
                    >
                        Notify Assignees
                    </Checkbox>
                )}
            </div>

            {renderApprovals()}
        </Modal>
    );
};
