/**
 * UTILITY FUNCTIONS
 */
import React from 'react';
import { mapKeys, toArray, map, keyBy, snakeCase } from 'lodash';
import { toastr } from 'react-redux-toastr';

export const formatNumber = (num) =>
    Number.parseFloat(num)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatForStore = (list) => {
    return keyBy(
        map(list, (l) => {
            return mapKeys(l, (_, key) => {
                if (key === 'value') {
                    return 'id';
                }
                if (key === 'label') {
                    return 'name';
                }
                if (key === 'isAllAccessList') {
                    return snakeCase(key);
                }
                return key;
            });
        }),
        'id'
    );
};

export const formatForDropdown = (list) => {
    return map(toArray(list), (l) => {
        return mapKeys(l, (value, key) => {
            if (key === 'id') {
                return 'value';
            }
            if (key === 'name') {
                return 'label';
            }
            return key;
        });
    });
};

export const buttonStyle = (width, height, color, backgroundColor) => {
    return {
        height,
        width,
        border: `1px ${color || '#00ade2'} solid`,
        color: `${color || '#00ade2'}`,
        alignSelf: 'flex-end',
        padding: '1px',
        marginRight: '3px',
        backgroundColor: backgroundColor || 'white',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '17px',
    };
};

// TODO (Daniel): deprecate
const toastOptions = {
    icon: <i className="fa-solid fa-cloud-arrow-down" />,
};

export const notifyUploadError = (filename) =>
    toastr.error(
        'Error',
        `Unable to upload your file "${filename}", please try again later. If the problem persists, please contact support.`,
        toastOptions
    );

export const notifyUploadDone = (filename) =>
    toastr.success(
        'Success!',
        `Your file "${filename}" was successfully uploaded.`,
        toastOptions
    );

export const notifyUploadProgress = () =>
    toastr.info(
        'File Uploading',
        'Please wait for the file to complete uploading before closing this window.',
        toastOptions
    );

export const notifyFileDeleted = (filename) =>
    toastr.success(
        'Success!',
        `Your file "${filename}" was successfully deleted.`,
        toastOptions
    );

export const notifyFileNotDeleted = (filename) =>
    toastr.error(
        'Error',
        `Your file "${filename}" could not be deleted, please try again later. If the problem persists, please contact support.`,
        toastOptions
    );
