import { ReportWorkflowRole, EntityReportWidgetListItem } from 'waypoint-types';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { connect, RootStateOrAny } from 'react-redux';
import { selectCurrentUser } from 'state/user/selectors';
import { message, Select } from 'antd';
import { WidgetTypes, workflowStatusOptions } from '../constants';
import { css } from 'emotion';
import { updateEntityReportWidgetStatus } from 'waypoint-requests';

interface EntityReportWorkflowStatusProps {
    entityReportWidget: EntityReportWidgetListItem | null;
    userId: number;
    entityReportWorkflowRows: ReportWorkflowRole[];
    onStatusChanged: (value: string | null) => void;
}

const reportStatusSelect = (color: string) => css`
    .ant-select-selection-item {
        color: #fff !important;
    }
    width: 135px;
    box-shadow: none;
    border-radius: 8px;
    border: none;
    color: #fff;
    background-color: ${color};
    text-align: center;
    justify-content: center;
`;

const EntityReportWorkflowStatus = ({
    entityReportWidget,
    userId,
    entityReportWorkflowRows,
    onStatusChanged,
}: EntityReportWorkflowStatusProps) => {
    if (
        !entityReportWidget ||
        entityReportWidget.widget_type === WidgetTypes.CoverPage
    ) {
        return null;
    }

    const reviewers = entityReportWorkflowRows.filter(
        (row) => row.report_role === WORKFLOW_REVIEWER
    );
    const assignees = entityReportWorkflowRows.filter(
        (row) => row.report_role === WORKFLOW_ASSIGNEE
    );

    const renderWorkflowStatusOptions = () => {
        return workflowStatusOptions.map((statusOption) => (
            <Select.Option key={statusOption.value} value={statusOption.value}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'center',
                    }}
                >
                    <span>{statusOption.label}</span>
                </div>
            </Select.Option>
        ));
    };
    const userWorkflowRole = () => {
        if (!userId || !entityReportWorkflowRows) {
            return null;
        }
        const isUserReviewer = reviewers.find((wr) => wr.user_id === userId);
        if (isUserReviewer) {
            return WORKFLOW_REVIEWER;
        }

        const isUserAssignee = assignees.find((wa) => wa.user_id === userId);

        if (isUserAssignee) {
            return WORKFLOW_ASSIGNEE;
        }

        return null;
    };

    const getStatusSelectOption = () => {
        return workflowStatusOptions.filter(
            (w) => w.value === entityReportWidget.status
        )[0];
    };

    const updateReportStatus = async (newStatus: string) => {
        try {
            const params = {
                reportId: entityReportWidget.report_id,
                entityReportWidgetId: entityReportWidget.entityReportWidgetId,
                status: newStatus,
            };
            onStatusChanged(newStatus);
            const response = await updateEntityReportWidgetStatus(params);
            message.success('Entity Report Widget status updated!');

            return response;
        } catch (e) {
            onStatusChanged(entityReportWidget.status ?? null);
            message.error(
                'An error occurred while updating entity report widget status'
            );
        }
    };

    return (
        <div data-test="entity-report-widget-status-test">
            <Select
                bordered={false}
                showArrow={false}
                disabled={!userWorkflowRole()}
                className={reportStatusSelect(getStatusSelectOption()?.color)}
                value={entityReportWidget.status}
                onChange={(value: string) => updateReportStatus(value)}
            >
                {renderWorkflowStatusOptions()}
            </Select>
        </div>
    );
};

const mapState = (s: RootStateOrAny) => {
    const { id: userId } = selectCurrentUser(s);

    return {
        userId,
    };
};

export default connect(mapState)(EntityReportWorkflowStatus);
