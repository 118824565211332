import React from 'react';
import { Skeleton, Card as AntDesignCard } from 'antd';
import { css } from 'emotion';
import CrosstabPivotGrid from './CrosstabPivotGrid';
import { DisabledDashboard } from 'waypoint-react';
import { CrosstabAccountNode } from './accounts';
import { Dictionary } from 'ts-essentials';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Moment } from 'moment';

const card = css`
    box-shadow:
        0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0px rgba(0, 0, 0, 0.04);

    .ant-card-body {
        padding-top: 14px;
    }
`;

export const CrosstabCard = ({
    accountNamesByMappingCode,
    dataSource,
    rootAccount,
    resetState,
    attributeSelection,
    setAttributeSelection,
    selectedAttribute,
    setIsFieldVisible,
    isFieldVisible,
    isLoading,
    modeSelection,
    setModeSelection,
    setIsOpenSettings,
    isOpenSettings,
    periodRange,
    setPeriodRange,
}: {
    accountNamesByMappingCode: Dictionary<string>;
    dataSource: PivotGridDataSource | null;
    rootAccount: CrosstabAccountNode | null;
    resetState: () => void;
    attributeSelection: string | null;
    setAttributeSelection: (value: string) => void;
    selectedAttribute: string | null;
    isFieldVisible: Dictionary<boolean>;
    setIsFieldVisible: (value: Dictionary<boolean>) => void;
    isLoading: boolean;
    modeSelection: string[];
    setModeSelection: (value: string[]) => void;
    setIsOpenSettings: (value: boolean) => void;
    isOpenSettings: boolean;
    periodRange: [Moment, Moment] | null;
    setPeriodRange: (value: [Moment, Moment] | null) => void;
}): JSX.Element => {
    const notAvailable = !rootAccount || isLoading || !dataSource;

    return (
        <>
            {isLoading ? (
                <Skeleton
                    active={true}
                    title={false}
                    paragraph={{ rows: 15, style: { margin: 30 } }}
                />
            ) : (
                <div style={{ padding: '25px 35px' }}>
                    <AntDesignCard
                        data-test="crosstab-card"
                        loading={false}
                        size={'default'}
                        className={card}
                    >
                        {notAvailable ? (
                            <DisabledDashboard />
                        ) : (
                            <>
                                <CrosstabPivotGrid
                                    modeSelection={modeSelection}
                                    setModeSelection={setModeSelection}
                                    accountNamesByMappingCode={
                                        accountNamesByMappingCode
                                    }
                                    dataSource={dataSource}
                                    resetState={resetState}
                                    rootAccount={rootAccount}
                                    attributeSelection={attributeSelection}
                                    setAttributeSelection={
                                        setAttributeSelection
                                    }
                                    selectedAttribute={selectedAttribute}
                                    isFieldVisible={isFieldVisible}
                                    setIsFieldVisible={setIsFieldVisible}
                                    isLoading={isLoading}
                                    periodRange={periodRange}
                                    setPeriodRange={setPeriodRange}
                                    setIsOpenSettings={setIsOpenSettings}
                                    isOpenSettings={isOpenSettings}
                                />
                            </>
                        )}
                    </AntDesignCard>
                </div>
            )}
        </>
    );
};
