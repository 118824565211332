import {
    AttributeFromAPI,
    EntityAttributesContextType,
} from 'contexts/entity-attributes/EntityAttributesTypes';
import { MutableRefObject, useContext, useRef } from 'react';
import { EntityAttributesContext } from 'contexts';
import { isEqual } from 'lodash';

export const useGetGroupableAttributes = (): AttributeFromAPI[] => {
    const entityAttributesContext =
        useContext<EntityAttributesContextType | null>(EntityAttributesContext);

    if (!entityAttributesContext) {
        throw Error(
            'useGetGroupableAttributes must be used within an EntityAttributesContextProvider'
        );
    }

    const groupableAttributeCodes: string[] = (
        entityAttributesContext?.data?.groupableAndFilterableAttributes || []
    )
        .filter((attribute) => attribute.groupBy)
        .map((attribute) => attribute.attributeCode);

    const groupableAttributes: AttributeFromAPI[] | undefined =
        entityAttributesContext?.data?.attributes.filter((attribute) =>
            groupableAttributeCodes?.includes(attribute.dataIndex)
        );

    const uniqueAttributes: AttributeFromAPI[] = Object.values(
        (groupableAttributes ?? []).reduce(
            (
                result: { [key: string]: AttributeFromAPI },
                attribute: AttributeFromAPI
            ) => {
                result[attribute.dataIndex] = attribute;

                return result;
            },
            {}
        )
    );

    const groupableAttributesRef: MutableRefObject<AttributeFromAPI[]> =
        useRef<AttributeFromAPI[]>(uniqueAttributes);

    if (!isEqual(groupableAttributesRef.current, uniqueAttributes)) {
        groupableAttributesRef.current = uniqueAttributes;
    }

    return groupableAttributesRef.current;
};
