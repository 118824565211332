import { percentageRenderer } from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    convertColumnsToAntd,
    headerClass,
} from './GridExportConversionUtils';
import { Table } from 'antd';
import { css } from 'emotion';

const rowClassOverride = css`
    font-size: 12px;
`;

export const leasingPlanBaseColumns: ColumnDescriptor[] = [
    {
        title: 'Leasing Activity',
        dataIndex: 'leasing_activity',
        key: 'leasing_activity',
        align: 'center',
        dataType: 'string',
        width: '150px',
    },
    {
        title: 'Sq. Ft.',
        dataIndex: 'sq_ft',
        key: 'sq_ft',
        align: 'center',
        dataType: 'number',
        width: '120px',
    },
    {
        title: 'Rentable Sq. Ft.',
        dataIndex: 'rentable_sq_ft',
        key: 'rentable_sq_ft',
        dataType: 'number',
        align: 'center',
        width: '120px',
    },
    {
        title: '%',
        dataIndex: 'percent_of_total',
        key: 'percent_of_total',
        align: 'center',
        dataType: 'string',
        width: '80px',
    },
];

export const LeasingPlanExportableGrid = ({
    data,
}: {
    data: {
        leasing_activity: string;
        sq_ft: number;
        rentable_sq_ft: number;
        percent_of_total: string;
    }[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        leasingPlanBaseColumns,
        true,
        null
    );

    return (
        <Table
            dataSource={data}
            style={{ width: '650px' }}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClassOverride}
        />
    );
};
