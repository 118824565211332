import { formatTableData } from 'components/profile/details/property-attributes/table/utils';
import { Table } from 'antd';
import { textRenderer } from 'utils/tables/renderers';
import {
    convertColumnsToAntd,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';

export const entityAttributesColumns = [
    {
        title: 'Attribute',
        dataIndex: 'title',
        key: 'title',
        render: textRenderer,
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: textRenderer,
    },
];

export const AttributesExportableGrid = ({
    data,
}: {
    data: {
        value: string | number;
        key: string;
        title: string;
        dataIndex: string;
    }[];
}) => {
    const orderedData = data.sort((a, b) => a.title.localeCompare(b.title));

    const { gridColumns } = convertColumnsToAntd(
        entityAttributesColumns,
        true,
        null
    );

    return (
        <Table
            size="small"
            dataSource={formatTableData(orderedData)}
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
        />
    );
};
