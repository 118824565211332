import memoizeOne from 'memoize-one';
import { compact, find, get, isEqual, isObject } from 'lodash';
import { PERFORMANCE_OVERVIEW_CONFIG } from 'components/analytics/portfolioSegmentation/constants';
import {
    currencyRenderer,
    integerRenderer,
    floatRenderer,
} from 'utils/tables/renderers';
import {
    GROUP_BY_LIST,
    KPI_LIST,
    CURRENCY,
    INTEGER,
    PERCENTAGE,
} from 'components/analytics/portfolioSegmentation/constants';

export function savePerformanceOverviewConfiguration(configuration) {
    localStorage.setItem(
        PERFORMANCE_OVERVIEW_CONFIG,
        JSON.stringify(configuration)
    );
}

export function getPerformanceOverviewConfiguration(config) {
    let performanceOverviewConfig = JSON.parse(
        localStorage.getItem(PERFORMANCE_OVERVIEW_CONFIG)
    );
    if (!performanceOverviewConfig) {
        performanceOverviewConfig = config;
    }
    return performanceOverviewConfig;
}

export const getMetricLabel = (value) => get(KPI_LIST, [value, 'label'], value);

export const formatMetricValue = (value) => {
    const type = get(KPI_LIST, [value, 'type']);

    switch (type) {
        case CURRENCY:
            return currencyRenderer(value);
        case INTEGER:
            return integerRenderer(value);
        case PERCENTAGE:
            return floatRenderer(value, 2);
        default:
            return integerRenderer(value, 0);
    }
};

export const getGroupLabel = (value) => {
    return get(find(GROUP_BY_LIST, { value }), 'label') || value;
};

// MANU: There is some payloads on performance overview which have a lot of items, this is for for those arrays
export const randomColor = () =>
    Math.floor(Math.random() * 16777215).toString(16);

export const getPerformanceOverviewColumns = (groupBy, metric) => [
    {
        title: getGroupLabel(groupBy),
        dataIndex: 'metric',
        type: 'text',
        width: '55%',
        ellipsis: true,
    },
    {
        title: getMetricLabel(metric),
        align: 'right',
        dataIndex: 'value',
        type: 'number',
        render: formatMetricValue,
    },
    {
        title: `${getMetricLabel(metric)} (%)`,
        align: 'right',
        dataIndex: 'percentage',
        type: 'number',
        render: (value) => floatRenderer(value, 2),
    },
];

export const dataToCharts = (data) =>
    data.map((d) => {
        const colorCode = `#${randomColor()}`;
        return {
            value: d.value,
            label: d.metric,
            color: colorCode,
        };
    });

export const transformDataToCharts = memoizeOne(dataToCharts, isEqual);
/**
 * transform the metrics for performance on the normal value label options for react select
 * @param {array} data - array of the options
 * @returns {array} with the accomodated value label for react select
 */
export const transformMetricsToSelectOptions = (data) =>
    compact(
        data.map((item) => {
            const metricsDefinition = get(KPI_LIST, [item.kpi_list]);
            if (!metricsDefinition) {
                return null;
            }

            return {
                value: item.kpi_list,
                label: metricsDefinition.label,
                ...item,
            };
        })
    );

/**
 * transform the group by options for performance on the normal value label options for react select
 * @param {array} data - array of the options
 * @returns {array} with the accomodated value label for react select
 */
export const transformGroupByToSelectOptions = (data) =>
    data.map((item) => ({
        value: item.group_by_code,
        label: item.group_by_name,
        ...item,
    }));

export const typeOfFilterAttribute = (type) => {
    const attributes = ['city', 'country', 'state'];

    if (attributes.some((attr) => type === attr)) {
        type = 'attribute';
    } else if (type === 'entity_type') {
        type = 'entity_type';
    } else {
        type = 'custom_attributes';
    }

    return type;
};

export const transformFiltersToAttributes = (activeFilters) => {
    const filters = {};
    activeFilters.forEach((filter) => {
        filters[filter.valueLabel] = filter.values;
    });
    return filters;
};

export const transformFiltersLabelsAndValues = (data) => {
    const labels = Object.keys(data).map((key) => ({
        label: data[key].displayName,
        value: key,
    }));
    const filters = {};
    Object.keys(data).map((key) => {
        filters[data[key].displayName] = data[key].filterValues;
        return filters;
    });
    return {
        labels,
        filters,
    };
};

export const isCorrectObjFormat = (item) =>
    isObject(item) && item.label && item.value;
