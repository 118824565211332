import { Card } from 'antd';
import { DataGrid } from 'devextreme-react';
import { Column, Paging } from 'devextreme-react/data-grid';
import { useGetEntityOwnershipPercentage } from 'waypoint-hooks/data-access/useGetEntityOwnershipPercentage';

interface PropertyProfileOwnershipParams {
    entityCode: string;
}

const OWNER = 'Owner';
export const PropertyProfileOwnership = ({
    entityCode,
}: PropertyProfileOwnershipParams) => {
    const { data: percentageTypeOptions } = useGetEntityOwnershipPercentage({
        entityCode,
    });

    percentageTypeOptions?.sort((a, b) => {
        if (a.percentageType === OWNER) {
            return -1;
        }

        if (b.percentageType === OWNER) {
            return 1;
        }

        return 0;
    });

    return (
        <Card
            title="Ownership"
            id="ownership"
            style={{ margin: '20px 0' }}
            data-test="property-ownership-card"
        >
            <DataGrid
                dataSource={percentageTypeOptions}
                data-test="property-ownership-grid"
                showBorders={true}
            >
                <Paging enabled={false} />
                <Column dataField="stakeholder" caption="Stakeholder" />
                <Column dataField="percentageType" caption="Type" />
                <Column
                    dataField="percentage"
                    caption="Percentage"
                    dataType={'number'}
                    format={{ type: 'percent', precision: 4 }}
                />
            </DataGrid>
        </Card>
    );
};
