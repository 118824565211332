import React, { useRef } from 'react';
import { Skeleton } from 'antd';
import { DataGrid } from 'devextreme-react';
import { DisabledDashboard } from 'waypoint-react';
import { Column, Pager, Selection } from 'devextreme-react/data-grid';
import { DocumentUpload } from 'waypoint-types';
import { formatDateInUserTimeZone } from 'utils/dates';

interface DocumentsTableProps {
    entityCode: string;
    documentsUploaded: DocumentUpload[] | undefined;
    isValidatingDocumentsUploaded: boolean;
    documentsUploadedError: boolean;
    handleSelectionChanged: (event: any) => void; // type mismatch
}

export const DocumentsTable = ({
    documentsUploaded,
    isValidatingDocumentsUploaded,
    documentsUploadedError,
    handleSelectionChanged,
}: DocumentsTableProps) => {
    const dataGridRef = useRef<DataGrid<any, any>>(null);

    if (isValidatingDocumentsUploaded) {
        return (
            <Skeleton loading={true} active={true} paragraph={{ rows: 1 }} />
        );
    }

    if (documentsUploadedError) {
        return (
            <DisabledDashboard text="There was an error loading the documents" />
        );
    }

    return (
        <DataGrid
            ref={dataGridRef}
            id="documents-table"
            dataSource={documentsUploaded}
            onSelectionChanged={(event) => handleSelectionChanged(event)}
            wordWrapEnabled={true}
            showBorders={true}
            width={'100%'}
            height={550}
        >
            <Selection mode="multiple" />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />
            <Column
                dataField="document_url"
                caption="Name"
                alignment={'left'}
                allowSorting={true}
            />
            <Column
                calculateCellValue={(rowData: DocumentUpload) => {
                    const urlSplit = rowData.document_url.split('.');
                    return urlSplit[urlSplit.length - 1].toUpperCase();
                }}
                dataField="document_type"
                caption="Type"
                alignment={'center'}
                allowSorting={true}
            />
            <Column
                calculateCellValue={(rowData: DocumentUpload) =>
                    `${rowData.uploadCreatedByUser.firstname} ${rowData.uploadCreatedByUser.lastname}`
                }
                caption="Added by"
                alignment={'center'}
                allowSorting={true}
            />
            <Column
                calculateCellValue={(rowData: DocumentUpload) =>
                    formatDateInUserTimeZone(rowData.timestamps.created_at)
                }
                caption="Added on"
                alignment={'center'}
                allowSorting={true}
            />
        </DataGrid>
    );
};
