import React from 'react';
import { Descriptions, Row as AntRow, Col as AntCol } from 'antd';
import {
    KPIMode,
    calculateKPI,
    getFormattedLabel,
    renderGrossValue,
} from '../PropertyProfileUtils';
import { DASH_DASH } from 'config/constants';
import { formatMoney } from 'utils/formatters';
import { PropertyInformationSectionProps } from '../types';
import {
    balanceSheetGavSource,
    propertyProfileGavSources,
    valuationsGavSource,
} from '../PropertyProfileConstants';
import { DebtAndValueKpis, PropertyDetailsProps } from 'waypoint-types';

interface PropertyValueProps extends PropertyInformationSectionProps {
    title?: React.ReactNode;
    periodSelected?: Date | null;
    valueAndDebtData: DebtAndValueKpis;
    clientKpiPreferences: string;
    leasePropertyData: PropertyDetailsProps | undefined;
    gavSource: propertyProfileGavSources;
    setSelectedValuations?: (selectedValuations: number) => void;
    asOfDate: Date | undefined;
}

const PropertyValue = ({
    entityCode,
    periodSelected,
    propertyInfo,
    valueAndDebtData,
    clientKpiPreferences,
    leasePropertyData,
    gavSource,
    title,
    asOfDate,
    setSelectedValuations,
}: PropertyValueProps) => {
    const labelStyle = {
        width: 160,
    };

    const navValue =
        Number(valueAndDebtData?.gross_value ?? 0) -
        Number(valueAndDebtData?.debt ?? 0);

    const shouldShowGainLoss =
        navValue &&
        valueAndDebtData?.gross_value &&
        valueAndDebtData.total_cost;

    const kpiPreference =
        clientKpiPreferences === KPIMode.PSF ? 'PSF' : 'Per Unit';

    const gavKpiValue = valueAndDebtData?.gross_value
        ? calculateKPI(
              clientKpiPreferences as KPIMode,
              valueAndDebtData?.gross_value,
              leasePropertyData
          )
        : DASH_DASH;

    const navKpiValue = valueAndDebtData?.gross_value
        ? calculateKPI(
              clientKpiPreferences as KPIMode,
              navValue,
              leasePropertyData
          )
        : DASH_DASH;

    const gavPeriodSelected =
        periodSelected && periodSelected.toString() !== ''
            ? periodSelected
            : asOfDate ?? new Date();

    const renderGavComponent = () => {
        return (
            <>
                {renderGrossValue({
                    gavSource,
                    gavSourceType: propertyProfileGavSources,
                    entityCode,
                    propertyInfo,
                    periodSelected: gavPeriodSelected,
                    asOfDate: undefined,
                    valueAndDebtData,
                    setSelectedValuations,
                })}{' '}
                | {gavKpiValue} {kpiPreference}
            </>
        );
    };

    const gavValue = valueAndDebtData?.gross_value
        ? renderGavComponent()
        : renderGrossValue({
              gavSource,
              gavSourceType: propertyProfileGavSources,
              entityCode,
              propertyInfo,
              periodSelected: gavPeriodSelected,
              asOfDate: undefined,
              valueAndDebtData,
          });

    const gainLossKpiValue = shouldShowGainLoss
        ? calculateKPI(
              clientKpiPreferences as KPIMode,
              navValue - Number(valueAndDebtData.total_cost),
              leasePropertyData
          )
        : DASH_DASH;

    const items = [
        {
            label: 'Total Cost',
            value: valueAndDebtData?.total_cost
                ? `${formatMoney(valueAndDebtData?.total_cost)} | ${calculateKPI(
                      clientKpiPreferences as KPIMode,
                      valueAndDebtData?.total_cost,
                      leasePropertyData
                  )} ${kpiPreference}`
                : DASH_DASH,
        },
        {
            label: 'Statement Value',
            value: valueAndDebtData?.statement_value
                ? `${formatMoney(valueAndDebtData?.statement_value)} | ${calculateKPI(
                      clientKpiPreferences as KPIMode,
                      valueAndDebtData?.statement_value,
                      leasePropertyData
                  )}`
                : DASH_DASH,
        },
        {
            label: `GAV ${
                gavSource === propertyProfileGavSources.VALUATIONS
                    ? `(${valuationsGavSource})`
                    : `(${balanceSheetGavSource})`
            }`,
            value: gavValue,
        },
        {
            label: 'NAV',
            value: valueAndDebtData?.gross_value
                ? `${formatMoney(navValue)} | ${navKpiValue} ${kpiPreference}`
                : DASH_DASH,
        },
        {
            label: 'Gain/Loss',
            value: shouldShowGainLoss
                ? `${formatMoney(
                      Number(navValue) -
                          Number(valueAndDebtData?.total_cost ?? 0)
                  )} | ${gainLossKpiValue} ${kpiPreference}`
                : DASH_DASH,
        },
    ];

    return (
        <>
            <AntRow align="stretch" wrap={true}>
                <AntCol>
                    <Descriptions
                        title={
                            <div
                                style={{
                                    backgroundColor: 'lightgray',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                }}
                            >
                                {title}
                            </div>
                        }
                        size="small"
                        bordered={false}
                        layout="horizontal"
                        column={{
                            xxl: 1,
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                        contentStyle={{
                            textAlign: 'left',
                            marginRight: 15,
                        }}
                        labelStyle={labelStyle}
                    >
                        {items.map(
                            (data, dataIndex) =>
                                data && (
                                    <Descriptions.Item
                                        label={getFormattedLabel(data.label)}
                                        span={1}
                                        key={dataIndex}
                                    >
                                        {data.value}
                                    </Descriptions.Item>
                                )
                        )}
                    </Descriptions>
                </AntCol>
                <div
                    style={{ paddingTop: 0, paddingBottom: 12, width: '100%' }}
                />
            </AntRow>
        </>
    );
};

export default PropertyValue;
