import { formatInTimeZone } from 'date-fns-tz';
import { IColumnProps } from 'devextreme-react/data-grid';
import dxDataGrid from 'devextreme/ui/data_grid';
import { SavedConfiguration, SavedConfigurationState } from 'waypoint-types';

export const createSavedConfigurationPayload = (e: SavedConfigurationState) => {
    const columnOptions = [
        'visible',
        'visibleIndex',
        'sortOrder',
        'sortIndex',
        'groupIndex',
    ];

    const columnsObject: { [x: string]: any } = {
        columns: {},
    };

    for (const column of e.columns) {
        const columnName = column.name;
        if (!columnName) {
            continue;
        }

        columnsObject.columns[columnName] = {};

        for (const option of columnOptions) {
            const columnOption = column[option as keyof IColumnProps];
            if (columnOption !== undefined) {
                columnsObject.columns[columnName][option] = columnOption;
            }
        }
    }
    if (e.filterValue) {
        columnsObject['filterValue'] = e.filterValue;
    }
    return columnsObject;
};

const getValidFilterParts = (filterValue: any[], columnNames: string[]) => {
    // case 1: single filter
    if (typeof filterValue[0] === 'string') {
        if (columnNames.includes(filterValue[0])) {
            return filterValue;
        }
        return [];
    }
    // case 2: multiple filters
    return filterValue.filter((f) => {
        if (Array.isArray(f)) {
            // for filter ops like 'not and' or 'not or', the first element of the
            // filter array is '!' and the actual filter array where the column name
            // is found is the second element
            const columnName = f[0] === '!' ? f[1][0] : f[0];
            return columnNames.includes(columnName);
        }
        return true;
    });
};

const convertDateString = (filterString: string, formatAsString: boolean) => {
    // saved date filters are getting stored with the timezone string at the end,
    // which gives us an easy way of identifying which strings need to be converted
    // to a Date object or formatted date string
    if (filterString.endsWith('.000Z')) {
        return formatAsString
            ? formatInTimeZone(filterString, 'UTC', 'yyyy-MM-dd')
            : new Date(filterString);
    }
    return filterString;
};

const checkDateComparisonFilter = (
    filterValue: string | any[],
    formatAsString: boolean
) => {
    // any date filters stored as strings need to be converted to dates
    // or, for PDF antd exports, properly formatted strings
    if (Array.isArray(filterValue)) {
        const lastIndex = filterValue.length - 1;
        const lastElement = filterValue[lastIndex];
        if (
            Array.isArray(lastElement) &&
            lastElement.every((le) => typeof le === 'string')
        ) {
            filterValue[lastIndex] = lastElement.map((le) =>
                convertDateString(le, formatAsString)
            );
        } else if (typeof lastElement === 'string') {
            return filterValue.map((fv) =>
                convertDateString(fv, formatAsString)
            );
        }
        return filterValue;
    }

    if (typeof filterValue === 'string') {
        return convertDateString(filterValue, formatAsString);
    }

    return filterValue;
};

export const processValidFilters = (
    filterValue: any,
    columnNames: any[],
    formatAsString = false
) => {
    const validFilters = getValidFilterParts(filterValue, columnNames);
    return validFilters.map((vf) =>
        checkDateComparisonFilter(vf, formatAsString)
    );
};

export const applyStoredConfiguration = (
    dataGrid: dxDataGrid,
    storedConfig: SavedConfiguration,
    hiddenColumns?: string[] // don't let saved cause columns that should be hidden to appear
) => {
    if (!storedConfig?.filters_json?.grid_config?.columns) {
        return null;
    }

    const config = storedConfig.filters_json.grid_config.columns;
    const columnCount = dataGrid.columnCount();

    const columnNames = [];

    for (let i = 0; i < columnCount; i++) {
        const columnName = dataGrid.columnOption(i, 'name');
        if (!columnName) {
            continue;
        }
        columnNames.push(columnName);
        if (hiddenColumns && hiddenColumns.includes(columnName)) {
            config[columnName].visible = false;
        }
        dataGrid.columnOption(columnName, config[columnName]);
    }

    if (storedConfig?.filters_json?.grid_config?.filterValue) {
        // remove any filters applied to columns that no longer exist
        const validFilters = processValidFilters(
            storedConfig.filters_json.grid_config.filterValue,
            columnNames
        );
        dataGrid.option('filterValue', validFilters);
    }
};
