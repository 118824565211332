import React from 'react';
import ReactFC from 'react-fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import fusiontheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import FusionCharts from 'fusioncharts';
import { chartBuilder } from 'components/charts';
import {
    KPISortByOptions,
    KPISortDirections,
    propertyDetailsValues,
    PropertyDetailsValuesType,
} from 'components/leases/constants';
import { stringSort, numericalSort } from 'utils/tables/sorters';
import {
    PropertyDetailsProps,
    PropertyDetailsPropsWithTotals,
} from 'waypoint-types';

charts(FusionCharts);
fusiontheme(FusionCharts);

interface ComparisonKpiChartProps {
    propertyDetails: PropertyDetailsPropsWithTotals[];
    leftAxis: keyof PropertyDetailsValuesType;
    rightAxis: keyof PropertyDetailsValuesType;
}

interface ComparisonKpiChartSortProps extends ComparisonKpiChartProps {
    sortAxis: KPISortByOptions;
    sortDirection: KPISortDirections;
}

export const buildChartConfiguration = ({
    propertyDetails,
    leftAxis,
    rightAxis,
}: ComparisonKpiChartProps) => {
    // CATEGORIES AND SERIES
    const categories: { label: string }[] = propertyDetails.map((property) => ({
        label: property?.property_name ?? '',
    }));

    const nonMoneyAttributes = [
        propertyDetailsValues.total_units,
        propertyDetailsValues.vacant_area,
        propertyDetailsValues.vacant_units,
        propertyDetailsValues.wale,
        propertyDetailsValues.occupancy_rate,
        propertyDetailsValues.lease_count,
        propertyDetailsValues.rentable_sq_ft,
        propertyDetailsValues.occupied_units,
        propertyDetailsValues.occupied_sq_ft,
    ];

    const leftAxisSeries = {
        seriesName: propertyDetailsValues[leftAxis],
        color: '#4888B8',
        data: propertyDetails.map((property) => ({
            value: property?.[leftAxis],
        })),
    };
    const rightAxisSeries = {
        seriesName: propertyDetailsValues[rightAxis],
        color: '#EAAB63',
        parentYAxis: 'S',
        renderAs: 'line',
        lineThickness: 5,
        data: propertyDetails.map((property) => ({
            value: property?.[rightAxis],
        })),
    };

    // CHART CONFIG
    const chart = chartBuilder();
    chart.type('scrollcombidy2d');
    chart.data();
    chart.dataset([leftAxisSeries, rightAxisSeries]);
    chart.categories([{ category: categories }]);
    chart.height('450');
    chart.style({
        exportFormats: 'PNG|PDF|JPG|SVG',
        numVisiblePlot: 15,
        flatScrollBars: '1',
        scrollheight: 10,
        sFormatNumberScale: 1,
        numberPrefix: !nonMoneyAttributes.includes(
            propertyDetailsValues[leftAxis]
        )
            ? '$'
            : '',
        sNumberPrefix: !nonMoneyAttributes.includes(
            propertyDetailsValues[rightAxis]
        )
            ? '$'
            : '',
        numberSuffix:
            propertyDetailsValues[leftAxis] ===
            propertyDetailsValues.occupancy_rate
                ? '%'
                : '',
        sNumberSuffix:
            propertyDetailsValues[rightAxis] ===
            propertyDetailsValues.occupancy_rate
                ? '%'
                : '',
        numDivLines: 5,
        pYAxisName: propertyDetailsValues[leftAxis],
        sYAxisName: propertyDetailsValues[rightAxis],
        theme: 'fusion',
        legendIconScale: 1,
        legendItemFontSize: 14,
        showLegend: '1',
        forceDecimals: '1',
        drawCrossLine: '1',
        decimals: '1',
        sDecimals: '1',
        baseFontSize: '14',
        caption: 'Leasing KPIs',
    });
    return chart.config;
};

const ComparisonKpiChart = ({
    propertyDetails,
    leftAxis,
    rightAxis,
    sortAxis,
    sortDirection,
}: ComparisonKpiChartSortProps): JSX.Element => {
    const sortPropertyDetailsData = (
        a: PropertyDetailsPropsWithTotals,
        b: PropertyDetailsPropsWithTotals
    ) => {
        if (sortAxis === KPISortByOptions.ALPHABETICAL) {
            return stringSort(b.property_name, a.property_name);
        }

        const leftOrRightAxis =
            sortAxis === KPISortByOptions.LEFT_AXIS ? leftAxis : rightAxis;

        const first = sortDirection === KPISortDirections.ASCENDING ? a : b;
        const second = sortDirection === KPISortDirections.ASCENDING ? b : a;

        return (
            numericalSort(first[leftOrRightAxis], second[leftOrRightAxis]) ||
            stringSort(b.property_name, a.property_name)
        );
    };

    const propertyDetailsDataForChart: PropertyDetailsPropsWithTotals[] =
        propertyDetails.sort(sortPropertyDetailsData).map((property) => ({
            ...property,
            occupancy_rate: property.occupancy_rate * 100,
        }));

    const chartConfig = buildChartConfiguration({
        propertyDetails: propertyDetailsDataForChart,
        leftAxis,
        rightAxis,
    });
    return (
        <div>
            <ReactFC {...chartConfig} data-test="expiration-schedule-chart" />
        </div>
    );
};

export default ComparisonKpiChart;
