import { toISO } from 'components/dates/utils';

export const formatTableData = (data) => {
    return data.map((item) => {
        if (item.key === 'acquisitiondate' && item.value !== null) {
            item.value = toISO(item.value);
        }
        return item;
    });
};
