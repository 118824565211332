import { Table } from 'antd';
import { currencyRenderer, integerRenderer } from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    GridDataRow,
    SavedConfigColumn,
    applySavedConfigSortingToGridData,
    applySavedFiltersToGridData,
    convertColumnsToAntd,
    getColumnTotalCells,
    getTableOrGroupSummaryData,
    groupHeaderRowClass,
    headerClass,
    rowClass,
    sortDataByColumnKey,
} from './GridExportConversionUtils';
import {
    CapitalProjectGrid,
    SavedConfiguration,
    SelectOptions,
} from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { getRowCostTotal } from 'components/planning/capital-projects/PlanningCapitalProjectUtils';
import { calculateCapitalCustomSummary } from './PlanningCapitalProjectsTableExportableGrid';
import { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { getPropertyName } from 'waypoint-utils/entity';

interface PlanningCapitalProjectPlanExportGridProps {
    capitalPlanData: CapitalProjectGrid[];
    savedConfig: SavedConfiguration | null;
    columnYears: number[];
    filteredYears: number[];
    setIsReadyForPDFExport: (value: boolean) => void;
    propertyOptions: SelectOptions[];
}

export interface CapitalProjectGridWithGroupHeaderFlag
    extends CapitalProjectGrid {
    isGroupHeader?: boolean;
    children?: CapitalProjectGridWithGroupHeaderFlag[];
}

export const planningCapitalProjectPlanBaseColumns = (
    columnYears: number[],
    propertyOptions: SelectOptions[]
) => {
    const baseColumns = [
        {
            title: 'Project',
            dataIndex: 'name',
            dataType: 'string',
            key: 'name',
            align: 'left',
            summaryType: 'count',
            showInGroupedRow: true,
        },
        {
            title: 'Property',
            dataIndex: 'entity_code',
            dataType: 'string',
            key: 'entity_code',
            align: 'left',
            render: (
                value: number | string,
                rowData: CapitalProjectGridWithGroupHeaderFlag
            ) => {
                return rowData?.isGroupHeader && typeof value === 'number'
                    ? ''
                    : getPropertyName(propertyOptions, value as string);
            },
        },
        {
            title: 'Category',
            dataIndex: 'category_value',
            dataType: 'string',
            key: 'category_value',
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            dataType: 'string',
            key: 'description',
            align: 'left',
            cssClass: 'wordWrapColumn',
        },
        {
            title: 'Subcategory',
            dataIndex: 'subcategory_value',
            dataType: 'string',
            key: 'subcategory_value',
            align: 'center',
        },
        {
            title: 'Priority',
            dataIndex: 'priority_value',
            dataType: 'string',
            key: 'priority_value',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status_value',
            dataType: 'string',
            key: 'status_value',
            align: 'center',
        },
        {
            title: 'Incl. in UW',
            dataIndex: 'underwriting',
            dataType: 'boolean',
            key: 'underwriting',
            align: 'center',
            render: capitalize,
        },
        {
            title: 'Escalatable',
            dataIndex: 'is_escalatable',
            dataType: 'boolean',
            key: 'is_escalatable',
            align: 'center',
            render: capitalize,
        },
        {
            title: 'Energy Savings',
            dataIndex: 'energy_saving',
            dataType: 'boolean',
            key: 'energy_saving',
            align: 'center',
            render: capitalize,
        },
    ];

    const yearColumns = columnYears.map((year) => ({
        title: year.toString(),
        dataIndex: `monthly_costs_${year}`,
        key: `monthly_costs_${year}`,
        dataType: 'number',
        render: currencyRenderer,
        summaryType: 'sum',
        showInGroupedRow: true,
    }));

    const budgetColumns = [
        {
            title: 'Total Budget',
            dataIndex: 'total_budget',
            key: 'total_budget',
            dataType: 'number',
            render: currencyRenderer,
            summaryType: 'sum',
            showInGroupedRow: true,
        },
        {
            title: 'Budget / SF',
            dataIndex: 'budget_psf',
            key: 'budget_psf',
            align: 'right',
            dataType: 'number',
            render: currencyRenderer,
        },
    ];

    return [...baseColumns, ...yearColumns, ...budgetColumns];
};

const defaultVisibleColumns = [
    'name',
    'category_value',
    'description',
    'total_budget',
    'budget_psf',
];

const capitalPlanTableSummaryFormatters = (columnYears: number[]) => {
    const baseFormatters: Dictionary<ExportableGridSummaryFormatter> = {
        name: {
            summaryType: 'count',
            render: (value: number) => {
                return `${value} Projects`;
            },
        },
        total_budget: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        budget_psf: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        rentable_square_footage: {
            summaryType: 'custom',
            render: integerRenderer,
        },
    };
    for (const year of columnYears) {
        baseFormatters[`monthly_costs_${year}`] = {
            summaryType: 'sum',
            render: currencyRenderer,
        };
    }
    return baseFormatters;
};

const capitalPlanGroupTreeBuilder = (
    capitalPlans: CapitalProjectGrid[],
    gridColumns: ColumnDescriptor[],
    columnYears: number[],
    groupingKeys: (keyof CapitalProjectGrid)[],
    blankFields: string[] = [],
    savedConfigColumns?: Dictionary<SavedConfigColumn>
): CapitalProjectGridWithGroupHeaderFlag[] => {
    if (!groupingKeys.length) {
        return capitalPlans;
    }

    const groupingKey = groupingKeys[0];
    const groupedCapitalPlans = capitalPlans.reduce(
        (dict, cp: CapitalProjectGrid) => {
            let cpField = cp[groupingKey] as string;
            if (cpField === null || cpField === undefined) {
                cpField = '';
            }
            if (!Object.keys(dict).includes(cpField)) {
                dict[cpField] = [cp];
                return dict;
            }
            dict[cpField].push(cp);
            return dict;
        },
        {} as Dictionary<CapitalProjectGrid[]>
    );

    const groupedCapitalPlansData = [];
    for (const entry of Object.entries(groupedCapitalPlans)) {
        const groupRow = {
            [groupingKey]: entry[0],
            isGroupHeader: true,
        };

        const groupTotals = getTableOrGroupSummaryData(
            entry[1],
            gridColumns,
            capitalPlanTableSummaryFormatters(columnYears),
            calculateCapitalCustomSummary
        );

        const innerRowBlankFields = blankFields.reduce((dict, bf) => {
            dict[bf] = '';
            return dict;
        }, {} as Dictionary<string>);

        groupedCapitalPlansData.push({
            ...groupTotals,
            ...innerRowBlankFields,
            ...groupRow,
            children:
                groupingKeys.length > 1
                    ? capitalPlanGroupTreeBuilder(
                          entry[1],
                          gridColumns,
                          columnYears,
                          groupingKeys.slice(1),
                          groupingKeys,
                          savedConfigColumns
                      )
                    : entry[1].map((cp: CapitalProjectGrid) => {
                          return {
                              ...cp,
                              ...innerRowBlankFields,
                          };
                      }),
        });
    }

    const dataType =
        gridColumns.find((gc) => gc.dataIndex === groupingKey)?.dataType ??
        'string';

    const sortOrder = savedConfigColumns
        ? savedConfigColumns[groupingKey]?.sortOrder ?? 'asc'
        : 'asc';

    return sortDataByColumnKey(
        dataType,
        groupingKey,
        sortOrder,
        groupedCapitalPlansData as GridDataRow[]
    ) as CapitalProjectGridWithGroupHeaderFlag[];
};

const capitalPlanWithColumnYearAndValueData = (
    capitalPlanData: CapitalProjectGrid[],
    columnYears: number[],
    filteredYears: number[]
) => {
    const gridWithYearColumnAndValueData = capitalPlanData.map((cp) => {
        const columnYearData = columnYears.reduce(
            (dict, year) => {
                dict[`monthly_costs_${year}`] = getRowCostTotal(
                    cp,
                    'budget',
                    year
                );
                return dict;
            },
            {} as Dictionary<number | string>
        );
        columnYearData['total_budget'] = getRowCostTotal(
            cp,
            'budget',
            null,
            filteredYears
        );
        columnYearData['category_value'] = cp.category?.value ?? '';
        columnYearData['subcategory_value'] = cp.subcategory?.value ?? '';
        columnYearData['priority_value'] = cp.priority?.value ?? '';
        columnYearData['status_value'] = cp.status?.value ?? '';
        return { ...cp, ...columnYearData };
    });
    return gridWithYearColumnAndValueData;
};

export const PlanningCapitalPlanExportableGrid = ({
    capitalPlanData,
    savedConfig,
    columnYears,
    filteredYears,
    setIsReadyForPDFExport,
    propertyOptions,
}: PlanningCapitalProjectPlanExportGridProps): JSX.Element => {
    const [filteredCapitalPlanData, setFilteredCapitalPlanData] = useState<
        CapitalProjectGrid[] | null
    >(null);

    columnYears.map((year) =>
        defaultVisibleColumns.push(`monthly_costs_${year}`)
    );

    // get the base column structure for antd and apply any relevant saved config settings
    const { gridColumns, groupedColumnNames, columnsForFilteringAndSorting } =
        convertColumnsToAntd(
            planningCapitalProjectPlanBaseColumns(
                columnYears,
                propertyOptions
            ) as ColumnDescriptor[],
            false,
            savedConfig,
            defaultVisibleColumns
        );

    // apply saved config filters to data
    useMemo(async () => {
        if (!savedConfig?.filters_json?.grid_config?.filterValue) {
            setFilteredCapitalPlanData(capitalPlanData);
            return;
        }
        const filteredData = await applySavedFiltersToGridData(
            capitalPlanData,
            savedConfig,
            columnsForFilteringAndSorting,
            true
        );
        setFilteredCapitalPlanData(filteredData);
    }, [capitalPlanData]);

    if (!filteredCapitalPlanData) {
        return <></>;
    }

    const capitalPlanWithYearColumns = capitalPlanWithColumnYearAndValueData(
        filteredCapitalPlanData,
        columnYears,
        filteredYears
    );

    // apply saved config sorting to data
    const sortedCapitalPlans = applySavedConfigSortingToGridData(
        capitalPlanWithYearColumns,
        columnsForFilteringAndSorting,
        savedConfig
    );

    const processedGroupColumnNames = groupedColumnNames.map((name) =>
        name.replace('.', '_')
    );

    // apply saved config grouping to data
    // P.S. yes, this looks weird passing groupedColumnNames twice,
    // but it's needed bc of the recursion in this function
    const processedGridData = capitalPlanGroupTreeBuilder(
        sortedCapitalPlans as CapitalProjectGrid[],
        columnsForFilteringAndSorting,
        columnYears,
        processedGroupColumnNames as (keyof CapitalProjectGrid)[],
        processedGroupColumnNames,
        savedConfig?.filters_json?.grid_config?.columns
    );

    setIsReadyForPDFExport(true);

    return (
        <Table
            dataSource={
                processedGridData as CapitalProjectGridWithGroupHeaderFlag[]
            }
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={(row) => {
                return row?.isGroupHeader ? groupHeaderRowClass : rowClass;
            }}
            expandable={{
                defaultExpandAllRows:
                    savedConfig?.filters_json?.local_config?.expanded ?? false,
                expandIcon: () => {
                    return null;
                },
            }}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    sortedCapitalPlans,
                    capitalPlanTableSummaryFormatters(columnYears),
                    calculateCapitalCustomSummary
                )
            }
        />
    );
};
