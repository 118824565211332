import { useMemo } from 'react';
import { css } from 'emotion';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { GRAY } from 'config/colors';
import { AttributesCard } from 'components/attributes/card/AttributesCard';
import useSWR from 'swr';
import { getAttributes } from 'waypoint-requests';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const pageStyle = css`
    display: block;
    background-color: ${GRAY};
    width: 100%;
`;

const AttributesDashboard = (): JSX.Element => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const { data, error } = useSWR('/api/attributes', getAttributes, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    });

    const entities = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.entities.filter(
            (x) =>
                typeof x.entity_code === 'string' &&
                entityCodes.includes(x.entity_code)
        );
    }, [data, entityCodes]);

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Attributes}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <AttributesCard
                    key="1"
                    attributes={{
                        data: entities,
                        columns: data?.attributes ?? [],
                    }}
                    error={error}
                    isLoading={!data && !error}
                />
            </div>
        </PermissionedWrapper>
    );
};

export default AttributesDashboard;
