export const proto = {
    GENERATE: '@@proto/GENERATE',
};

export const browser = {
    SIZE_EVENT: '@@browser/SIZE_EVENT',
    TOGGLE_SIDEBAR: '@@browser/SIZE_EVENT',
    TOPBAR_HEIGHT: '@@browser/TOPBAR_HEIGHT',
    PAGE_TITLE: '@@browser/SIZE_EVPAGE_TITLEENT',
};

export const user = {
    GET_CLIENT_OBJ: '@@user/GET_CLIENT_OBJ',
    GET_USER_OBJ: '@@user/GET_USER_OBJ',
    GET_HEARTBEAT: '@@user/GET_HEARTBEAT',
    RESET_FILTERS: '@@user/RESET_FILTERS',
    SET_USER_CONFIG: '@@user/SET_USER_CONFIG',
    SET_FILTER: '@@user/SET_FILTER',
    SET_IMAGE: '@@user/SET_IMAGE',
    SET_NOTIFICATION: '@@user/SET_NOTIFICATION',
    SET_ACTIVE_ACCOUNT_GROUPS: '@@user/SET_ACTIVE_ACCOUNT_GROUPS',
    SET_ACTIVE_FILTER_CODE: '@@user/SET_ACTIVE_FILTER_CODE',
    SET_ACTIVE_ACCOUNT_TAB: '@@user/SET_ACTIVE_ACCOUNT_TAB',
    SET_ACCOUNT_TYPE_LEVEL: '@@user/SET_ACCOUNT_TYPE_LEVEL',
    SET_DECIMAL_DISPLAY: '@@user/SET_DECIMAL_DISPLAY',
    SET_NEGATIVE_VALUE: '@@user/SET_NEGATIVE_VALUE',
    TOGGLE_LOADING_PROPERTIES: '@@user/TOGGLE_LOADING_PROPERTIES',
};

export const users = {
    LOAD: '@@users/LOAD',
    DEACTIVATE: '@@users/DEACTIVATE',
    ACTIVATE: '@@users/ACTIVATE',
    LOAD_USER: '@@users/LOAD_USER',
};

export const clientCategories = {
    LOAD: '@@clientCategories/LOAD',
};

export const properties = {
    MERGE: '@@properties/MERGE',
    SET_ACCESS_LIST: '@@properties/SET_ACCESS_LIST',
    SET_ASSET_FILTER: '@@properties/SET_ASSET_FILTER',
    UPDATE_ENTITY_PHOTO: '@@properties/UPDATE_ENTITY_PHOTO',
};

export const favorites = {
    ADD_FAVORITE: '@@favorites/ADD_FAVORITE',
    REMOVE_FAVORITE: '@@favorites/REMOVE_FAVORITE',
};

export const opportunities = {
    LOAD: '@@opportunities/LOAD',
    DELETE: '@@opportunities/DELETE',
};

export const tags = {
    LOAD: '@@tags/LOAD',
    DELETE: '@@tags/DELETE',
};

export const images = {
    LOAD: '@@images/LOAD',
    DELETE: '@@images/DELETE',
};

export const attachments = {
    LOAD: '@@attachments/LOAD',
    DELETE: '@@attachments/DELETE',
};

export const comments = {
    LOAD: '@@comments/LOAD',
    DELETE: '@@comments/DELETE',
};

export const actionItems = {
    LOAD: '@@actionItems/LOAD',
    DELETE: 'actionItems/DELETE',
};

export const explanationTypes = {
    LOAD: '@@explanationTypes/LOAD',
};

export const suites = {
    LOAD: '@@suites/LOAD',
};

export const leases = {
    LOAD_LEASES: '@@leases/LOAD_LEASES',
    LOAD_GROUP_LEASE_STATISTICS: '@@leases/LOAD_GROUP_LEASE_STATISTICS',
    LOAD_PROPERTY_LEASE_STATISTICS: '@@leases/LOAD_PROPERTY_LEASE_STATISTICS',
};

export const dates = {
    LOAD_AS_OF_DATE: '@@dates/LOAD_AS_OF_DATE',
};

export const tenants = {
    LOAD: '@@tenants/LOAD',
    LOAD_GROUP_TENANTS: '@@tenants/LOAD_GROUP_TENANTS',
    LOAD_GROUP_TENANTS_METADATA: '@@tenants/LOAD_GROUP_TENANTS_METADATA',
    LOAD_PROPERTY_TENANTS: '@@tenants/LOAD_PROPERTY_TENANTS',
    LOAD_PROPERTY_TENANT_STATISTICS:
        '@@tenants/LOAD_PROPERTY_TENANT_STATISTICS',
    LOAD_GROUP_TENANT_STATISTICS: '@@tenants/LOAD_GROUP_TENANT_STATISTICS',
    LOAD_TENANT_IDS_BY_PROPERTY_GROUP:
        '@@tenants/LOAD_TENANT_IDS_BY_PROPERTY_GROUP',
    LOAD_TENANT_IDS_BY_PROPERTY: '@@tenants/LOAD_TENANT_IDS_BY_PROPERTY',
};

export const dashboardConfigurations = {
    LOAD: '@@dashboardConfigurations/LOAD',
};
