import React, { useCallback, useEffect, useState } from 'react';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import useSWR, { mutate } from 'swr';
import {
    getAllReportMetadataByEntityCodes,
    getAsOfDates,
    getWorkflowRolesByEntityCodes,
} from 'waypoint-requests';
import VarianceReportStatusBanner from './VarianceReportStatusBanner';
import {
    getByThresholdBarChartConfig,
    getStatusBreakdownDoughnutChartConfig,
    getUserRoleBreakdownDoughnutChartConfig,
} from '../VarianceReportStatusCharts';
import {
    EntityReportWorkflowRole,
    IDataGrid,
    ReportMetadata,
    ThresholdRule,
    WorkflowRole,
    SelectedVarianceRowKeys,
    UpdateVarianceReport,
} from 'waypoint-types';
import { toastr } from 'react-redux-toastr';
import {
    useGetClientAndEntityAttributeValues,
    useGetMentionableUsers,
    useGetSelectedFilteredEntityCodes,
    useGetThresholdRules,
    useGetUserData,
} from 'waypoint-hooks';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { Dictionary } from 'ts-essentials';
import VarianceReportStatusTable from './VarianceReportStatusTable';
import { DisabledDashboard } from 'waypoint-react';
import { toEndOfMonth, toISO } from 'components/dates/utils';
import {
    getUsernameById,
    isUserInReviewers,
} from 'components/financials/variance-report-status/VarianceReportStatusUtils';
import { css } from 'emotion';
import { message, Button, Modal, Checkbox, Select } from 'antd';
import { useSessionStorage } from 'usehooks-ts';
import { batchUpdateWorkflowRoles } from 'waypoint-requests';
import { PropertyDetailAttributeEnum } from 'components/propertyProfile/PropertyProfileConstants';

interface AsOfDateAccounting {
    label: string;
    last_loaded_at: string;
    period_end: string;
    period_start: string;
}

interface AsOfDateResponse {
    accounting: AsOfDateAccounting[];
    leasing: {
        last_loaded_at: string;
    };
}

interface UnassignedCounts {
    assignee: number;
    reviewer: number;
}

const chartContainerStyle = (isCollapsed: boolean) => ({
    height: isCollapsed ? 0 : '100%',
    overflow: isCollapsed ? 'hidden' : 'auto',
    backgroundColor: '#fff',
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 6px 10px 0px rgba(0, 0, 0, 0.04)`,
    marginBottom: '10px',
});

const showCheckBox = (visible: boolean) => css`
    height: ${visible ? '32px' : '0px'} !important;
    opacity: ${visible ? '1' : '0'} !important;
    transition: 300ms all ease-out !important;
    margin-top: ${visible ? '6px' : '0px'} !important;
`;

const gridContainerStyle = (isCollapsed: boolean) => {
    if (!isCollapsed) {
        return '';
    }

    return css`
        .dx-item.dx-box-item:nth-child(2) {
            height: ${isCollapsed ? '0' : 'auto'};
            flex: 0 !important;
        }
    `;
};

const footerStyle = (isFooterVisible: boolean) => css`
    position: absolute;
    bottom: ${isFooterVisible ? 0 : -80}px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: rgb(216 220 222) 0px 1px 0px inset;
    display: flex;
    z-index: 4;
    transition: bottom 0.3s ease-in-out;
`;

const alignStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const wrapper = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

const title = css`
    font-weight: bold;
    margin-right: 16px;
`;

const VarianceReportStatusContainer = () => {
    const { userId: currentUserId, isAdmin } = useGetUserData();
    const [dataGrid, setDataGrid] = useState<IDataGrid | null>(null);
    const [reportMetadata, setReportMetadata] = useState<
        ReportMetadata[] | null
    >(null);

    const [reportMetadataByEndDate, setReportMetadataByEndDate] = useState<
        ReportMetadata[] | null
    >(null);

    const [workflowAssigneeCounts, setWorkflowAssigneeCounts] =
        useState<Dictionary<number> | null>(null);

    const [workflowReviewerCounts, setWorkflowReviewerCounts] =
        useState<Dictionary<number> | null>(null);

    const [unassignedCounts, setUnassignedCounts] =
        useState<UnassignedCounts | null>(null);

    const [workflowAssignees, setWorkflowAssignees] = useState<Dictionary<
        WorkflowRole[]
    > | null>(null);

    const [workflowReviewers, setWorkflowReviewers] = useState<Dictionary<
        WorkflowRole[]
    > | null>(null);

    const [selectedRole, setSelectedRole] = useState<string>(WORKFLOW_ASSIGNEE);

    const [thresholdRules, setThresholdRules] = useState<
        ThresholdRule[] | null
    >(null);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [batchValues, setBatchValues] = useState<UpdateVarianceReport>({
        assignee: [],
        reviewer: [],
        selectedVarianceReports: [],
        sendAssigneeNotification: false,
        sendReviewerNotification: false,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    const [periodEnd, setPeriodEnd] = useSessionStorage<string | null>(
        'lastPeriodEndSelected',
        null
    );

    const [isLoadingAsOfDate, setIsLoadingAsOfDate] = useState<boolean>(true);

    const userWorkflowRoleOptions = useGetMentionableUsers() ?? [];
    const usersWithStrictEntityAccess =
        useGetMentionableUsers(batchValues.selectedVarianceReports, true) ?? [];

    const onSelectionChanged = useCallback(
        async ({ selectedRowKeys }: Dictionary<SelectedVarianceRowKeys[]>) => {
            if (selectedRowKeys.length === 0) {
                setIsFooterVisible(false);
                resetSelection();
                return;
            }
            setIsFooterVisible(true);
            const selectedVarianceReports = selectedRowKeys.map(
                (r: SelectedVarianceRowKeys) => r.entityCode
            );

            setBatchValues({
                ...batchValues,
                selectedVarianceReports,
            });
        },
        []
    );

    const resetSelection = () => {
        setBatchValues({
            assignee: [],
            reviewer: [],
            selectedVarianceReports: [],
            sendAssigneeNotification: false,
            sendReviewerNotification: false,
        });
    };

    const onCancel = () => {
        setModalVisible(false);
        dataGrid?.instance.clearSelection();
        resetSelection();
        onSelectionChanged({ selectedRowKeys: [] });
    };

    const onEdit = () => {
        setModalVisible(true);
    };

    const onAssigneeChange = (value: string[]) => {
        setBatchValues({ ...batchValues, assignee: value });
    };

    const onReviewerChange = (value: string[]) => {
        setBatchValues({
            ...batchValues,
            reviewer: value,
        });
    };

    const onSendAssigneeNotificationChange = (value: boolean) => {
        setBatchValues({
            ...batchValues,
            sendAssigneeNotification: value,
        });
    };

    const onSendReviewerNotificationChange = (value: boolean) => {
        setBatchValues({
            ...batchValues,
            sendReviewerNotification: value,
        });
    };

    const onSave = async () => {
        await batchUpdateWorkflowRoles(batchValues);
        setModalVisible(false);
        dataGrid?.instance.clearSelection();
        onSelectionChanged({ selectedRowKeys: [] });
        resetSelection();
        mutate(
            `/api/workflow-roles/entity-codes/${entityCodesCacheKey}/${userRolesCacheKey}`
        );
        if (
            batchValues.sendAssigneeNotification ||
            batchValues.sendReviewerNotification
        ) {
            message.success(`Users were successfully notified`);
        }
    };

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const entityCodesCacheKey = entityCodes.join(',');

    const userRolesCacheKey = userWorkflowRoleOptions
        ?.map((uw) => uw.id)
        .join(',');

    const setFilteredThresholdRules = (thresholdRules: ThresholdRule[]) => {
        const filteredThresholdRules: ThresholdRule[] = thresholdRules.reduce(
            (acc, t) => {
                const requiredVarianceRules = t.variance_rules.filter(
                    (vr) => vr.workflow_required
                );

                if (requiredVarianceRules.length) {
                    const entityCodeRules = {
                        entity_code: t.entity_code,
                        variance_rules: requiredVarianceRules,
                    };
                    acc.push(entityCodeRules);
                }

                return acc;
            },
            [] as ThresholdRule[]
        );

        setThresholdRules(filteredThresholdRules);
    };

    const { data: clientThresholdRules } = useGetThresholdRules(
        entityCodes,
        () => message.error('Error fetching threshold rules')
    );

    useEffect(() => {
        if (!clientThresholdRules) {
            return;
        }

        setFilteredThresholdRules(clientThresholdRules);
    }, [clientThresholdRules]);

    const { data: workflowRolesData } = useSWR(
        entityCodesCacheKey && clientThresholdRules
            ? `/api/workflow-roles/entity-codes/${entityCodesCacheKey}/${userRolesCacheKey}`
            : null,
        () => getWorkflowRolesByEntityCodes(entityCodes),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                toastr.error('Request Failed', 'Workflow Roles not found');
            },
            shouldRetryOnError: false,
        }
    );

    useEffect(() => {
        if (!workflowRolesData) {
            return;
        }

        setUpWorkflowRoles(workflowRolesData, clientThresholdRules);
    }, [workflowRolesData]);

    const { data: reportMetadataRows } = useSWR(
        entityCodesCacheKey
            ? `/api/report-metadata/search/entity-codes/${entityCodesCacheKey}`
            : null,
        () =>
            getAllReportMetadataByEntityCodes(
                entityCodes,
                'comparative-income'
            ),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                toastr.error('Request Failed', 'Report Metadata not found');
            },
            shouldRetryOnError: false,
        }
    );

    const { data: entityFiscalYearEndDates } =
        useGetClientAndEntityAttributeValues(
            [PropertyDetailAttributeEnum.FinancialYearEnding],
            true
        );

    useEffect(() => {
        if (!reportMetadataRows) {
            return;
        }

        setFilteredReportMetadata(reportMetadataRows);
    }, [reportMetadataRows]);

    const { data: asOfDate } = useSWR(
        entityCodesCacheKey ? `/api/as-of-date/${entityCodesCacheKey}` : null,
        () => getAsOfDates(entityCodes),
        {
            onError: () => {
                toastr.error('Request Failed', 'As of Date not found');
            },
            shouldRetryOnError: false,
        }
    );

    useEffect(() => {
        if (!asOfDate) {
            return;
        }

        transformAndSetAsOfDate(asOfDate);
    }, [asOfDate]);

    const workflowRoleTypes = [
        {
            label: 'Assignee',
            value: WORKFLOW_ASSIGNEE,
        },
        {
            label: 'Reviewer',
            value: WORKFLOW_REVIEWER,
        },
    ];

    const getFilteredMetadataByEndDateAndClientMode = (
        metadata: ReportMetadata[]
    ) => {
        /* 
            for better readability and future use, an explanation of order of fields for splitHash,
            based on our current income statement report metadata filter hash structure:
            0: column 1 mode
            1: column 1 period start
            2: column 1 period end
            3: column 2 mode
            4: column 2 period start
            5: column 2 period end
            6: periodicity
            7: comparison type (selected period, prior year, etc)
            8: variance display columns
            9: financial year end date
            10: variance comparison (col 1 vs col 2, 1 vs 3, etc.)
            there can be additional fields for a third column but those are irrelevant here
        */
        return metadata.filter((m) => {
            const splitHash = m.filter_hash.split('__');
            const hashMatchesPeriodEnd =
                splitHash[2] === periodEnd && splitHash[5] === periodEnd;
            const hashMatchesSupportedComparisonModes =
                splitHash[0] === 'actual' && splitHash[3] === 'budget';
            const financialYearEnd =
                JSON.parse(m.filter_raw_json)[0]?.financial_year_ending ??
                '12/31';
            const entityFinancialYearEnd =
                entityFiscalYearEndDates?.find(
                    (e) => e.entity_code === m.reference_id
                )?.value ?? '12/31';

            /*
                we currently only support actual vs budget comparisons for variance reporting, 
                with default comparison selections, so we need to need to make sure we're filtering 
                by the right modes and default selections in addition to matching the fiscal year end
                and period end dates. we can add back in the client mode check down the road 
                if we expand to support other modes like reforecast or proforma
            */
            return (
                hashMatchesPeriodEnd &&
                hashMatchesSupportedComparisonModes &&
                entityFinancialYearEnd === financialYearEnd &&
                splitHash[7] === 'selected_period' &&
                splitHash[8] === 'amount_and_pct' &&
                m.filter_hash.endsWith('comparison[0-1]')
            );
        });
    };

    useEffect(() => {
        if (reportMetadata) {
            const filteredMetadataByEndDate =
                getFilteredMetadataByEndDateAndClientMode(reportMetadata);
            setReportMetadataByEndDate(filteredMetadataByEndDate);
        }
    }, [periodEnd, entityFiscalYearEndDates]);

    const setFilteredReportMetadata = (reportMetadata: ReportMetadata[]) => {
        const filteredMetadataByEndDate =
            getFilteredMetadataByEndDateAndClientMode(reportMetadata);
        setReportMetadata(reportMetadata);
        setReportMetadataByEndDate(filteredMetadataByEndDate);
    };

    const setUpWorkflowRoles = (
        workflowRoles: EntityReportWorkflowRole[],
        thresholdRules: ThresholdRule[] | undefined
    ) => {
        if (!thresholdRules) {
            return;
        }
        const assignees: Dictionary<number> = {};
        const unassigned: UnassignedCounts = { assignee: 0, reviewer: 0 };
        const reviewers: Dictionary<number> = {};
        const workflowAssigneesByEntityCodes: Dictionary<WorkflowRole[]> = {};
        const workflowReviewersByEntityCodes: Dictionary<WorkflowRole[]> = {};

        const entityHasRole = (referenceId: string, role: string) => {
            return workflowRoles.some(
                (w) =>
                    w.reference_id === referenceId &&
                    w.reference_type === 'entity' &&
                    w.report_role === role
            );
        };

        const reportCountByEntityCodes = thresholdRules.reduce((dict, item) => {
            unassigned.assignee += entityHasRole(
                item.entity_code,
                WORKFLOW_ASSIGNEE
            )
                ? 0
                : 1;
            unassigned.reviewer += entityHasRole(
                item.entity_code,
                WORKFLOW_REVIEWER
            )
                ? 0
                : 1;
            dict[item.entity_code] = item.variance_rules.filter(
                (vr) => vr.workflow_required
            ).length;
            return dict;
        }, {} as Dictionary<number>);

        for (const w of workflowRoles) {
            const entityReportCount = reportCountByEntityCodes[w.reference_id];
            if (
                !Object.keys(workflowAssigneesByEntityCodes).includes(
                    w.reference_id
                )
            ) {
                workflowAssigneesByEntityCodes[w.reference_id] = [];
                workflowReviewersByEntityCodes[w.reference_id] = [];
            }
            const username = getUsernameById(
                w.user_id,
                userWorkflowRoleOptions ?? []
            );
            if (w.report_role === WORKFLOW_ASSIGNEE) {
                if (Object.keys(assignees).includes(username)) {
                    assignees[username] += entityReportCount;
                } else {
                    assignees[username] = entityReportCount;
                }
                workflowAssigneesByEntityCodes[w.reference_id].push({
                    userId: Number(w.user_id),
                    userName: username,
                });
            } else {
                if (Object.keys(reviewers).includes(username)) {
                    reviewers[username] += entityReportCount;
                } else {
                    reviewers[username] = entityReportCount;
                }
                workflowReviewersByEntityCodes[w.reference_id].push({
                    userId: Number(w.user_id),
                    userName: username,
                });
            }
        }
        setWorkflowAssigneeCounts(assignees);
        setWorkflowReviewerCounts(reviewers);
        setUnassignedCounts(unassigned);
        setWorkflowAssignees(workflowAssigneesByEntityCodes);
        setWorkflowReviewers(workflowReviewersByEntityCodes);
    };

    const transformAndSetAsOfDate = (asOfDateResponse: AsOfDateResponse) => {
        const actualPeriodEnd = asOfDateResponse.accounting.find(
            (a) => a.label === 'Actual'
        )?.period_end;

        const periodMonthEnd = periodEnd ? periodEnd : actualPeriodEnd;

        setPeriodEnd(toISO(toEndOfMonth(new Date(periodMonthEnd as string))));
        setIsLoadingAsOfDate(false);
    };

    if (isLoadingAsOfDate || !periodEnd || !entityFiscalYearEndDates) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const isReviewer = isUserInReviewers(workflowReviewers, currentUserId);

    const paneAreaWithoutFooterRemaining = 0;
    const paneAreaWithFooterRemaining = 100;
    const CHART_HEIGHTS = isCollapsed ? 0 : 442;
    const PADDING_HEIGHTS = isCollapsed ? 110 : 0;

    const tableHeight =
        window.innerHeight - CHART_HEIGHTS - PADDING_HEIGHTS - 30;
    const paneRightArea =
        tableHeight -
        (isFooterVisible
            ? paneAreaWithFooterRemaining
            : paneAreaWithoutFooterRemaining);

    return (
        <div
            className={gridContainerStyle(isCollapsed)}
            style={{ padding: '10px', height: '100%' }}
        >
            <ResponsiveBox>
                <Row ratio={0.2} />
                <Row ratio={0.7} />
                <Row ratio={2} />

                <Col />
                <Col />
                <Col />

                <Item>
                    <Location row={0} col={0} colspan={3} />
                    <VarianceReportStatusBanner
                        periodEnd={periodEnd}
                        setPeriodEnd={setPeriodEnd}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                    />
                </Item>
                <Item>
                    <Location row={1} col={0} colspan={1} />
                    <div style={chartContainerStyle(isCollapsed)}>
                        <div style={{ width: '100%', overflow: 'hidden' }}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginTop: '14px',
                                    marginBottom: '10px',
                                }}
                            >
                                Report Status Summary
                            </div>
                            {getStatusBreakdownDoughnutChartConfig(
                                reportMetadataByEndDate,
                                thresholdRules,
                                periodEnd
                            )}
                        </div>
                    </div>
                </Item>
                <Item>
                    <Location row={1} col={1} colspan={1} />
                    <div
                        style={{
                            ...chartContainerStyle(isCollapsed),
                            margin: '0 5px 10px 5px',
                        }}
                    >
                        <div style={{ width: '100%', overflow: 'hidden' }}>
                            <div
                                style={{
                                    height: '40px',
                                    textAlign: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        marginRight: '5%',
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                >
                                    Reports By
                                </span>
                                <Select
                                    style={{
                                        width: '100px',
                                        marginTop: '5px',
                                    }}
                                    options={workflowRoleTypes}
                                    value={selectedRole}
                                    onChange={(value: string) =>
                                        setSelectedRole(value)
                                    }
                                />
                            </div>
                            <div style={{ width: isCollapsed ? 1 : '100%' }}>
                                {getUserRoleBreakdownDoughnutChartConfig(
                                    selectedRole === WORKFLOW_ASSIGNEE
                                        ? workflowAssigneeCounts
                                        : workflowReviewerCounts,
                                    selectedRole === WORKFLOW_ASSIGNEE
                                        ? unassignedCounts?.assignee ?? 0
                                        : unassignedCounts?.reviewer ?? 0,
                                    thresholdRules?.length ?? 0
                                )}
                            </div>
                        </div>
                    </div>
                </Item>
                <Item>
                    <Location row={1} col={2} colspan={1} />
                    <div style={chartContainerStyle(isCollapsed)}>
                        <div
                            style={{
                                marginTop: '14px',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            Status Breakdown By Threshold Rule
                        </div>
                        {getByThresholdBarChartConfig(
                            thresholdRules,
                            reportMetadataByEndDate
                        )}
                    </div>
                </Item>

                <Item>
                    <Location row={2} col={0} colspan={3} />
                    {modalVisible && (
                        <Modal
                            title="Edit properties"
                            open={true}
                            destroyOnClose
                            onOk={onSave}
                            closeIcon={<></>}
                            footer={[
                                <Button key="back" onClick={onCancel}>
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={onSave}
                                >
                                    Save
                                </Button>,
                            ]}
                        >
                            <div className={wrapper}>
                                <span className={title}>Assignee</span>
                                <Select
                                    disabled={!isAdmin && !isReviewer}
                                    mode="multiple"
                                    placeholder="Select assignee"
                                    style={{ width: '100%', marginTop: 6 }}
                                    onChange={onAssigneeChange}
                                    value={batchValues.assignee}
                                    options={usersWithStrictEntityAccess.map(
                                        (user: any) => ({
                                            label: user.text,
                                            value: user.id,
                                        })
                                    )}
                                    filterOption={(input, option) =>
                                        (
                                            option?.label.toLocaleLowerCase() ??
                                            ''
                                        ).includes(input.toLocaleLowerCase())
                                    }
                                />

                                <Checkbox
                                    className={showCheckBox(
                                        batchValues.assignee.length > 0
                                    )}
                                    onChange={(e) =>
                                        onSendAssigneeNotificationChange(
                                            e.target.checked
                                        )
                                    }
                                >
                                    Notify assignee(s)
                                </Checkbox>
                            </div>

                            <div className={wrapper}>
                                <span className={title}>Reviewer</span>
                                <Select
                                    disabled={!isAdmin}
                                    mode="multiple"
                                    placeholder="Select reviewer"
                                    style={{ width: '100%', marginTop: 6 }}
                                    value={batchValues.reviewer}
                                    onChange={onReviewerChange}
                                    options={usersWithStrictEntityAccess.map(
                                        (user: any) => ({
                                            label: user.text,
                                            value: user.id,
                                        })
                                    )}
                                    filterOption={(input, option) =>
                                        (
                                            option?.label.toLocaleLowerCase() ??
                                            ''
                                        ).includes(input.toLocaleLowerCase())
                                    }
                                />

                                <Checkbox
                                    className={showCheckBox(
                                        batchValues.reviewer.length > 0
                                    )}
                                    onChange={(e) =>
                                        onSendReviewerNotificationChange(
                                            e.target.checked
                                        )
                                    }
                                >
                                    Notify reviewer(s)
                                </Checkbox>
                            </div>
                        </Modal>
                    )}
                    <div
                        style={{
                            height: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                paddingBottom: 12,
                                height: paneRightArea,
                                overflow: 'auto',
                            }}
                        >
                            <VarianceReportStatusTable
                                periodEnd={periodEnd}
                                thresholdRules={thresholdRules}
                                reportMetadata={reportMetadataByEndDate}
                                workflowAssignees={workflowAssignees}
                                workflowReviewers={workflowReviewers}
                                userWorkflowRoleOptions={
                                    userWorkflowRoleOptions
                                }
                                onSelectionChanged={onSelectionChanged}
                                getDataGridRef={setDataGrid}
                                entityFiscalYearEndDates={
                                    entityFiscalYearEndDates
                                }
                            />
                        </div>
                    </div>
                    <div
                        className={footerStyle(isFooterVisible)}
                        style={{
                            ...alignStyle,
                            width: '100%',
                            padding: '16px',
                        }}
                    >
                        <div>
                            <span
                                style={{
                                    marginLeft: 18,
                                    fontSize: 14,
                                    paddingRight: 18,
                                }}
                            >
                                {batchValues.selectedVarianceReports.length}{' '}
                                Variance Report
                                {batchValues.selectedVarianceReports.length > 1
                                    ? 's'
                                    : ''}{' '}
                                Selected
                            </span>
                            <Button type="primary" onClick={onEdit}>
                                Edit
                            </Button>
                        </div>
                        <div>
                            <Button
                                style={{
                                    marginRight: '18px',
                                }}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Item>
            </ResponsiveBox>
        </div>
    );
};

export default VarianceReportStatusContainer;
