import React from 'react';
import { get } from 'lodash';
import { formatNumberToDecimalPlace, formatPercentage } from 'utils/formatters';
import { DASH_DASH } from 'config/constants';
import { getDisplayTypeAbbreviation } from 'components/analytics/financialOverview/menu/displayType/utils';
import {
    getAccountMappingRankingDataFor,
    getChildAccountMappingRankingDataFor,
} from 'components/analytics/ranking/utils/RankingTooltipUtils';
import { RankingTooltip } from 'components/analytics/ranking';
import { RankingData, RankingTooltipListItems } from 'waypoint-types';

interface ChildAccountTooltipData {
    value: string;
    propertyRankValue: number;
    percentageOfParent?: string;
}

const AccountGroupTooltip = ({
    property,
    seriesName,
    displayType,
}: {
    property: RankingData;
    seriesName: string;
    displayType: string;
}) => {
    const propertyName = get(property, 'property_name', DASH_DASH);

    const accountMappingRankingData = getAccountMappingRankingDataFor(
        property.account_mapping
    );

    const childAccountTooltipData: ChildAccountTooltipData =
        getChildAccountMappingRankingDataFor(
            property.account_mapping.children,
            seriesName
        );

    let calculatedPercentageOfParent = 0;
    if (
        childAccountTooltipData.propertyRankValue &&
        accountMappingRankingData.propertyRankValue
    ) {
        calculatedPercentageOfParent =
            (childAccountTooltipData.propertyRankValue /
                accountMappingRankingData.propertyRankValue) *
            100;
    }
    childAccountTooltipData.percentageOfParent = formatPercentage(
        calculatedPercentageOfParent
    );

    const listItems: RankingTooltipListItems[] = [
        {
            label: accountMappingRankingData.name,
            value: (
                <>
                    <span>{accountMappingRankingData.value} &nbsp;</span>
                    <span>{getDisplayTypeAbbreviation(displayType)}</span>
                </>
            ),
        },
        {
            label: seriesName,
            value: (
                <>
                    <span>{childAccountTooltipData.value} &nbsp;</span>
                    <span>{getDisplayTypeAbbreviation(displayType)}</span>
                </>
            ),
        },
        {
            label: `% of ${accountMappingRankingData.name}`,
            value: childAccountTooltipData.percentageOfParent,
        },
    ];

    if (displayType === 'value_per_occupied_sqft') {
        listItems.splice(1, 0, {
            label: 'Occupied sq ft',
            value: formatNumberToDecimalPlace(
                get(property, 'occupied_sq_ft'),
                0
            ),
        });
    }
    if (displayType === 'value_per_rentable_sqft') {
        listItems.splice(1, 0, {
            label: 'Rentable sq ft',
            value: formatNumberToDecimalPlace(
                get(property, 'rentable_sq_ft'),
                0
            ),
        });
    }

    return <RankingTooltip items={listItems} header={propertyName} />;
};

export default AccountGroupTooltip;
