import PropTypes from 'prop-types';
import React from 'react';
import d3 from 'd3';
import { isUndefined, isNull } from 'lodash';
import defaultStylePP from './defaultStylePP';
import { DefaultCell } from './DefaultCellRenderer';
import { DASH_DASH } from 'config/constants';

const numberFormatter = d3.format(',.2f');

// NOTE: This is a basic percentage cell renderer. It is not meant to handle coefficients and styling differences between positive and negative numbers. A new cellRenderer will be required for that.
class PercentageCell extends React.Component {
    render() {
        const { cellData, ...rest } = this.props;
        const isInvalid = isUndefined(cellData) || isNull(cellData);
        let data = cellData;
        if (isInvalid) {
            data = `${DASH_DASH}%`;
            return <DefaultCell cellData={cellData} {...rest} />;
        }
        data = numberFormatter(cellData);
        data = `${cellData} %`;
        return <DefaultCell cellData={data} {...rest} />;
    }
}

PercentageCell.propTypes = {
    cellData: PropTypes.any,
};

export const PercentageCellRenderer = defaultStylePP(PercentageCell);
export default PercentageCellRenderer;
