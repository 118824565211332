import React, { Children, Component } from 'react';
import { get, isString, isEqual } from 'lodash';
import { css, cx } from 'emotion';
import { ViewToggle } from 'waypoint-react';
import theme from 'config/theme';

const header = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`;

const labelStyle = css`
    color: ${theme.colors.grays.text};
    font-family: Lato;
    font-size: 12px;
`;

const container = css`
    height: inherit;
    display: flex;
    flex-direction: column;
`;

class ViewSwitch extends Component {
    constructor(props) {
        super(props);

        const options = Children.map(
            props.children,
            (child) => child.props.viewId
        );

        this.state = {
            active: get(options, [0], null),
            options,
        };

        this.switchView = this.switchView.bind(this);
    }

    componentDidUpdate(_, prevState) {
        const options = Children.map(
            this.props.children,
            (child) => child.props.viewId
        );

        if (!isEqual(options, prevState.options)) {
            const defaultSelectedOption = get(options, [0], null);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                active: defaultSelectedOption,
                options,
            });
        }
    }

    getActiveChild() {
        return Children.toArray(this.props.children)
            .filter(
                (child) =>
                    child.props.viewId === '*' ||
                    child.props.viewId === this.state.active
            )
            .map((el) => React.cloneElement(el, { viewId: undefined }));
    }

    switchView(id) {
        this.setState({ active: id });
    }

    render() {
        const {
            className,
            label,
            labelClassName,
            extra = null,
            showToggle = true,
        } = this.props;
        const { active, options } = this.state;
        return (
            <div className={cx(container, className)}>
                <div className={header}>
                    {isString(label) ? (
                        <p className={cx(labelStyle, labelClassName)}>
                            {label}
                        </p>
                    ) : (
                        label
                    )}
                    {extra}
                    {showToggle && (
                        <ViewToggle
                            active={active}
                            options={options}
                            onChange={this.switchView}
                        />
                    )}
                </div>
                <div id="active_child">{this.getActiveChild()}</div>
            </div>
        );
    }
}

export default ViewSwitch;
