import { Button, Modal, Form, Input, Select, Radio } from 'antd';
import { LeasingGuideline } from 'waypoint-types';
import { css } from 'emotion';

interface EditGuidelineModalProps {
    selectedRowData: LeasingGuideline | null;
    visible: boolean;
    onClose: () => void;
    onSaved: (data: LeasingGuideline) => void;
}

const modalStyle = css`
    width: 550px !important;
    height: 750px !important;
    @media screen and (max-width: 1280px) {
        max-width: 85% !important;
    }
    .ant-form-item-label {
        font-size: 14px;
        font-family: Lato;
    }
`;

const tailLayout = {
    wrapperCol: { offset: 8, span: 20 },
};

const { Option } = Select;

const suffixSelector = (
    <Form.Item name="leasing_commissions_type" noStyle>
        <Select defaultValue="$">
            <Option value="$" children={undefined}></Option>
            <Option value="%" children={undefined}></Option>
        </Select>
    </Form.Item>
);

export const EditGuidelineModal = ({
    selectedRowData,
    visible,
    onClose,
    onSaved,
}: EditGuidelineModalProps) => {
    const [form] = Form.useForm();

    return (
        <Modal
            centered
            title={selectedRowData?.id ? `Edit Guideline` : 'Add Guideline'}
            open={visible}
            className={modalStyle}
            onCancel={onClose}
            destroyOnClose
            footer={null}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                layout="horizontal"
                style={{ maxWidth: 700 }}
                scrollToFirstError
                form={form}
                initialValues={selectedRowData ?? []}
                onFinish={(values) => {
                    const leasingGuidelinesData = selectedRowData
                        ? {
                              ...values,
                              id: selectedRowData.id,
                          }
                        : values;
                    onSaved(leasingGuidelinesData);
                }}
                name="leasing-guidelines-form"
            >
                <Form.Item
                    name="guideline"
                    label="Guideline"
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        maxLength={100}
                        placeholder="Type guideline here"
                        value={selectedRowData?.guideline}
                        autoComplete="off"
                    />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                    name="type"
                    label="Type"
                    initialValue={selectedRowData?.type ?? 'New Lease'}
                >
                    <Radio.Group
                        defaultValue={selectedRowData?.type ?? 'New Lease'}
                    >
                        <Radio value={'New Lease'}>New Lease</Radio>
                        <Radio value={'Renewal'}>Renewal</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="starting_rent" label="Starting Rent">
                    <Input
                        style={{ width: '200px' }}
                        type="number"
                        addonBefore="$"
                        addonAfter="PSF"
                        defaultValue={selectedRowData?.starting_rent}
                    />
                </Form.Item>
                <Form.Item
                    name="escalations"
                    label="Escalations"
                    rules={[
                        {
                            validator: async (rule, value) => {
                                if (
                                    !isNaN(value) &&
                                    (value < 0 || value > 100)
                                ) {
                                    throw new Error(
                                        'Please input a valid percentage!'
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Input
                        type="number"
                        style={{ width: '200px' }}
                        addonAfter={'%'}
                        value={selectedRowData?.escalations}
                    />
                </Form.Item>
                <Form.Item name="term_months" label="Term">
                    <Input
                        type="number"
                        style={{ width: '200px' }}
                        addonAfter={'Mos'}
                        defaultValue={selectedRowData?.term_months}
                    />
                </Form.Item>
                <Form.Item name="concessions_months" label="Concessions">
                    <Input
                        type="number"
                        style={{ width: '200px' }}
                        addonAfter={'Mos'}
                        defaultValue={selectedRowData?.concessions_months}
                    />
                </Form.Item>

                <Form.Item
                    name="leasing_commissions"
                    label={'Leasing Commissions'}
                >
                    <Input
                        type="number"
                        style={{ width: '200px' }}
                        addonBefore={suffixSelector}
                        defaultValue={selectedRowData?.leasing_commissions}
                    />
                </Form.Item>
                <Form.Item
                    name="tenant_improvements_per_year"
                    label="Tenant Improvements"
                >
                    <Input
                        style={{ width: '200px' }}
                        type="number"
                        addonBefore={'$'}
                        addonAfter={'Per Year'}
                        defaultValue={
                            selectedRowData?.tenant_improvements_per_year
                        }
                    />
                </Form.Item>
                <Form.Item label="Market Comps">
                    <Form.Item
                        name="market_comps_low"
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                        }}
                    >
                        <Input
                            style={{ width: '150px' }}
                            placeholder="Low"
                            addonBefore="$"
                            type="number"
                            defaultValue={selectedRowData?.market_comps_low}
                        />
                    </Form.Item>
                    <Form.Item
                        name="market_comps_high"
                        style={{
                            display: 'inline-block',
                            width: 'calc(50% - 8px)',
                            margin: '0 8px',
                        }}
                    >
                        <Input
                            style={{ width: '150px' }}
                            placeholder="High"
                            addonBefore={'$'}
                            type="number"
                            defaultValue={selectedRowData?.market_comps_high}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button
                        size="middle"
                        onClick={onClose}
                        style={{ marginRight: '10px' }}
                    >
                        Cancel
                    </Button>
                    <Button size="middle" type="primary" htmlType="submit">
                        {selectedRowData ? 'Update' : 'Save'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
