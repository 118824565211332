import React, { useState } from 'react';
import DataGrid, {
    Item as ToolbarItem,
    Toolbar,
    ColumnChooser,
    HeaderFilter,
    Column,
    Format,
    Editing,
    RequiredRule,
    Lookup,
    Paging,
} from 'devextreme-react/data-grid';
import {
    NOT_RENEWING,
    calculateEffectiveRent,
    calculateNetEffectiveRentPerSFYear,
    calculateTotalCapital,
    calculateTotalTenantImprovements,
    leasingCommissionsTypes,
} from './LeasingPlanUtils';
import { LeasingPlanActivity } from 'waypoint-types/leasing-plans';
import {
    leaseExpectationOptions,
    leaseTypeOptions,
    LeasingPlanActivityType,
} from './LeasingPlanUtils';
import { CellPreparedEvent, SavingEvent } from 'devextreme/ui/data_grid';
import { moneyFormatter } from 'utils/formatters';
import { css } from 'emotion';
import { LeasingPlanActivityExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/LeasingPlanActivityExportableGrid';

const noWrap = css`
    white-space: nowrap;
`;

interface LeasingPlanActivityTableProps {
    tableType: string;
    data: LeasingPlanActivity[] | undefined;
    onSaveRow: (data: LeasingPlanActivity) => void;
    onDeleteRow?: (data: string) => void;
    dataGridRef?: React.RefObject<DataGrid<any, any>>;
    isPDFExport?: boolean;
}

export const LeasingPlanActivityTable = ({
    tableType,
    data,
    onSaveRow,
    onDeleteRow,
    dataGridRef,
    isPDFExport,
}: LeasingPlanActivityTableProps) => {
    const [isAddingNewRow, setIsAddingNewRow] = useState<boolean>(false);
    const isNewLeases = tableType === LeasingPlanActivityType.NewLeases;

    const setRowCellsDataByUnit = (
        rowData: LeasingPlanActivity,
        unit: string
    ) => {
        const leaseData = data?.find((activity) => activity.unit === unit);
        if (!leaseData) {
            return;
        }

        Object.assign(rowData, leaseData);
        rowData.id = undefined;
        rowData.tenant_name = '';
        rowData.tenant_code = null;
    };

    const prepareSave = (event: SavingEvent) => {
        if (!event.changes.length) {
            return;
        }

        const change = event.changes[0];

        if (change.type === 'update') {
            onSaveRow({ ...change.key, ...change.data });
        }
        if (isNewLeases && change.type === 'insert') {
            change.data.activity_type = leaseTypeOptions[0].value;
            onSaveRow(change.data);
            setIsAddingNewRow(false);
        }
    };

    const onCellPrepared = (
        e: CellPreparedEvent<LeasingPlanActivity, unknown>
    ) => {
        const isDataRowTypeAndExpectationFieldWithValue =
            e.rowType === 'data' &&
            e.column.dataField === 'expectation' &&
            !!e?.data?.expectation;

        if (!isDataRowTypeAndExpectationFieldWithValue) {
            return;
        }

        e.cellElement.style.cssText =
            e.data.expectation === NOT_RENEWING
                ? `background-color: #df010161`
                : `background-color: #00c85480`;
    };

    const mapAvailableLeaseUnits = () => {
        const existingLeaseUnits = data
            ?.filter((activity) => activity.activity_type)
            .map((d) => d.unit);
        return data
            ?.filter(
                (activity) =>
                    !activity.activity_type &&
                    !existingLeaseUnits?.includes(activity.unit)
            )
            .map((activity: LeasingPlanActivity) => {
                return {
                    value: activity.unit,
                    label: getUnitLabel(activity),
                };
            });
    };

    const unitCellRender = (props: { data: LeasingPlanActivity }) => {
        if (!isNewLeases) {
            return props.data.unit;
        }
        // find the original renewal lease to show the current status
        const renewalLease = data?.find(
            (d) =>
                d.leasable_space_code === props.data.leasable_space_code &&
                d.unit === props.data.unit &&
                !d.activity_type
        );
        return getUnitLabel(renewalLease ?? props.data);
    };

    const getUnitLabel = (lease: LeasingPlanActivity): string => {
        const expectationOption = leaseExpectationOptions.find(
            (o) => o.value === lease.expectation
        );
        const leaseStatus = !lease.tenant_code
            ? 'Vacant'
            : expectationOption?.label ?? '';
        return `${lease.unit} (${leaseStatus})`;
    };

    if (isPDFExport) {
        return (
            <LeasingPlanActivityExportableGrid
                data={
                    isNewLeases
                        ? data?.filter((activity) => activity.activity_type) ??
                          []
                        : data ?? []
                }
                isNewLeases={isNewLeases}
            />
        );
    }

    return (
        <DataGrid
            ref={dataGridRef}
            dataSource={
                isNewLeases
                    ? data?.filter((activity) => activity.activity_type)
                    : data
            }
            id="leasing-plan-activity-table"
            showBorders={true}
            wordWrapEnabled={!isPDFExport}
            allowColumnReordering={true}
            width={'100%'}
            hoverStateEnabled={true}
            onSaving={prepareSave}
            onRowRemoved={(e) =>
                onDeleteRow && e.data.id && onDeleteRow(e.data.id)
            }
            onInitNewRow={() => setIsAddingNewRow(true)}
            onEditCanceled={() => setIsAddingNewRow(false)}
            onCellPrepared={onCellPrepared}
        >
            <ColumnChooser enabled={true} mode={'select'} height={350} />
            <HeaderFilter visible={true} />
            <Toolbar>
                <ToolbarItem location="before" name="groupPanel" />
                <ToolbarItem name="addRowButton" />
                <ToolbarItem name="exportButton" />
                <ToolbarItem location="after" name="columnChooserButton" />
                <ToolbarItem name="searchPanel" locateInMenu="right" />
            </Toolbar>
            <Editing
                mode="row"
                allowUpdating={true}
                allowAdding={isNewLeases}
                allowDeleting={isNewLeases}
            />
            <Paging enabled={true} pageSize={10} />
            <Column
                allowEditing={isNewLeases && isAddingNewRow}
                showInColumnChooser={false}
                dataField="unit"
                dataType="string"
                caption="Unit"
                alignment={'left'}
                width={'auto'}
                minWidth={120}
                cssClass={noWrap}
                fixed="left"
                allowHeaderFiltering={false}
                cellRender={unitCellRender}
                setCellValue={(rowData: LeasingPlanActivity, value: string) =>
                    setRowCellsDataByUnit(rowData, value)
                }
            >
                {isAddingNewRow ? (
                    <Lookup
                        valueExpr="value"
                        displayExpr="label"
                        dataSource={mapAvailableLeaseUnits}
                    ></Lookup>
                ) : (
                    <></>
                )}
                <RequiredRule />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={false}
                showInColumnChooser={false}
                dataField="rentable_sf"
                caption="RSF"
                width={'auto'}
                alignment={'center'}
                fixed="left"
                allowHeaderFiltering={false}
            >
                <Format type="fixedPoint" precision={0} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={isNewLeases}
                showInColumnChooser={false}
                dataField="tenant_name"
                caption="Tenant"
                width={'auto'}
                alignment={'left'}
                fixed="left"
                cssClass={noWrap}
            />
            <Column
                allowSorting={true}
                allowEditing={false}
                showInColumnChooser={false}
                dataField="lease_expiration_date"
                caption="LXD"
                width={'90px'}
                alignment={'center'}
                dataType="date"
                allowHeaderFiltering={false}
                fixed="left"
            >
                <Format type="shortDate" />
            </Column>
            {isNewLeases ? (
                <Column
                    allowSorting={false}
                    allowEditing={true}
                    dataField="activity_type"
                    caption="Type"
                    width={'110px'}
                    alignment={'center'}
                    fixed="left"
                    cellRender={(cellData) =>
                        leaseTypeOptions.find((o) => o.value === cellData.value)
                            ?.label ?? ''
                    }
                >
                    <Lookup
                        valueExpr="value"
                        displayExpr="label"
                        dataSource={leaseTypeOptions}
                    />
                </Column>
            ) : (
                <Column
                    allowSorting={false}
                    allowEditing={true}
                    showInColumnChooser={false}
                    dataField="expectation"
                    caption="Renewal Expectation"
                    width={'110px'}
                    alignment={'left'}
                    fixed="left"
                    cellRender={(cellData) =>
                        leaseExpectationOptions.find(
                            (o) => o.value === cellData.value
                        )?.label ?? ''
                    }
                >
                    <Lookup
                        valueExpr="value"
                        displayExpr="label"
                        dataSource={leaseExpectationOptions}
                    />
                </Column>
            )}
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="commencement_date"
                dataType="date"
                caption="LCD"
                width={'90px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="shortDate" />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="term"
                dataType="number"
                caption="Term (months)"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="fixedPoint" precision={1} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="base_rent_sf"
                dataType="number"
                caption="Base Rent (PSF / Year)"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="free_rent"
                dataType="number"
                caption="Free Rent (months)"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="fixedPoint" precision={1} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={false}
                dataField="effective_rent"
                dataType="number"
                caption="Eff. Rent (PSF / Year)"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
                calculateCellValue={(leaseActivity: LeasingPlanActivity) =>
                    calculateEffectiveRent(leaseActivity)
                }
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="market_ready_tenant_improvements"
                dataType="number"
                caption="Market Ready TIs PSF"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="tenant_improvements_sf"
                dataType="number"
                caption="TIA PSF"
                width={'70px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={false}
                dataField="total_tenant_improvements"
                dataType="number"
                caption="Total TI"
                width={'100px'}
                alignment={'center'}
                allowHeaderFiltering={false}
                calculateCellValue={(leaseActivity: LeasingPlanActivity) =>
                    calculateTotalTenantImprovements(leaseActivity)
                }
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="leasing_commissions_type"
                dataType="string"
                caption="LC Type"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Lookup
                    valueExpr="value"
                    displayExpr="label"
                    dataSource={leasingCommissionsTypes}
                />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="leasing_commissions"
                dataType="number"
                caption="LC"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
                cellRender={({ data }) => {
                    if (data.leasing_commissions === null) {
                        return '';
                    }
                    if (
                        data.leasing_commissions_type ===
                        leasingCommissionsTypes[0].value // $/SF
                    ) {
                        return `${moneyFormatter(data.leasing_commissions)}`;
                    }
                    // default to percentage if no type selected
                    const percentage = Number(data.leasing_commissions).toFixed(
                        2
                    );
                    return `${percentage}%`;
                }}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={true}
                dataField="total_leasing_commisions"
                dataType="number"
                caption="Total LC"
                width={'100px'}
                alignment={'center'}
                allowHeaderFiltering={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={false}
                dataField="total_capital"
                dataType="number"
                caption="Total Capital"
                width={'100px'}
                alignment={'center'}
                allowHeaderFiltering={false}
                calculateCellValue={(leaseActivity: LeasingPlanActivity) =>
                    calculateTotalCapital(leaseActivity)
                }
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                allowSorting={true}
                allowEditing={false}
                dataField="ner_psf_year"
                dataType="number"
                caption="NER PSF/Year"
                width={'80px'}
                alignment={'center'}
                allowHeaderFiltering={false}
                calculateCellValue={(leaseActivity: LeasingPlanActivity) =>
                    calculateNetEffectiveRentPerSFYear(leaseActivity)
                }
            >
                <Format type="currency" precision={2} />
            </Column>
        </DataGrid>
    );
};
