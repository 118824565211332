import DataGrid from 'devextreme-react/data-grid';
import PDFMerger from 'pdf-merger-js';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF, jsPDFOptions } from 'jspdf';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { PDFOptions } from '@progress/kendo-drawing/pdf';
import {
    PDFDocument,
    PageSizes,
    StandardFonts,
    PDFPage,
    PDFFont,
    rgb,
} from 'pdf-lib';
import { PDFHeaderParams } from 'waypoint-types/report/types';

export class PDFBuilder {
    private _merge: PDFMerger;
    private _pdfMargins = {
        top: '1.5cm',
        right: '1cm',
        bottom: '1.5cm',
        left: '1cm',
    };
    private _pdfMarginBottomWithLogo = '2cm';

    private _templateLineMargin = 20;
    private _templateTextMargin = 30;

    private _datagridTitleMargins = {
        small: 5,
        medium: 10,
        large: 15,
    };

    constructor() {
        this._merge = new PDFMerger();
    }

    public async appendPDFBuilder(otherBuilder: PDFBuilder) {
        return this.addRawPDF(await otherBuilder.getRawPDF());
    }

    public async addAttachment(
        document: PDFDocument,
        widgetId: string,
        pdfTemplateParams?: PDFHeaderParams
    ) {
        const attachmentPDF = await this.addSectionTitleToAttachment(
            document,
            widgetId,
            pdfTemplateParams
        );
        await this.addRawPDF(attachmentPDF);
    }

    public async addRawPDF(document: PDFDocument) {
        await this._merge.add(await document.save());
    }

    public async getRawPDF(): Promise<PDFDocument> {
        return PDFDocument.load(await this._merge.saveAsBuffer());
    }

    public async setRawPDF(document: PDFDocument) {
        this._merge = new PDFMerger();
        return this.addRawPDF(document);
    }

    public async addElementById(
        id: string,
        options: PDFOptions,
        scale: number,
        pdfTemplateParams?: PDFHeaderParams,
        excludeHeaders?: boolean
    ) {
        const element = document.getElementById(id);

        if (!element) {
            throw new Error(`Cannot find element with id: ${id}`);
        }

        return this.addElement(
            element,
            options,
            scale,
            pdfTemplateParams,
            excludeHeaders
        );
    }

    public async addElement(
        element: HTMLElement,
        options: PDFOptions,
        scale: number,
        pdfTemplateParams?: PDFHeaderParams,
        excludeHeaders?: boolean
    ) {
        const drawingGroup = await drawDOM(element, {
            scale,
            paperSize: options?.paperSize ?? 'Letter',
            landscape: options?.landscape ?? false,
            repeatHeaders: true,
            margin: {
                ...this._pdfMargins,
                bottom: pdfTemplateParams?.reportHasLogo
                    ? this._pdfMarginBottomWithLogo
                    : this._pdfMargins.bottom,
            },
        });

        const pdfURL = await exportPDF(drawingGroup);

        const pdfDataUri = await this.addHeadersToPdf(
            pdfURL,
            options,
            pdfTemplateParams,
            undefined,
            undefined,
            undefined,
            excludeHeaders
        );

        await this._merge.add(pdfDataUri);
    }

    public async addDataGrid(
        dataGridRef: React.RefObject<DataGrid<any, any>>,
        pdfOptions?: PDFOptions,
        widgetId?: string,
        narrativePosition?: string,
        fontSizes?: {
            head?: number;
            body?: number;
        },
        pdfTemplateParams?: PDFHeaderParams,
        extraDataGridRef?: {
            dataGrid: React.RefObject<DataGrid<any, any>>;
            gridTitle: string;
        }[],
        dataGridTile: string = ''
    ) {
        if (!dataGridRef?.current?.instance) {
            return this;
        }
        const dataGridjsPDFOptions: jsPDFOptions = {
            orientation: pdfOptions?.landscape ? 'l' : 'p',
            format: pdfOptions?.paperSize === 'Legal' ? 'legal' : 'letter',
        };
        const doc = new jsPDF(dataGridjsPDFOptions);
        const narrativeElement = document.getElementById(
            `narrativeTextBox_${widgetId}`
        );

        /* addressing a mystery devextreme export error. if a datagrid ref gets
        passed into the exportDataGridToPdf function below without a leading html element
        (i.e. a narrative ABOVE or a recurring charge grid above a rent roll grid),
        the export function hangs forever. I've found no evidence online of this being
        a known issue, but inserting this empty html seems to fix the problem across the board
        */
        await doc.html('');
        doc.setFontSize(11);

        const autoTableStartY =
            narrativeElement && narrativePosition === 'above'
                ? await this.calculateStartYForDataGrid(
                      narrativeElement,
                      doc,
                      pdfOptions
                  )
                : this._templateLineMargin;

        const hasExtraGrids = extraDataGridRef && extraDataGridRef.length;
        hasExtraGrids &&
            doc.text(
                dataGridTile,
                this._datagridTitleMargins.medium,
                autoTableStartY ?? 0 + this._datagridTitleMargins.medium
            );

        const addTitleHeight = hasExtraGrids
            ? this._datagridTitleMargins.small
            : 0;
        const startY = (autoTableStartY ?? 0) + addTitleHeight;

        await this.exportDataGrid(
            dataGridRef,
            doc,
            { startY: hasExtraGrids ? startY : autoTableStartY },
            fontSizes
        );

        if (hasExtraGrids) {
            let extraFinalY = (doc as any).lastAutoTable.finalY;
            for (const extraDataGrid of extraDataGridRef) {
                if (!extraDataGrid) {
                    return;
                }
                doc.text(
                    extraDataGrid.gridTitle,
                    this._datagridTitleMargins.medium,
                    extraFinalY + this._datagridTitleMargins.medium
                );

                await this.exportDataGrid(
                    extraDataGrid.dataGrid,
                    doc,
                    { startY: extraFinalY + this._datagridTitleMargins.large },
                    fontSizes
                );
                extraFinalY = (doc as any).lastAutoTable.finalY;
            }
        }

        const finalY = (doc as any).lastAutoTable.finalY;

        const pdfDataUri = await this.addHeadersToPdf(
            doc.output('arraybuffer'),
            pdfOptions,
            pdfTemplateParams,
            widgetId,
            narrativePosition,
            finalY
        );
        await this._merge.add(pdfDataUri);
    }

    private async exportDataGrid(
        dataGridRef: React.RefObject<DataGrid<any, any>>,
        doc: jsPDF,
        position: {
            startY?: number | null;
            startX?: number | null;
        },
        fontSizes?: {
            head?: number;
            body?: number;
        },
        horizontalPageBreak = false
    ) {
        if (!dataGridRef?.current?.instance) {
            return this;
        }

        await exportDataGridToPdf({
            jsPDFDocument: doc,
            component: dataGridRef.current.instance,
            autoTableOptions: {
                startY: position.startY,
                tableLineWidth: 0,
                styles: {
                    cellPadding: 0.5,
                    fontSize: 7,
                },
                horizontalPageBreak,
                horizontalPageBreakRepeat: 0,
                bodyStyles: {
                    lineWidth: 0,
                    fontSize: fontSizes?.body ?? 7,
                    valign: 'middle',
                },

                headStyles: {
                    textColor: [255, 255, 255],
                    fillColor: [48, 128, 186],
                    fontSize: fontSizes?.head ?? 8,
                    fontStyle: 'bold',
                    cellPadding: 1,
                    valign: 'bottom',
                },
                alternateRowStyles: {
                    fillColor: [245, 245, 245],
                },
                margin: { top: 20, left: position.startX ?? 10, right: 10 },
            },
        });
    }

    public async mergeChartAndTablePDF(
        pdfOptions?: PDFOptions,
        pdfTemplateParams?: PDFHeaderParams,
        firstPageElementsCount = 2
    ) {
        const pdfDoc = await this.getRawPDF();
        const pdfPages = pdfDoc.getPages();
        const newPdf = await PDFDocument.create();

        const helveticaFont = await newPdf.embedFont(
            StandardFonts.HelveticaBold
        );

        const combinedPage = newPdf.addPage(this.getPageSizes(pdfOptions));
        for (let i = 0; i < firstPageElementsCount; i++) {
            combinedPage.drawPage(await newPdf.embedPage(pdfPages[i]), {
                x: 0,
                y: 0,
            });
        }

        for (let i = firstPageElementsCount; i < pdfPages.length; i++) {
            const pageEmbed = await newPdf.embedPage(pdfPages[i]);
            const newPage = newPdf.addPage(this.getPageSizes(pdfOptions));
            newPage.drawPage(pageEmbed, {
                x: 0,
                y: 0,
            });

            this.addHeader(newPage, helveticaFont, pdfTemplateParams);
        }

        await this.setRawPDF(newPdf);
        return await this.getRawPDF();
    }

    public async calculateStartYForDataGrid(
        element: HTMLElement | null,
        doc: jsPDF,
        options?: PDFOptions,
        scale?: number,
        marginTop?: number
    ) {
        if (!element) {
            return null;
        }
        // We use kendo drawDOM to get the height of the element
        // but we use jspdf to actually draw the element, because datagrid uses autoTable
        const drawingGroup = await drawDOM(element, {
            scale: scale ?? 0.6,
            paperSize: options?.paperSize ?? 'Letter',
            landscape: options?.landscape ?? false,
            margin: {
                left: '1cm',
                right: '1cm',
            },
        });
        const bbox = drawingGroup.bbox();
        if (!bbox) {
            return null;
        }
        const trimBoxHeight = bbox.size.height * 0.36;
        const headerMargin = marginTop ?? 25;
        const pageHeight = doc.internal.pageSize.getHeight() - 10;
        if (trimBoxHeight > pageHeight) {
            const factorHeight = trimBoxHeight / pageHeight;
            const getDecimal = factorHeight.toString().split('.')[1];
            return Number(`.${getDecimal}`) * pageHeight + headerMargin;
        }
        return trimBoxHeight + headerMargin;
    }

    public async loadNarrativePdf(pdfOptions?: PDFOptions, widgetId?: string) {
        const narrativeElement = document.getElementById(
            `narrativeTextBox_${widgetId}`
        );
        if (!narrativeElement) {
            return;
        }
        const drawingGroupNar = await drawDOM(narrativeElement, {
            scale: 0.6,
            paperSize: pdfOptions?.paperSize ?? 'Letter',
            landscape: pdfOptions?.landscape ?? false,
            margin: this._pdfMargins,
        });

        const headerPDFNar = await exportPDF(drawingGroupNar, {
            margin: 0,
        });
        return await PDFDocument.load(headerPDFNar);
    }

    private addHeader = (
        page: PDFPage,
        pdfFont: PDFFont,
        pdfTemplateParams?: PDFHeaderParams
    ) => {
        const reportName = pdfTemplateParams?.reportName ?? '';
        const property = pdfTemplateParams?.propertyName ?? '';
        const propertyWidth = pdfFont.widthOfTextAtSize(property, 14);

        page.drawLine({
            start: {
                x: this._templateLineMargin,
                y: page.getHeight() - 35,
            },
            end: {
                x: page.getWidth() - this._templateLineMargin,
                y: page.getHeight() - 35,
            },
            thickness: 1,
            color: rgb(0.16, 0.16, 0.16),
            opacity: 1,
        });
        page.drawText(property, {
            x: page.getWidth() - this._templateTextMargin - propertyWidth,
            y: page.getHeight() - 25,
            size: 14,
            font: pdfFont,
            color: rgb(0.16, 0.16, 0.16),
        });
        page.drawText(reportName, {
            x: this._templateTextMargin,
            y: page.getHeight() - 25,
            font: pdfFont,
            size: 14,
            color: rgb(0.16, 0.16, 0.16),
        });
    };

    public async addSectionTitleToPdf(
        sectionTitleElement: any,
        dimensions: [number, number]
    ) {
        // Draw section title using kendo
        const drawingSectionTitleElement = await drawDOM(sectionTitleElement, {
            scale: 0.5,
            paperSize: dimensions,
            margin: this._pdfMargins,
        });

        // Export the drawn element to pdf (this returns a string), then load the string with PDFDocument
        const exportedSectionTitlePdf = await exportPDF(
            drawingSectionTitleElement,
            {
                margin: this._pdfMargins,
            }
        );

        return await PDFDocument.load(exportedSectionTitlePdf);
    }

    public async addSectionTitleToAttachment(
        reportPdf: PDFDocument,
        widgetId: string,
        pdfTemplateParams?: PDFHeaderParams
    ) {
        const leftMargin = -0.05;
        const scale = 0.9;
        const firstPageBottomMargin = -0.05;
        const bottomMargin = -0.075;

        const pdfDoc = await PDFDocument.create();

        const sectionTitleElement = document.getElementById(
            `sectionTitle_${widgetId}`
        );

        const helveticaFont = await pdfDoc.embedFont(
            StandardFonts.HelveticaBold
        );

        const { width, height } = reportPdf.getPage(0).getSize();
        const firstPageDimensions: [number, number] = [width, height];

        const sectionTitlePdf = await this.addSectionTitleToPdf(
            sectionTitleElement,
            firstPageDimensions
        );

        // First page we add the section title to the pdf
        const combinedPage = pdfDoc.addPage(firstPageDimensions);
        combinedPage.drawPage(
            await pdfDoc.embedPage(sectionTitlePdf.getPage(0)),
            {
                x: 0,
                y: 0,
            }
        );

        reportPdf.getPages().forEach(async (page, index) => {
            const dimensions = page.getSize();

            // We add the first attachment page to the combined page (with section title)
            if (index === 0) {
                combinedPage.drawPage(
                    await pdfDoc.embedPage(page, {
                        left: dimensions.width * leftMargin,
                        right: dimensions.width,
                        bottom: dimensions.height * firstPageBottomMargin,
                        top: dimensions.height,
                    }),
                    {
                        xScale: scale,
                        yScale: scale,
                    }
                );
                this.addHeader(combinedPage, helveticaFont, pdfTemplateParams);
            }

            if (index > 0) {
                const newPage = pdfDoc.addPage([
                    dimensions.width,
                    dimensions.height,
                ]);
                newPage.drawPage(
                    await pdfDoc.embedPage(page, {
                        left: dimensions.width * leftMargin,
                        right: dimensions.width,
                        bottom: dimensions.height * bottomMargin,
                        top: dimensions.height,
                    }),
                    {
                        xScale: scale,
                        yScale: scale,
                    }
                );
                this.addHeader(newPage, helveticaFont, pdfTemplateParams);
            }
        });

        return pdfDoc;
    }

    public async addHeadersToPdf(
        doc: string | ArrayBuffer,
        pdfOptions?: PDFOptions,
        pdfTemplateParams?: PDFHeaderParams,
        widgetId?: string,
        narrativePosition?: string,
        finalY?: number,
        excludeHeaders?: boolean
    ): Promise<string | ArrayBuffer> {
        const pdfDoc = await PDFDocument.create();
        const reportPdf = await PDFDocument.load(doc);
        const pdfNarrative = await this.loadNarrativePdf(pdfOptions, widgetId);
        const helveticaFont = await pdfDoc.embedFont(
            StandardFonts.HelveticaBold
        );
        if (pdfNarrative) {
            return await this.addNarrativeToPDF(
                pdfNarrative,
                pdfDoc,
                reportPdf,
                helveticaFont,
                finalY,
                pdfTemplateParams,
                narrativePosition,
                pdfOptions
            );
        }

        const pages = reportPdf.getPages();

        for (let index = 0; index < pages.length; index++) {
            const page = pages[index];
            const newPage = pdfDoc.addPage(this.getPageSizes(pdfOptions));
            const autoTablePageEmbed = await pdfDoc.embedPage(page);
            newPage.drawPage(autoTablePageEmbed, {
                x: 0,
                y: 0,
            });
            if (!excludeHeaders) {
                // skip adding the header to the cover page or table of contents
                this.addHeader(newPage, helveticaFont, pdfTemplateParams);
            }
        }

        return await pdfDoc.saveAsBase64({ dataUri: true });
    }

    public async addNarrativeToPDF(
        pdfNarrative: PDFDocument,
        pdfDoc: PDFDocument,
        reportPdf: PDFDocument,
        helveticaFont: PDFFont,
        finalY?: number,
        pdfTemplateParams?: PDFHeaderParams,
        narrativePosition?: string,
        pdfOptions?: PDFOptions
    ) {
        if (narrativePosition === 'above') {
            const firstGridPage = await this.addNarrative(
                pdfNarrative,
                pdfDoc,
                pdfOptions,
                0
            );
            const autoTablePageEmbed = await pdfDoc.embedPage(
                reportPdf.getPages()[0]
            );
            firstGridPage.drawPage(autoTablePageEmbed, {
                x: 0,
                y: 0,
            });
            this.addHeader(firstGridPage, helveticaFont, pdfTemplateParams);
        }

        reportPdf.getPages().forEach(async (page, index) => {
            /**
             * If narrative position equals above and the report has more than 1 page
             * we added the headers in the statement above, so ignore the first page (index = 0)
             * else we add headers to all pages
             */
            const shouldAddHeaders =
                narrativePosition === 'above' && reportPdf.getPages().length > 1
                    ? index > 0 && index < reportPdf.getPages().length - 1
                    : index < reportPdf.getPages().length - 1;
            if (shouldAddHeaders) {
                const newPage = pdfDoc.addPage(this.getPageSizes(pdfOptions));
                const autoTablePageEmbed = await pdfDoc.embedPage(page);
                newPage.drawPage(autoTablePageEmbed, {
                    x: 0,
                    y: 0,
                });
                this.addHeader(newPage, helveticaFont, pdfTemplateParams);
            }
        });
        if (narrativePosition === 'below') {
            const lastGridPage = await this.addNarrative(
                pdfNarrative,
                pdfDoc,
                pdfOptions,
                Number(finalY) - 10
            );

            const autoTablePageEmbed = await pdfDoc.embedPage(
                reportPdf.getPages()[reportPdf.getPages().length - 1]
            );
            lastGridPage.drawPage(autoTablePageEmbed, {
                x: 0,
                y: 0,
            });
            this.addHeader(lastGridPage, helveticaFont, pdfTemplateParams);
        }

        return await pdfDoc.saveAsBase64({ dataUri: true });
    }

    public async addNarrative(
        pdfNarrative: PDFDocument,
        pdfDoc: PDFDocument,
        pdfOptions?: PDFOptions,
        marginY?: number
    ) {
        pdfNarrative.getPages().forEach(async (page, index: number) => {
            if (index === pdfNarrative.getPages().length - 1) {
                return;
            }
            const newPage = pdfDoc.addPage(this.getPageSizes(pdfOptions));
            const narrativePageEmbed = await pdfDoc.embedPage(page);
            newPage.drawPage(narrativePageEmbed, {
                x: 0,
                y: -this.mmToPoints(Number(marginY)),
            });
        });
        const lastPage =
            pdfNarrative?.getPages()[pdfNarrative.getPages().length - 1];
        const newPage = pdfDoc.addPage(this.getPageSizes(pdfOptions));

        const narrativePageEmbed2 = await pdfDoc.embedPage(lastPage);
        newPage.drawPage(narrativePageEmbed2, {
            x: 0,
            y: -this.mmToPoints(Number(marginY)),
        });
        return newPage;
    }

    private mmToPoints(mm: number) {
        // JSpdf returns mm, but pdf-lib uses points
        // 1 mm = 2.83465 points
        return mm * 2.83465;
    }

    public getPageSizes(pdfOptions?: PDFOptions): [number, number] {
        const pageSize =
            pdfOptions?.paperSize === 'Legal'
                ? PageSizes.Legal
                : PageSizes.Letter;
        return pdfOptions?.landscape ? [pageSize[1], pageSize[0]] : pageSize;
    }

    public async save(filename: string) {
        return this._merge.save(filename);
    }
}
