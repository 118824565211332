import { API_URL } from 'config/constants';
import { AttributeGroupableAndFilterableFromAPI } from 'contexts';

export async function getGroupableAndFilterableAttributes(): Promise<
    AttributeGroupableAndFilterableFromAPI[]
> {
    const response = await fetch(`${API_URL}/attributes/groupable-filterable`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();

    return data;
}
