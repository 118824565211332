import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getAttributes } from 'waypoint-requests';
import { EntityAttributesFromAPI } from 'contexts';

export const useGetAttributes =
    (): DataHookResponse<EntityAttributesFromAPI> => {
        const { data, error, mutate } = useSWR(
            `/api/attributes`,
            getAttributes,
            {
                revalidateOnFocus: false,
                revalidateOnMount: true,
            }
        );

        return {
            isLoading: !data,
            isError: !!error,
            data,
            mutate,
        };
    };
