import { isNull, isUndefined } from 'lodash';
import d3 from 'd3';
import { DASH_DASH } from 'config/constants';

export const doubleDash = '\u2014\u2014';
export const singleDash = '\u2014';
export const addCommas = d3.format(',');

export const numberIsInvalid = (val) => {
    return isNull(val) || isUndefined(val) || isNaN(val);
};

export const formatNumber = (val) => {
    return numberIsInvalid(val) ? doubleDash : addCommas(val);
};

export const formatNumberToDecimalPlace = (val, decimalPlaces = 2) => {
    return numberIsInvalid(val)
        ? doubleDash
        : addCommas(Number(val).toFixed(decimalPlaces));
};

export const waleFormat = (val, decimalPlaces = 1) => {
    return numberIsInvalid(val)
        ? doubleDash
        : Number(val).toFixed(decimalPlaces);
};

export const moneyFormatter = d3.format('$,.2f');

export const compactMoneyFormatter = d3.format('$,.3s');

export const formatMoney = (figure) => {
    return numberIsInvalid(figure) ? `$${DASH_DASH}` : moneyFormatter(figure);
};

export const compactFormatMoney = (figure) => {
    return numberIsInvalid(figure)
        ? `$${DASH_DASH}`
        : compactMoneyFormatter(figure).replace('G', 'B').replace('k', 'K');
};

export const formatPercentage = (val, decimialPlaces = 2) => {
    return numberIsInvalid(val)
        ? `${DASH_DASH}%`
        : `${val.toFixed(decimialPlaces)}%`;
};
