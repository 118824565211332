import { toArray } from 'lodash';
import { EntityAttributesFromAPI } from 'contexts';
import { API_URL } from 'config/constants';

export async function getAttributes(): Promise<EntityAttributesFromAPI> {
    const response = await fetch(`${API_URL}/attributes`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const {
        data: entities,
        metadata: { columns },
    } = await response.json();

    const finalColumns = toArray(columns);

    return { entities, attributes: finalColumns };
}
