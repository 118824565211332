import ReactFC from 'react-fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import fusiontheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import FusionCharts from 'fusioncharts';
import zoomline from 'fusioncharts/fusioncharts.zoomline';
import ReactFusioncharts from 'react-fusioncharts';
import { OccupancyHistoryProps, OvertimeType } from 'waypoint-types';
import {
    getOccupancyHistoryDataset,
    buildOccupancyHistoryChartConfig,
    getActualsDataset,
} from './utils';
import { Moment } from 'moment';

charts(FusionCharts);
fusiontheme(FusionCharts);
ReactFusioncharts.fcRoot(FusionCharts, charts, zoomline);

interface OccupancyTrendChartProps {
    occupancyHistory: OccupancyHistoryProps[];
    actualsData: OvertimeType[];
    periodRange: [Moment, Moment] | null;
    accountName: string;
    isDataLoading?: boolean;
    dataLoadError?: string;
    isPDFExport?: boolean;
    setIsChartReadyToExport?: (value: boolean) => void;
}

const OccupancyTrendChart = (props: OccupancyTrendChartProps) => {
    const {
        occupancyHistory,
        actualsData,
        periodRange,
        accountName,
        isPDFExport,
        setIsChartReadyToExport,
    } = props;
    if (!periodRange) {
        return <div></div>;
    }
    const startDate = periodRange[0];
    const endDate = periodRange[1];
    const chartConfig = buildOccupancyHistoryChartConfig(
        getOccupancyHistoryDataset(occupancyHistory, startDate, endDate),
        getActualsDataset(actualsData, startDate, endDate),
        accountName,
        isPDFExport
    );
    const onDrawComplete = () => {
        setIsChartReadyToExport && setIsChartReadyToExport(true);
    };
    return (
        <div
            style={{
                marginTop: -12,
            }}
        >
            <ReactFC
                {...chartConfig}
                data-test="occupancy-trend-chart"
                fcEvent-drawComplete={onDrawComplete}
            />
        </div>
    );
};

export default OccupancyTrendChart;
