import React, { useEffect, useState } from 'react';
import {
    SWOTAnalysis,
    SWOT_CARD_TYPE,
} from 'components/swotAnalysis/SWOTAnalysis';
import {
    HOLD_SELL_CARD_TYPE,
    HoldSellAnalysis,
} from 'components/holdSellAnalysis/HoldSellAnalysis';
import {
    AnnualPrioritiesAndObjectives,
    ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE,
} from 'components/annualPrioritiesAndObjectives/AnnualPrioritiesAndObjectives';
import {
    MarketOverview,
    MARKET_OVERVIEW_CARD_TYPE,
} from 'components/marketOverview/MarketOverview';
import theme from 'config/theme';
import { CommentsPaneWrapper } from 'waypoint-react/comment/CommentsPaneWrapper';
import { useGetMentionableUsers, useGetQueryParam } from 'waypoint-hooks';
import { Skeleton } from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import {
    LeasingGuidelines,
    LEASING_GUIDELINES_CARD_TYPE,
} from 'components/leasingGuidelines/LeasingGuidelines';
import {
    CONTRACTS_CARD_TYPE,
    ContractsContainer,
} from 'components/contracts/ContractsContainer';
import { TypeOfContractsView } from 'components/contracts/interfaces';
import LeasingPlanCard, {
    LEASING_PLAN_CARD_TYPE,
} from 'components/leasingPlan/LeasingPlanCard';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import PlanningCapitalProjectsContainer from 'components/planning/capital-projects/PlanningCapitalProjectsContainer';
import SubsectionMenu from './SubsectionMenu';
import { PLANNING_CAPITAL_PROJECTS, SUBSECTION_KEY } from './constants';
import { useGetReportMetadataByCommentId } from 'waypoint-hooks/data-access/useGetReportMetadataByCommentId';

interface StrategyPlanningSectionProps {
    entityCode: string;
    menuItems: ItemType[];
    onSetSubsection: (subsection: string) => void;
    hasCapitalTrackerEnabled: boolean;
}

const StrategyPlanningSectionWrapper = ({
    entityCode,
    menuItems,
    onSetSubsection,
    hasCapitalTrackerEnabled,
}: StrategyPlanningSectionProps) => {
    const userMentionOptions = useGetMentionableUsers([entityCode]);
    if (!userMentionOptions) {
        return <Skeleton></Skeleton>;
    }

    return (
        <CommentsPaneWrapper
            userMentionOptions={userMentionOptions}
            commentsPaneWrapperStyle={{
                border: `1px solid ${theme.colors.grays.background}`,
                borderLeft: 'none',
                zIndex: 10,
            }}
        >
            <StrategyPlanningSection
                entityCode={entityCode}
                menuItems={menuItems}
                onSetSubsection={onSetSubsection}
                hasCapitalTrackerEnabled={hasCapitalTrackerEnabled}
            />
        </CommentsPaneWrapper>
    );
};

const StrategyPlanningSection = ({
    entityCode,
    menuItems,
    onSetSubsection,
    hasCapitalTrackerEnabled,
}: StrategyPlanningSectionProps) => {
    const commentIdQueryParam = useGetQueryParam('comment_id');
    const subsectionQueryParam = useGetQueryParam(SUBSECTION_KEY);
    const defaultMenuKey =
        commentIdQueryParam && subsectionQueryParam
            ? subsectionQueryParam
            : (menuItems[0]?.key as string);
    const [menuSelection, setMenuSelection] = useState<string>(defaultMenuKey);

    const { data: reportMetadataForCommentId } =
        useGetReportMetadataByCommentId(commentIdQueryParam ?? undefined);

    const {
        update: updateCommentsPane,
        toggleComments,
        initializeComments,
    } = useCommentsPane();

    useEffect(() => {
        if (!reportMetadataForCommentId) {
            return;
        }
        toggleComments();
        initializeComments(reportMetadataForCommentId.comment_thread_id);

        updateCommentsPane({
            scrollToCommentId: commentIdQueryParam,
        });
    }, [reportMetadataForCommentId]);

    return (
        <>
            <SubsectionMenu
                menuItems={menuItems}
                setMenuSelection={setMenuSelection}
                onSetSubsection={onSetSubsection}
                defaultMenuKey={defaultMenuKey}
            />
            <div
                data-test="strategy-planning-section"
                style={{ padding: '0 24px 20px 24px', marginTop: '60px' }}
            >
                {hasCapitalTrackerEnabled &&
                    menuSelection === PLANNING_CAPITAL_PROJECTS && (
                        <div data-test="property-profile-capital-projects-section">
                            <PlanningCapitalProjectsContainer
                                entityCode={entityCode}
                                heightOffset={260}
                            />
                        </div>
                    )}
                {menuSelection ===
                    ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE && (
                    <div
                        style={{ marginTop: 20 }}
                        id={ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE}
                    >
                        <AnnualPrioritiesAndObjectives
                            entityCode={entityCode}
                        />
                    </div>
                )}
                {menuSelection === MARKET_OVERVIEW_CARD_TYPE && (
                    <div
                        id={MARKET_OVERVIEW_CARD_TYPE}
                        style={{ marginTop: 20 }}
                    >
                        <MarketOverview entityCode={entityCode} />
                    </div>
                )}
                {menuSelection === SWOT_CARD_TYPE && (
                    <div
                        data-test="swot-analysis-card"
                        id={SWOT_CARD_TYPE}
                        style={{ marginTop: 20 }}
                    >
                        <SWOTAnalysis entityCode={entityCode} />
                    </div>
                )}
                {menuSelection === HOLD_SELL_CARD_TYPE && (
                    <div
                        data-test="hold-sell-analysis-card"
                        id={HOLD_SELL_CARD_TYPE}
                        style={{ marginTop: 20 }}
                    >
                        <HoldSellAnalysis entityCode={entityCode} />
                    </div>
                )}
                {menuSelection === LEASING_GUIDELINES_CARD_TYPE && (
                    <div
                        data-test="leasing-guidelines-card"
                        id={LEASING_GUIDELINES_CARD_TYPE}
                        style={{ marginTop: 20 }}
                    >
                        <LeasingGuidelines entityCode={entityCode} />
                    </div>
                )}
                {menuSelection === CONTRACTS_CARD_TYPE && (
                    <div
                        data-test="contracts-card"
                        id={CONTRACTS_CARD_TYPE}
                        style={{ marginTop: 20 }}
                    >
                        <ContractsContainer
                            entityCodes={[entityCode]}
                            typeOfView={TypeOfContractsView.PropertyProfilePage}
                        />
                    </div>
                )}
                {menuSelection === LEASING_PLAN_CARD_TYPE && (
                    <div
                        data-test={LEASING_PLAN_CARD_TYPE}
                        id="leasing-plan-card"
                        style={{ marginTop: '20px' }}
                    >
                        <LeasingPlanCard
                            entityCode={entityCode}
                            isPropertyProfilePage={true}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default StrategyPlanningSectionWrapper;
