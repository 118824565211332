import { NativeEventInfo } from 'devextreme/events';
import dxCheckBox, { ValueChangedEvent } from 'devextreme/ui/check_box';
import { DataGrid } from 'devextreme-react';
import {
    Field,
    PivotGridDataSourceField,
} from 'devextreme/ui/pivot_grid/data_source';

export type CheckBoxEvent = NativeEventInfo<dxCheckBox, Event> &
    ValueChangedEvent;

export interface SummaryDisplayMode {
    text: string;
    value: PivotGridDataSourceField['summaryDisplayMode'];
}

/**
 * DevExtreme published a SummaryCell type in 21.1, and removed it in 21.2.
 *
 * This extracts the internal type of the first parameter of the
 * calculateSummaryValue function declared in the Field type
 */
export type PivotGridSummaryCell = Parameters<
    NonNullable<Field['calculateSummaryValue']>
>[0];

export type CrosstabDataGrid = DataGrid<DataRecord, 'account_mapping_codes'>;
export type CrosstabExpandedListElement = string[] | { [x: string]: any };

export interface CrosstabOptions {
    hideColumnSubtotals: boolean;
    showTotalsAtEndOfGroup: boolean;
    showInOutlineForm: boolean;
}

export enum CrosstabPeriod {
    YearQuarterMonth = 'YearQuarterMonth',
    YearMonth = 'YearMonth',
    QuarterMonth = 'QuarterMonth',
    Month = 'Month',
}

export interface DataRecord {
    LVL0CODE: string;
    LVL0NAME: string;
    LVL1CODE: string;
    LVL1NAME: string;
    LVL2CODE: string;
    LVL2NAME: string;
    LVL3CODE: string;
    LVL3NAME: string;
    LVL4CODE: string;
    LVL4NAME: string;
    ENTITY_CODE: string;
    DISPLAY_NAME: string;
    PERIOD_END: Date;
    PRIMARY_MODE: number;
    SECONDARY_MODE: number;
    VARIANCE: number;
    ATTRIBUTE: string;
}
