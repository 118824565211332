import React from 'react';
import { pick } from 'lodash';
import { DASH_DASH } from 'config/constants';
import { getDisplayTypeAbbreviation } from 'components/analytics/financialOverview/menu/displayType/utils';
import { formatMoney, formatPercentage } from 'utils/formatters';
import { Trend } from 'components/analytics/financialOverview/cards';
import { formatDateRangeForCopy } from 'waypoint-utils';
import { StatisticsCard } from 'waypoint-react';

const TotalCard = (props) => {
    const { data, loading, period, displayType, accountMappingName } = props;
    const gridProps = pick(props, ['data-grid', 'style']);
    const value = `${formatMoney(data.amount)} ${getDisplayTypeAbbreviation(
        displayType
    )}`;
    const { variance_is_good: trendIsPositive } = data;
    const metric = `Budget Variance ${
        trendIsPositive ? '+' : ''
    }${formatPercentage(data.variance_ratio * 100)}`;
    const subheading = formatDateRangeForCopy(period);

    return (
        <StatisticsCard
            {...gridProps}
            value={value}
            metric={<Trend metric={metric} trendIsPositive={trendIsPositive} />}
            subheading={subheading}
            loading={loading}
            action={props.onClick}
            title={accountMappingName || DASH_DASH}
            data-test="component-total-card"
        />
    );
};

export default TotalCard;
