import React from 'react';
import { Button, Dropdown, Tooltip } from 'antd';
import { DownOutlined, CloseCircleFilled } from '@ant-design/icons';
import { css } from 'emotion';
import { SavedConfiguration } from 'waypoint-types';
import { RowSelection } from 'components/financials/crosstab/CrosstabRowSelection';
import { RentRollColumnSelection } from 'components/leases/expirations/cards/rent-roll/utils';
import theme from 'config/theme';

interface SavedGridConfigSelectParams {
    savedConfigurationsData: SavedConfiguration[] | undefined;
    selectedConfiguration: SavedConfiguration | null;
    setSelectedConfiguration: (config: SavedConfiguration | null) => void;
    excludeUserConfigs?: boolean;
    crosstabDefaultConfigs?: TreeItem[];
    rentRollDefaultConfigs?: TreeItem[];
    crosstabRowSelection?: RowSelection;
    rentRollColumnSelection?: RentRollColumnSelection;
    setCrosstabRowSelection?: (value: RowSelection) => void;
    setRentRollColumnSelection?: (value: RentRollColumnSelection) => void;
    resetOptions?: () => void;
    customMenuLabels?: [string, string, string];
    isNarrativeTemplate?: boolean;
}

const noArrowClass = css`
    .ant-dropdown-menu-submenu-arrow-icon {
        display: none;
    }
`;

interface TreeItem {
    label: string;
    key: string;
}

interface TreeData {
    [x: string]: {
        label: string;
        children: TreeItem[];
    };
}

const SavedGridConfigSelect = ({
    savedConfigurationsData,
    selectedConfiguration,
    setSelectedConfiguration,
    excludeUserConfigs,
    crosstabDefaultConfigs,
    rentRollDefaultConfigs,
    crosstabRowSelection,
    setCrosstabRowSelection,
    rentRollColumnSelection,
    setRentRollColumnSelection,
    resetOptions,
    customMenuLabels,
    isNarrativeTemplate,
}: SavedGridConfigSelectParams) => {
    const onConfigSelected = (key: string) => {
        if (key === 'shared_configs' || key === 'user_configs') {
            return;
        }
        if (key === 'default_view') {
            setSelectedConfiguration(null);
            return;
        }
        const config = savedConfigurationsData?.find(
            (c: SavedConfiguration) => c.id === key
        );
        setSelectedConfiguration(config ?? null);
    };

    const onCrosstabRowSelection = (key: RowSelection) => {
        setSelectedConfiguration(null);
        resetOptions && resetOptions();
        setCrosstabRowSelection && setCrosstabRowSelection(key);
    };

    const onRentRollColumnSelection = (key: RentRollColumnSelection) => {
        setSelectedConfiguration(null);
        setRentRollColumnSelection && setRentRollColumnSelection(key);
    };

    const onClickItemChildren = (children: TreeItem, configType: string) => {
        if (configType === 'default_view') {
            if (isCrosstab) {
                onCrosstabRowSelection(children.key as RowSelection);
                return;
            }

            onRentRollColumnSelection(children.key as RentRollColumnSelection);
            return;
        }
        onConfigSelected(children.key);
    };

    const getFilteredItemChildren = (
        children: TreeItem[],
        configType: string
    ) => {
        const isDefaultViewAndNoSelection =
            configType === 'default_view' &&
            (isCrosstab || isRentRoll) &&
            !selectedConfiguration;

        if (isDefaultViewAndNoSelection) {
            return children.filter((child) => {
                if (isRentRoll && child.key !== rentRollColumnSelection) {
                    return true;
                }
                if (isCrosstab && child.key !== crosstabRowSelection) {
                    return true;
                }
                return false;
            });
        }

        return children.filter(
            (child) => child.key !== selectedConfiguration?.id
        );
    };

    const getChildrenMenu = (): TreeItem[] => {
        if (crosstabDefaultConfigs) {
            return crosstabDefaultConfigs;
        }

        if (rentRollDefaultConfigs) {
            return rentRollDefaultConfigs;
        }

        return [];
    };

    const isCrosstab = !!crosstabDefaultConfigs;
    const isRentRoll = !!rentRollDefaultConfigs;

    const getDefaultLabel = () => {
        if (customMenuLabels) {
            return customMenuLabels[0];
        }
        return isCrosstab || isRentRoll ? 'Default Views' : 'Default View';
    };

    const reduceInitializer = {
        default_view: {
            label: getDefaultLabel(),
            children: getChildrenMenu(),
        },
        user_configs: {
            label: customMenuLabels ? customMenuLabels[1] : 'My Saved Views',
            children: [],
        },
        shared_configs: {
            label: customMenuLabels ? customMenuLabels[2] : 'Shared Views',
            children: [],
        },
    };

    const treeData =
        savedConfigurationsData
            ?.filter((config: SavedConfiguration) => !config.deleted_at)
            .reduce((acc: TreeData, config: SavedConfiguration) => {
                const treeItem = {
                    label: config.name,
                    key: config.id,
                };
                if (config.reference_type === 'client') {
                    acc['shared_configs'].children.push(treeItem);
                    return acc;
                }
                acc['user_configs'].children.push(treeItem);
                return acc;
            }, reduceInitializer) ?? {};

    const allItems = Object.keys(treeData).map((configType) => {
        const menuItem = treeData[configType];

        // exclude current selection from menu; users can use the reset option for this case
        let filteredMenuItemChildren: TreeItem[] = getFilteredItemChildren(
            menuItem.children,
            configType
        );

        return {
            className: !filteredMenuItemChildren.length ? noArrowClass : '',
            key: configType,
            label: menuItem.label,
            onClick: () => onConfigSelected(configType),
            disabled:
                (!filteredMenuItemChildren.length &&
                    configType !== 'default_view') ||
                (!selectedConfiguration &&
                    !isCrosstab &&
                    !isRentRoll &&
                    configType === 'default_view'), // can't select default if already selected
            children: filteredMenuItemChildren.length
                ? filteredMenuItemChildren.map((c: TreeItem) => {
                      return {
                          label: c.label,
                          key: c.key,
                          onClick: () => onClickItemChildren(c, configType),
                      };
                  })
                : null,
        };
    });

    const getFilteredItems = () => {
        if (excludeUserConfigs) {
            return allItems.filter((item) => item.key !== 'user_configs');
        }
        if (isNarrativeTemplate) {
            return allItems.filter((item) => item.key !== 'default_view');
        }
        return allItems;
    };

    const items = getFilteredItems();

    const getTooltipText = () => {
        if (
            selectedConfiguration?.name &&
            selectedConfiguration.name.length > 30
        ) {
            return selectedConfiguration.name;
        }
        return null;
    };

    const getDisplayName = () => {
        if (selectedConfiguration) {
            return selectedConfiguration.name;
        }
        if (!!crosstabDefaultConfigs) {
            return (
                crosstabDefaultConfigs.find(
                    (config) => config.key === crosstabRowSelection
                )?.label ?? ''
            );
        }
        if (!!rentRollDefaultConfigs) {
            return (
                rentRollDefaultConfigs.find(
                    (config) => config.key === rentRollColumnSelection
                )?.label ?? ''
            );
        }
        return customMenuLabels ? customMenuLabels[0] : 'Default View';
    };

    const useAdjustedWidth = isCrosstab || isRentRoll || isNarrativeTemplate;

    return (
        <Tooltip title={getTooltipText()}>
            <Dropdown menu={{ items }} trigger={['click']}>
                <Button
                    style={{
                        textAlign: 'left',
                        alignItems: 'center',
                        flex: 0,
                        justifyContent: 'space-between',
                        height: '32px',
                        width: useAdjustedWidth ? '250px' : '200px',
                        padding: '0 15px',
                    }}
                    block
                    onClick={(e) => e.preventDefault()}
                >
                    <span
                        style={{
                            width: useAdjustedWidth ? '200px' : '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            marginTop: '4px',
                        }}
                    >
                        {getDisplayName()}
                    </span>
                    {isNarrativeTemplate &&
                    !!selectedConfiguration &&
                    !!resetOptions ? (
                        <CloseCircleFilled
                            style={{
                                verticalAlign: 'middle',
                                height: '28px',
                                color: theme.colors.grays.medium,
                            }}
                            onClick={() => resetOptions()}
                        />
                    ) : (
                        <DownOutlined
                            style={{ verticalAlign: 'middle', height: '25px' }}
                        />
                    )}
                </Button>
            </Dropdown>
        </Tooltip>
    );
};

export default SavedGridConfigSelect;
