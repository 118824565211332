import moment from 'moment';
import { toISO, toEndOfMonth, toStartOfMonth } from 'components/dates/utils';
import { GetReportMetadataCommentsParams } from 'waypoint-requests/report-metadata/getReportMetadataComments';
import { BALANCE_SHEET_REPORT_TYPE } from 'components/financials/balanceSheet/components/BalanceSheetProvider';
import { ComparisonFilterPayload } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';

interface PeriodType {
    startMonth: string;
    endMonth: string;
}

const getMonths = (startMonth: string, endMonth: string): string[] => {
    const start = moment(startMonth);
    const end = moment(endMonth);
    const months = [];
    while (start <= end) {
        months.push(toISO(toEndOfMonth(start)));
        start.add(1, 'month');
    }
    return months;
};

export const getRangeOfMonths = ({
    startMonth,
    endMonth,
}: PeriodType): string[] => getMonths(startMonth, endMonth);

export const getBalanceSheetMetadataRequestFilters = (
    periods: string[]
): ComparisonFilterPayload[] => {
    return [
        {
            periodicity: 'month',
            start_date: toStartOfMonth(moment.utc(periods[0])).format(
                'YYYY-MM-DD'
            ),
            end_date: periods[0],
            mode: 'actual',
            financial_year_ending: '',
        },
        {
            periodicity: 'month',
            start_date: toStartOfMonth(moment.utc(periods[1])).format(
                'YYYY-MM-DD'
            ),
            end_date: periods[1],
            mode: 'actual',
            financial_year_ending: '',
        },
    ];
};

export const getBalanceSheetMetadataRequestParams = (
    entityCode: string,
    periods: string[]
): GetReportMetadataCommentsParams => {
    return {
        referenceId: entityCode,
        referenceType: 'entity',
        reportType: BALANCE_SHEET_REPORT_TYPE,
        filters: getBalanceSheetMetadataRequestFilters(periods),
    };
};
