import { API_URL } from 'config/constants';

export const getCapexProjects = (entityCode) => {
    return fetch(`${API_URL}/properties/${entityCode}/capex`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', Accept: 'text/csv' },
    });
};

export const downloadCapex = (entityCode) => {
    const config = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    };

    const response = fetch(
        `${API_URL}/properties/${entityCode}/capex/download`,
        config
    );

    return response;
};
