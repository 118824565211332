import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import { DownloadButton } from 'waypoint-react';

const button = css`
    color: ${theme.colors.grays.medium};
    cursor: pointer;
    &:active {
        color: ${theme.colors.blues.primary};
    }
    &:hover {
        color: ${theme.colors.blues.primary};
    }
`;
const downloadButtonStyle = css`
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 20px !important;
`;
const downloadButtonBigStyle = css`
    margin: 3px 12px 4px 12px;
`;
/**
 * This is component is used mainly in the ChartTableSwitchCard. It contains two buttons: one for download and one that fires an onClick callback. The callback button is for general purposes and is commonly used to open a menu.
 */
const ActionButtons = ({
    onClick,
    loading,
    downloadCsv,
    downloading,
    buttonSize,
}) => {
    return (
        <React.Fragment>
            {downloadCsv && (
                <DownloadButton
                    className={
                        buttonSize === 'big'
                            ? downloadButtonBigStyle
                            : downloadButtonStyle
                    }
                    disabled={loading || downloading}
                    onClick={downloadCsv}
                />
            )}
            <i
                data-test-action-element
                className={`fa-solid fa-cog ${button}`}
                onClick={onClick}
                size={buttonSize === 'big' && 'lg'}
            />
        </React.Fragment>
    );
};

ActionButtons.propTypes = {
    downloadCsv: PropTypes.func,
    downloading: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    buttonSize: PropTypes.string,
};

export default ActionButtons;
