import React from 'react';
import { Button, ButtonProps } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { LocalReportItem } from 'waypoint-types';
import {
    componentVerifications,
    reportHasCorrectSettings,
} from '../ReportUtils';
import { Tooltip } from 'antd';

const widgetTypesWithNoSettings = [
    'attachment',
    'attributes',
    'executive_summary',
];

interface ReportWidgetSettingsButtonProps {
    reportItem: LocalReportItem;
    toggleIsDrawerOpen: (selectedReportItem: LocalReportItem) => void;
}

const BUTTON_STYLE = {
    marginLeft: 6,
    marginRight: 6,
};

const shouldShowAsDisabled = (
    reportItem: LocalReportItem,
    widgetTypesWithNoSettings: string[]
): boolean => {
    return (
        !reportItem.component ||
        widgetTypesWithNoSettings.includes(reportItem.component)
    );
};

const shouldSettingsBeChecked = (reportItem: LocalReportItem): boolean => {
    return Object.keys(componentVerifications).includes(
        reportItem.component ?? ''
    );
};

const ReportWidgetSettingsButton: React.FC<ReportWidgetSettingsButtonProps> = ({
    reportItem,
    toggleIsDrawerOpen,
}) => {
    const isDisabled = shouldShowAsDisabled(
        reportItem,
        widgetTypesWithNoSettings
    );
    const hasCorrectSettings = shouldSettingsBeChecked(reportItem)
        ? reportHasCorrectSettings(reportItem)
        : true;

    const buttonProps: Partial<ButtonProps> = {
        size: 'small',
        icon: <SettingOutlined />,
        style: BUTTON_STYLE,
        type: 'primary',
        disabled: isDisabled,
        onClick: () => toggleIsDrawerOpen(reportItem),
        danger: !hasCorrectSettings,
        'data-test': 'report-widget-settings-button',
    };

    return (
        <Tooltip title={hasCorrectSettings ? '' : 'Required Setting'}>
            <Button {...buttonProps}></Button>
        </Tooltip>
    );
};

export default ReportWidgetSettingsButton;
