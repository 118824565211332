import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { css } from 'emotion';

const avatarUploader = (height: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
    height: ${height};
    width: 100%;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    text-align: center;
    transition: border-color 0.3s;
    &:hover {
        border-color: #1890ff;
    }
    .ant-upload.ant-upload-select-picture-card {
        height: 100% !important;
        width: 100% !important;
    }
`;

interface UploadPropertyPictureProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    photoIsUploading: boolean;
    placeholderText?: string;
    height?: number;
}

const UploadPropertyPicture = ({
    handleChange,
    photoIsUploading,
    height,
    placeholderText = 'Click to Upload a Property Photo',
}: UploadPropertyPictureProps) => {
    return (
        <div
            data-test="upload-picture-component"
            className={avatarUploader(height ? `${height}px` : '100%')}
            id="upload-picture-picker"
        >
            <input
                type="file"
                disabled={photoIsUploading}
                id="avatar"
                name="avatar"
                accept="image/jpg, image/jpeg"
                onChange={handleChange}
                style={{
                    opacity: 0,
                    height: '100%',
                    width: '100%',
                    fontSize: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                }}
            />
            <div>
                {photoIsUploading ? (
                    <LoadingOutlined spin style={{ fontSize: 36 }} />
                ) : (
                    <CloudUploadOutlined style={{ fontSize: 36 }} />
                )}

                <div style={{ marginTop: 8 }}>
                    {photoIsUploading ? 'Uploading...' : placeholderText}
                </div>
            </div>
        </div>
    );
};

export default UploadPropertyPicture;
