import { PropertyDetailsProps, PropertyType } from '../../waypoint-types';
import { useEffect, useRef } from 'react';
import PropertyCard from './PropertyCard';
import { RouteUrls } from '../../routes/RouteConstants';

export const PropertyListItem = ({
    property,
    showPropertyPhotos,
    selectedPropertyId,
    leasesData,
}: {
    selectedPropertyId?: string;
    property: PropertyType;
    showPropertyPhotos: boolean;
    leasesData?: PropertyDetailsProps[];
}) => {
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (property.id === selectedPropertyId) {
            ref.current?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }, [ref, property, selectedPropertyId]);

    return (
        <li
            style={{
                marginTop: '12px',
            }}
            ref={ref}
        >
            <PropertyCard
                key={property.id}
                property={property}
                showPhoto={showPropertyPhotos}
                selected={property.id === selectedPropertyId}
                onPropertySelected={() =>
                    window.location.assign(
                        `#${RouteUrls.PROPERTY_PROFILE_DETAILS}?pureid=${property.id}`
                    )
                }
                leasesData={leasesData}
            />
        </li>
    );
};
